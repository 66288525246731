import { Professor } from "@/types";
import { fromFirestore, searchClient } from ".";
import { firestore, storage } from "../config/firebase.config";
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

const algoliaIndexName = "professors"
const collectionName = "users";
const ProfessorCollectionName = "professors/{0}"

class ProfessorService {
    async getById(professorId:string): Promise<Professor | null>{
        const docRef = doc(firestore, ProfessorCollectionName.replace("{0}", professorId));

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return fromFirestore<Professor>({
                ...docSnap.data()
            } as Professor);
        }
        return null;

        // try {
        //     const index = searchClient.initIndex(algoliaIndexName);
        //     const object = await index.getObject(professorId);
        //     console.log(object); 
        //     return object as Professor;
        // } catch (error) {
        //     console.error(error);
        //     return null;
        // }
        
        
    }
    async addToFavorites(idUser:string, idRefProfessor:string):Promise<boolean>{
        // const collectionRef = collection(firestore, collectionName.replace("{0}", idUser));
        const docRef = doc(firestore, collectionName,idUser);
        
        if (docRef == null) {
            console.error(`Error updating: users/${idUser}`);
            console.error(" Doesn't find User ");
            return false;
        }

        try {
            await updateDoc(docRef, {
                favorites:arrayUnion(idRefProfessor)
            });
            return true;

        } catch (error) {
            console.error(`Error updating: users/${idUser}`);
            console.error(`updating value: ${idRefProfessor}`);
            console.error(error);
            return false;
        }
    }

    async removeFromFavorites(idUser:string, idRefProfessor:string):Promise<boolean>{
        const docRef = doc(firestore, collectionName,idUser);
           
        if (docRef == null) {
            console.error(`Error updating: users/${idUser}`);
            console.error(" Doesn't find User ");
            return false;
        }

        try {
            await updateDoc(docRef, {
                favorites:arrayRemove(idRefProfessor)
                
            });
           
            return true;

        } catch (error) {
            console.error(`Error updating: users/${idUser}`);
            console.error(error);
            return false;
        }
    }

     async getListByUser(idUser:string): Promise<string[]>{
        const docRef = doc(firestore, collectionName, idUser);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data()
            if(userData && userData.favorites && userData.favorites.length > 0){
                return userData.favorites as string[]
            }else{
                console.log("Favorites field is empty or doesnt exist")
                return[]
            }
          
          
        } else {
          console.log("No such document!");
          return []
        }
    }
    async getFavorites(props:string[]):Promise<Professor[] | null>{
        
        
        try {
                const index = searchClient.initIndex(algoliaIndexName);
                const objects = await index.getObjects(props);
                const professors = objects.results as Professor[]
                return professors
            } catch (error) {
                    console.error(error);
                    return null;
            }

                // console.log("uidProps",uidsProfessors)
                // const collectionName= "professors"
                // const collectionRef = collection(firestore, collectionName)
                
                // const q = query(collectionRef, where("__name__", "in", uidsProfessors))
        
                // const querySnapshot= await getDocs(q);
                // console.log("coincidence",querySnapshot)
                // const professors: Professor[] = querySnapshot.docs.map((doc)=>{
                //     return doc.data() as Professor
                // })
                // console.log(professors)
                // return professors
            }
        async downloadCV (professorId:string, name: string){
            
            getDownloadURL(ref(storage, `professors/${professorId}/cv`))
              .then((url) => {
                // `url` is the download URL for 'images/stars.jpg'
            
                // This can be downloaded directly:
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = () => {
                  const blob = xhr.response;
                  const link = document.createElement("a");
                  const urlBlob = window.URL.createObjectURL(blob); // Convierte el blob en una URL
                  link.href = urlBlob;
                  link.setAttribute("download", `${name}-CV.pdf`); // Asigna el nombre del archivo
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link); // Limpiar el DOM
                  window.URL.revokeObjectURL(urlBlob); // Limpia la URL del blob
                };
                xhr.open('GET', url); // Abre la solicitud GET hacia la URL del archivo
                xhr.send(); // Enviar la solicitud
              })
              .catch((error) => {
                console.error("Error al obtener la URL de descarga:", error);
              });
        }
        }
        
        export default new ProfessorService(); 