import { auth, firestore } from "@/config/firebase.config";
import { GoogleAuthProvider, UserCredential, browserSessionPersistence, createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, setPersistence, signInWithEmailAndPassword, signInWithPopup, signOut, updateProfile } from "firebase/auth";
import {  ReactNode, createContext, useContext, useEffect, useState } from "react";
import { InstituteProps, User, UserAuthenticated, UserData } from "@/types";
import Swal from 'sweetalert2'
import { doc, getDoc } from "firebase/firestore";
import instituteService from "@/api/institute.service";

type UserCredentialWithoutOperationType = Omit<UserCredential, "operationType">;

interface UserCredentialAndFirestore extends UserCredentialWithoutOperationType{
    userData: UserData
    subscriptionLevel: number
    isTest?: boolean
}


interface AuthContextType {
    signUp: (user: User) => Promise<boolean>;
    login: (user: UserAuthenticated) => Promise<UserCredential>;
    loginWithGoogle: () => Promise<UserCredential>;
    logOut: () => void;
    getUserFromFirebase: (uid: string) => Promise<UserData | null>;
    user: UserCredentialAndFirestore | null;
    setUser: React.Dispatch<React.SetStateAction<UserCredentialAndFirestore | null>>;
    institute: InstituteProps | null
    setInstitute: React.Dispatch<React.SetStateAction<InstituteProps | null>>;
}
export const AuthContext = createContext<AuthContextType | undefined>(undefined)


export const AuthContextProvider = ({children}:{ children: ReactNode })=>{
    const [user, setUser] = useState<UserCredentialAndFirestore | null>(null)
    const [institute, setInstitute] = useState<InstituteProps | null>(null)
    // console.log(institute)
    const [loading, setLoading] = useState(true)
// console.log(user)
   
function signUp(userAuth: User): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        const showAlert = () => {
            Swal.fire({
                title: "Por favor, verifique el correo",
                icon: "info",
                footer: `Se envió un correo de verificación a: ${auth.currentUser?.email}`,
                timer: 2000,
            });
        };

        setPersistence(auth, browserSessionPersistence);
        createUserWithEmailAndPassword(auth, userAuth.email, userAuth.password)
            .then(async () => {
                const user = auth.currentUser;
                try {
                    if (user) {
                        await updateProfile(user, {
                            displayName: userAuth.displayName,
                        });

                        await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendWelcome",{
                            method:"POST",
                            headers: {
                              "Content-Type": "application/json",
                              },
                            body: JSON.stringify({
                              secret:import.meta.env.VITE_SECRET_EMAIL_KEY,
                              name: userAuth.displayName,
                              email: userAuth.email,  
                              role:"institutes",
                            })
                          })
                          

                        await sendEmailVerification(user);
                        showAlert();
                        console.log('Email verification sent!');
                        resolve(true); // Resuelve la promesa con éxito
                    }
                } catch (error) {
                    console.error('No se pudo obtener el usuario actual.');
                    reject(false); // Rechaza la promesa en caso de error
                }
            })
            .catch((error) => {
                console.error('Error en signUp:', error);
                reject(false); // Rechaza la promesa en caso de error en la creación del usuario
            });
    });
}

     function login (user: UserAuthenticated): Promise<UserCredential>{
        try{
            setPersistence(auth, browserSessionPersistence)
            return signInWithEmailAndPassword(auth, user.email, user.password)
        }catch (error){
            console.error(error)
            return Promise.reject(error)
        }
    }

    function loginWithGoogle(){
            const googleProvider = new GoogleAuthProvider();
            const signIn = signInWithPopup(auth, googleProvider);
            return signIn
        
    }

    function logOut(){
        signOut(auth)
        .then(()=>{
            console.log("sign-out successful")
        })
        .catch((error)=>{
            console.error(error)
        })
    }

    async function getUserFromFirebase(uid:string):Promise<UserData | null>{
        const docRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           const userData = docSnap.data()
        //    console.log("userData",userData)
           return userData as UserData
          
        } else {
          console.log("No such document!");
          return null
        }
    }

    async function getSubscriptionLevel(ref: string | undefined):Promise<number>{
        if(ref){

            const docRef = doc(firestore, ref)
            const docSnap = await getDoc(docRef)
    
            if (docSnap.exists()) {
               const instituteData = docSnap.data()
                const subscriptionLevel = instituteData.subscriptionLevel;
                return subscriptionLevel
            }
            return 0

        }
        
        return 0

    }

    async function getIsTest(ref: string | undefined):Promise<boolean>{
        if(ref){

            const docRef = doc(firestore, ref)
            const docSnap = await getDoc(docRef)
    
            if (docSnap.exists()) {
               const instituteData = docSnap.data()
                const isTest = instituteData.isTest??false;
                return isTest
            }
            return false
        }
        return false
    }

    
     useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, 
            async (currentUser)=>{
                if(currentUser){
                    const userDataFirebase = await getUserFromFirebase(currentUser.uid)

                    const subscriptionLevel = userDataFirebase? await getSubscriptionLevel(userDataFirebase.ref) : 0
                    const isTest = userDataFirebase? await getIsTest(userDataFirebase.ref) : false
                    if(userDataFirebase){

                        const instituteData = await instituteService.getById(userDataFirebase.institute)
                        setInstitute(instituteData)
                        const updatedUser: UserCredentialAndFirestore = {
                            user:currentUser,
                            providerId: currentUser.providerId,
                            userData: userDataFirebase,
                            subscriptionLevel: userDataFirebase.role === "Super Administrador" ? 3 : subscriptionLevel,
                            isTest: isTest,
                        };
                        setUser(updatedUser);                        
                    }

               }else{
                setUser(null)
                setInstitute(null)
               }
               setLoading(false)
            })
            return()=>{
                unsubscribe()
            }
    },[]);

    const value = {
        signUp,
        login,
        loginWithGoogle,
        logOut,
        getUserFromFirebase,
        user,
        setUser,
        institute,
        setInstitute
    }

    
    return(
        <AuthContext.Provider value={value}>
            {!loading && children }
        </AuthContext.Provider>
    )
}

export const useAuth = ():AuthContextType=>{
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthContextProvider");
      }
      return context;
}