import logo from '@/assets/LogoGU.svg'
import { useState } from 'react'
import Swal from 'sweetalert2'
import FacebookIcon from '@/assets/facebook-app-icon.svg';
import InstagramIcon from '@/assets/ig-instagram-icon.svg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './ForgotPassword.scss'
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '@/utilities';
// import { sendEmail } from '@/utilities/sendEmail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@/assets/wa-whatsapp-icon.svg'

export const ForgotPassword=()=> {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    
    const [values, setValues] = useState({
        email:""
    })

    const navigate = useNavigate()

    const handleSubmit = async (event:React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault()
        const showAlert=()=>{
          Swal.fire({
              title:'Por favor, revise su correo para recuperar su contraseña',
              icon: 'info',
              footer: 'Vuelve cuando tengas los permisos',
              timer: 10000,            
          })}
        try {
           //Enviar correo para recuperar contraseña
            showAlert();
        } catch (error) {
          console.error(error)
        }
      }

    const handlerChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const {value, name} = event.target
        setValues({
          ...values,
          [name]: value
        })
    }
    return (
      <Box sx={{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          margin:{xs:"0"}
      }}>
  
        <Box sx={{
          width:{xs:"100%", sm:"80%"},
          padding:"40px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          flexDirection:"column"
        }}>
  
        <Box sx={{width:"100%", marginTop:{xs:"5px", sm:"0"}}}>
  
          <Button 
          variant='contained'
              sx={{
                backgroundColor:colors.grey[100],
                color:colors.grey[900],
                display:"flex",
                textDecoration:"none",
                gap:"10px",
                border:`0.5px solid ${colors.grey[300]}`,
                padding:"10px 20px",
                borderRadius:"5px",
              }}
              onClick={()=>navigate("/login")}
            >
              <ArrowBackIcon/>
              Volver a inicio de sesión
            </Button>
        </Box>
               
            <Box sx={{
              width:{xs:"70%",sm:"15%"}, 
              height:"35%",
              maxHeight:"120px", 
              padding:"20px",
              maxWidth:"180px", 
              display:"flex", 
              justifyContent:"center",
              alignItems:"center"
              }}>
  
              <img src={logo} alt='logo' style={{width:"100px", height:"100px"}}/>
              <Box >
                    <Typography variant="h5" sx={{fontWeight:"bolder", color:"#002D82", lineHeight:"1"}}>Guía</Typography>
                    <Typography variant="h5" sx={{fontWeight:"bolder", color:"#007FC0"}}>Universitaria</Typography>
              </Box> 
            </Box>
  
            <Typography variant='h3'sx={{textAlign:"center", margin:"20px 0", color:colors.grey[100]}}>¿Ha olvidado su contraseña?</Typography>
  
            <Typography variant='h5'sx={{textAlign:"justify"}}>Por favor, ingresa el correo electrónico del cual quieres recuperar la contraseña, o en su defecto contáctanos. Estaremos atentos para servirte</Typography>
  
            <form onSubmit={handleSubmit} style={{display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center", gap:"20px"}}>
                
            <Box sx={{marginTop:"20px", width:{xs:"100%",}}} >
                <TextField
                  required
                  label="Correo Electrónico"
                  fullWidth
                  type="email"
                  name="email"
                  id='email'
                  value={values.email}
                  onChange={handlerChange}
  
                  sx={{color:colors.grey[100]}}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
              </Box>
              <Button variant="contained" type="submit" sx={{backgroundColor:colors.blueAccent[500], width:{xs:"100%", sm:"fit-content"}, fontSize:"16px"}}>Recuperar contraseña</Button>
            </form>
  
            <Box sx={{
              display:"flex",
              gap:"15px",
              justifyContent:"center",
              alignItems:"center",
              textAlign:"center",
              width:"100%",
              flexWrap:"wrap"
            }} >
                <Box sx={{
                  minWidth:"48%",
                  textAlign:"center",
                }}>
                <Typography variant='h5' sx={{textAlign:"center",display:"flex", alignItems:"center", justifyContent:"center", gap:"10px", margin:"20px 0", color:colors.grey[100]}}>
                    <img src={WhatsAppIcon} alt="Whatsapp-icon" width={20} height={20}/>
                    Contáctanos
                  </Typography>
                  <Typography variant='h5' ><a href='https://wa.me/573003621500' target='_blank' rel='noopener noreferrer'>+57 300 3621500</a> </Typography>
              </Box>

              <Box sx={{
                minWidth:"48%",
                textAlign:"center",
              }}>
                  <Typography variant='h5' sx={{textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center", gap:"10px",  margin:"20px 0", color:colors.grey[100]}}>  <MailIcon/> Escríbenos</Typography>
                  <a href="mailto:soporte@guiauniversitaria.net"><Typography variant='h5'>soporte@guiauniversitaria.net</Typography></a>
              </Box>
            </Box>
  
            <Typography variant='h4' sx={{textAlign:"center", margin:"20px 0", color:colors.grey[100]}}>Síguenos</Typography>
            <Typography variant='h5' sx={{textAlign:"justify", marginBottom:"20px"}}>Entérate de las últimas noticias, estadísticas e información que podemos proporcionar acerca de las Instituciones de Educación Superior en nuestras redes.</Typography>
  
            <Box sx={{
              width:"100%",
              maxWidth:"200px",
              display:"flex",
              justifyContent:"space-between"
            }} >
            <Link to="https://www.facebook.com/GuiaUniversitariaWeb"><img src={FacebookIcon} alt="Whatsapp-icon" width={20} height={20}/></Link>
              <LinkedInIcon sx={{color:"#007FC0", fontSize:"26px"}}/>
              <Link to="https://www.instagram.com/guia.universitaria/"><img src={InstagramIcon} alt="Whatsapp-icon" width={20} height={20}/></Link> 
            </Box>
  
            <Box sx={{
              width:"100%",
              maxWidth:"400px",
              display:"flex",
              justifyContent:"space-between",
              marginTop:"50px",
            }}>
              <Typography variant='h6' sx={{fontWeight:"500", cursor:"pointer"}}>Ayuda</Typography>
              <Typography variant='h6' sx={{fontWeight:"500", cursor:"pointer"}} onClick={()=>navigate("/login")}>Iniciar Sesión</Typography>
              <Typography variant='h6' sx={{fontWeight:"500", cursor:"pointer"}}>Privacidad</Typography>
            </Box>
        </Box>
      </Box>
    )
}
