import { Box, Grid, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import './AddNewIES.scss'
import HelpIcon from '@mui/icons-material/Help';
import { tokens } from '@/utilities';
import { useState } from 'react';

interface Props{
  formik: any
}

export const FormIESAdministrative = (props:Props) => {
  const {formik} = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  
  return (
    <Box sx={{
      display:"flex",
      flexDirection:"column",
      backgroundColor:colors.primary[400],
      padding:"20px 40px",
      borderRadius:"5px",
      marginTop:"20px",
      gap:"20px",
      width:"80%"
    }} >

    <Typography variant="h3" sx={{color:colors.grey[100]}}>Detalles Administrativos</Typography>
    
    <hr style={{width:"100%"}}/>

      <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat(2,1fr)",
        width:"100%",
        gap:"20px",
        justifyContent:"center",
        textAlign:"center",
        alignItems:"center",
      }}>

        <Grid item xs={12} sm={10}>
            <TextField
              fullWidth
              required
              label="Número de Identificación Tributaria"
              id="taxID"
              name="taxID"
              size="small"
              autoComplete="off"
              value={formik.values.taxID}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid>

        <Grid item xs={12} sm={10}>
          <Box sx={{display:"flex"}}>
          <TextField
              fullWidth
              required
              id="code"
              label="Código IES (SNIES)"
              variant="outlined"
              name="code"
              size="small"
              autoComplete="off"
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
            />
               <Tooltip
                  title={
                    <Typography variant='h5'>
                      Puedes consultar el código correspondiente en la siguiente página:{' '}
                      <a href="https://snies.mineducacion.gov.co/1778/w3-propertyvalue-67298.html?_noredirect=1" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                        <Typography variant='h5' sx={{color:colors.blueAccent[500], fontWeight:"bold"}}> https://snies.mineducacion.gov.co/1778/w3-propertyvalue-67298.html?_noredirect=1</Typography>
                       
                      </a>
                    </Typography>
                  }
                  arrow
                  onClose={handleTooltipClose}
                  open={isTooltipOpen}
                >
                  <IconButton onMouseEnter={handleTooltipOpen} >
                    <HelpIcon className="icon" />
                  </IconButton>
                </Tooltip>
            </Box>
        </Grid>

        <Grid item xs={12} sm={10}>
              <TextField
              id="email"                           
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email Admin IES"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid>

        <Grid item xs={12} sm={10}>
              <TextField
              id="webSite"
              name="webSite"
              value={formik.values.webSite}
              onChange={formik.handleChange}
              label="Sitio Web"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid>

      <Grid item xs={12} sm={10}>
              <TextField
              id="president"
              name="president"
              value={formik.values.president}
              onChange={formik.handleChange}
              label="Rector"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid>

        <Grid item xs={12} sm={10}>
              <TextField
              id="vicePresident"
              name="vicePresident"
              value={formik.values.vicePresident}
              onChange={formik.handleChange}
              label="Vice Rector"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid>
{/* 
        <Grid item xs={12} sm={10}>
              <TextField
              required
              id="headquarters"
              name="headquarters"
              value={formik.values.headquarters}
              onChange={formik.handleChange}
              label="Sede"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px" }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px" }
              }}
              />
        </Grid> */}
      </Box>
    </Box>
  )
}
