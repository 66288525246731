import { firestore, storage } from "@/config/firebase.config";
import { ImageProps } from "@/types";
import { calculateSize, convertBase64ToFile } from "@/utilities";
import { collection, doc, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  StorageReference,
} from "firebase/storage";
import Swal from "sweetalert2";

const storageName = "ies/{0}/{name}";
const metaDataMIME = {
  contentType: "image/jpeg",
};
const MAX_WIDTH = 320;
const MAX_HEIGHT = 180;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

const careerCollectionName = "/institutes/{0}/faculties/{1}/careers";

class ImagesService {
  async upload(instituteId: string, images: File[]): Promise<ImageProps[]> {
    // const storageRef = ref(storage, storageName.replace("{0}", instituteId))
    const promises: Promise<ImageProps[]>[] = [];

    images.map((file) => {
      const fileStorageRef = storageName
        .replace("{0}", instituteId)
        .replace("{name}", file.name);

      const storageRef = ref(storage, fileStorageRef);

      const compressedImagesPromise = this.optimizedImages(storageRef, file);
      promises.push(compressedImagesPromise);
    });
    try {
      const allUploadedImages = await Promise.all(promises);
      const uploadedImages = allUploadedImages.flat();
      // Swal.fire({
      //     title: 'Imágenes cargadas exitosamente',
      //     imageUrl: '../assets/LogoPNG.png',
      //     imageWidth: 100,
      //     imageHeight: 100,
      //     imageAlt: 'Logo',
      // })
      return uploadedImages;
    } catch (error) {
      console.error("Error uploading images:", error);
    }

    // return images.map((image) => {
    //     const fileStorageName = storageName
    //       .replace("{0}", instituteId)
    //       .replace("{name}", image.name);
    //     const url = `${fileStorageName}?alt=media&token=${image.name}`;

    //     return {
    //         title: image.name,
    //         url: url,
    //         alt: `Image - ${image.name}`,
    //       }
    // });
    return [];
  }

  async uploadCareersImages(
    instituteId: string,
    images: File[],
    facultyId: string,
    careerId: string
  ): Promise<ImageProps[]> {
    // const storageRef = ref(storage, storageName.replace("{0}", instituteId))
    const promises: Promise<ImageProps[]>[] = [];
    images.map((file, index: number) => {
      const fileStorageRef = `ies/${instituteId}/${facultyId}/${careerId}/image${index}.jpg`;
      const storageRef = ref(storage, fileStorageRef);

      const compressedImagesPromise = this.optimizedImages(storageRef, file);
      promises.push(compressedImagesPromise);
    });
    try {
      const allUploadedImages = await Promise.all(promises);
      const uploadedImages = allUploadedImages.flat();
      // alert("Todas las imagenes fueron cargadas")
      // Swal.fire({
      //     title: 'Imágenes cargadas exitosamente',
      //     imageUrl: '../assets/LogoPNG.png',
      //     imageWidth: 100,
      //     imageHeight: 100,
      //     imageAlt: 'Logo',
      // })
      return uploadedImages;
    } catch (error) {
      console.error("Error uploading images:", error);
    }

    // return images.map((image) => {
    //     const fileStorageName = storageName
    //       .replace("{0}", instituteId)
    //       .replace("{name}", image.name);
    //     const url = `${fileStorageName}?alt=media&token=${image.name}`;

    //     return {
    //         title: image.name,
    //         url: url,
    //         alt: `Image - ${image.name}`,
    //       }
    // });
    return [];
  }
  async updateImagesByCareerFirestore(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: ImageProps[],
    defaultImage?: ImageProps
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        iesImages: value,
        defaultImage: defaultImage,
      };

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async uploadFacultyImage(
    instituteId: string,
    images: File[],
    facultyId: string
  ): Promise<ImageProps[]> {
    // const storageRef = ref(storage, storageName.replace("{0}", instituteId))

    // console.log("imageess", images)
    // console.log(instituteId)
    // console.log(facultyId)
    const promises: Promise<ImageProps[]>[] = [];
    images.map((file, index) => {
      const fileStorageRef = `ies/${instituteId}/${facultyId}/image${index}.jpg`;
      const storageRef = ref(storage, fileStorageRef);
      const compressedImagesPromise = this.optimizedImages(storageRef, file);
      promises.push(compressedImagesPromise);
    });
    try {
      const allUploadedImages = await Promise.all(promises);
      const uploadedImages = allUploadedImages.flat();
      // alert("Todas las imagenes fueron cargadas")
      Swal.fire({
        title: "Imágenes cargadas exitosamente",
        icon: "success",
        timer: 2000,
      });
      return uploadedImages;
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    return [];
  }
  async updateImagesByInstituteFirestore(
    instituteId: string,
    value: ImageProps[],
    defaultImage?: ImageProps
  ): Promise<boolean> {
    const collectionRef = collection(firestore, "institutes");
    const docRef = doc(collectionRef, instituteId);

    if (docRef == null) {
      console.error(`Error updating: institutes/${instituteId}`);
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        images: value,
        defaultIesImage: defaultImage,
      };

      await updateDoc(docRef, updateData);
      return true;
    } catch (error) {
      console.error(`Error updating: institutes/${instituteId}`);
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }
  async uploadIesImages(
    instituteId: string,
    images: File[],
    prevImages?: ImageProps[],
    defaultImage?: ImageProps
  ): Promise<ImageProps[]> {
    const promises: Promise<ImageProps[]>[] = [];
    images.map((file, index) => {
      const fileStorageRef = `ies/${instituteId}/image${index}.jpg`;
      const storageRef = ref(storage, fileStorageRef);
      const compressedImagesPromise = this.optimizedImages(storageRef, file);
      promises.push(compressedImagesPromise);
    });

    try {
      const allUploadedImages = await Promise.all(promises);
      const uploadedImageStorage = allUploadedImages.flat();
      const imagesToUpload = prevImages
        ? [...prevImages, ...uploadedImageStorage]
        : uploadedImageStorage;

      let defaultImageFormatted;
      if (defaultImage?.url) {
        if (defaultImage?.url.startsWith("https://")) {
          defaultImageFormatted = defaultImage;
        } else {
          const fileStorageDefaultImageRef = `ies/${instituteId}/defaultImage.jpg`;
          const storageDefaultImageRef = ref(
            storage,
            fileStorageDefaultImageRef
          );
          const defaultImageFile = convertBase64ToFile(defaultImage) as File;
          const compressedImage = await this.optimizedImages(
            storageDefaultImageRef,
            defaultImageFile
          );
          defaultImageFormatted = compressedImage[0];
        }
      }
      this.updateImagesByInstituteFirestore(
        instituteId,
        imagesToUpload,
        defaultImageFormatted
      );
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    return [];
  }

  async urlsToFiles(urls: string[]) {
    const filePromises = urls.map(async (url, index) => {
      // Si la URL es una imagen en base 64
      if (/^data:image\/[a-z]+;base64,/.test(url)) {
        const base64Data = url.split(",")[1];
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const file = new File([uint8Array], "imagen.png", {
          type: "image/png",
        });
        return file;
      } else {
        // Si la URL es una web
        const response = new File([url], `archivo${index}.png`, {
          type: "image/png",
        });
        return response;
      }
    });
    const files = await Promise.all(filePromises);
    return files;
  }

  async updateImageFacultyFirestore(
    instituteId: string,
    facultyId: string,
    value: ImageProps[]
  ): Promise<boolean> {
    const facultyCollectionName = "/institutes/{0}/faculties";
    const collectionRef = collection(
      firestore,
      facultyCollectionName.replace("{0}", instituteId)
    );

    const docRef = doc(collectionRef, facultyId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        images: value,
      };

      await updateDoc(docRef, updateData);
      console.log(`Route: institutes/${instituteId}/faculties/${facultyId}`);
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async uploadFacultyLogo(
    instituteId: string,
    image: File,
    facultyId: string
  ): Promise<any> {
    // const storageRef = ref(storage, storageName.replace("{0}", instituteId))
    const fileStorageRef = `ies/${instituteId}/${facultyId}/logo.jpg`;
    const storageRef = ref(storage, fileStorageRef);

    try {
      const compressedImagesPromise = this.optimizedImages(storageRef, image);
      // console.log(compressedImagesPromise)
      return compressedImagesPromise;
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    return null;
  }

  async uploadFacultyDefaultImage(
    instituteId: string,
    image: File,
    facultyId: string
  ): Promise<any> {
    // const storageRef = ref(storage, storageName.replace("{0}", instituteId))
    const fileStorageRef = `ies/${instituteId}/${facultyId}/defaultImage.jpg`;
    const storageRef = ref(storage, fileStorageRef);

    try {
      const compressedImagesPromise = this.optimizedImages(storageRef, image);
      // console.log(compressedImagesPromise)
      return compressedImagesPromise;
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    return null;
  }

  async updateLogoFacultyFirestore(
    instituteId: string,
    facultyId: string,
    value: ImageProps
  ): Promise<boolean> {
    const facultyCollectionName = "/institutes/{0}/faculties";
    const collectionRef = collection(
      firestore,
      facultyCollectionName.replace("{0}", instituteId)
    );

    const docRef = doc(collectionRef, facultyId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        logo: value,
      };

      await updateDoc(docRef, updateData);
      console.log(`Route: institutes/${instituteId}/faculties/${facultyId}`);
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  private async optimizedImages(
    storageRef: StorageReference,
    file: File
  ): Promise<ImageProps[]> {
    return new Promise<ImageProps[]>((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onerror = function () {
        URL.revokeObjectURL(this.src);
        reject(new Error("Cannot load image"));
      };
      img.onload = function () {
        URL.revokeObjectURL((this as HTMLImageElement).src);
        // calculate new width and height
        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);

        //convert canvas to blob with indicated quality

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Error compressing image"));
              return;
            }

            //upload Blob to Firebase Storage
            const uploadTask = uploadBytesResumable(
              storageRef,
              blob,
              metaDataMIME
            );
            uploadTask.on(
              "state_changed",
              // (snapshot)=>{},
              null,
              (error) => {
                console.error(error);
                reject(new Error("Error uploading compressed image"));
              },

              async () => {
                try {
                  const downloadURL = await getDownloadURL(
                    uploadTask.snapshot.ref
                  );

                  // console.log('File available at', downloadURL);
                  const compressedImageProps: ImageProps = {
                    url: downloadURL,
                  };
                  // console.log(compressedImageProps)
                  resolve([compressedImageProps]);
                } catch (error) {
                  console.error("Error getting download URL:", error);
                  reject(new Error("Error getting download URL"));
                }
              }
            );
          },
          MIME_TYPE,
          QUALITY
        );
      };
    });
  }
}
export default new ImagesService();
