
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        display:"flex",
        justifyContent:"center",
        flexDirection: 'row',
        marginTop: 24,
        width:"100%",
        marginBottom: 24,

    },
    reportTitle:{
        color: "#007FC0",
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 16,
        fontFamily: 'Helvetica-Bold',
    }
  });

export const PDFTitle = ({title}:{title:string}) => (
    
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
  )

