import algoliasearch from "algoliasearch"
import { createInMemoryCache } from '@algolia/cache-in-memory';

const apiSearchKey = import.meta.env.VITE_SEARCH_API_KEY_ALGOLIA
const appId = import.meta.env.VITE_APPLICATION_ID_ALGOLIA

export const searchClient = algoliasearch(appId, apiSearchKey,{
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false })
})
