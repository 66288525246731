import { CareerProps, LocationProps } from "@/types";
import { firestore } from "../config/firebase.config";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  getDoc,
  setDoc,
  CollectionReference,
} from "firebase/firestore";
import { fromFirestore, toFirestore } from ".";

const careerCollectionName = "/institutes/{0}/faculties/{1}/careers";

class CareerService {
  async getAll(ref: string, active: boolean = true): Promise<CareerProps[]> {
    const collectionRef = collection(firestore, `${ref}/careers`);

    const q = query(collectionRef, where("active", "==", active));
    const querySnapshot = await getDocs(q);
    const careers: CareerProps[] = querySnapshot.docs.map((doc) => {
      return fromFirestore<CareerProps>({
        id: doc.id,
        ...doc.data(),
      } as CareerProps);
    });
    return careers;
  }

  // async getAllByStudyLevel(ref:string, studyLevel:string): Promise <CareerProps[]>{
  //     const collectionRef = collection(firestore,`${ref}/careers`)
  //     const
  // }

  async getById(
    instituteId: string,
    facultyId: string,
    careerId: string
  ): Promise<CareerProps | null> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return fromFirestore<CareerProps>({ ...docSnap.data() } as CareerProps);
    }
    return null;
  }

  async create(
    instituteId: string,
    facultyId: string,
    career: CareerProps,
    careerCode: string
  ): Promise<boolean> {
    try {
      const collectionPath = careerCollectionName
        .replace("{0}", instituteId)
        .replace("{1}", facultyId);
      const documentRef = doc(firestore, collectionPath, careerCode);

      await setDoc(documentRef, {
        ...toFirestore(career),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return true;
    } catch (error) {
      console.error("Error creating a new career", error);
      return false;
    }
  }
  async createCareerFromBulk(career: CareerProps) {
    try {
      if (career.ref) {
        const collectionRef = collection(firestore, career.ref);
        const docData = await addDoc(collectionRef, {
          // const docData = await setDoc(doc(firestore,career.ref) , {
          ...toFirestore(career),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        console.log(
          `Documento creado para carrera "${career.name}" con ID: ${docData.id}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateID(
    instituteId: string,
    facultyId: string,
    careerId: string,
    id: string
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        id: id,
      };

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${id}`);
      console.error(error);
      return false;
    }
  }

  async update(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: any
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      //convert String to Date
      const datesNames = ["inscriptions", "startYear", "startClass"];
      for (const [name, iValue] of Object.entries(value)) {
        if (datesNames.includes(name) && iValue) {
          let newDateValue = null;
          try {
            if (iValue instanceof Date) newDateValue = iValue;
            else newDateValue = new Date(iValue.toString());
            console.warn(
              `Start in try parse date ${name} with value ${iValue}`
            );
          } catch (error) {
            console.error(
              `Error in try parse date ${name} with value ${iValue}`
            );
            console.error(error);
          }
          value = {
            ...value,
            [name]: newDateValue,
          };
        }
      }

      const updateData = toFirestore({
        ...value,
        // code:value.code,
        // contactEmail: value.contactEmail,
        // contactPhone: value.contactPhone,
        // costCredit: value.costCredit,
        // credits: value.credits ,
        // currency: value.currency,
        // dualProgram: value.dualProgram,
        // duration: value.duration ,
        // exchange: value.exchange,
        // iesCharacter: value.iesCharacter,
        // inscriptions:value.inscriptions? new Date(value.inscriptions):  new Date(),
        // internationalAccreditation: value.internationalAccreditation,
        // levelStudy: value.levelStudy,
        // mandatoryInterships: value.mandatoryInterships,
        // mandatoryThesis: value.mandatoryThesis,
        // modalities: value.modalities,
        // name: value.name,
        // nationalAccreditation: value.nationalAccreditation,
        // periods: value.periods,
        // phone_code_country:value.phone_code_country || "Prefijo",
        // price: value.price,
        // schedule: value.schedule,
        // startClass: value.startClass ? new Date(value.startClass) : new Date(),
        // startYear:value.startYear ? new Date (value.startYear) : new Date(),
        // summerProgram: value.summerProgram,
        // webSite: value.webSite,
        updatedAt: new Date(),
      });

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async updateActiveStatus(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: boolean
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );

    const docRef = doc(collectionRef, careerId);
    if (docRef == null) {
      console.error(`Error updating: users/${careerId}`);
      console.error(" Doesn't find this user ");
      return false;
    }
    try {
      await updateDoc(docRef, {
        updatedAt: serverTimestamp(),
        active: value,
      }); // Add a new document with a generated id.
      return true;
    } catch (error) {
      console.error(`Error updating: users/${careerId}`);
      console.error(" Doesn't find this user ");
      return false;
    }
  }

  async updateHeadquarter(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: LocationProps[]
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        headquarter: value,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async updateProfileGraduated(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: string
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        profileGraduated: value,
        updatedAt: serverTimestamp(),
      };
      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async updateProgramDescription(
    instituteId: string,
    facultyId: string,
    careerId: string,
    value: string
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        description: value,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true;
    } catch (error) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${value}`);
      console.error(error);
      return false;
    }
  }

  async delete(
    instituteId: string,
    facultyId: string,
    careerId: string
  ): Promise<boolean> {
    const collectionRef = collection(
      firestore,
      careerCollectionName.replace("{0}", instituteId).replace("{1}", facultyId)
    );
    const docRef = doc(collectionRef, careerId);

    if (docRef.id == null) {
      console.error(
        `Error deleting: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error("Doesn't find Career ");
      return false;
    }

    try {
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error(
        `Error deleting: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(error);
      return false;
    }
  }

  private async updateMovedCareer (collectionRef:CollectionReference, careerId: string, instituteId:string, facultyId:string): Promise<boolean>{
    const docRef = doc(collectionRef, careerId);

    if (docRef == null) {
      console.error(
        `Error updating: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(" Doesn't find this career ");
      return false;
    }

    try {
      const updateData = {
        ref: `institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`,
      };

      await updateDoc(docRef, updateData);
      console.log(
        `Route: institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      return true
    } catch (error) {
      console.error(
        `institutes/${instituteId}/faculties/${facultyId}/careers/${careerId}`
      );
      console.error(`updating value: ${careerId}`);
      console.error(error);
      return false;
    }
  }

  async moveCareers(
    instituteId: string,
    fromFacultyId: string,
    toFacultyId: string,
    careers: CareerProps[],
    toFacultyName: string
  ): Promise<boolean> {
    try {
      const collectionPath = careerCollectionName
        .replace("{0}", instituteId)
        .replace("{1}", toFacultyId);
      const movePromises = careers.map(async (career) => {
        const documentRef = doc(firestore, collectionPath, career.code);

         await setDoc(documentRef, {
          ...toFirestore(career),
          status: "Moved",
          prevRef: career.ref,
          facultyName: toFacultyName,
          uidFaculty: toFacultyId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        const collectionRef = collection(
          firestore,
          collectionPath,
        );

        const updateCareer = await this.updateMovedCareer(collectionRef, career.code, instituteId, toFacultyId)

        if(career.id && updateCareer){
           this.delete(instituteId, fromFacultyId, career.id)
        }
        return { updateCareer };
      });
      const moveResults = await Promise.all(movePromises)
      const allSuccessful = moveResults.every((result) => result.updateCareer);

      return allSuccessful;

    } catch (error) {
      console.error("Error moving a  career", error);
      return false;
    }
  }
}
export default new CareerService();
