import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTheme } from '@mui/material';
import { tokens } from '@/utilities';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:"15px"
};

interface Props {
    email:string
    phoneNumber: number
    linkedIn?:string
    name:string
    age:Date
    gender:string
    phone_code:string
    nationality:string
}

export const ModalContact = ({email, phoneNumber,nationality, linkedIn, name, age, gender, phone_code }: Props)=> {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const birthDateFromFirebase = new Date (age)
  // const formattedBirthDate = new Date(birthDateFromFirebase).toISOString().split('T')[0];
  const currentDate = new Date()
  const ageDifference = currentDate.getTime() - birthDateFromFirebase.getTime()
    const ageInYears = Math.floor(ageDifference/(1000 * 60 * 60 * 24 * 365.25))
  const theme = useTheme()
  const colors = tokens(theme.palette.mode);

  return (
    <div>
        <Button onClick={handleOpen} variant='contained' 
        sx={{gap:"10px",  ":hover":{
                cursor:"pointer",
                backgroundColor: colors.blueAccent[500]
              }}}>
            <PersonAddIcon/>
            Conectar
        </Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
       >
        <Box sx={style}>
          <Box sx={{display:"flex", justifyContent:"space-between"}}>
            <Typography  variant="h3" color={colors.grey[100]}>
              Datos de {name}
            </Typography>
            <CloseIcon
                  onClick={handleClose}
                  sx={{
                  borderRadius:"50%",
                  color:colors.grey[100],
                  ":hover":{background:`${colors.grey[400]}`, scale:"1.2", cursor:"pointer"}
                  }}/>

          </Box>
          <Typography sx={{ mt: 2 }}>
            <strong>Correo :</strong> {email}
           </Typography>
          <Typography  sx={{ mt: 2 }}>
            <strong>Teléfono :</strong> {`(${phone_code ? phone_code : ""}) ${phoneNumber}`}
          </Typography>
          <Typography  sx={{ mt: 2 }}>
            <strong>Edad : </strong>{ageInYears}
          </Typography>   
          <Typography  sx={{ mt: 2 }}>
             <strong>Género :</strong> {gender}
          </Typography>
          <Typography  sx={{ mt: 2 }}>
            <strong>Nacionalidad :</strong>  {nationality}
          </Typography>
          <Typography  sx={{ mt: 2 }}>
            <strong> Redes: </strong>{linkedIn ?<a target='_blank' href={linkedIn} style={{textDecoration:"none", cursor:"pointer"}}>{linkedIn}</a> : "No registra datos"}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
