import { LocalStorageTypes } from "@/models";
import { InstituteProps } from "@/types";
import { getSessionStorage, setSessionStorage } from "@/utilities";
import {createSlice, current} from '@reduxjs/toolkit'

const initialState: InstituteProps[] = []

export const inActiveSlice = createSlice({
    name: LocalStorageTypes.INACTIVE,
    initialState: getSessionStorage(LocalStorageTypes.INACTIVE)? JSON.parse(getSessionStorage(LocalStorageTypes.INACTIVE) as string) : initialState,
    reducers:{
        addToInactiveList: (_, action)=>{
            setSessionStorage(LocalStorageTypes.INACTIVE, action.payload)
            return action.payload
        },

        removeFromInactiveList: (state, action)=>{
            const filteredState = current(state).filter((institute: InstituteProps)=> institute.id !== action.payload.id)
            setSessionStorage(LocalStorageTypes.INACTIVE, filteredState)
            return filteredState
        }        
    }
})

export const {addToInactiveList, removeFromInactiveList} = inActiveSlice.actions