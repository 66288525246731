import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SchoolIcon from '@mui/icons-material/School';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';




export const dataSliderSuperAdmin = [
    {
        name: "Institución",
        icon: <BusinessIcon/>,
        link: "institute",
        role: ["Administrador IES" ]
    },
    {
        name: "Analítica",
        icon: <DashboardIcon/>,
        link: "dashboard",
        role: ["Super Administrador", "Profesor","Administrador IES", "Administrador Facultad" ]
    },
    {
        name: "Profesores",
        icon: <FolderSharedIcon/>,
        link: "professors",
        role: ["Super Administrador", "Administrador IES", "Administrador Facultad" ]

    }, 
    {
        name: "Facultades",
        icon: <SchoolIcon/>,
        link: "faculties",
        role: ["Administrador IES"]
        
    },
    {
        name: "Carreras",
        icon: <SchoolIcon/>,
        link: "careers",
        role: ["Administrador Facultad"]

    },
    {
        name: "Usuarios",
        icon: <GroupIcon/>,
        link: "users",
        role: ["Super Administrador", "Administrador IES" ]

    },
    {
        name: "Instituciones",
        icon: <BusinessIcon/>,
        link: "institutes",
        role: ["Super Administrador"]

    },
    // {
    //     name: "Calendario",
    //     icon: <CalendarMonthOutlinedIcon/>,
    //     link: "calendar",
    //     role: ["Super Administrador", "Profesor","Administrador IES", "Administrador Facultad" ]

    // },
    {
        name: "Planes",
        icon: <WorkspacePremiumIcon/>,
        link: "plans",
        role: ["Super Administrador", "Administrador IES", "Administrador Facultad", "Profesor" ]

    },

]