import './DetailProfessor.scss'
// import SendIcon from '@mui/icons-material/Send';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import logo from '@/assets/LogoGU.svg';
import {  useNavigate, useParams } from 'react-router-dom';
import professorService from '@/api/professor.service';
import { useEffect, useState } from 'react';
import { Professor } from '@/types';
import { Box,  Button,  Typography, useTheme } from '@mui/material';
import { tokens } from '@/utilities';
// import { CardChat } from '@/components';
import { ModalContact } from '@/modals';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DateDifference from '@/api/dateAdapter';
import Swal from 'sweetalert2';


export const DetailProfessor = () => {
    const {id} = useParams<string>();
    const [professorData, setProfessorData]= useState<Professor | null>()
    // const {user}= useAuth()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate()

    // console.log(professorData)
   useEffect(()=>{
    const fetchProfessorData   = async()=>{
        try {
            if(id){
                const getProfessorData = await professorService.getById(id)
                setProfessorData(getProfessorData)
            }
        } catch (error) {
            console.error(error)
        }
    }
    if(id){
        
        fetchProfessorData()
    }
   },[id])

const handleDownload = async (professorId: string, name:string) => {
    await professorService.downloadCV(professorId, name)
  };
    
  return (
    <Box sx={{
        paddingRight:"20px",
        paddingBottom:"60px"
    }} className='detail-professor' >

        <Button 
        variant='contained'
        sx={{
          
               display:"flex",
               gap:"10px",
               borderRadius:"5px",
               marginTop:"20px",
               fontWeight:"regular"
              }}
              onClick={()=> navigate(-1)}
            >
                <ArrowBackIcon/>
                  Ver otros profesores
            </Button>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px"
            }}>
            <Box className='profile-detail'>
                <div className='banner-professor'>
                    <img src='https://images.unsplash.com/photo-1506765515384-028b60a970df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80' alt='banner-image'/>
                </div>
                
                <div className="info-detail">
                    <button className='image-profile'>
                        <img  src={professorData?.photoUrl || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBp5Jh1FS7gjMnHJhhV_QDcZvq6Gqkorglw&usqp=CAU"} alt="image-profile" />
                    </button>

                    <div className='text-detail'>
                        <div className="left-side-detail">
                            <Typography variant='h3'sx={{color:colors.grey[100], marginTop:"20px", textAlign:{xs:"center", md:"left"}}}>{professorData?.fullName}</Typography>
                            <Typography variant='h5'sx={{color:colors.grey[200], marginTop:"20px"}} className='description'>{professorData?.about}
                            </Typography>
                            {professorData?.personalData.location.country &&<Typography variant='h5'sx={{color:colors.grey[500], marginTop:"20px"}}>{professorData?.personalData.location.city} , {professorData?.personalData.location.country} </Typography>}
                        </div>

                        <div className="right-side-detail">
                            <Typography variant='h3'sx={{color:colors.grey[100], margin:"20px 0"}}>Nivel Académico</Typography>
                            {/* <ul>
                                {professorData?.education.map((institute, index)=>(
                                <li key={index}>
                                    <img src={institute.logo || logo} alt='University-image'/>
                                    <Typography variant='h4' sx={{color:colors.grey[200], textAlign:"justify"}}>{institute.institute}</Typography>
                                </li>
                               ))}                             
                            </ul>    */}
                                {professorData?.personalData.educationLevel &&<Typography variant='h5' sx={{color:colors.grey[200], textAlign:{xs:"left", sm:"right"},}}>{professorData?.personalData.educationLevel + " " + "en" + " " + professorData?.title}</Typography>}


                        </div> 
                    </div>
                </div>

                    <div className="buttons">
                        {professorData &&<ModalContact age={professorData.personalData.birthDate || ""} email={professorData.personalData.email || ""} phoneNumber={professorData.personalData.phone || 0} linkedIn={professorData.linkedinUrl}  name={professorData.fullName} gender={professorData.personalData.gender} phone_code={professorData.personalData.phone_code_country || ""} nationality={professorData.personalData.nationality}
                        />}
                        
                        {professorData?.cv ? (
                    
                            <Button variant='contained' sx={{width:"fit-content"}} onClick={() => handleDownload(professorData.uid, professorData.fullName)}>
                               Descargar CV
                            </Button>

                        )
                        : ( <Button variant='contained' sx={{width:"fit-content"}} 
                            onClick={()=>
                                Swal.fire({
                                          title: "El usuario aún no ha subido su curriculum",
                                          icon: "info",
                                          timer: 3000,
                                      })
                                }>
                              Descargar CV
                            </Button>)
                    }
                    
                    {/* <button className='btn-profile send'>
                         <li><SendIcon className="icon-send"/></li>
                        <span>Enviar Mensaje</span>
                        
                    </button> */}
                    {/* {id && professorData &&(
                        <CardChat user={user} id={id} professorData={professorData}/>

                    )}

                    <button className='btn-profile more'>
                        <span>Más</span>
                        <li><MoreHorizIcon/></li>
                        
                    </button>    */}
                </div>
            </Box>

        </Box>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px"
            }}>
            <div className='about-detail'>
                <Typography variant='h3'sx={{color:colors.grey[100], margin:"20px 0", marginTop:"40px"}}>Acerca de</Typography>
                <Typography variant='h5'sx={{color:colors.grey[200], marginBottom:"40px", textAlign:"justify"}}>{professorData?.about}</Typography>
            </div>
        </Box>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px"
            }}>
            <div className='experience-detail'>
                <Typography variant='h3'sx={{color:colors.grey[100], margin:"40px 0"}}>Experiencia laboral</Typography>
                <ul>
                    {professorData?.experience.map((company, index)=>(
                    <li key={index}>
                        <div className='companies'>
                            <div className='image-company'>
                                <img src={company.logo ||logo} alt="" />
                            </div>

                            <div className='company-experience'>
                                <div className='info-company'>
                                    <Typography variant='h5'sx={{color:colors.grey[100], marginBottom:"10px", fontWeight:"bolder"}}>{company.title}</Typography>
                                    <Typography variant='h5'sx={{color:colors.grey[200],textAlign:"justify"}}>{company.company}</Typography>
                                    <Typography variant='h5'sx={{color:colors.grey[200],textAlign:"justify"}}>
                                        <DateDifference startMonth={company.startDate} endMonth={company.endDate}/>
                                    </Typography>
                                    <Typography variant='h5'sx={{color:colors.grey[500],textAlign:"justify"}}>{company.location}</Typography>

                                </div>
                                <div className='job-description'>
                                    <Typography variant='h5'sx={{color:colors.grey[200],textAlign:"justify", margin:"20px 0"}}>{company.description}</Typography>
                                </div>
                            </div>
                        </div>
                    </li>

                    ))}
                </ul>
            </div>
        </Box>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px"
            }}>
            <div className='education-detail'>
                <Typography variant='h3'sx={{color:colors.grey[100], margin:"20px 0", marginTop:"40px"}}>Educación</Typography>
                <ul>
                    {professorData?.education.map((institute, index)=>(
                        <li key={index}>
                            <div className='educational-institutes'>
                                <div className='image-institute'>
                                    <img src={institute.logo || logo} alt="" />
                                </div>

                                <div className='education-info'>
                                        <Typography variant='h5'sx={{color:colors.grey[100], marginBottom:"10px", fontWeight:"bolder"}}>{institute.institute}</Typography>
                                        <Typography variant='h5'sx={{color:colors.grey[200],textAlign:"justify"}}>{institute.title}</Typography>
                                        <Typography variant='h5'sx={{color:colors.grey[200],textAlign:"justify"}}>
                                            <DateDifference startMonth={institute.startDate} endMonth={institute.endDate} isEducation={true}/>

                                        </Typography>
                                </div>
                            </div>
                        </li>
                    ))}

                </ul>
            </div>
        </Box>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px",
            border:"2px solid transparent",
            justifyContent:"center"
            
            }}>
            <Box className='areas-interest'>
               <Typography variant='h3'sx={{color:colors.grey[100], margin:"20px 40px", marginTop:"40px"}}>Áreas de interés</Typography>
               <ul >
                    {professorData?.areasOfInterest && professorData?.areasOfInterest.map((area, index)=>(
                        <li key={index}>
                            {area}
                        </li>
                    ))}
                </ul> 
            </Box>
        </Box>

        <Box sx={{
            backgroundColor:colors.primary[400],
            boxShadow:"0 0 0 1px rgb(0,0,0,0.08)",
            borderRadius:"0.8rem",
            marginTop:"20px", 
            // padding:{xs:"0 40px", md:"0"},
            border:"2px solid transparent",
            justifyContent:"center"
            }}>
            <Box className='languages'>
               <Typography variant='h3'sx={{color:colors.grey[100], margin:"20px 40px"}}>Idiomas</Typography>
               <ul>
                    {professorData?.personalData.languages ? professorData?.personalData.languages.map((language, index)=>(
                        <li key={index}>
                            {language}
                        </li>
                    ))
                    :""
                }
                </ul> 
            </Box>
        </Box>
    

</Box>
  )
   
  
}
