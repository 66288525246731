import { CareerProps } from "@/types";
import {
  characterType,
  convertToDate,
  currencies,
  modalitiesType,
  periodsType,
  scheduleType,
  studyLevelsType,
  SwitchButton,
  tokens,
} from "@/utilities";
import {
  Box,
  Button,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { EditableInput, EditableSelectInput } from "@/utilities/Components";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";

interface Props {
  premiumPlan: string;
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  isEditable: boolean;
}

export default function CareerInfo({
  premiumPlan,
  selectedCareer,
  updateCareer,
  isEditable,
}: Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [editableData, setEditableData] = useState<Partial<CareerProps>>({
    code: selectedCareer.code || "",
    costCredit: selectedCareer.costCredit || null,
    credits: selectedCareer.credits || null,
    currency: selectedCareer.currency || "",
    dualProgram: selectedCareer.dualProgram || false,
    duration: selectedCareer.duration || null,
    exchange: selectedCareer.exchange || false,
    iesCharacter: selectedCareer.iesCharacter || "",
    inscriptions: selectedCareer.inscriptions,
    internationalAccreditation:
      selectedCareer.internationalAccreditation || false,
    levelStudy: selectedCareer.levelStudy || "",
    mandatoryInterships: selectedCareer.mandatoryInterships || false,
    mandatoryThesis: selectedCareer.mandatoryThesis || false,
    modalities: selectedCareer.modalities || "",
    name: selectedCareer.name || "",
    nationalAccreditation: selectedCareer.nationalAccreditation || false,
    periods: selectedCareer.periods || "",
    price: selectedCareer.price || null,
    schedule: selectedCareer.schedule || "",
    startClass: selectedCareer.startClass,
    startYear: selectedCareer.startYear,
    summerProgram: selectedCareer.summerProgram || false,
  });

  const initialSwitchValues: Record<string, boolean> = {
    nationalAccreditation: Boolean(editableData.nationalAccreditation),
    internationalAccreditation: Boolean(
      editableData.internationalAccreditation
    ),
    exchange: !!editableData.exchange,
    dualProgram: !!editableData.dualProgram,
    summerProgram: !!editableData.summerProgram,
    mandatoryInterships: !!editableData.mandatoryInterships,
    mandatoryThesis: !!editableData.mandatoryThesis,
  };

  const [switchValues, setSwitchValues] = useState(initialSwitchValues);

  const handleCareerInfoChange = (
    event: React.ChangeEvent<any> | SelectChangeEvent<any>
  ) => {
    const { name, value } = event.target;

    setEditableData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (switchName: string) => {
    setSwitchValues((prevSwitchValues) => {
      const updatedSwitchValues = {
        ...prevSwitchValues,
        [switchName]: !prevSwitchValues[switchName],
      };

      setEditableData((prevValues) => ({
        ...prevValues,
        ...updatedSwitchValues,
      }));

      return updatedSwitchValues;
    });
  };

  async function handleCareerInfoSubmit() {
    try {
      const selectedCareerInfo = {
        code: selectedCareer.code || "",
        costCredit: selectedCareer.costCredit || null,
        credits: selectedCareer.credits || null,
        currency: selectedCareer.currency || "",
        dualProgram: selectedCareer.dualProgram || false,
        duration: selectedCareer.duration || null,
        exchange: selectedCareer.exchange || false,
        iesCharacter: selectedCareer.iesCharacter || "",
        inscriptions: selectedCareer.inscriptions,
        internationalAccreditation:
          selectedCareer.internationalAccreditation || false,
        levelStudy: selectedCareer.levelStudy || "",
        mandatoryInterships: selectedCareer.mandatoryInterships || false,
        mandatoryThesis: selectedCareer.mandatoryThesis || false,
        modalities: selectedCareer.modalities || "",
        name: selectedCareer.name || "",
        nationalAccreditation: selectedCareer.nationalAccreditation || false,
        periods: selectedCareer.periods || "",
        price: selectedCareer.price || null,
        schedule: selectedCareer.schedule || "",
        startClass: selectedCareer.startClass,
        startYear: selectedCareer.startYear,
        summerProgram: selectedCareer.summerProgram || false,
      };

      if (JSON.stringify(editableData) === JSON.stringify(selectedCareerInfo)) {
        setIsEditing(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (selectedCareer.id) {
        const data = {
          ...editableData,
        };
        await careersService.update(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          data
        );
        updateCareer(data, selectedCareer.id);
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditing(!isEditing);
    } catch (error) {
      console.error("Error updating information", error);
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: { xs: "flex", sm: "grid" },
        flexDirection: "column",
        gridTemplateColumns: {
          sm: "repeat(2,minmax(0,100%))",
          md: "repeat(3,minmax(0,100%))",
        },
        gap: "20px",
      }}
    >
      {isEditing ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "25px", sm: "25px" },
              display: "flex",
            }}
            onClick={handleCareerInfoSubmit}
            // disabled={emailError || phoneError}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "25px", sm: "25px" },

              display: "flex",
            }}
            onClick={() => setIsEditing(!isEditing)}
          >
            Editar
          </Button>
        </Box>
      )}

      <Typography
        sx={{
          textAlign: { xs: "left" },
          gridColumn: "1/-1",
          color: colors.grey[100],
          width: { xs: "70%", sm: "80%" },
          marginBottom: "20px",
        }}
        variant="h3"
      >
        {editableData.name}
      </Typography>

      {isEditing ? (
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Nombre del programa
          </Typography>
          <EditableInput
            value={editableData.name}
            name="name"
            type="text"
            isEditing={isEditing}
            onChange={handleCareerInfoChange}
          />
        </Box>
      ) : (
        ""
      )}

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Código del Programa
        </Typography>
        <EditableInput
          value={editableData.code}
          name="code"
          type="text"
          isEditing={isEditing}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Nivel de estudio del Programa
        </Typography>
        <EditableSelectInput
          value={editableData.levelStudy}
          name="levelStudy"
          type="text"
          isEditing={isEditing}
          data={studyLevelsType}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Modalidad del Programa
        </Typography>
        <EditableSelectInput
          value={editableData.modalities}
          name="modalities"
          type="text"
          isEditing={isEditing}
          data={modalitiesType}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Precio del Programa
        </Typography>
        <EditableInput
          value={editableData.price === -1 ? null : editableData.price}
          name="price"
          type="text"
          isEditing={isEditing}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Tipo de moneda
        </Typography>
        <EditableSelectInput
          value={editableData.currency}
          name="currency"
          type="text"
          isEditing={isEditing}
          data={currencies}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Créditos del Programa
        </Typography>
        <EditableInput
          value={editableData.credits}
          name="credits"
          type="text"
          isEditing={isEditing}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Duración del Programa
        </Typography>
        <EditableInput
          value={editableData.duration}
          name="duration"
          type="text"
          isEditing={isEditing}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Periodos del Programa
        </Typography>
        <EditableSelectInput
          value={editableData.periods}
          name="periods"
          type="text"
          isEditing={isEditing}
          data={periodsType}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Tipo de horario
        </Typography>
        <EditableSelectInput
          value={editableData.schedule}
          name="schedule"
          type="text"
          isEditing={isEditing}
          data={scheduleType}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Carácter de la institución
        </Typography>
        <EditableSelectInput
          value={editableData.iesCharacter}
          name="iesCharacter"
          type="text"
          isEditing={isEditing}
          data={characterType}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Costo del crédito
        </Typography>
        <EditableInput
          value={editableData.costCredit}
          name="costCredit"
          type="text"
          isEditing={isEditing}
          onChange={handleCareerInfoChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Año de inicio
        </Typography>
        {isEditing ? (
          <TextField
            required
            type="date"
            id="startYear"
            name="startYear"
            fullWidth
            size="small"
            autoComplete="off"
            variant="outlined"
            value={
              editableData.startYear
                ? convertToDate(editableData.startYear)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={handleCareerInfoChange}
            sx={{ color: colors.grey[100] }}
            InputLabelProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
            InputProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.startYear
              ? convertToDate(editableData.startYear).toISOString().slice(0, 10)
              : ""}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Inscripciones
        </Typography>
        {isEditing ? (
          <TextField
            required
            type="date"
            id="inscriptions"
            name="inscriptions"
            fullWidth
            size="small"
            autoComplete="off"
            variant="outlined"
            value={
              editableData.inscriptions
                ? convertToDate(editableData.inscriptions)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={handleCareerInfoChange}
            sx={{ color: colors.grey[100] }}
            InputLabelProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
            InputProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.inscriptions
              ? convertToDate(editableData.inscriptions)
                  .toISOString()
                  .slice(0, 10)
              : ""}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Inicio de clases
        </Typography>
        {isEditing ? (
          <TextField
            required
            type="date"
            id="startClass"
            name="startClass"
            fullWidth
            size="small"
            autoComplete="off"
            variant="outlined"
            value={
              editableData.startClass
                ? convertToDate(editableData.startClass)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={handleCareerInfoChange}
            sx={{ color: colors.grey[100] }}
            InputLabelProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
            InputProps={{
              style: { color: colors.grey[100], fontSize: "16px" },
            }}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.startClass
              ? convertToDate(editableData.startClass)
                  .toISOString()
                  .slice(0, 10)
              : ""}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Acreditación Nacional
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.nationalAccreditation}
            setChecked={() => handleSwitchChange("nationalAccreditation")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.nationalAccreditation ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Acreditación Internacional
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.internationalAccreditation}
            setChecked={() => handleSwitchChange("internationalAccreditation")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.internationalAccreditation ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Posibilidad de intercambio
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.exchange}
            setChecked={() => handleSwitchChange("exchange")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.exchange ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Doble Titulación
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.dualProgram}
            setChecked={() => handleSwitchChange("dualProgram")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.dualProgram ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Programa de verano
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.summerProgram}
            setChecked={() => handleSwitchChange("summerProgram")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.summerProgram ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Prácticas obligatorias
        </Typography>

        {isEditing ? (
          <SwitchButton
            checked={switchValues.mandatoryInterships}
            setChecked={() => handleSwitchChange("mandatoryInterships")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.mandatoryInterships ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Tésis o trabajo de grado obligatorio
        </Typography>
        {isEditing ? (
          <SwitchButton
            checked={switchValues.mandatoryThesis}
            setChecked={() => handleSwitchChange("mandatoryThesis")}
          />
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {editableData.mandatoryThesis ? "Si" : "No"}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Fecha de Actualización
        </Typography>
        <Typography variant="h5" color={colors.grey[200]}>
          {editableData.updatedAt?.slice(0, 10)}
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Fecha de Creación
        </Typography>
        <Typography variant="h5" color={colors.grey[200]}>
          {editableData.createdAt?.slice(0, 10)}
        </Typography>
      </Box>
    </Box>
  );
}
