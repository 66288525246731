import { InstituteProps } from "../types/institute.type";
import { firestore, storage } from "../config/firebase.config";
import { fromFirestore, toFirestore } from "./firestoreAdapter";
import { collection, doc, addDoc, getDocs, updateDoc, deleteDoc, query, where, serverTimestamp, getDoc } from "firebase/firestore";
import { ImageProps } from "@/types";
import { StorageReference, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { calculateSize } from "@/utilities";

const collectionName = "institutes";
const collectionRef = collection(firestore, collectionName);
const storageLogoName = "ies/{0}/logo.jpg"

const metaDataMIME = {
    contentType: 'image/jpeg'
}
const MAX_WIDTH = 320;
const MAX_HEIGHT = 300;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

interface SubscriptionData{
    // endDate:Date
    level:number
    // startDate:Date
}

class IntitiuteService {

    async getAll(active: boolean ): Promise<InstituteProps[]> {
        const q = query(collectionRef, where("active", "==", active));
        const querySnapshot = await getDocs(q);

        const institutes: InstituteProps[] = querySnapshot.docs.map((doc) => {
            //return data compatible with data types specified in the tasks variable 
            return fromFirestore<InstituteProps>({
                id: doc.id,
                ...doc.data()
            } as InstituteProps);
        });
        // Return the first institute with the searched code
        return institutes;
    }

    async getById(id: string): Promise<InstituteProps | null> {
        const docRef = doc(collectionRef, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const docId = docSnap.id;
            return fromFirestore<InstituteProps>({
                id: docId,
                ...docSnap.data()
            } as InstituteProps);
        }
        return null;
    }

    async getByCode(code: string): Promise<InstituteProps[]> {
        const q = query(collectionRef, where("code", "==", code));
        const querySnapshot = await getDocs(q);

        const institutes: InstituteProps[] = querySnapshot.docs.map((doc) => {
            //return data compatible with data types specified in the tasks variable 
            return fromFirestore<InstituteProps>({
                id: doc.id,
                ...doc.data()
            } as InstituteProps);
        });
        // Return the first institute with the searched code
        return institutes;
    }

    async create(institute: InstituteProps): Promise<string> {
        // Add a new document with a generated id.
        const docData = await addDoc(collectionRef, {
            ...toFirestore(institute),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        });
        return docData.id;
    }

    async update(id: string, value: any): Promise<boolean> {
        const docRef = doc(firestore, collectionName, id);

        if (docRef == null) {
            console.error(`Error updating: institutes/${id}`);
            console.error(" Doesn't find Insitute ");
            return false;
        }

        try {
            // Clone value object
            const newValue = { ...value };
            // Remove id from Insititute object
            // delete newValue['id'];
            // Set updatedAt field with Server timestamp
            newValue.updatedAt = serverTimestamp();
            await updateDoc(docRef, {
                ...newValue
            });// Add a new document with a generated id.
            return true;

        } catch (error) {
            console.error(`Error updating: institutes/${id}`);
            console.error(`updating value: ${value}`);
            console.error(error);
            return false;
        }
    }

    async delete(id: string): Promise<boolean> {

        const docRef = doc(firestore, collectionName, id);

        if (docRef.id == null) {
            console.error(`Error deleting: institutes/${id}`);
            console.error("Doesn't find Insitute ");
            return false;
        }

        try {
            await deleteDoc(docRef);
            return true;
        } catch (error) {
            console.error(`Error deleting: institutes/${id}`);
            console.error(error);
            return false;
        }
    }

    async setActive(id: string, active: boolean): Promise<boolean> {
        {
            const docRef = doc(firestore, collectionName, id);

            if (docRef == null) {
                console.error(`Error setting active: institutes/${id}`);
                console.error("Doesn't find Faculty");
                return false;
            }

            try {
                await updateDoc(docRef, {
                    active: active,
                    updatedAt: serverTimestamp()
                });// Add a new document with a generated id.
                return true;

            } catch (error) {
                console.error(`Error setting active: institutes/${id}`);
                console.error(`active value: ${active}`);
                console.error(error);
                return false;
            }
        }
    }

    async updateSubscriptioLevel(instituteId:string, data:SubscriptionData):Promise<boolean>{
        const collectionRef = collection(firestore,"institutes")
        const docRef = doc(collectionRef, instituteId)

        if(docRef === null){
            console.error(`Error updating: professors/${instituteId}`)
            return false
        }

        try {
           const updateData = {
            // 'subscription.endDate': data.endDate,
            // 'subscription.startDate': data.startDate,
            'subscriptionLevel': data.level,
           }
           await updateDoc(docRef, updateData)
           return true
        } catch (error) {
            console.error(`Error updating: professors/${instituteId}`);
            console.error(error);
            return false;
        }
    }

    async updateLogo(iesID:string, image: File):Promise<ImageProps | null>{
        try {
        const fileStorageRef = storageLogoName
        .replace("{0}", iesID)
        
        const storageRef = ref(storage, fileStorageRef);

            const uploadedImage = await this.optimizedImage(storageRef, image)
            alert("Imagen subida exitosamente");

            const collectionRef = collection(firestore,"institutes")
            const docRef = doc(collectionRef, iesID)       

            const updateData = {
                'logo': uploadedImage.url,
                'updatedAt': serverTimestamp()
            }
            await updateDoc(docRef, updateData)
            return uploadedImage
        } catch (error) {
            console.error('Error uploading image:', error);
            alert("Hubo un error cargando la imagen");

        }

        return null
    }
    
    async optimizedImage(storageRef: StorageReference, file:File): Promise<ImageProps>{
        return new Promise<ImageProps>((resolve, reject)=>{
            const img = new Image();
            img.src= URL.createObjectURL(file)
            img.onerror= function(){
                URL.revokeObjectURL(this.src);
                reject(new Error("Cannot load image"))
            }
            img.onload = function(){
                URL.revokeObjectURL((this as HTMLImageElement).src)

                // calculate new width and height
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT); 
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight

                const ctx =  canvas.getContext("2d")
                ctx?.drawImage(img, 0, 0, newWidth, newHeight)

                //convert canvas to blob with indicated quality

                canvas.toBlob(
                    (blob)=>{
                        if(!blob){
                            reject(new Error("Error compressing image"));
                            return;
                        }

                        //upload Blob to Firebase Storage
                        const uploadTask = uploadBytesResumable(storageRef, blob, metaDataMIME);
                        uploadTask.on(
                            'state_changed',
                            // (snapshot)=>{},
                            null,
                            (error)=>{
                                console.log(error)
                                reject(new Error("Error uploading compressed image"));
                            },
        
                            async()=>{
                                try {
                                     const downloadURL= await getDownloadURL(uploadTask.snapshot.ref)
                                   
                                    console.log('File available at', downloadURL);
                                    const compressedImageProps : ImageProps = {
                                        url: downloadURL
                                    }

                                    resolve(compressedImageProps)  
                                } catch (error) {
                                    console.error('Error getting download URL:', error);
                                    reject(new Error("Error getting download URL"));
                                }
                            }
                        )
                        
                    },
                    MIME_TYPE,
                    QUALITY
                )
            }
        })
    }
}

export default new IntitiuteService();
