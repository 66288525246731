import { useCallback, useContext, useEffect, useState } from "react";
import ComparativeAnalyticsComponent from "./comparativeAnalytics.component";
import { AuthContext, useAuth } from "@/utilities";
import institutesData from "@/api/JSON/InstitutesFresh.json";
import facultyService from "@/api/faculty.service";
import careersService from "@/api/careers.service";
import { OptionSelect } from "./comparativeAnalytics.model";

const dataJSON = JSON.parse(JSON.stringify(institutesData));

const ComparativeAnalyticsContainer = () => {
  const [searchTermIES, setSearchTermIES] = useState<string>("");
  const [selectedIES, setSelectedIES] = useState<string>("");
  const [isDropdownIESVisible, setIsDropdownIESVisible] = useState(false);

  const [selectedFaculty, setSelectedFaculty] = useState<string>("");
  const [isDropdownFacultiesVisible, setIsDropdownFacultiesVisible] = useState(false);
  const [searchTermFaculty, setSearchTermFaculty] = useState<string>("");
  const [faculties, setFaculties] = useState<OptionSelect[]>([]);

  const [selectedCareer, setSelectedCareer] = useState<string>("");
  const [isDropdownCareerVisible, setIsDropdownCareerVisible] = useState(false);
  const [searchTermCareer, setSearchTermCareer] = useState<string>("");
  const [careers, setCareers] = useState<OptionSelect[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dataCareer, setDataCareer] = useState<OptionSelect>();

  const [comparationData, setComparationData] = useState<any>();

  const authContext = useContext(AuthContext);

  const user = authContext?.user;
  const { institute } = useAuth();

  const userAdminValue = user?.userData.role ?? "";

  const filteredIESOptions = dataJSON.institutes
    .map((institute: any) => {
      const instituteCode = Object.keys(institute)[0];
      const instituteData = institute[instituteCode][1];
      if (instituteData && instituteData.name) {
        const label = `${instituteData.name} || ${instituteData.headquarters[0].city}`;
        return { value: instituteCode, label };
      }
      return null;
    })
    .filter((option: OptionSelect) => option !== null)
    .filter((option: OptionSelect) =>
      option.label.toLowerCase().includes(searchTermIES.toLowerCase())
    );

  const filteredFacultyOptions = faculties
    .filter((option: OptionSelect) => option !== null)
    .filter((option: OptionSelect) =>
      option.label.toLowerCase().includes(searchTermFaculty.toLowerCase())
    );

  const filteredCareersOptions = careers
    . filter((option: OptionSelect) => option !== null)
    .filter((option: OptionSelect) =>
      option.label.toLowerCase().includes(searchTermCareer.toLowerCase())
    );

  const resetDependentFields = (level: "ies" | "faculty" | "career") => {
    switch (level) {
      case "ies":
        setSelectedFaculty("");
        setSearchTermFaculty("");
        setFaculties([]);
        setIsDropdownFacultiesVisible(false);
        // setComparationData(null)
        resetDependentFields("faculty"); // Recursivo para limpiar niveles inferiores
        break;
      case "faculty":
        setSelectedCareer("");
        setSearchTermCareer("");
        setCareers([]);
        // setComparationData(null)
        setIsDropdownCareerVisible(false);
        break;
      case "career":
        // setComparationData(null)
        break;
      default:
        console.warn(`Unhandled reset level: ${level}`);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "ies" | "faculty" | "career"
  ) => {
    const value = e.target.value;
    // if(value.length <= 0 ) setComparationData(null)

    switch (type) {
      case "ies":
        setSearchTermIES(value);
        setIsDropdownIESVisible(!!value);
        if (!value){
          setSelectedIES(value);
          resetDependentFields("ies");
        } 
        break;
      case "faculty":
        setSearchTermFaculty(value);
        setIsDropdownFacultiesVisible(!!value);
        if (!value){
          setSelectedFaculty(value);
          resetDependentFields("faculty");
        } 
        break;
      case "career":
        setSearchTermCareer(value);
        setIsDropdownCareerVisible(!!value);
        break;
      default:
        console.warn(`Unhandled input type: ${type}`);
    }
  };

  const handleSelectChange = (
    selectedValue: OptionSelect,
    type: "ies" | "faculty" | "career"
  ) => {
    switch (type) {
      case "ies":
        const prevIesValue = selectedIES;
        setSelectedIES(selectedValue.value);
        setSearchTermIES(selectedValue.label);
        setIsDropdownIESVisible(false);
        if(selectedValue.value != prevIesValue)
          resetDependentFields("ies");
        break;
      case "faculty":
        const prevFacultyValue = selectedFaculty;
        setSelectedFaculty(selectedValue.value);
        setSearchTermFaculty(selectedValue.label);
        setIsDropdownFacultiesVisible(false);
        if(selectedValue.value != prevFacultyValue)
          resetDependentFields("faculty");
        break;
      case "career":
        if(selectedValue.value)
          setDataCareer(selectedValue);
        setSelectedCareer(selectedValue.value);
        setSearchTermCareer(selectedValue.label);
        setIsDropdownCareerVisible(false);
        break;
      default:
        console.warn(`Unhandled select type: ${type}`);
    }
  };

  const getFaculties = useCallback(async () => {
    try {
      const facultiesData =
        userAdminValue !== "Super Administrador"
          ? institute && (await facultyService.getAll(institute.id))
          : selectedIES && (await facultyService.getAll(selectedIES));

      if (facultiesData) {
        const formattedFaculties = facultiesData.map((faculty) => ({
          value: faculty.ref,
          label: faculty.name,
        }));
        setFaculties(formattedFaculties);
        setIsDropdownFacultiesVisible(true);
      }
    } catch (error) {
      console.error("Error trying to get faculties", error);
    }
  }, [selectedIES, institute, userAdminValue]);

  const getCareers = useCallback(async () => {
    try {
      const ref = `${user?.userData.ref}/faculties/${user?.userData.uidFaculty}`;
      const getCareersData =
        userAdminValue === "Administrador Facultad"
          ? ref && (await careersService.getAll(ref))
          : await careersService.getAll(selectedFaculty);

      if (getCareersData) {
        const formattedCareers = getCareersData.map((career) => {
          return {
            value: career.ref ?? "",
            label: `${career.name} || SNIES: ${career.code}`,
            code: career.code,
            institutionId: career.uidInstitution,
          };
        });
        setCareers(formattedCareers);
        setIsDropdownCareerVisible(true);
      }
    } catch (error) {
      console.error("Error trying to get careers", error);
    }
  }, [selectedFaculty, user, userAdminValue]);

  const getComparationData = useCallback(async () => {
    const code = dataCareer?.code;
    const institutionId = dataCareer?.institutionId;

    try {
      if (code && institutionId) {
        setIsLoading(true);
        await fetch(
          "https://us-central1-guia-universitaria-dev.cloudfunctions.net/getComparationTopByCareer",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              career_id: code,
              institute_id: institutionId,
              days: 30,
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setComparationData(data.flat());
            setIsLoading(false);
          });
      }
      setIsLoading(false)
    } catch (error) {
      console.error("Error trying to get getComparationData", error);
    }
  }, [dataCareer?.code, dataCareer?.institutionId]);

  useEffect(() => {
    void getFaculties();
  }, [getFaculties]);

  useEffect(() => {
    void getCareers();
  }, [getCareers]);

  return (
    <ComparativeAnalyticsComponent
      userType={userAdminValue}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      selectedIES={selectedIES}
      searchTermIES={searchTermIES}
      optionsIES={filteredIESOptions}
      optionsFaculties={filteredFacultyOptions}
      searchTermFaculty={searchTermFaculty}
      isDropdownIESVisible={isDropdownIESVisible}
      isDropdownFacultiesVisible={isDropdownFacultiesVisible}
      selectedFaculty={selectedFaculty}
      optionsCareers={filteredCareersOptions}
      searchTermCareer={searchTermCareer}
      isDropdownCareerVisible={isDropdownCareerVisible}
      selectedCareer={selectedCareer}
      comparationData={comparationData}
      careerLabel={searchTermCareer}
      getComparationData={getComparationData}
      isLoading={isLoading}
      dataCareer={dataCareer}
    />
  );
};

export default ComparativeAnalyticsContainer;
