import { CardProfessor } from "."
import { searchClient } from "@/api";
import { Professor } from "@/types/professor.type";
import { InstantSearch, SearchBox, Hits, Stats,  Pagination, Configure, ClearRefinements} from "react-instantsearch";
import './ProfessorList.scss'
import { ModalProfessorFilters } from "@/modals";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import professorService from "@/api/professor.service";
import { useAuth } from "@/utilities";
// import { useDispatch, useSelector } from "react-redux";
// import { AppStore } from "@/redux/store";
// import { addToProfessors } from "@/redux/states";

const indexName = "professors"

type HitProps = {
    hit:Professor
}

export function ProfessorList () {
    const {user} = useAuth()
    const uid = user?.user.uid
    const [userFavorites, setUserFavorites] = useState<string[]>([]);

    useEffect(() => {
        const fetchFavorites = async () => {
          if (uid) {
            const favorites = await professorService.getListByUser(uid);
            setUserFavorites(favorites);
          }
        };
    
        fetchFavorites(); 
      }, [uid]);
  
    const Hit = ({hit}:HitProps)=>{
        const navigate = useNavigate()
        const goToDetailPage = (objectID: string)=>{
            navigate(`/professor/${objectID}`)
          }
          console.log(hit)
    
        return (
            <div className="cards">
                {/* {hit.subscriptionLevel && hit.subscriptionLevel > 0 ? */}
                <CardProfessor 
                    onClick={()=>goToDetailPage(hit.objectID)}
                    bannerSrc=""
                    profileImgSrc= {hit.photoUrl?hit.photoUrl:"" }
                    name= {hit.fullName}
                    speciality= {hit.personalData.speciality}
                    educationLevel= {hit.personalData.educationLevel}
                    id={hit.objectID}
                    isFavorite={false}
                    className=""
                    userFavorites={userFavorites}
                    setUserFavorites={setUserFavorites}
                    />
                    {/* :""
                } */}
            </div>
          )
    }
    return (
        <Box sx={{justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center"}}>
            
                <InstantSearch 
                    searchClient={searchClient} 
                    indexName={indexName} 
                    routing={true}
                    insights={true}
                    
                >
                

                <Box className="filters">
                    <Configure 
                        hitsPerPage={10}
                        ruleContexts={[]}
                        />
                    
                    <Box className="searchbox">
                        <SearchBox placeholder='Busca una especialidad'/>
                        <Stats translations={{
                            rootElementText({ nbHits, processingTimeMS, nbSortedHits, areHitsSorted }) {
                            return areHitsSorted && nbHits !== nbSortedHits
                            ? `${nbSortedHits!.toLocaleString()} resultados relevantes ordenados de  ${nbHits.toLocaleString()} found in ${processingTimeMS.toLocaleString()}ms`
                            // : `${nbHits.toLocaleString()}  resultados encontrados en ${processingTimeMS.toLocaleString()}ms`;
                            // : `${nbHits.toLocaleString()}  resultados encontrados`
                            : `${nbHits.toLocaleString()}  resultados encontrados`
                            }
                        }}/>
                    
                    </Box>

                    <Box className="filters-list">
                            <ModalProfessorFilters />

                            <ClearRefinements 
                                style={{padding:"0", marginLeft:"20px"}}
                                classNames={{
                                    root: "clear",
                                    button: "btn-clear",
                                    disabledButton:"btn-clear-disabled"
                                }}
                                translations={{
                                    resetButtonText: 'Borrar filtros',
                                }}
                            />
                    
                    </Box>
                </Box>
                    <Hits hitComponent={Hit} />
                    <Box sx={{marginBottom:"20px",display:"flex", justifyContent:"flex-end",  width:"100%", paddingRight:"50px"}}>
                        <Pagination showLast/>  
                    </Box>
                </InstantSearch>

        </Box>
          
      )
}
