import React from 'react';
import './PanelFilters.scss';
import { Box, useTheme } from '@mui/material';
import { tokens } from '@/utilities';

interface Props {
    children: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
}

export function PanelFilters({ children, header, footer, }: Props) {
  
  const theme = useTheme()
  const colors = tokens(theme.palette.mode);
  
    return (
    <Box 
      sx={{
        backgroundColor:colors.primary[400],
      }}
      className="panel-filter" >
      {header && <div className="panel-header">{header}</div>}
      <div className="panel-body">{children}</div>
      {footer && <div className="panel-footer">{footer}</div>}
    </Box>
  );
}
