import { Box } from "@mui/material"
import PanelTabs from "../Dashboard/Sidebar/Tabs"
import { FormAddNewIES, Table } from ".."



export const TabsInstitute = () => {

    const data = [
        {
            name: "Activas",
            link: <Table active={true}/>
        },
        {
            name: "Inactivas",
            link: <Table active={false}/>
        },
        {
            name: "Crear Institución",
            link: <FormAddNewIES/>
        },
    
    ]
  return (
    <Box>
        <PanelTabs data={data}/>
    </Box>
  )
}
