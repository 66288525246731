import './AddNewIES.scss'
import { ChangeEvent, useState } from "react"
import imagesService from "@/api/images.service"
import { ImageProps } from '@/types'
import { Box, Grid, InputLabel, TextField, TextareaAutosize, Typography, useTheme } from '@mui/material'
import { tokens } from '@/utilities'
import Swal from 'sweetalert2'

interface Props{
  // formik: FormikProps<FormikValues>
  formik: any

}

export const FormIESImages = (props:Props) => {
  const {formik} = props

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [selectedImages, setSelectedImages] = useState<File[]>([]); 
  const [downloadURLs, setDownloadURLs] = useState<ImageProps[]>([]);
   console.log(downloadURLs)

  const handleImageChange =(event:ChangeEvent<HTMLInputElement>)=>{
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files) {
    const selectedFiles = Array.from(inputElement.files)
    setSelectedImages(selectedFiles)
    // setSelectedImages((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFiles])
  }
}
  const handleUploadImages = async ()=>{
    if (selectedImages.length === 0){
      Swal.fire({
        title:"Selecciona imágenes para subir.",
        icon:"info",
        timer:2000
      })
      return;
    }
    try {
      const instituteId  = formik.values.code
      const uploadedURLs = await imagesService.upload(instituteId , selectedImages);
      setDownloadURLs(uploadedURLs)
      Swal.fire({
        title:"Imágenes cargadas exitosamente",
        icon:"success",
        timer:2000
      })
    } catch (error) {
      console.error('Error uploading images:', error);
      Swal.fire({
        title:"Error cargando las imágenes. Por favor, intente nuevamente",
        icon:"error",
        timer:2000
      })
    }
  }
  return (
    <Box   sx={{
      display:"flex",
      flexDirection:"column",
      backgroundColor:colors.primary[400],
      padding:"20px 40px",
      borderRadius:"5px",
      marginTop:"20px",
      gap:"20px",
      width:"80%"
    }}>

      <Typography variant="h3" sx={{color:colors.grey[100]}}>Imágenes y Descripción</Typography>
      <hr style={{width:"100%"}}/>

      <Box sx={{
        display:"grid",
        gridTemplateColumns:"repeat(2,1fr)",
        width:"100%",
        gap:"20px",
        justifyContent:"center",
        textAlign:"center",
        alignItems:"center",
      }} >

          <Grid item xs={12} sm={10}>
            <InputLabel id="logo">Añadir logo institucional</InputLabel>
                <TextField
                fullWidth
                variant="outlined"
                type="file"
                id="logo"
                name="logo"
                value={formik.values.logo}
                inputProps={{
                  accept: "image/png, image/jpeg, image/webp",
                  onChange:handleImageChange
                  // onChange: (event:ChangeEvent<HTMLInputElement>) => {
                  //   const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                  //   formik.setFieldValue("logo", file); // Actualizamos el campo logo en el formulario de Formik.
                  // },
                }}
               
                />
              {/* <button onClick={handleUploadImage}>Upload</button> */}
              {/* <img src={url || ''} alt='firebase-img'/> */}
          </Grid>

          <Grid item xs={12} sm={10}>
              <InputLabel id="images">Añadir imágenes de portada</InputLabel>
                <TextField
                type="file"
                id="images"
                name="images"
                value={formik.values.images}
                onChange={handleImageChange}
                inputProps={{
                  accept: "image/png, image/jpeg, image/webp",
                  multiple:true   
                  }}
                />
              <button onClick={handleUploadImages}>Añadir Imagenes</button>
              {/* <img src={url || ''} alt='firebase-img'/> */}
          </Grid>

          <div className="input-textarea">
              <Typography variant="h4" color={colors.grey[100]}>Descripción  /  Misión</Typography>
                <TextareaAutosize
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Por favor, escriba una breve descripción o misión de la institución..."
                id="textarea"
                style={{
                  borderRadius: '5px',
                  borderColor: 'primary.main',
                  padding: '5px 10px',
                  width:"100%",
                  minWidth: '250px',
                  fontSize: 'medium',
                  fontFamily:"inherit",
                  minHeight: '200px',
                  whiteSpace: 'pre-wrap',
                  resize: 'vertical',
                }}  
                />
          </div>

          {/* <div className="buttons">
              <button type="submit" className="special-btns">Submit</button>
          </div> */}
              {/* <button onClick={()=>deleteProjects()} className="special-btn">Delete</button> */}
      </Box>
    </Box>
  )
}
