export function generateRandomPassword(length: number) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    return password;
  }

// export function generateRandomPassword(length: number) {
//   const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
//   let password = "";
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * charset.length);
//     password += charset.charAt(randomIndex);
//   }
//   return password;
// }

// function generateUniqueCodes(count: number, length: number) {
//   const uniqueCodes = new Set<string>();
//   const generatedCodes: string[] = [];

//   while (uniqueCodes.size < count) {
//     const firstPart = generateRandomPassword(length / 2);
//     const secondPart = generateRandomPassword(length / 2);
//     const code = `${firstPart}-${secondPart}`;
    
//     if (!uniqueCodes.has(code)) {
//       uniqueCodes.add(code);
//       generatedCodes.push(code);
//     }
//   }

//   return generatedCodes;
// }

// // Llama a esta función para generar 100 códigos únicos de 8 caracteres con un guión en el medio
// const generatedCodes = generateUniqueCodes(100, 8);
// console.log(generatedCodes);