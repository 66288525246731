import { firestore } from "@/config/firebase.config";
import { LocationProps } from "@/types";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";


interface DataInstitute{
    ref:string
    name:string
    email:string
    phone?:number | null
    phone_code?:string | null
    institute:string | null
    faculty?:string
    uidInstitute:string | null
    uidFaculty?:string
    plan:string
    role:string
    headquarter: LocationProps[]
    userId?:string
}



class WaitingList{

    async createByInstitute(data: DataInstitute){
        try {
            
            const collectionName = "/iesWaitingList"
            const collectionRef = collection(firestore, collectionName
                )
            const docData = await addDoc(collectionRef,{
                ...data,
                createdAt: serverTimestamp(),
            })
            return docData.id;
        } catch (error) {
            console.error(error)
        }
    }


}
export default new WaitingList()
