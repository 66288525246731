import { Box } from "@mui/material"
import { FormatUploadCareers, PanelTabsCareers, TableCareers } from "."
import { FormCreateCareer } from ".."
import { useAuth } from "@/utilities"
import { useState } from "react"


export const TabsCareers = () => {
    const {user} = useAuth()
    const facultyId = user?.userData.faculty
    const role = user?.userData.role
    
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (tab:number) => {
      setSelectedTab(tab);
    };  

    const data = [
        {
            name: "Activas",
            link: <TableCareers state={true}/>
        },
        {
            name: "Inactivas",
            link:<TableCareers state={false}/>
        },
        {
          name: "Crear Carrera",
          link: <FormCreateCareer facultyId={facultyId} handleChangeTab={handleChangeTab}/>
        },
        {
          name: "Subir Carreras",
          link: <FormatUploadCareers />
        },
    
    ]
    const dataIES = [
      {
        name: "Activas",
        link: <TableCareers state={true}/>
    },
    {
        name: "Inactivas",
        link:<TableCareers state={false}/>
    },
    {
      name: "Crear Carrera",
      link: <FormCreateCareer  handleChangeTab={handleChangeTab}/>
    },
    ]
  return(
    <Box>

    <PanelTabsCareers data={role === "Administrador IES"? dataIES : data} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
    </Box>
  )
}
