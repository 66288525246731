import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


interface Data {
  name: string
  link: React.ReactNode
}
interface Props {
    data: Data[]
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
}

export default function PanelTabsFaculties({data, selectedTab, setSelectedTab}: Props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedTab(newValue)
  };

  React.useEffect(() => {
    setValue(selectedTab); // Reset the sub tab value whenever a new main tab is selected
}, [selectedTab]);

  return (
    <Box sx={{ 
      width: '100%',
      display:"flex",
      flexDirection:"column",
    
      }}>
      <Box sx={{ 
        width: '100%',
        marginBottom:'10px', 
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
          style={{
            fontWeight:"bolder"
          }}
        >
          {data.map((element, index) => (
            <Tab 
              key={index} 
              value={index} 
              label={element.name} 
              style={{
                fontFamily:  ['Roboto', 'system-ui', '-apple-system', 'BlinkMacSystemFont', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', "sans-serif", 'Source Sans Pro'].join(","),
                fontWeight:"bold"
              }}
              />
            ))}
        </Tabs>
      </Box>

      <Box sx={{
        width: "100%",
       }}>
        {data[value].link}
      </Box>
 
    </Box>
  );
}