import { useState } from "react"
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import logo from '@/assets/Logo_R.png'
import './Login.scss'
import {  mapAuthCodeToMessage, tokens, useAuth } from "@/utilities";
import { Box, Button, InputLabel, TextField, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { FirebaseError } from "firebase/app";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FirebaseError } from "firebase/app";
import userAdminService from "@/api/userAdmin.service";

const recoverPassword = "/recover&password"

export const Login = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode);
  
  const {login, logOut} = useAuth()
  const [userLogin, setUserLogin]= useState({
    email: "",
    password:"",
  })
  
  const [isRegistering, setIsregistering] = useState(false)
  const navigate =useNavigate()
  
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const [emailError, setEmailError] = useState(false);

    const isValidEmail = (email:string) => {
      const lowerCaseEmail = email.toLowerCase();
      return lowerCaseEmail.includes('.edu') || lowerCaseEmail.includes('guiauniversitaria.net');
    };
     
  // const {login, loginWithGoogle, loginWithFacebook,loginWithGithub, logOut} = useAuth()

  const handleSubmit = async (event:React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault()
    const showAlert=()=>{
      Swal.fire({
          title:'¡Bienvenido!',
          icon: 'success',
          showConfirmButton: false, 
          allowOutsideClick: false, 
          allowEscapeKey: false, 
          timer: 2000,            
      })}
    try {
       const logged = await login(userLogin)
      if(logged.user.emailVerified){
        if (logged) {
         showAlert();  
         setTimeout(() => {
           navigate('/dashboard');
         }, 2000); 
       }
      } else if(logged.user.email?.toLowerCase().endsWith("@guiauniversitaria.net")){
        showAlert();  
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000); 
      }else{
        Swal.fire({
          title: "Por favor, verifica tu correo para continuar",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#DOD2D2",
          confirmButtonText: "Reenviar verificación"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await userAdminService.resendVerificationEmail(logged.user)
            Swal.fire({
              title: "¡Correo enviado!",
              text: "Revisa tu bandeja de entrada para verificar tu correo.",
              icon: "success"
            });
          }
        });
        logOut()
               
      }     

    } catch (error:unknown) {
      // showAlertWrong()
    if (error instanceof FirebaseError) {
      console.log(error.code)
      const errorMessage = mapAuthCodeToMessage(error.code);
      Swal.fire({
        title:errorMessage,
        icon: 'error',
        footer: 'Intenta otra vez',
        timer: 4000,            
    })
    }
    }
  }

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const UserUid = await loginWithGoogle();   // uid  = UserUid.user.uid
  //     // const data = {Nickname: UserUid.user.displayName , Img : UserUid.user.photoURL}
  //     console.log(UserUid.user.uid) 
  //     // sendProfileDetails(data, UserUid.user.uid) 
  //     navigate("/profile");
  //   } catch (error) {
  //     if (error instanceof FirebaseError) {
  //       console.error(error)
  //       const errorMessage = mapAuthCodeToMessage(error.code);
  //       Swal.fire({
  //         title:errorMessage,
  //         icon: 'error',
  //         footer: 'Intenta otra vez',
  //         timer: 4000,            
  //     })
  //    }
  //   }
  // };

  const handlerChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const {value, name} = event.target
    if (name === 'email') {
    setUserLogin({
      ...userLogin,
      [name]: value
    })
    setEmailError(!isValidEmail(value));
  }
  setUserLogin({
    ...userLogin,
    [name]: value
  })

  }
  return (
    <>
      {isRegistering?
        navigate("/register")
        :

        <Box sx={{
          maxHeight:"100vh",
          gap:{xs:"0"},
          display:"flex",
          flexDirection:"column",
          width:"100%",
          height:"100vh",
          overflowY:"scroll"
         }}>

          <Box sx={{
              width:"100%",
              display:"flex",
              position:{xs:"relative"},
              justifyContent:{xs:"center", sm:"flex-start"},
              padding:"0 40px",
              marginTop:"30px",
              marginBottom:{xs:"30px", md:'0'}
              }}
            >
               <Link to='https://guiauniversitaria.net/'
                  style={{
                    backgroundColor:"#3F51B5",
                    color:"white",
                    display:"flex",
                    alignItems:"center",
                    textDecoration:"none",
                    gap:"10px",
                    padding:"10px 20px",
                    borderRadius:"5px",
                    fontSize:"16px"

                  }}
                >
                  <ArrowBackIcon/>
                  <Typography variant="h5" sx={{color:"white"}}> Volver a Guía Universitaria</Typography>
                </Link>
            </Box>
            
          <div className="login">
            <div className="left-side-login">
              <form onSubmit={handleSubmit} className="form-login">
              <Box sx={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
              <img
                src={logo}
                alt="logo"
                />
                {/* <Box >
                  <Typography variant="h5" sx={{fontWeight:"bolder", color:"#002D82", lineHeight:"1"}}>Guía</Typography>
                  <Typography variant="h5" sx={{fontWeight:"bolder", color:"#007FC0"}}>Universitaria</Typography>
                </Box> */}
            </Box>
                <h1>Panel Institucional</h1>

              <div className="div-input">
                <InputLabel sx={{
                    fontWeight: "bold",
                    fontSize:"16px",
                  }}
                    id="email">Correo Electrónico</InputLabel>
                <EmailIcon className="icon"/>
                <TextField 
                  required
                  placeholder="Ingrese su correo"
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="on"
                  value={userLogin.email}
                  onChange={handlerChange}
                  error={emailError}
                  helperText={emailError ? 'El correo electrónico debe tener la extensión .edu o guiauniversitaria'  : ''}
                  sx={{
                    width: "100%",
                    "& input:-webkit-autofill": {
                      transition: "background-color 5000s ease-in-out 0s",
                      backgroundColor: "transparent !important",
                    },
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      padding: "5px 15px 5px 45px",
                      fontSize:"16px",
                      color: "#292929",
                    }
                  }}
                />
              </div>

              <div className="div-input">
                <InputLabel
                sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                }} 
                id="password">Contraseña</InputLabel>
                <LockIcon className="icon"/>
                <TextField 
                  required
                  // type={showPassword ? 'text' : 'password'}
                  type={showPassword ? "text" :"password"}
                  name="password"
                  id="password"
                  
                  // label="Contraseña"
                  value={userLogin.password}
                  onChange={handlerChange}
                  placeholder="Ingrese su contraseña"
                  autoComplete="on"
                  sx={{
                    width: "100%",
                    "& input:-webkit-autofill": {
                      transition: "background-color 5000s ease-in-out 0s",
                      backgroundColor: "transparent !important",
                    },
                  }}
                  InputLabelProps={{
                        style: { 
                          padding: "5px 15px 5px 45px",
                          color: "#292929",
                          fontSize:"16px" }
                      }}
                      InputProps={{
                        style:{ 
                          padding: "5px 15px 5px 45px",
                          fontSize:"16px",
                          color: colors.grey[400]
                        }
                      }}
                />
                 <VisibilityOutlinedIcon sx={{
                position:"absolute",
                zIndex:10,
                color:"#3F51B5",
                right:"12px",
                width: "20px",
                height: "20px",
                top: "55%",
                cursor:"pointer"
              }}
              onClick={togglePasswordVisibility}
              />
              </div>

              <Button variant="contained"  disabled={emailError} type="submit" className="login-btn">Iniciar sesión</Button>
              {/* <Button variant="contained"  type="submit" className="login-btn">Iniciar sesión</Button> */}

              <Link to={recoverPassword} className="forgot-pass">
                ¿Olvidó la contraseña?
              </Link>

              <div className="switch-register">
                <Typography variant="h5" sx={{marginBottom:"20px"}}>¿No tiene una cuenta?<span onClick={()=> setIsregistering(!isRegistering)} className="link">¡Regístrate aquí!</span></Typography>
              </div>
{/* 
              <Typography variant="h5">Iniciar sesión con:</Typography>
              <hr style={{width:"100%", marginBottom:"25px"}}/>      

             <Button onClick={handleGoogleSignIn}><img src={GoogleIcon} alt="Google" style={{width:"30px"}}/></Button>  */}

              {/* <div className="icons">
                <button className="icon">{<FacebookIcon/>}</button>
                <button className="icon">{<GitHubIcon/>}</button>
                <button className="icon">{<GoogleIcon/>}</button>
              </div> */}

              </form>
            </div>
           <hr style={{width:"100%", marginBottom:"25px"}}/> 
            <div className="right-side-login">
              <div className="content-info">
               <Typography variant="h5">¡Atención Directores de Área, Programas y vicerrectores Universitarios!</Typography>
               <Typography variant="h5">¿Deseas mejorar la visualización de tu oferta académica, optimizar la
                    captación de
                    nuevos estudiantes y potenciar tus programas de especialización y maestría? ¿Quieres saber cuál
                    es
                    la carrera más buscada y con quién te comparan?</Typography>
               <Typography variant="h5">¡Tenemos la solución!</Typography>
               <br/>
               <Typography variant="h5">Regístrate y obtén la versión free de nuestra plataforma. Aprovecha esta
                    oportunidad para:</Typography>
               <ul>
                    <li><Typography variant="h5">Mejorar la visibilidad de tus programas académicos.</Typography> </li>
                    <li><Typography variant="h5">Optimizar la captación de más estudiantes a tu institución.</Typography> </li>
                    <li><Typography variant="h5">Potenciar tus programas de especialización y maestría.</Typography> </li>
                    <li><Typography variant="h5"> Analítica de datos.</Typography></li>
                    <li><Typography variant="h5">Conocer las tendencias de búsqueda y comparaciones de carreras.</Typography> </li>
               </ul>
               <Typography variant="h5">No dejes pasar esta oportunidad de llevar tu universidad al siguiente nivel.
                    ¡Regístrate hoy mismo y comienza a disfrutar de estos beneficios!</Typography>
              </div>
            </div>
          </div>

         </Box>
      }
    </>
  )
}

