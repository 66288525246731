import NearMeIcon from '@mui/icons-material/NearMe';
import { institutionTypes, institutionCharacters, tokens, SwitchButton } from "@/utilities";
import { useState} from 'react'
import { ModalCareers,  ModalCreateFaculty } from "@/modals";
import { InstituteProps } from "@/types";
import { Box, Button, Typography, useTheme } from '@mui/material';
import './TableDetail.scss'
import instituteService from '@/api/institute.service';

interface Props {
    selectedInstituteData: InstituteProps 
  }


function TableDetail ({selectedInstituteData}:Props){
    const [isEditing, setIsEditing] = useState<boolean>(false)
    
    const [editableData, setEditableData] = useState<InstituteProps>(selectedInstituteData)
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    
    const [isOnHighAccreditation, setIsOnHighAccreditation] = useState<boolean | undefined>(selectedInstituteData.highAccreditation);
    const [isOnInternationalAccreditation, setIsOnInternationalAccreditation] = useState<boolean | undefined>(selectedInstituteData.internationalAccreditation);
    const code = selectedInstituteData.code

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)=>{
        const {name, value} = event.target;
        setEditableData(prevData=>({
            ...prevData,
            [name]: value,
        }))
    }

    async function  handleFormSubmit() {
        try {
            setEditableData(selectedInstituteData)

            if (JSON.stringify(editableData) === JSON.stringify(selectedInstituteData)) {
                setIsEditing(false);
                return;
            }
            
                await instituteService.update(code, editableData)
                setIsEditing(!isEditing)
            
        } catch (error) {
            console.error('Error updating information', error)
        }
    }


  return (
    <Box sx={{
        padding:{xs:"0", md:"0 40px 40px 40px"},
        position:"relative",
    }}
    className="detail-table"
    >
        <Box className="header-details">
            <Box className="banner">
            {selectedInstituteData.images && selectedInstituteData.images.length > 0 && (
                    <img src={selectedInstituteData.images[0].url} alt={`${selectedInstituteData?.name} image`} />
                )}
            </Box>
            <Box className="credentials">
                <Box className="credentials-left">
                    <div className="logo"> <img src={selectedInstituteData?.logo} alt={`${selectedInstituteData?.name} logo`}/></div>
                        {isEditing
                        ?(
                            <input
                                type="url"
                                name="webSite"
                                value={editableData?.webSite || selectedInstituteData?.webSite}
                                placeholder={selectedInstituteData?.webSite || editableData?.webSite}
                                onChange={handleFieldChange}
                            />
                        )                          
                        
                        : (<Box sx={{
                            background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
                            borderRadius:"5px",
                            width:{xs:"100%", md:"70%"},
                            display:"flex",
                            justifyContent:"space-around",
                            alignItems:"center",
                            marginTop:"20px",
                            cursor: "pointer"

                        }}>
                            <Button sx={{color:"#f2f0f0",            
                            fontSize:"16px",
                            fontWeight:"bold",}}><a style={{textDecoration:"none", color:"inherit"}} href={selectedInstituteData?.webSite}>{selectedInstituteData?.webSite}</a></Button>
                            <NearMeIcon/>
                        </Box>)
                            
                        }
                </Box>
                <Box className="credentials-right">
                            {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="name"
                                    value={editableData?.name || selectedInstituteData?.name}
                                    placeholder={selectedInstituteData?.name || editableData?.name}
                                    onChange={handleFieldChange}
                                />
                            )
                            : <Typography variant='h3' sx={{color:colors.grey[100]}}>{selectedInstituteData?.name
                            }</Typography>
                        }
                   
                        {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="acronym"
                                    value={editableData?.acronym || selectedInstituteData?.acronym}
                                    placeholder={selectedInstituteData?.acronym || editableData?.acronym}
                                    onChange={handleFieldChange}
                                />
                            )
                            : <Typography variant='h3'>{selectedInstituteData?.acronym}</Typography>
                            }
                    
                    
                    {isEditing
                    ? <Button sx={{
                        background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
                        color:"#f2f0f0",            
                        borderRadius:"5px",
                        fontWeight:"bolder",
                        fontSize:"16px",
                        width:"20%"
                    }}  onClick={handleFormSubmit}>Guardar</Button>
                    : <Button sx={{
                        background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
                        color:"#f2f0f0",            
                        borderRadius:"5px",
                        fontWeight:"bolder",
                        fontSize:"16px",
                        width:"20%"
                    
                    }} onClick={()=> setIsEditing(!isEditing)}>Editar</Button>
                    }

                </Box>
            </Box>
        </Box>

        <hr/>
        <Box className="content">
            <Box sx={{backgroundColor:colors.primary[400]}} className="left-side">
                <Box className="administrative-info">
                           <Typography variant='h4' color={colors.grey[100]}>Carácter de Insitución: </Typography>
                            
                            {isEditing
                            ?(
                                <select
                                    name="character"
                                    value={editableData?.character || selectedInstituteData?.character}
                                    placeholder={selectedInstituteData?.character || editableData?.character}
                                    onChange={handleFieldChange}
                                >
                                    {institutionCharacters.map((name:string, index: number)=>(
                                    <option value={name} key={index}>{name}</option>
                                ))}
                                </select>
                            )
                            :<Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.character}</Typography>
                            }
                        <Typography variant='h4' color={colors.grey[100]}>Tipo de institución:</Typography>  
                        
                            {isEditing
                            ?(
                                <select
                                    name="type"
                                    value={editableData?.type || selectedInstituteData?.type}
                                    placeholder={selectedInstituteData?.type || editableData?.type}
                                    onChange={handleFieldChange}
                                >
                                    {institutionTypes.map((name:string, index: number)=>(
                                    <option value={name} key={index}>{name}</option>
                                ))}
                                </select>
                            )
                            : <Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.type}</Typography>
                            
                            }
                        
                        <Typography variant='h4' color={colors.grey[100]}>Fecha de Fundación: </Typography>
                        
                            {isEditing && selectedInstituteData?.fundationDate
                            ?(
                                <input
                                    type="date"
                                    name="fundationDate"
                                    // value={
                                    //     (editableData?.fundationDate &&
                                    //       editableData.fundationDate.toISOString().split('T')[0]) ||
                                    //     selectedInstituteData.fundationDate?.toISOString().split('T')[0]
                                    //   }                           
                                    value={""}
                                    onChange={handleFieldChange}
                                />
                              
                            )
                            : <Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.fundationDate? new Date(selectedInstituteData.fundationDate).toLocaleDateString(): ""}</Typography>
                            } 
                        

                        <Typography variant='h4' color={colors.grey[100]}>Rector:</Typography>
                            {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="president"
                                    value={editableData?.president || selectedInstituteData?.president}
                                    placeholder={selectedInstituteData?.president || editableData?.president}
                                    onChange={handleFieldChange}
                                />
                            )
                            :<Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.president}</Typography>
                            }

                        <Typography variant='h4' color={colors.grey[100]}>Vice-Rector: </Typography>
                        
                            {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="vicePresident"
                                    value={editableData?.vicePresident || selectedInstituteData?.vicePresident}
                                    placeholder={selectedInstituteData?.vicePresident || editableData?.vicePresident}
                                    onChange={handleFieldChange}
                                />
                            )
                            :<Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.vicePresident}</Typography>
                            }
                        </Box>
                        <div className="acredditation">

                        <Typography variant='h4' color={colors.grey[100]}>Código IES:</Typography> 
                        
                            {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="code"
                                    value={editableData?.code || selectedInstituteData?.code}
                                    placeholder={selectedInstituteData?.code || editableData?.code}
                                    onChange={handleFieldChange}
                                />
                            )
                            :<Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.code}</Typography>
                            }  
                        
                        <Typography variant='h4' color={colors.grey[100]}>NIT:</Typography>
                        
                            {isEditing
                            ?(
                                <input
                                    type="text"
                                    name="taxID"
                                    value={editableData?.taxID || selectedInstituteData?.taxID}
                                    placeholder={selectedInstituteData?.taxID || editableData?.taxID}
                                    onChange={handleFieldChange}
                                />
                            )
                            :<Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.taxID}</Typography>
                            }

                        <Typography variant='h4' color={colors.grey[100]}>Alta acreditación:</Typography>
                        {isEditing

                        ? <SwitchButton 
                            checked={isOnHighAccreditation} 
                            setChecked={()=>{
                                setIsOnHighAccreditation(!isOnHighAccreditation)
                                setEditableData((prevData)=>({
                                    ...prevData,
                                    highAccreditation:!isOnHighAccreditation
                                }))
                            }}/>
                        : <Typography variant='h5' color={colors.grey[200]}>{(selectedInstituteData?.highAccreditation === true)? 'Si': 'No'}</Typography>
                        }

                        <Typography variant='h4' color={colors.grey[100]}>Acreditación Internacional: </Typography>
                        {isEditing
                        ?  <SwitchButton 
                                checked={isOnInternationalAccreditation} 
                                setChecked={()=>{
                                    setIsOnInternationalAccreditation(!isOnInternationalAccreditation)
                                    setEditableData((prevData)=>({
                                        ...prevData,
                                        internationalAccreditation:!isOnInternationalAccreditation
                                    }))
                                }}/>
                        : <Typography variant='h5' color={colors.grey[200]}>{(selectedInstituteData?.internationalAccreditation === true? 'Si': 'No')}</Typography>
                        }

                        <Typography variant='h4' color={colors.grey[100]}>Nivel de Suscripción: </Typography>
                        <Typography variant='h5' color={colors.grey[200]}>{selectedInstituteData?.subscriptionLevel}</Typography>
                        </div>
               
            </Box>

            
            <Box className="right-side">
                <Box className="description">
                    <Typography variant='h3'
                        sx={{color:colors.grey[100], textAlign:"center"}}>Misión:</Typography> 
                    <Typography variant='h5'>{selectedInstituteData?.description}</Typography>
                        {/* {isEditing
                            ?(
                                <input
                                    type="textarea"
                                    name="description"
                                    value={editableData?.description || selectedInstituteData?.description}
                                    placeholder={selectedInstituteData?.description || editableData?.description}
                                    onChange={handleFieldChange}
                                    className="textarea"
                                />
                            )
                            : */}
                            
                </Box>

                <Box 
                    sx={{display:"flex",
                        flexWrap:"wrap",
                        backgroundColor:colors.primary[400],
                        borderRadius:"15px"
                    }}
              
                >
                    <Typography variant='h3' 
                    sx={{color:colors.grey[100], textAlign:"center", padding:"20px 0", width:"100%"}}>Facultades</Typography>
                  
          
                    <ModalCareers code={code} />
              
                    
                    {isEditing && code
                        ?
                        (<Box sx={{width:"100%", display:"flex", justifyContent:"center", margin:"20px 0"}}>
                           <ModalCreateFaculty code={code}/>
                        </Box>)
                        : ''}
                </Box>
                
            </Box>
            
        </Box>
            <Box className="dates">
                <p>Creado en: {selectedInstituteData.createdAt ? new Date(selectedInstituteData.createdAt).toLocaleDateString(): 'No hay registro'}</p>
                <p>Actualizado en: {selectedInstituteData.updateAt ? new Date(selectedInstituteData.updateAt).toLocaleDateString(): 'No hay registro'}</p>
            </Box>
        
        
    </Box>
  )
}

export default TableDetail