import {Provider} from 'react-redux/es/exports'
import { Calendar, MyAccount} from './views'
import store from './redux/store'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ComparativeAnalytics, Dashboard, DetailProfessor, ForgotPassword,  Login,  PremiumPlans, Register, Sidebar,  TabsCareers, TabsFaculties, TabsInstitute, TabsProfessor, TabsUsers, Topbar, UpdateInstitute } from './components'
// import { ColorModeContext, tokens, useMode } from './utilities'
import { ColorModeContext,  useMode } from './utilities'
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { AuthContextProvider } from './utilities'
import { PrivateRoute } from './utilities/AuthRouteComponent'
import './App.css'
import { PaypalProvider } from './api'
import { PDFViewe } from './utilities/PDFViewe'

type TitleMapping = {
  [key: string]: string;
};

function App() {
  const [theme, colorMode] = useMode()
  // const mode = 'palette' in theme ? theme.palette.mode : 'dark';
  // const colors = tokens(mode);

  const [open, setOpen] = useState<boolean>(true)
  // const [chatsOpen, setChatsOpen] = useState<boolean>(true)
  const location = useLocation()
  const showTemplate = !['/login', '/register', "/", "/recover&password", "/report"].includes(location.pathname)
  
  
    // const { state } = location;
  
    // const imgBase64 = state?.image.buffer || "https://img.freepik.com/vector-gratis/ilustracion-icono-global_53876-9267.jpg"
   
    const isMobile = useMediaQuery('(max-width: 768px)');

    // Efecto para actualizar el estado 'open' cuando 'isMobile' cambia
    useEffect(() => {
      setOpen(!isMobile); // Si es móvil, 'open' es false, de lo contrario, 'open' es true
    }, [isMobile]); 

    useEffect(()=>{
      const titleMapping: TitleMapping= {
          '/': 'Inicio de sesión GU',
          '/login': 'Inicio de sesión GU',
          '/dashboard': 'Analítica GU',
          '/professors': 'Profesores GU',
          '/faculties' : 'Facultades GU',
          '/users': 'Usuarios GU',
          '/plans': 'Planes GU',
          '/institute': 'Mi instituto GU'
        };
      const pageTitle = titleMapping[location.pathname] || 'Dashboard GU';
      
      document.title = pageTitle

    }, [location.pathname])

 
     
  return (
    <AuthContextProvider>
      <Provider store={store}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <PaypalProvider>
            <Box className="app">
            {showTemplate && ( 
              <Box className={`${open? 'left-side' : 'left-sideClosed'}`}>
                  <Sidebar  open={open} setOpen={setOpen}  className=""/>
              </Box>
            )}
              <main className='content'>
                {showTemplate && (
                  <Box className={`${open? 'right-side' : 'right-sideClosed'}`}>
                  <Box className="container-search">
                    <Topbar open={open} setOpen={setOpen}/>
                  </Box>        

            
            

                {/* <Box
                  sx={{
                    backgroundColor:colors.primary[400],
                    boxShadow:`0.2px 0px 0.5px ${colors.blueAccent[400]}, 0 2px 5px ${colors.blueAccent[400]}`
                    
                  }}
                  className={`messages-container ${chatsOpen? "closed" : ""}`}
                  
                  >
                  <Messages chatsOpen={chatsOpen} setChatsOpen={setChatsOpen}/>
                </Box>  */}
              </Box>
                )}
                <Routes>

                    <Route path='/' element={<Login/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/register' element={<Register/>}/>
                    <Route path='/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                    <Route path='/institutes' element={<PrivateRoute adminLevel={3}><TabsInstitute/></PrivateRoute>}/>
                    <Route path='/institute' element={<PrivateRoute adminLevel={2}><UpdateInstitute/></PrivateRoute>}/>
                    <Route path='/faculties' element={<PrivateRoute adminLevel={2}><TabsFaculties/></PrivateRoute>}/>
                    <Route path='/careers' element={<PrivateRoute adminLevel={1}><TabsCareers/></PrivateRoute>}/>
                    <Route path='/calendar' element={<PrivateRoute adminLevel={1}><Calendar/></PrivateRoute>}/>
                    <Route path='/users' element={<PrivateRoute adminLevel={2}><TabsUsers/></PrivateRoute>}/>
                    <Route path='/professors' element={<PrivateRoute adminLevel={1}><TabsProfessor/></PrivateRoute>}/>
                    <Route path='/professor/:id' element={<PrivateRoute adminLevel={1} subscriptionLevel={0}><DetailProfessor/></PrivateRoute>}/>
                    <Route path='/faculty/:code/:id' element={<PrivateRoute adminLevel={2}><TabsCareers/></PrivateRoute>}/>
                    <Route path='/plans' element={<PremiumPlans/>}/>
                    <Route path='/recover&password' element={<ForgotPassword/>}/>
                    <Route path='/my_account' element={<PrivateRoute><MyAccount/></PrivateRoute>}/>
                    <Route path='/report' element={<PDFViewe/>}/>
                    <Route path='/comparative_analytics' element={<PrivateRoute adminLevel={1} subscriptionLevel={1}><ComparativeAnalytics/></PrivateRoute>}/>

                    {/* <Route path='/download/report' element={<TemplateReport/>}/> */}
               
                  </Routes>
             
                </main>
              </Box>
              </PaypalProvider>
          </ThemeProvider>
      </ColorModeContext.Provider>
      </Provider>
    </AuthContextProvider>
  )
}

export default App
