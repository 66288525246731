import { institutionCharacters, institutionTypes, tokens } from "@/utilities"
import './AddNewIES.scss'
import { Box, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";

// interface Props{
//   formik: FormikProps<FormikValues> 
// }
interface Props{
  formik: any
}


export const FormIESDetails = (props:Props) => {
    const {formik} = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
    <Box  sx={{
      display:"flex",
      flexDirection:"column",
      backgroundColor:colors.primary[400],
      padding:"20px 40px",
      borderRadius:"5px",
      marginTop:"20px",
      gap:"20px",
      width:"80%"
    }} 
    >
      <Box sx={{
                textAlign: "center",
                alignItems: "center",}}>
            <Typography variant="h3" sx={{color:colors.grey[100]}}>Ingresa la información de tu IES</Typography>
            <Typography variant="h5" sx={{color:colors.grey[200]}}>Los campos marcados con * son obligatorios</Typography>
      </Box>

        <Box sx={{
          textAlign:"left",
          marginTop:"30px"
        }}>
              <Typography variant="h4" sx={{color:colors.grey[200]}}> Envía tus requerimientos</Typography>
              <Typography variant="h6" sx={{color:colors.grey[200]}}>Por favor, llene el formulario para crear la información de la Institución Educativa en la base de datos  y poder continuar al siguiente paso</Typography>
        </Box>

  
        <hr style={{width:"100%"}}/>

      <Box 
       sx={{
        display:"grid",
        gridTemplateColumns:"repeat(2,1fr)",
        width:"100%",
        gap:"20px",
        justifyContent:"center",
        textAlign:"center",
        alignItems:"center",
      }}
       >
      <Box sx={{ gridColumn:"1/3", }}>
          <Typography variant="h4" sx={{color:colors.grey[100]}}>Detalles del Instituto</Typography>
        </Box>


          <Grid item xs={12} sm={10}>
              <TextField
                required
                id="name"
                name="name"
                label="Nombre de la Institución"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px" }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}
              />
          </Grid>

          <Grid  item xs={12} sm={10}>
                <TextField
                name="acronym"
                id="acronym"
                label="Siglas"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={formik.values.acronym}
                onChange={formik.handleChange}
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px" }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}                />
          </Grid>

          <Grid  item xs={12} sm={10}>
            <InputLabel 
            sx={{
              fontWeight: "bolder",
              fontSize:"16px",
              marginBottom:"5px"}}
            id="fundationDate">Año de fundación</InputLabel>
              <TextField
              type="date"
              id="fundationDate"
              name="fundationDate"
              value={formik.values.fundationDate}
              onChange={formik.handleChange}
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              sx={{color:colors.grey[100]}}
              InputLabelProps={{
                style: { color: colors.grey[100],
                fontSize:"16px",
              }
              }}
              InputProps={{
                style:{ color: colors.grey[100],
                  fontSize:"16px",},
              }}
              />
        </Grid>
        
        <Grid item xs={12} sm={10}>
            <InputLabel 
            sx={{
              fontWeight: "bolder",
              fontSize:"16px",
              marginBottom:"5px"}}
            id="type">Tipo de Institución *</InputLabel>
            <Select
              fullWidth
              size="small"
              variant="outlined"
              id="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              >
              {institutionTypes.map((name: string, index: number) => (
                <MenuItem value={name} key={index}>
                  {name}
                </MenuItem>
              ))}
            </Select>
      </Grid>

      <Grid item xs={12} sm={10}>
        <InputLabel 
        sx={{
          fontWeight: "bolder",
          fontSize:"16px",
          marginBottom:"5px"}}
        id="character">Carácter de la Institución *</InputLabel>
        <Select
          fullWidth 
          size="small"
          variant="outlined"
          id="character"
          name="character"
          value={formik.values.character}
          onChange={formik.handleChange}
        >
          {institutionCharacters.map((name: string, index: number) => (
            <MenuItem value={name} key={index}>
              {name}
            </MenuItem>
          ))}
     </Select>
    </Grid>
      </Box>
    </Box>
  )
}
