import { Box, Button, Modal, Typography,  useTheme,  DialogContent, IconButton, DialogActions} from "@mui/material"
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { tokens, useAuth } from "@/utilities";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Crop from "@mui/icons-material/Crop";
import { Send } from "@mui/icons-material"; 
import userAdminService from "@/api/userAdmin.service";
import { CropEasy } from "@/components";
import Swal from "sweetalert2";


export const MyAccount = () => {
    
  const {user, setUser}= useAuth()
  const theme = useTheme()
  const colors = tokens(theme.palette.mode);
  

  const [values, setValues]= useState({
    image:`${user?.userData?.photoUrl || ""}`,

  })


    const [openImage, setOpenImage] = useState(false);
  
    const handleClickOpenImage = () => setOpenImage(true);
    
    const handleCloseImage = () =>{
      setOpenImage(false);
      setFile(null)
      setPhotoURL(user?.userData?.photoUrl)
    } 



    //---------------------------Image Profile----------------------------
    const [file, setFile] = useState<File | null>(null);
    const [photoURL, setPhotoURL] = useState(user?.userData?.photoUrl);
    const [openCrop, setOpenCrop] = useState(false);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB 

    const handleChangeImage = (e:React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          Swal.fire({
            title:"5Mb",
            text:'El archivo es demasiado grande. El tamaño máximo permitido es 5MB.',
            icon:"info",
            timer:2000
          })
          e.target.value = ''; 
        } else {
          setFile(file);
          setPhotoURL(URL.createObjectURL(file));
          setOpenCrop(true);
        }
      }
    }
    };

    const handleUploadImage = async () => {
      if (file) {
        try {
          const userId = user?.user.uid;
          if (userId) {
            const uploadedImage = await userAdminService.updateProfileImage(userId, file);
            if (uploadedImage) {
              setValues({
                ...values,
                image: uploadedImage.url
              })
              setUser((prevUser:any) => ({
                ...prevUser,
                userData: {
                  ...prevUser.userData,
                  photoUrl: uploadedImage.url,
                },
              }))
              setOpenImage(false)
              // Imagen subida con éxito, ahora puedes hacer más acciones si es necesario
              // console.log('URL de la imagen subida:', uploadedImage.url);
            } else {
              // Manejar errores si la subida de la imagen falla
              console.error('Error al subir la imagen.');
            }
          } else {
            // Manejar el caso en el que el usuario no está autenticado
            console.log('Usuario no autenticado.');
          }
        } catch (error) {
          // Manejar errores generales
          console.error('Error al subir la imagen:', error);
        }
      } else {
        // Manejar el caso en el que no se ha seleccionado ningún archivo
        console.log('No se ha seleccionado ningún archivo.');
      }
    };

  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        width:{xs:"80%", md:"700px"},
        transform: 'translate(-50%, -50%)',
        bgcolor: `${colors.primary[400]}`,
        boxShadow: 24,
        p: 4,
        height:"auto",
        marginTop:{xs:"60px", sm:"0px" , md:"0px"}    };

  return (
    <>
    <Box sx={{
          padding:{xs:"20px 0", md:"20px  40px"},
          width:{xs:"100%", md:"50%"},
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center",
          textAlign:"center",
          gap:"10px",
        }}>
       
          <Box sx={{
            position:"relative",
          }}>
           
            
            {values.image.length > 5
            ? 
           ( <img
             src={values.image}
             alt={user?.user.displayName || "Imagen no disponible"}
             loading='eager'
             style={{
              display:"flex",
              height:"220px",
              width:"220px",
              borderRadius:"50%",
              textAlign:"center",
              border:`1px solid ${colors.blueAccent[500]}`,
              objectFit:"cover"
            }}
            onClick={handleClickOpenImage}
             />)
             :
            <img
             src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBp5Jh1FS7gjMnHJhhV_QDcZvq6Gqkorglw&usqp=CAU"}
             alt="Imagen no disponible"
             loading='eager'
             style={{
              display:"flex",
              height:"220px",
              width:"220px",
              borderRadius:"50%",
              textAlign:"center",
              border:`1px solid ${colors.blueAccent[500]}`,
              objectFit:"cover"
            }}
            onClick={handleClickOpenImage}
             />

          }

            <AddAPhotoOutlinedIcon sx={{
              position:"absolute",
              right:0,
              top:0,
              fontSize:"30px",
              color:"#007FC0",
              ":hover":{
                  cursor:"pointer",
                  scale:"1.2"
              }
              }}
              onClick={handleClickOpenImage}
              />

          </Box>

      </Box>

       {/* ------------------Image modal and handler---------------------- */}
        
        {openImage 
        ?(
          <>
            <Modal 
              open={openImage}
              onClose={handleCloseImage}
              style={{
                height:"100%",
                overflowY:"scroll",
                zIndex:2
            }}
              >
                <Box sx={style}>
                  <Typography  variant="h4" color={colors.grey[100]} >
                      Selecciona para modificar tu foto de perfil
                  </Typography>
                  <CloseIcon sx={{
                    position:"absolute",
                    right:"15px",
                    top:"15px"
                  }}
                  onClick={handleCloseImage}
                  />

              {!openCrop?(
                <>
                
                    {values.image.length > 5
                        ? 
                      ( 
                        <DialogContent dividers>
                      <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", justifyContent:"center"}}>
                          <label htmlFor="image" style={{position:"relative"}}>
                            <input
                              accept="image/*"
                              id="image"
                              type="file"
                              style={{ display: 'none'}}
                              onChange={handleChangeImage}
                            />
                            <img
                              src={photoURL}
                              loading='eager'
                              style={{
                                display:"flex",
                                height:"220px",
                                width:"220px",
                                borderRadius:"50%",
                                textAlign:"center",
                                border:`1px solid ${colors.blueAccent[500]}`,
                                objectFit:"cover",
                                cursor:"pointer",
                                alignItems:"center",

                              }}
                            />
                      <AddAPhotoOutlinedIcon sx={{
                          position:"absolute",
                          right:0,
                          top:0,
                          fontSize:"30px",
                          color:"#007FC0",
                          ":hover":{
                              cursor:"pointer",
                              scale:"1.2"
                          }
                          }}
                          onClick={handleClickOpenImage}
                          />

                          </label>

                        </Box>
                      </DialogContent>
                      )
                        :
                        <img
                        src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBp5Jh1FS7gjMnHJhhV_QDcZvq6Gqkorglw&usqp=CAU"}
                        alt="Imagen no disponible"
                        loading='eager'
                        style={{
                          display:"flex",
                          height:"220px",
                          width:"220px",
                          borderRadius:"50%",
                          textAlign:"center",
                          border:`1px solid ${colors.blueAccent[500]}`,
                          objectFit:"cover"
                        }}
                        />
                    }

                        <DialogActions sx={{width:"100%", minWidth:{xs:"100%", sm:350}}}>
                          <Box sx={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"100%",
                            gap:"8px"
                          }} >
                            {file && (
                              <Button sx={{
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center", 
                                padding:"0 10px",
                              }} 
                              variant="outlined"
                              onClick={()=>setOpenCrop(true)}
                              >
                                    <IconButton
                                      aria-label="Crop"
                                      color="secondary"
                                      onClick={() => setOpenCrop(true)}
                                      sx={{display:"flex", padding:"0 5px"}}
                                    >
                                      <Crop />
                                    </IconButton>
                                    Modificar
                                </Button>
                                  )}

                              <Button variant="contained" endIcon={<Send />} onClick={handleUploadImage} sx={{backgroundColor:"#007FC0", display:"flex"}}>
                                  Guardar
                              </Button>
                          </Box>
                        </DialogActions>
                </>
                )
              :(
                photoURL?
                <CropEasy {...{photoURL, setOpenCrop, setPhotoURL, setFile}}/>
                :""
              )
              }
             
                </Box>
            </Modal>
          </>
        )
        :""
        }
        
    

    </>
  )
}
