import { GeoPoint, Timestamp } from "firebase/firestore";
import { GeoPointProps } from "../types/geoPoint.type";

export const fromFirestore = <T extends Record<string, unknown>>(document: T) => {
    Object.entries(document).forEach(([key, value]) => {
        if (typeof value !== 'object' || value === null) return;
        if (value instanceof Timestamp) {
            // @ts-ignore
            document[key] = value.toDate().toISOString();
            return;
        }
        if (value instanceof GeoPoint) {
            // @ts-ignore
            // document[key] = { latitude: value._lat, longitude: value._long } as GeoPointProps;
            // document[key] = value.toJSON() as GeoPointProps;
            document[key] = new GeoPointProps(value.latitude, value.longitude).toJSON();
            return;
        }
        // @ts-ignore
        document[key] = fromFirestore(value);
    });
    return document as T;
};

export const toFirestore = <T extends Record<string, unknown>>(document: T) => {
    Object.entries(document).forEach(([key, value]) => {
        if (typeof value !== 'object' || value === null) return;
        // if ((/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value.toString()))) {
        if (key === 'createdAt' || key === 'updatedAt' || key === 'date' || key === 'startClass' || key === 'startYear' || key === 'inscriptions') {
            // @ts-ignore
            try {
                const dateValue: Date = new Date(value.toString());
                // document[key] = Timestamp.fromDate(dateValue);
                document = Object.assign(document, { [key]: Timestamp.fromDate(dateValue) });
            } catch (error) {
                console.error("Error in toFirestore.", `key: ${key}`, `value: ${value}`);
            }
            return;
        }
        // if (value.toString().indexOf('latitude:') > -1 && value.toString().indexOf('longitude:') > -1) {
        if (key === 'geoLocation' || key === 'location') {
            // if (value instanceof {latitude: number}) {
            // if (value instanceof GeoPointProps) {
            // @ts-ignore
            try {
                const jsonValue = value as { latitude: number; longitude: number };
                // document[key] = new GeoPoint(jsonValue.latitude, jsonValue.longitude);
                document = Object.assign(document, { [key]: new GeoPoint(jsonValue.latitude, jsonValue.longitude) });
            } catch (error) {
                console.error("Error in toFirestore.", `key: ${key}`, `value: ${value}`);
            }
            // GeoPointProps{};
            return;
        }
        // @ts-ignore
        document[key] = toFirestore(value);
    });
    return document as T;
};