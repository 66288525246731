import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridRenderCellParams,
  GridRowSelectionModel,
  esES,
} from "@mui/x-data-grid";
import { SwitchButton, tokens, useAuth } from "@/utilities";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { CareerProps, facultyProps } from "@/types";
import careersService from "@/api/careers.service";
import { ModalDetailByCareer, ModalUpdateFaculty } from "@/modals";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import facultyService from "@/api/faculty.service";
import Swal from "sweetalert2";
import { ModalMoveCareers } from "./Modal-moveCareers";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  state: boolean;
}

export const TableCareers = ({ state }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [careers, setCareers] = useState<CareerProps[]>([]);
  const [selectedCareer, setSelectedCareer] = useState<CareerProps>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useAuth();
  const { code, id } = useParams<string>();
  const location = useLocation();
  const role = user?.userData.role;
  const ref =
    role === "Administrador IES"
      ? `${user?.userData.ref}/faculties/${id}`
      : `${user?.userData.ref}/faculties/${user?.userData.uidFaculty}`;
  const facultyID = role === "Administrador IES" ? id : user?.userData.faculty;
  const instituteID =
    role === "Administrador IES" ? code : user?.userData.institute;

    const facultyNameOption = role === "Administrador Facultad" ? user?.userData.faculty : location.state.facultyName
  const [facultyName, setIsFacultyName] = useState<string>(facultyNameOption);

  const navigate = useNavigate();

  const [facultyData, setFacultyData] = useState<facultyProps>();

  const [openModalMove, setOpenModalMove] = useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [selectedCareers, setSelectedCareers] = useState<CareerProps[]>([]);

  const name =
    role === "Administrador IES"
      ? `Facultad de ${location.state.facultyName}`
      : `Facultad de ${facultyData?.name}`;

  const currentFaculty = {
    id: facultyID,
    name,
    uidInstitution: instituteID,
  };

  const filteredCareers = careers.filter((career) =>
    career.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    setSelectionModel(newSelectionModel);
  };

  function findSelectedCareers(
    careers: CareerProps[],
    selectionModel: GridRowSelectionModel
  ) {
    // Filtra las carreras cuyo ID está presente en el selectionModel
    const selectedCareers = careers.filter((career) =>
      career.id ? selectionModel.includes(career.id.toString()) : []
    );

    return selectedCareers;
  }
  const handleDetailClick = useCallback((id: string) => {
    try {
      const career = careers.find((career) => career.id === id);
      setSelectedCareer(career);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Career doesnt find in collection", error);
    }
  }, [careers]);

  const handleSwitch = useCallback(
    async (careerId: string, value: boolean) => {
      try {
        if (instituteID && facultyID && careerId) {
          Swal.fire({
            title: "El estado de la facultad está a punto de cambiar",
            text: "¿Desea continuar con la operación?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#002D82",
            confirmButtonText: "Si, ¡cámbialo!",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const update = await careersService.updateActiveStatus(
                instituteID,
                facultyID,
                careerId,
                value
              );
              if (update) {
                setCareers((prevCareers: CareerProps[]) =>
                  prevCareers.map((career: CareerProps) =>
                    career.id === careerId ? { ...career, active: value } : career
                  )
                );
                setCareers((prevCareers) =>
                  prevCareers.filter((career) => career.id !== careerId)
                );
              }
              Swal.fire(
                "¡Actualizado!",
                "Su facultad fue actualizada satisfactoriamente",
                "success"
              );
            }
          });
        }
      } catch (error) {
        console.error("Error updating active status", error);
      }
    },
    [instituteID, facultyID]
  );
  

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Nombre de la carrera",
        flex: 3,
        minWidth: 350,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={async () => handleDetailClick(params.row.id)}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: "modalities",
        headerName: "Modalidad",
        flex: 1,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={async () => handleDetailClick(params.row.id)}
          >
            {params.value}
          </Box>
        ),
      },

      {
        field: "levelStudy",
        headerName: "Nivel de estudio",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "updatedAt",
        minWidth: 100,
        headerName: "Última modificación",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {params.value.slice(0, 10)}
          </Box>
        ),
      },
      {
        key: "detail",
        field: "detail",
        headerName: "Ver detalle",
        flex: 1,
        type: "actions",
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              backgroundColor: "#3F51B5",
              borderRadius: "25px",
              border: "none",
            }}
            onClick={async () => handleDetailClick(params.row.id)}
          >
            <Button
              variant="outlined"
              sx={{ border: "none", "&:hover": { border: "none" } }}
            >
              <RemoveRedEyeIcon style={{ color: "white" }} />
            </Button>
          </Box>
        ),
      },
      {
        field: "active",
        headerName: "Activo",
        minWidth: 70,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            {/* <SwitchButton checked={params.value} setChecked={() => userAdminService.updateActiveStatus(params.row.uid, !params.value)}/> */}
            <SwitchButton
              checked={params.value}
              setChecked={() => handleSwitch(params.row.id, !params.value)}
            />
          </Box>
        ),
      },
    ],
    [handleDetailClick, handleSwitch]
  );
  const updateCareer = (career: Partial<CareerProps>, id: string) => {
    setCareers((prevCareers) => {
      const updatedCareer = prevCareers.map((careerMap) => {
        if (careerMap.id === id) {
          return { ...careerMap, ...career };
        }
        return careerMap;
      });
      return updatedCareer;
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Está a punto de eliminar esta facultad",
      text: "¿Desea continuar con la operación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      // cancelButtonColor: '#d33',
      cancelButtonColor: "#002D82",
      confirmButtonText: "Si, ¡eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteFaculty =
          instituteID &&
          facultyID &&
          (await facultyService.delete(instituteID, facultyID));
        if (deleteFaculty) {
          Swal.fire(
            "¡Eliminada!",
            "Su facultad fue eliminada satisfactoriamente",
            "success"
          );
          navigate("/faculties");
        } else {
          Swal.fire(
            "¡Intenta nuevamente!",
            "Hubo un error al intentar eliminar la facultad",
            "error"
          );
        }
      }
    });
  };

  useEffect(() => {
    const newSelectedCareers = findSelectedCareers(careers, selectionModel);
    setSelectedCareers(newSelectedCareers);
  }, [selectionModel, careers]);

  useEffect(() => {
    async function fetchCareersByFaculty() {
      try {
        if (ref) {
          const getCareers = await careersService.getAll(ref, state);
          setCareers(getCareers);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCareersByFaculty();
  }, [ref, state]);

  useEffect(() => {
    async function fetchFacultyData() {
      try {
        const facultyDataSession = sessionStorage.getItem("facultyData");
        if (facultyDataSession) {
          setFacultyData(JSON.parse(facultyDataSession));
        } else {
          if (
            role === "Administrador Facultad" &&
            user?.userData.institute &&
            user.userData.uidFaculty
          ) {
            const facultyData = await facultyService.getById(
              user?.userData.institute,
              user?.userData.uidFaculty
            );
            if (facultyData) {
              setFacultyData(facultyData);
              sessionStorage.setItem(
                "facultyData",
                JSON.stringify(facultyData)
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchFacultyData();
  }, [role, user?.userData.uidFaculty, user?.userData.institute]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setSearchTerm("");
    }
  }, [searchTerm]);

  return careers.length === 0 ? (
    <Box style={{ width: "100%", padding: "20px" }}>
      {role === "Administrador IES" && (
        <Box
          sx={{
            marginTop: { xs: "5px", sm: "-15px", md: "-55px" },
            marginBottom: "20px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#3F51B5",
              color: "#f2f0f0",
              display: "flex",
              textDecoration: "none",
              gap: "10px",
              padding: "10px 20px",
              borderRadius: "5px",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: colors.blueAccent[500],
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/faculties")}
          >
            <ArrowBackIcon />
            Volver a facultades
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
          {role === "Administrador IES"
            ? `Facultad de ${facultyName}`
            : `Facultad de ${facultyData?.name}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <ModalUpdateFaculty
            code={code ?? ""}
            facultyName={facultyName}
            setIsFacultyName={setIsFacultyName}
            refFirebase={ref ?? ""}
          />
          <Button variant="outlined" onClick={handleDelete}>
            Eliminar
          </Button>
        </Box>
      </Box>

      {/* <Typography variant='h6' sx={{color:colors.grey[100], marginBottom:"20px"}}>Nota: En el encabezado de la tabla podrás encontrar diferentes filtros.</Typography> */}

      <p>Aún no se han registrado carreras...</p>
    </Box>
  ) : (
    <Box style={{ width: "100%", padding: "20px" }}>
      {role === "Administrador IES" && (
        <Box
          sx={{
            marginTop: { xs: "5px", sm: "-15px", md: "-55px" },
            marginBottom: "20px",
            width: { xs: "100%", sm: "fit-content" },
            justifyContent: { xs: "center", sm: "flex-start" },
            display: "flex",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#3F51B5",
              color: "#f2f0f0",
              display: "flex",
              textDecoration: "none",
              gap: "10px",
              padding: "10px 20px",
              borderRadius: "5px",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: colors.blueAccent[500],
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/faculties")}
          >
            <ArrowBackIcon />
            Volver a facultades
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
          {role === "Administrador IES"
            ? `Facultad de ${location.state.facultyName}`
            : `Facultad de ${facultyData?.name}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <ModalUpdateFaculty
            code={code ?? ""}
            facultyName={facultyName}
            setIsFacultyName={setIsFacultyName}
            refFirebase={ref ?? ""}
          />
          <Button variant="outlined" onClick={handleDelete}>
            Eliminar
          </Button>
        </Box>
      </Box>

      {/* <Typography variant='h6' sx={{color:colors.grey[100], marginBottom:"20px"}}>Nota: En el encabezado de la tabla podrás encontrar diferentes filtros.</Typography> */}
      <TextField
        sx={{
          width: "100%",
          marginBottom: "2rem",
          "& .MuiInputBase-input": {
            border: "none", 
          },
        }}
        type="text"
        placeholder="Buscar por carrera..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          paddingBottom: "80px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#3F51B5",
            borderBottom: "none",
            color: "#fcfcfc",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#3F51B5",
          },
          "& .MuiTablePagination-root": {
            color: "#fcfcfc",
          },
          ".MuiDataGrid-sortIcon": {
            visibility: "visible",
            color: "#fcfcfc !important",
          },
          ".MuiSvgIcon-root": {
            color: "#fcfcfc !important",
          },
        }}
      >
        <DataGrid
          rows={filteredCareers}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionModelChange}
          rowSelectionModel={selectionModel}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          // checkboxSelection
          autoHeight
          disableColumnFilter={false}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          slots={{
            toolbar: () => (
              <div
                style={{
                  display: selectedCareers.length >= 1 ? "flex" : "none",
                  gap: "0.5rem",
                  justifyContent: "flex-end",
                  marginBottom: ".5rem",
                }}
              >
                {/* <Button
                  // color="error"
                  variant="outlined"
                  onClick={() => {
                    console.log("Deactivate selected");
                  }}
                >
                  Eliminar
                </Button>
                <Button
                  // color="success"
                  variant="outlined"
                  onClick={() => {
                    console.log("Activate selected");
                  }}
                >
                  Activar
                </Button> */}
                <Button
                  // color="info"
                  variant="outlined"
                  onClick={() => setOpenModalMove(true)}
                >
                  Mover
                </Button>
              </div>
            ),
          }}
          sx={{
            // disable cell selection style
            ".MuiDataGrid-cell:focus": {
              outline: "none",
              cursor: "pointer",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
            "& .MuiDataGrid-selectedRowCount": {
              color: "#fcfcfc",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "#fcfcfc",
              visibility: "visible",
            },
            "& .MuiTablePagination-root": {
              color: "#fcfcfc",
            },
            "& .MuiSvgIcon-root": {
              color: "#fcfcfc !important",
            },
            "&.MuiDataGrid-filterIcon": {
              visibility: "visible !important",
              color: "#fcfcfc !important",
            },
            "& .MuiDataGrid-headerFilterRow": {
              visibility: "visible !important",
              color: "#fcfcfc !important",
            },
            "& .MuiDataGrid-menuOpen": {
              visibility: "visible !important",
              color: "#fcfcfc !important",
            },
            ".MuiDataGrid-iconButtonContainer": {
              visibility: "visible !important",
            },
            ".MuiDataGrid-menuIcon": {
              visibility: "visible !important",
              marginRight: "20px",
            },
            ".MuiDataGrid-cellCheckbox": {
              color: "#141414 !important",
            },
          }}
        />
      </Box>
      {selectedCareer && (
        <ModalDetailByCareer
          updateCareer={updateCareer}
          selectedCareer={selectedCareer}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {openModalMove && (
        <ModalMoveCareers
          open={openModalMove}
          setOpen={setOpenModalMove}
          facultyData={currentFaculty}
          selectedCareers={selectedCareers}
          setCareers={setCareers}
          careers={careers}
        />
      )}
      {/* {isEditingFaculty && code && <ModalUpdateFaculty 
                code={code}
                facultyName={facultyName}
                ref={ref}
                isEditingFaculty={isEditingFaculty}
                setIsEditingFaculty={setIsEditingFaculty}
             />
      } */}
    </Box>
  );
};
