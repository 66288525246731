import { CustomComponentProps, tokens, useAuth } from "@/utilities";
import "./CardProfessor.scss";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import professorService from "@/api/professor.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";

interface Props extends CustomComponentProps {
  bannerSrc: string;
  profileImgSrc: string;
  name: string;
  speciality: string[];
  languages?: string[];
  educationLevel?: string;
  onClick?: () => void;
  id: string;
  isFavorite: boolean;
  handleDelete?: (id: string) => void;
  userFavorites?: string[];
  setUserFavorites?: any;
}

const showAlert = (title: string, footer?: string) => {
  Swal.fire({
    title: title,
    icon: "success",
    footer: footer,
    timer: 4000,
  });
};

export const CardProfessor = (props: Props) => {
  const { user } = useAuth();
  const isProfessorFavorite = props.userFavorites?.includes(props.id);
  const [starColor, setStarColor] = useState(isProfessorFavorite ? "gold" : "");
  //---------------------MUI snackbar--------------------------------
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  // console.log(openSnackbar)
  // console.log(snackbarMessage)

  //---------------------------------
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const handleFavorite = async () => {
    if (user) {
      if (!isProfessorFavorite) {
        await professorService.addToFavorites(user.user.uid, props.id);
        setStarColor("gold");
        props.setUserFavorites((prevFavorites: string[]) => [
          ...prevFavorites,
          props.id,
        ]);
        //     setSnackbarMessage("Se agregó a favoritos exitosamente ")
        // setOpenSnackbar(true);
        showAlert(
          "Se agregó a favoritos exitosamente ",
          "Ya puedes verlo en tu lista de favoritos"
        );
      } else {
        await professorService.removeFromFavorites(user.user.uid, props.id);
        setStarColor("");
        props.setUserFavorites((prevFavorites: string[]) =>
          prevFavorites.filter((favorite) => favorite !== props.id)
        );
        // setSnackbarMessage("Se eliminó de favoritos exitosamente ")
        // setOpenSnackbar(true);
        showAlert("Se eliminó de favoritos exitosamente ", "");
      }
    }
  };

  const premiumLevel = 0;
  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
      }}
      className="card-professor"
    >
      {/* <CustomizedSnackbars
        severity="success"
        message={snackbarMessage}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      /> */}
      <div className="top-side">
        <img
          src={
            props.bannerSrc
              ? props.bannerSrc
              : "https://images.unsplash.com/photo-1454117096348-e4abbeba002c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
          }
          alt="Banner image"
          className="banner-profile"
        />
        {props.isFavorite ? (
          <DeleteForeverIcon
            className="icon-favorite delete"
            onClick={() => props.handleDelete && props.handleDelete(props.id)}
          />
        ) : (
          <StarIcon
            className="icon-favorite"
            style={{ color: starColor }}
            onClick={handleFavorite}
          />
        )}
      </div>
      <img
        src={
          props.profileImgSrc
            ? props.profileImgSrc
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
        alt=""
        className="profile-img"
      />

      {user?.subscriptionLevel !== undefined &&
      user.subscriptionLevel >= premiumLevel &&
      !(user.isTest ?? false) ? (
        // <div className={`${premiumLevel === "Avanzado" ? "bottom-side" :"blur" }`}>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
          className="bottom-side"
        >
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              padding:" 0 2rem",
              gap:"0.5rem",
              height:"9rem",  
            }}
          >
            <Typography
              variant="h4"
              color={colors.grey[100]}
              onClick={() => (props.onClick ? props.onClick() : "")}
              sx={{}}
            >
              {props.name}
            </Typography>
            <Box  sx={{
              overflow:"hidden",
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              wordWrap:"break-word"
            }}>
            {props.speciality.slice(0, 5).map((speciality) => (
              <Typography key={speciality} variant="h6">
                {speciality}
              </Typography>
            ))}
            </Box>
          </Box>
          {/* <p>{props.languages?.join(" || ")}</p> */}

          <Box
            sx={{
              display: "flex",
              position: "absolute",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              bottom: "15px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ gap: "10px", fontWeight: "regular" }}
              onClick={() => (props.onClick ? props.onClick() : "")}
            >
              {" "}
              <PersonAddIcon className="icon" />
              Conectar
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box className=".bottom-side blur">
            <h2 onClick={() => ""}>Lorem ipsum.</h2>
          </Box>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", color: colors.grey[100] }}
          >
            {props.educationLevel === "Nivel Académico"
              ? ""
              : props.educationLevel}
          </Typography>
          {props.speciality.slice(0, 3).map((speciality) => (
            <Typography key={speciality} variant="h6">
              {speciality}
            </Typography>
          ))}

          <Button
            variant="outlined"
            sx={{
              gap: "10px",
              fontWeight: "regular",
              position: "absolute",
              bottom: 15,
            }}
            onClick={() => {
              Swal.fire({
                title:
                  "Para tener acceso a esta función adquiere uno de nuestros planes",
                icon: "info",
                footer:
                  "Si crees que hay un error por favor, comunicate con nosotros.",
                timer: 5000,
              }).then(() => {
                navigate("/plans");
              });
            }}
          >
            <PersonAddIcon className="icon" />
            Conectar
          </Button>
        </>
      )}
    </Box>
  );
};
