import { PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import Swal from "sweetalert2";

type ErrorType = string | Record<string, any>;

export const PaypalCheckoutButton=({product, onClose}:{product:any, onClose:()=>void}) =>{
    const [paidFor, setPaidFor] = useState<boolean>(false)
    const [error, setError] = useState< ErrorType | null>(null)

    const handleApprove = (orderId:any)=>{
        //Call backend function to fulfill order

        //if response is success
        setPaidFor(true)
        //Refresh user´s account or subscription status

        //Close modal 
        onClose()
        //if the response is error
        setError("error in order")
        console.log(orderId)
    }
    if(paidFor){
        Swal.fire({
            title: "Gracias por su compra",
            icon: "success",
            footer: `Su pago a sido procesado correctamente`,
            timer: 2000,
        })
    }
    if(error){
        Swal.fire({
            title: "No hemos podido procesar su compra",
            icon: "error",
            footer: error,
            timer: 2000,
        })
    }
    return (
        <PayPalButtons 
            style={{
                color:"silver",
                layout:"vertical",
                tagline:false,
                shape:"pill"
            }}
            // onClick={(data, actions)=>{
            onClick={(_, actions)=>{
                //Validate on button click, client or server side
                const hasAlreadyBoughtSubscription = false
                if(hasAlreadyBoughtSubscription){
                    setError("Ya compraste esta suscripción, disfruta de los beneficios que tenemos para ti")
                    return actions.reject()
                }else{
                    return actions.resolve()
                }

            }}
            createOrder={(_, actions)=>{
                //actions create an order with the transaction details
                return actions.order.create({
                    purchase_units:[
                        {
                            description:product.description,
                            amount:{
                                currency_code:"USD",
                                value:product.price
                            }
                        }
                    ]
                })
            }}
            onApprove={async (data, actions)=>{
                //capture the order, capture is a promise function that returns the order
                const order = await actions.order?.capture()
                console.log("order", order)

                handleApprove(data.orderID)
            }}
            onError={(error)=>{
                setError(error)
                console.error("Paypal Checkout on Error",error)
                onClose()
            }}
            onCancel={()=>{
                //Display the cancel message
                Swal.fire({
                    title: "Has decidido cancelar tu compra",
                    icon: "info",
                    footer: `Esperamos vuelvas pronto`,
                    timer: 2000,
                })
                onClose()
            }}
            />
        )
    }
    // function createOrder() {
    //     return fetch("/my-server/create-paypal-order", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         // use the "body" param to optionally pass additional order information
    //         // like product ids and quantities
    //         body: JSON.stringify({
    //             cart: [
    //                 {
    //                     id: "YOUR_PRODUCT_ID",
    //                     quantity: "YOUR_PRODUCT_QUANTITY",
    //                 },
    //             ],
    //         }),
    //     })
    //         .then((response) => response.json())
    //         .then((order) => order.id);
    // }
    // function onApprove(data) {
    //       return fetch("/my-server/capture-paypal-order", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           orderID: data.orderID
    //         })
    //       })
    //       .then((response) => response.json())
    //       .then((orderData) => {
    //             const name = orderData.payer.name.given_name;
    //             alert(`Transaction completed by ${name}`);
    //       });

    //     }
    // }