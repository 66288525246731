import { Box } from "@mui/material"
// import PanelTabs from "../Dashboard/Sidebar/Tabs"
import { PanelTabsUsers, TableUsers } from "."
import { CreateAdmin } from ".."
import { useEffect, useState } from "react"
import { useAuth } from "@/utilities"
import { UserAdminProfile, facultyProps } from "@/types"
import facultyService from "@/api/faculty.service"
import userAdminService from "@/api/userAdmin.service"


export const TabsUsers= () => {

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (tab:number) => {
    setSelectedTab(tab);
  };  
  
  const {user} = useAuth()
  const role = user?.userData.role
  
  const [adminUsers, setAdminUsers] = useState<UserAdminProfile[]>([])
  const [faculties, setFaculties] = useState<facultyProps[] | null>([])

  useEffect(()=>{
    async function fetchFaculties() {
        if(user && user.userData.institute){
            try {
                const getFaculties = await facultyService.getAll(user.userData.institute)
                setFaculties(getFaculties)
            } catch (error) {
                console.error("Error trying to get faculties", error)
            }
        }            
    }
    fetchFaculties()
  },[user])

  useEffect(()=>{
    async function fetchAdminUsers() {
        if(role === "Super Administrador"){
            try {
                const adminUsers = await userAdminService.getUsersAdmins()
                setAdminUsers(adminUsers)
            } catch (error) {
                console.error("Error trying to get users", error)
            }
        }else if(role === "Administrador IES" && user?.userData.institute)  {
          try {
                const adminUsers = await userAdminService.getUsersAdminFaculty(user.userData.institute)
                setAdminUsers(adminUsers)
          } catch (error) {
            console.error("Error trying to get users", error)
            
          }
        }          
    }
    fetchAdminUsers()
},[role, user?.userData.institute])

    const data = [
        {
            name: "Crear usuario",
            link: <CreateAdmin adminUsers={adminUsers} faculties={faculties} handleChangeTab={handleChangeTab}/>
        },
        {
          name: "Usuarios",
          link: <TableUsers adminUsers={adminUsers} setAdminUsers={setAdminUsers} faculties={faculties}/>
        }
  
    ]
  return (
    <Box>
        <PanelTabsUsers data={data} selectedTab={selectedTab}  setSelectedTab={setSelectedTab}/>
    </Box>
  )
}
