import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Button, FormControl,  MenuItem, Select, SelectChangeEvent, TextField, useTheme } from '@mui/material';
import { dialCodes, tokens } from '@/utilities';
import { UserAdminProfile, facultyProps } from '@/types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import { EditableInput } from '@/utilities/Components';
import Swal from 'sweetalert2';
import userAdminService from '@/api/userAdmin.service';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

interface Props {
    selectedAdmin:UserAdminProfile
  isModalOpen: boolean; 
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateAdminUsers: (adminToUpdate: UserAdminProfile) => void,
  faculties: facultyProps[] | null
  adminUsers:UserAdminProfile[] | []

}

export function ModalDetailAdmin({ selectedAdmin, isModalOpen,setIsModalOpen, updateAdminUsers,adminUsers, faculties}:Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    console.log(selectedAdmin)
  
  const [valuesChanged, setValuesChanged] = useState(false)
  const handleClose = () => {
    if(valuesChanged){
      Swal.fire({
        title: '¿Deseas guardar los cambios antes de salir?',
        text: 'Los cambios no guardados se perderán.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        allowOutsideClick: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleFormSubmit()
          setValuesChanged(false)
          setIsModalOpen(false);
        }else{
          setEditableData(selectedAdmin)
          setIsModalOpen(false)
          setValuesChanged(false)
          setIsEditing(false)
        }
      })
   }else{
    setIsModalOpen(false)
    setIsEditing(false)
   }
  }

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editableData, setEditableData] = useState<UserAdminProfile>(selectedAdmin)
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false)
  // const [faculties, setFaculties] = useState<facultyProps[] | null>([])
  const usedFaculties = adminUsers && adminUsers.map((admin) => admin.faculty);

  const filteredFaculties = faculties?.filter(
    (faculty) => !usedFaculties.includes(faculty.name)
  );

  console.log(faculties)

  const handleFieldChange = (event: React.ChangeEvent<any> | SelectChangeEvent<any>)=>{
    const {name, value} = event.target;

    if (name === 'email') {
      setEditableData((prevUser) => ({ ...prevUser, [name]: value }));
      setEmailError(!isValidEmail(value));
    }else if(name === "phoneNumber"){
      setEditableData((prevUser) => ({ ...prevUser, [name]: value }));
      setPhoneError(!isValidPhone(value))
    }
    setEditableData(prevData=>({
        ...prevData,
        [name]: value,
    }))
    setValuesChanged(true)
}

const isValidEmail = (email:string) => {
    const isEduEmail = email.toLowerCase().includes('.edu');
    return isEduEmail;
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneno=/^\d+$/;
    if(phone.match(phoneno)){
      return true
    }else{
      return false
    }
  };

  const [country, setCountry] = useState(editableData.phone_code_country ||'Prefijo');
  const handleCountryChange = (event:any) => {
    setCountry(event.target.value);
    setEditableData({
        ...editableData,
        phone_code_country:event.target.value
    })
  };

  async function  handleFormSubmit() {
    try {

        if (JSON.stringify(editableData) === JSON.stringify(selectedAdmin)) {
            setIsEditing(false);
            return;
        }
        
        if(selectedAdmin.uid){
          const uidFaculty = faculties?.filter((faculty) => faculty.name === editableData.faculty)[0].id;
          console.log(uidFaculty)
          const data={
            ...editableData,
            uidFaculty,
          }
          await userAdminService.updateUserData(selectedAdmin.uid, data)
          updateAdminUsers(editableData)
          setIsModalOpen(false)
          setValuesChanged(false)
          Swal.fire({
            title:"Los cambios se han guardado exitosamente",
            icon:"success",
            timer:2000
          })
          
        }
        setIsEditing(!isEditing)
        
    } catch (error) {
        console.error('Error updating information', error)
    }
}
useEffect(()=>{
  try {
    setEditableData(selectedAdmin)
  } catch (error) {
    console.error(error)
  }  
},[selectedAdmin])

// useEffect(()=>{
//     async function fetchFaculties() {
//         if(user && user.userData.institute){
//             try {
//                 const getFaculties = await facultyService.getAll(user.userData.institute)
//                 setFaculties(getFaculties)
//             } catch (error) {
//                 console.error("Error trying to get faculties", error)
//             }
//         }            
//     }
//     fetchFaculties()
//   },[user])

  return (
    <Box>
      <Dialog
        
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
   
      >
        <Box sx={{width:"100%", minWidth:{xs:"300px", sm:"550px",  md:"700px", overflowX:"hidden"}}}>

            <AppBar sx={{ position: 'sticky', backgroundColor:'#5394d4'}}>
            <Toolbar sx={{display:"flex", justifyContent:"space-between"}} >
                <Typography sx={{ flex: 1, fontWeight:"Bold", p:"0 8px" }} variant="h5" >
                {selectedAdmin.displayName}
                </Typography>
                <Button 
                    onClick={handleClose}
                    sx={{
                    color:colors.grey[900],
                    display:"flex",
                    textDecoration:"none",
                    gap:"10px",
                    borderRadius:"5px",
                    fontWeight:"bold",
                    textTransform:"none",
                    fontSize:"16px",
                    width:"fit-content",
                    "&:hover":{ backgroundColor: colors.grey[100], cursor:"pointer"}

                    }}
                >
                    <ArrowBackIcon/>
                    Volver 
            </Button>
            </Toolbar>
            </AppBar>

            <Box sx={{display:"flex", width:"100%", flexWrap:"wrap", justifyContent:"center", alignItems:"center", backgroundColor:colors.background.primary, paddingBottom:"40px", minHeight:"400px", overflowY:"auto"}}>

                <Box sx={{
                                position:"relative", 
                                width:{xs:"90%", sm:"70%"},
                                display:"flex",
                                flexDirection:"column",
                                }}>


                            {isEditing
                            ? <Box sx={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                                <Button  variant="contained" 
                                sx={{position:"relative", 
                                margin:"20px 0",                 
                                display:"flex"
                                }}  onClick={handleFormSubmit}
                                disabled={emailError || phoneError}
                                >Guardar</Button>
                                {/* {!isEditable && (
                                    <Typography variant="h5" sx={{margin:"0", color:"red"}}>{premiumPlan}</Typography>
                                )} */}
                            
                            </Box>
                            : 
                            <Box sx={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                            <Button variant="contained" sx={{
                            position:"relative", 
                            margin:"20px 0",                 
                            display:"flex"
                            
                            }} onClick={()=> {
                              setIsEditing(!isEditing)
                              }
                            }
                            >Editar</Button>
                            </Box>
                        }

                        
                      <Box sx={{marginBottom:"20px", display:"flex", width:"100%", flexDirection:"column"}}>
                        <Typography variant='h5' sx={{fontWeight:"bold"}} color={colors.grey[100]}>Nombre completo</Typography>
                        <EditableInput 
                          value={editableData.displayName}
                          name='displayName'
                          type="text"
                          isEditing={isEditing}
                          onChange={handleFieldChange}
                          />
                      </Box>
                      
                      <Box sx={{marginBottom:"20px", display:"flex", width:"100%", flexDirection:"column"}}>
                        <Typography variant='h5' sx={{fontWeight:"bold"}} color={colors.grey[100]}>Correo electrónico</Typography>
                        <EditableInput 
                          value={editableData.email}
                          name='email'
                          type="text"
                          error={emailError}
                          helperText={emailError ? 'El correo electrónico debe tener la extensión .edu' : ''}
                          isEditing={isEditing}
                          isDisabled={true}
                          onChange={handleFieldChange}
                          />
                      </Box>
                                            
                      
                                            
                      <Box sx={{marginBottom:"20px", display:"flex", width:"100%", flexDirection:"column"}}>
                        <Typography variant='h5' sx={{fontWeight:"bold"}} color={colors.grey[100]}>Tipo de administrador</Typography>
                      {isEditing?
                          <FormControl fullWidth size="small">
                          {/* <InputLabel id="role" sx={{color:`${colors.grey[100]} !important`, fontSize:"16px", backgroundColor:colors.background.primary, padding:"0 2px"}}>Tipo de administrador</InputLabel> */}
                          
                          <Select
                            required
                            labelId="role"
                            id="role"
                            name="role"
                            size="small"
                            value={editableData.role}
                            onChange={handleFieldChange} 
                            sx={{ color: colors.grey[100], backgroundColor:colors.background.primary, height:"45px", fontSize:16}}
                          >
                              <MenuItem value="Administrador Facultad" sx={{fontSize:"16px"}}>Administrador Facultad</MenuItem>
                            </Select>
                          </FormControl>
                        : <Typography variant='h5' color={colors.grey[200]}>{editableData.role? editableData.role : "Aún no asignado"}</Typography>                    
                      }
                      </Box>

                      
                      <Box sx={{marginBottom:"20px", display:"flex", width:"100%", flexDirection:"column"}}>
                        <Typography variant='h5' sx={{fontWeight:"bold"}} color={colors.grey[100]}>Teléfono de contacto</Typography>
                        {isEditing?
                        <FormControl id="phone" variant="outlined" fullWidth size="small" sx={{ display: "flex", flexDirection: "row" }}>
                           <Select
                            value={country}
                            id="phone_code_country"
                            label="Prefijo"
                            name="phone_code_country"
                            onChange={handleCountryChange}
                            sx={{ display: "flex", border: "none", width: {xs:"40%", sm:"45%"}, color:colors.grey[100], fontSize:16  }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        width: '40ch',
                                        right:0,
                                        margin: '0 0 0 24px',
                                        color:colors.grey[100] 
                                    },
                                },
                            }}
                        >
                            {dialCodes.map((country) => (
                            <MenuItem key={country.code} value={country.dial_code}>
                                ({country.dial_code}) {country.name}
                            </MenuItem>
                            ))}
                        </Select>
                          <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text" 
                          defaultValue={editableData.phoneNumber}
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          onChange={handleFieldChange}
                          error={phoneError}
                          helperText={phoneError ? 'Sólo se permiten números' : ''}
                          InputLabelProps={{
                              style: { color: colors.grey[100],
                              fontSize:"16px",
                            }
                            }}
                            InputProps={{
                              style:{ color: colors.grey[100],
                                fontSize:"16px",
                                width:"100%"
                              }
                            }}
                          />
                        </FormControl>

                        :<Typography variant='h5' color={colors.grey[200]}>
                           {`${editableData.phone_code_country ? `(${editableData.phone_code_country})` : ""}  ${editableData.phoneNumber? editableData.phoneNumber : ""}`}
                        </Typography>
                          }
                      
                      </Box>
                      
                      <Box sx={{marginBottom:"20px", display:"flex", width:"100%", flexDirection:"column"}}>
                      <Typography variant='h5' sx={{fontWeight:"bold"}} color={colors.grey[100]}>Facultad asignada</Typography>
                        {isEditing?
                            <FormControl fullWidth size="small" >
                                {/* <InputLabel id="faculty" sx={{color:`${colors.grey[100]} !important`, fontSize:"16px", backgroundColor:colors.background.primary, padding:"0 2px"}}>Elija la facultad</InputLabel> */}
                                <Select
                                id="faculty"
                                name="faculty"
                                size="small"
                                required
                                labelId="faculty"
                                value={editableData.faculty}
                                onChange={handleFieldChange} 
                                sx={{
                                    color: colors.grey[100],
                                    height:"45px",
                                    fontSize:16
                                    // '& .MuiOutlinedInput-notchedOutline': {
                                    //   borderColor: colors.grey[100],
                                    // },
                                    // '&:hover .MuiOutlinedInput-notchedOutline': {
                                    //   borderColor: colors.grey[100],
                                    // },
                                    // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    //   borderColor: colors.grey[100],
                                    // },
                                }}
                                >
                                <MenuItem  key={editableData.faculty} value={editableData.faculty} sx={{fontSize:"16px"}}>{editableData.faculty}</MenuItem>
                                {filteredFaculties?.map((faculty)=>(
                                <MenuItem  key={faculty.id} value={faculty.name} sx={{fontSize:"16px"}}>{faculty.name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        :<Typography variant='h5' color={colors.grey[200]}>{editableData.faculty ? editableData.faculty : "Aún no se ha asignado una facultad"}</Typography>
                            }
                        </Box>

                </Box>
                
            </Box>
        </Box>
      </Dialog> 
    </Box>
  );
}