import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { TableDetail } from '@/components';
import './Modals.scss'
import { InstituteProps } from '@/types';
import { Box, useTheme } from '@mui/material';
import { tokens } from '@/utilities';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

interface Props {
  selectedInstituteData:InstituteProps
  isModalOpen: boolean; 
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalTableDetail({selectedInstituteData, isModalOpen,setIsModalOpen}:Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // React.useEffect(() => {
  //   setOpen(false);
  // }, [selectedInstituteData]);

  return (
    <Box>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky', backgroundColor:'#3F51B5', opacity:'0.7'}}>
          <Toolbar >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{paddingLeft:'5%', "&:hover":{backgroundColor:"transparent"}}}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedInstituteData.name}
            </Typography>
            <Typography sx={{ ml: 2, paddingRight:'3%'}} variant="h6" component="div">
              {selectedInstituteData.email}
            </Typography>
            {/* <Button autoFocus sx={{paddingRight:'4%'}} color="inherit" onClick={handleClose}>
              Guardar
            </Button> */}
          </Toolbar>
        </AppBar>
          <Box sx={{backgroundColor:colors.primary[500], display:"flex", width:"100%"}}>
            <TableDetail selectedInstituteData={selectedInstituteData}/>
          </Box>
      </Dialog>
    </Box>
  );
}