import { ContactSocialMedia } from "@/types";
import {
  EditableInput,
  isValidPhone,
  isValidUrl,
  SwitchButton,
  tokens,
} from "@/utilities";
import {
  Box,
  InputLabel,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

interface Props {
  values: ContactSocialMedia;
  setSocialMedia: (contactInfo: any) => void;
}
type GeneralChangeEvent =
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent<string>;

type DefaultSwitchValues = keyof ContactSocialMedia;

export const SocialMediaForm = ({ values, setSocialMedia }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [whatsappError, setWhatsappError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);

  const handleSocialMediaChange = (event: GeneralChangeEvent) => {
    const { name, value } = event.target;
    switch (name) {
      case "whatsapp":
        setWhatsappError(!isValidPhone(value));
        break;
      case "facebook":
        setFacebookError(!isValidUrl(value, ["fb", "facebook.com"]));
        break;
      case "linkedin":
        setLinkedinError(!isValidUrl(value, ["linkedin.com", "lnkd.in"]));
        break;
      default:
        break;
    }
    setSocialMedia({
      ...values,
      [name]: value,
    });
  };

  const handleSwitch = (switchName: DefaultSwitchValues) => {
    setSocialMedia((prevValues: ContactSocialMedia) => {
      return {
        ...prevValues,
        [switchName]: !prevValues[switchName],
      };
    });
  };
  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
        gap: "1rem",
      }}
    >
      <InputLabel
        sx={{
          color: colors.grey[100],
          fontWeight: "bold",
          fontSize: "16px",
        }}
        htmlFor="socialMedia"
      >
        Redes sociales
      </InputLabel>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
          wordWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {/*-----Whatsapp-----*/}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <EditableInput
            value={values.whatsapp}
            name="whatsapp"
            type="text"
            label="Whatsapp"
            error={whatsappError && (values.hasWhatsapp ?? false)}
            helperText={
              whatsappError && values.hasWhatsapp
                ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                : ""
            }
            isEditing={true}
            isDisabled={!values.hasWhatsapp}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <SwitchButton
            checked={values.hasWhatsapp ?? false}
            setChecked={() => handleSwitch("hasWhatsapp")}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp Activo
          </Typography>
        </Box>

        {/*-----Linkedin-----*/}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <EditableInput
            value={values.linkedin}
            name="linkedin"
            type="text"
            label="LinkedIn"
            error={linkedinError && (values.hasLinkedin ?? false)}
            helperText={
              linkedinError && values.hasLinkedin
                ? "La URL debe tener dominio linkedin.com o lnkd.in"
                : ""
            }
            isEditing={true}
            isDisabled={!values.hasLinkedin}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <SwitchButton
            checked={values.hasLinkedin ?? false}
            setChecked={() => handleSwitch("hasLinkedin")}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Linkedin Activo
          </Typography>
        </Box>

        {/*-----Facebook-----*/}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <EditableInput
            value={values.facebook}
            name="facebook"
            type="text"
            label="Facebook"
            error={facebookError && (values.hasFacebook ?? false)}
            helperText={
              facebookError && values.hasFacebook
                ? "La URL debe tener dominio facebook o fb"
                : ""
            }
            isEditing={true}
            isDisabled={!values.hasFacebook}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
      
          <SwitchButton
            checked={values.hasFacebook ?? false}
            setChecked={() => handleSwitch("hasFacebook")}
          />
              <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Facebook Activo
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
