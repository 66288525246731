import { Page, Document, Image, StyleSheet, Text } from '@react-pdf/renderer';
import logo from '../../assets/Logo_R.png'
import { PDFTitle } from './PDFTitle';
import { TopCareers } from './TopCareers';
import { TopCareersByInstitution } from './TopCareersByInstitution';
import { ConnectionByDevice } from './ConnectionByDevice';
import { ImageUsersByRegion } from './ImageUsersByRegion';
import { TableUsersByCountry } from '@/utilities';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        paddingBottom:50,
        lineHeight: 1,
    }, 
    headerText: {
        fontSize: 8,
        position: 'absolute',
        bottom: 30,
        left: 40,
        right: 0,
        textAlign: 'left',
        color: 'grey',
        
      },
    logo: {
        height: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 40,
        textAlign: 'right',
        color: 'grey',
      },
  });


  export const ReportPDF = ({data}:{data:any}) => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Image style={styles.logo} src={logo} />
                    <PDFTitle title='Reporte Guía Universitaria' />
                    <TopCareers data={data.getTopCareers} />
                    <TopCareersByInstitution data={data.getTopCareersByInstitute}/>
                    <Text style={styles.headerText} fixed> {data.reporte}</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
                <Page size="A4" style={styles.page}>
                    <ConnectionByDevice data={data.connection}/>
                    <ImageUsersByRegion imgBase64={data.imgBase64}/>
                    <Text style={styles.headerText} fixed> {data.reporte}</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
                <Page size="A4" style={styles.page}>
                    {/* <TableUsersByRegion data={data.usersByRegion}/> */}
                    <TableUsersByCountry data={data.usersByRegion}/>
                    {/* <ConnectionDeviceImage data={data.imageDevice}/> */}
                    {/* <UsersByCountry data={data} /> */}
                    {/* <InvoiceThankYouMsg /> */}
                    <Text style={styles.headerText} fixed> {data.reporte}</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        );
  
