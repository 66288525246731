import {  StyleSheet, View, Text} from '@react-pdf/renderer';
import { Fragment } from 'react';



const borderColor = '#90e5fc'
const styles = StyleSheet.create({
  page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 30,
      paddingLeft:60,
      paddingRight:60,
      lineHeight: 1.5,
      flexDirection: 'column',
  }, 
  logo: {
      width: 74,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto'
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
},
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  country: {
    width: '70%',
    borderRightColor: borderColor,
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  city: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
  },
  qty: {
    width: '30%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%'
  },

  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
},
  countryRow: {
    width: '70%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
},
qtyRow: {
    width: '30%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
},
cityRow: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
},
amountRow: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
},

rowFooter: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  height: 24,
  fontSize: 12,
  fontStyle: 'bold',
},
descriptionFooter: {
  width: '80%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingRight: 8,
},
total: {
  width: '20%',
  textAlign: 'right',
  paddingRight: 8,
},

});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
      <Text style={styles.country}>País</Text>
      {/* <Text style={styles.city}>Ciudad</Text> */}
      <Text style={styles.qty}>Cantidad</Text>
      {/* <Text style={styles.rate}>@</Text> */}
      {/* <Text style={styles.amount}>Amount</Text> */}
  </View>
);

const InvoiceTableRow = ({items}:{items:any}) => {
  const rows = items.map( (item:any, index:number) => 
      <View style={styles.row} key={index}>
          <Text style={styles.countryRow}>{item.country}</Text>
          {/* <Text style={styles.cityRow}>{item.city}</Text> */}
          <Text style={styles.qtyRow}>{item.total_user_count}</Text>
          {/* <Text style={styles.amountRow}>{(item.qty * item.rate).toFixed(2)}</Text> */}
      </View>
  )
  return (<Fragment>{rows}</Fragment> )
};

const InvoiceTableFooter = ({total}:{total:number}) => {
  // const total = items.map(item => item.qty * item.rate)
  //     .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
  return(    
      <View style={styles.rowFooter}>
          <Text style={styles.descriptionFooter}>TOTAL</Text>
          <Text style={styles.total}>{total}</Text>
      </View>
  )
};

export const TableUsersByCountry = ({data}:{data:any}) => (
  
  <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={data} />
      {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
      <InvoiceTableFooter total={data.reduce((accumulator: number, currentValue:any) => {
        return accumulator + currentValue.total_user_count;
      }, 0)} />
  </View>
);


// export const TableUsersByRegion = ({data}:{data:any}) => (
//     //     <Page size="A4" style={styles.page}>
//     <View>
//         {data.map((item: any, index:number) => (
//           <InvoiceItemsTable key={index} data={item} />
//         ))}    
//     </View>
//     //     </Page> 

// )


