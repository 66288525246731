import {
  dialCodes,
  isValidEmail,
  isValidPhone,
  isValidUrl,
  SwitchButton,
  tokens,
} from "@/utilities";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { EditableInput } from "@/utilities/Components";
import { useState } from "react";
import { CareerProps, ContactSocialMedia, DefaultValue } from "@/types";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";
import { getContactInfoData, getSocialMediaData, hasContactInfoData, hasContactSocialMediaData } from "./formaters.hook";

interface Props {
  premiumPlan: string;
  isEditable: boolean;
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  fieldsToEdit:string
}

export const ContactInfo = ({
  premiumPlan,
  isEditable,
  selectedCareer,
  updateCareer,
  fieldsToEdit
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  //--------------Social Medias---------------
  const [whatsappError, setWhatsappError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);

  const [isEditingContactInfo, setIsEditingContactInfo] =
    useState<boolean>(false);

  const [defaultContactInfoValue, setDefaultContactInfoValue] = useState(
    selectedCareer?.contactInfo?.default || "ies"
  );
  const [defaultContactSocialValue, setDefaultContactSocialValue] = useState(
    selectedCareer?.contactSocialMedia?.default || "ies"
  );



  const [editableContactInfo, setEditableContactInfo] = useState<
    Partial<CareerProps>
  >({
    webSite: selectedCareer.webSite || "",
    webSiteFaculty: selectedCareer.webSiteFaculty || "",
    webSiteCareer: selectedCareer.webSiteCareer || "",
    contactInfo: {
      default: defaultContactInfoValue,
      ies: getContactInfoData(selectedCareer?.contactInfo?.ies, {}),
      faculty: getContactInfoData(
        selectedCareer?.contactInfo?.faculty,
        selectedCareer?.contactInfo?.ies
      ),
      career: getContactInfoData(
        selectedCareer?.contactInfo?.career,
        hasContactInfoData(selectedCareer?.contactInfo?.faculty)
          ? selectedCareer.contactInfo?.faculty
          : selectedCareer.contactInfo?.ies
      ),
    },
    contactSocialMedia: {
      default: defaultContactSocialValue,
    ies: getSocialMediaData(selectedCareer?.contactSocialMedia?.ies, {}),
    faculty: getSocialMediaData(selectedCareer?.contactSocialMedia?.faculty, selectedCareer?.contactSocialMedia?.ies),
    career: getSocialMediaData(
      selectedCareer?.contactSocialMedia?.career,
      hasContactSocialMediaData(selectedCareer?.contactSocialMedia?.faculty)
      ? selectedCareer.contactSocialMedia?.faculty
      : selectedCareer?.contactSocialMedia?.ies ?? {}
  ),
    },
  });

  function getWebSiteValue(defaultContactInfoValue: DefaultValue) {
    switch (defaultContactInfoValue) {
      case 'career':
        return editableContactInfo.webSiteCareer;
      case 'faculty':
        return editableContactInfo.webSiteFaculty;
      case 'ies':
        return editableContactInfo.webSite;
      default:
        return '';
    }
  }

  const [country, setCountry] = useState(
    editableContactInfo.contactInfo?.[defaultContactInfoValue]
      ?.phone_code_country || "Prefijo"
  );

  const handleChangeContactInfo = (
    event: React.MouseEvent<HTMLElement>,
    contactInfoDefault: DefaultValue
  ) => {
    if (contactInfoDefault != null) {
      event.preventDefault();
      setDefaultContactInfoValue(contactInfoDefault);
      setEditableContactInfo((prevInfo) => ({
        ...prevInfo,
        contactInfo: {
          ...prevInfo.contactInfo,
          default: contactInfoDefault,
        },
      }));
    }
  };

  const handleChangeContactSocial = (
    event: React.MouseEvent<HTMLElement>,
    contactSocialDefault: DefaultValue
  ) => {
    if (contactSocialDefault != null) {
      event.preventDefault();
      setDefaultContactSocialValue(contactSocialDefault);
      setEditableContactInfo((prevInfo) => ({
        ...prevInfo,
        contactSocialMedia: {
          ...prevInfo.contactSocialMedia,
          default: contactSocialDefault,
        },
      }));
    }
  };

  const handleContactInfoChange = (
    event: React.ChangeEvent<any> | SelectChangeEvent<any>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "contactEmail":
        setEmailError(!isValidEmail(value));
        break;
      case "phone_code_country":
        setCountry(value);
        break;
      case "contactPhone":
        setPhoneError(!isValidPhone(value));
        break;
      default:
        break;
    }
    setEditableContactInfo((prevInfo) => ({
      ...prevInfo,
      contactInfo: {
        ...prevInfo.contactInfo,
        default: prevInfo.contactInfo?.default || "ies",
        career: {
          ...prevInfo.contactInfo?.career,
          [name]: value,
        },
      },
    }));
    setEditableContactInfo((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSocialMediaChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    const name: "facebook" | "linkedin" | "whatsapp" = event.target.name; // Type as a union

    switch (name) {
      case "whatsapp":
        setWhatsappError(!isValidPhone(value));
        break;
      case "facebook":
        setFacebookError(!isValidUrl(value, ["fb", "facebook.com"]));
        break;
      case "linkedin":
        setLinkedinError(!isValidUrl(value, ["linkedin.com", "lnkd.in"]));
        break;
      default:
        break;
    }
    setEditableContactInfo((prevInfo) => {
      const career = prevInfo.contactSocialMedia?.career as ContactSocialMedia;
      career[name] = value;

      return {
        ...prevInfo,
        contactSocialMedia: {
          ...prevInfo.contactSocialMedia,
          default: prevInfo.contactInfo?.default || "ies",
          career,
        },
      };
    });
  };
  async function handleContactInfoSubmit() {
    try {
      const selectedContactInfo = {
        contactEmail:
          selectedCareer?.contactInfo?.[defaultContactInfoValue]
            ?.contactEmail || "",
        phone_code_country:
          selectedCareer?.contactInfo?.[defaultContactInfoValue]
            ?.phone_code_country || "Prefijo",
        contactPhone:
          selectedCareer?.contactInfo?.[defaultContactInfoValue]
            ?.contactPhone || null,
        webSite: selectedCareer.webSite || "",
        facebook:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.facebook || "",
        hasFacebook:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.hasFacebook || false,
        whatsapp:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.whatsapp || null,
        hasWhatsapp:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.hasWhatsapp || false,
        linkedin:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.linkedin || "",
        hasLinkedin:
          selectedCareer?.contactSocialMedia?.[defaultContactSocialValue]
            ?.hasLinkedin || false,
      };
      if (
        JSON.stringify(editableContactInfo) ===
        JSON.stringify(selectedContactInfo)
      ) {
        setIsEditingContactInfo(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (selectedCareer.id) {
        const data = {
          ...editableContactInfo,
        };
        console.warn("Updating in Database ...");
        
        await careersService.update(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          data
        );
        updateCareer(data, selectedCareer.id);
        console.warn("Updated in Database!");
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        // sessionStorage.setItem("currentCareers", JSON.stringify)
      }
      setIsEditingContactInfo(!isEditingContactInfo);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  }

  const handleContactInfoSwitchChange = (switchName: string) => {
    setEditableContactInfo((prevValues) => {
      const updatedContactSocialMedia = {
        ...prevValues.contactSocialMedia,
        default: prevValues.contactSocialMedia?.default || "ies",
      };
      const career = updatedContactSocialMedia.career;
      if (career) {
        switch (switchName) {
          case "hasFacebook":
            career.hasFacebook = !career.hasFacebook;
            break;
          case "hasWhatsapp":
            career.hasWhatsapp = !career.hasWhatsapp;
            break;
          case "hasLinkedin":
            career.hasLinkedin = !career.hasLinkedin;
            break;
          default:
            break;
        }
      }
      return {
        ...prevValues,
        contactSocialMedia: updatedContactSocialMedia,
      };
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingContactInfo ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
              // disabled={emailError || phoneError}
            }}
            onClick={handleContactInfoSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() => setIsEditingContactInfo(!isEditingContactInfo)}
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h3"
      >
        Información de Contacto
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      {isEditingContactInfo && (
        <Box sx={{ marginBottom: "20px", width: "100%" }}>
          <ToggleButtonGroup
            color="primary"
            value={defaultContactInfoValue}
            exclusive
            onChange={handleChangeContactInfo}
            aria-label="Platform"
          >
            <ToggleButton value="ies">
              Reusa datos de la Institución
            </ToggleButton>
            <ToggleButton value="faculty">
              Reusa datos de la Facultad
            </ToggleButton>
            <ToggleButton value="career">Usa datos personalizados</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Web/Enlace/Brochure
        </Typography>
        <EditableInput
          // value={getWebSiteValue(defaultContactInfoValue)}
          value={editableContactInfo.contactInfo?.[defaultContactInfoValue]
            ?.webSite}
          name="webSite"
          type="text"
          isEditing={
            isEditingContactInfo && defaultContactInfoValue === fieldsToEdit
          }
          onChange={handleContactInfoChange}
          isDisabled={!isEditable}
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Email/Contacto admisiones
          </Typography>
          <EditableInput
            value={
              editableContactInfo.contactInfo?.[defaultContactInfoValue]
                ?.contactEmail
            }
            name="contactEmail"
            type="text"
            error={emailError}
            helperText={
              emailError
                ? "El correo electrónico debe tener la extensión .edu"
                : ""
            }
            isEditing={
              isEditingContactInfo && defaultContactInfoValue === fieldsToEdit
            }
            onChange={handleContactInfoChange}
            isDisabled={!isEditable}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Teléfono de contacto
          </Typography>
          {isEditingContactInfo && defaultContactInfoValue === fieldsToEdit ? (
            <FormControl
              id="phone"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Select
                value={country}
                id="phone_code_country"
                label="Prefijo"
                name="phone_code_country"
                onChange={handleContactInfoChange}
                disabled={!isEditable}
                sx={{
                  display: "flex",
                  border: "none",
                  width: { xs: "40%", sm: "45%" },
                  color: colors.grey[100],
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: "40ch",
                      right: 0,
                      margin: "0 0 0 24px",
                      color: colors.grey[100],
                    },
                  },
                }}
              >
                {dialCodes.map((country) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    ({country.dial_code}) {country.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="contactPhone"
                name="contactPhone"
                type="text"
                defaultValue={
                  editableContactInfo.contactInfo?.[defaultContactInfoValue]
                    ?.contactPhone ?? ""
                }
                fullWidth
                autoComplete="off"
                variant="outlined"
                onChange={handleContactInfoChange}
                disabled={!isEditable}
                error={phoneError}
                helperText={phoneError ? "Sólo se permiten números" : ""}
                InputLabelProps={{
                  style: { color: colors.grey[100], fontSize: "16px" },
                }}
                InputProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    width: "100%",
                  },
                }}
              />
            </FormControl>
          ) : (
            <Typography variant="h5" color={colors.grey[200]}>
              {`${
                editableContactInfo.contactInfo?.[defaultContactInfoValue]
                  ?.phone_code_country
                  ? `(${editableContactInfo.contactInfo?.[defaultContactInfoValue]?.phone_code_country})`
                  : ""
              }  ${
                editableContactInfo.contactInfo?.[defaultContactInfoValue]
                  ?.contactPhone
                  ? editableContactInfo.contactInfo?.[defaultContactInfoValue]
                      ?.contactPhone
                  : ""
              }`}
            </Typography>
          )}
        </Box>
      </Box>

      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h4"
      >
        Redes Sociales
      </Typography>

      {isEditingContactInfo && (
        <Box sx={{ marginBottom: "20px", width: "100%" }}>
          <ToggleButtonGroup
            color="primary"
            value={defaultContactSocialValue}
            exclusive
            onChange={handleChangeContactSocial}
            aria-label="Platform"
          >
            <ToggleButton value="ies">
              Reusa datos de la Institución
            </ToggleButton>
            <ToggleButton value="faculty">
              Reusa datos de la Facultad
            </ToggleButton>
            <ToggleButton value="career">Usa datos personalizados</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        {/*-----Whatsapp-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp
          </Typography>
          <EditableInput
            value={
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.whatsapp
            }
            name="whatsapp"
            type="text"
            error={
              whatsappError &&
              (editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasWhatsapp ??
                false)
            }
            helperText={
              whatsappError &&
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasWhatsapp
                ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                : ""
            }
            isEditing={
              isEditingContactInfo && defaultContactSocialValue === fieldsToEdit
            }
            isDisabled={
              !isEditable ||
              !editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasWhatsapp
            }
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp Activo
          </Typography>
          {isEditingContactInfo &&
          defaultContactSocialValue === fieldsToEdit ? (
            <SwitchButton
              checked={
                editableContactInfo.contactSocialMedia?.career?.hasWhatsapp ??
                false
              }
              setChecked={() => handleContactInfoSwitchChange("hasWhatsapp")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasWhatsapp
                ? "Si"
                : "No"}
            </Typography>
          )}
        </Box>

        {/*-----Linkedin-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Linkedin
          </Typography>
          <EditableInput
            value={
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.linkedin
            }
            name="linkedin"
            type="text"
            error={
              linkedinError &&
              (editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasLinkedin ??
                false)
            }
            helperText={
              linkedinError &&
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasLinkedin
                ? "La URL debe tener dominio linkedin.com o lnkd.in"
                : ""
            }
            isEditing={
              isEditingContactInfo && defaultContactSocialValue === fieldsToEdit
            }
            isDisabled={
              !isEditable ||
              !editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasLinkedin
            }
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Linkedin Activo
          </Typography>
          {isEditingContactInfo &&
          defaultContactSocialValue === fieldsToEdit ? (
            <SwitchButton
              checked={
                editableContactInfo.contactSocialMedia?.career?.hasLinkedin ??
                false
              }
              setChecked={() => handleContactInfoSwitchChange("hasLinkedin")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasLinkedin
                ? "Si"
                : "No"}
            </Typography>
          )}
        </Box>

        {/*-----Facebook-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Facebook
          </Typography>
          <EditableInput
            value={
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.facebook
            }
            name="facebook"
            type="text"
            error={
              facebookError &&
              (editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasFacebook ??
                false)
            }
            helperText={
              facebookError &&
              editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasFacebook
                ? "La URL debe tener dominio facebook o fb"
                : ""
            }
            isEditing={
              isEditingContactInfo && defaultContactSocialValue === fieldsToEdit
            }
            isDisabled={
              !isEditable ||
              !editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasFacebook
            }
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Facebook Activo
          </Typography>
          {isEditingContactInfo &&
          defaultContactSocialValue === fieldsToEdit ? (
            <SwitchButton
              checked={
                editableContactInfo.contactSocialMedia?.career?.hasFacebook ??
                false
              }
              setChecked={() => handleContactInfoSwitchChange("hasFacebook")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableContactInfo.contactSocialMedia?.[defaultContactInfoValue]
                ?.hasFacebook
                ? "Si"
                : "No"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
