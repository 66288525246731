import { LocalStorageTypes } from "@/models";
import { Professor } from "@/types";
import {  getSessionStorage, setSessionStorage  } from "@/utilities";
import {createSlice} from '@reduxjs/toolkit'

const initialState: Professor[] = []

export const professorSlice = createSlice({
    name: LocalStorageTypes.PROFESSORS,
    initialState: getSessionStorage(LocalStorageTypes.PROFESSORS)? JSON.parse(getSessionStorage(LocalStorageTypes.PROFESSORS) as string) : initialState,
    reducers:{
        addToProfessors: (_, action)=>{
            setSessionStorage(LocalStorageTypes.PROFESSORS, action.payload)
            return action.payload
        },

       
    }
})
export const {addToProfessors} = professorSlice.actions