import { LocalStorageTypes } from "@/models";
import { InstituteProps } from "@/types";
import {  getSessionStorage, setSessionStorage } from "@/utilities";
import {createSlice, current} from '@reduxjs/toolkit'

const initialState: InstituteProps[] = []

export const activeSlice = createSlice({
    name: LocalStorageTypes.ACTIVE,
    initialState: getSessionStorage(LocalStorageTypes.ACTIVE)? JSON.parse(getSessionStorage(LocalStorageTypes.ACTIVE) as string) : initialState,
    reducers:{
        addToActiveList: (_, action)=>{
            setSessionStorage(LocalStorageTypes.ACTIVE, action.payload)
            return action.payload
        },

        removeFromActiveList: (state, action)=>{
            const filteredState = current(state).filter((institute: InstituteProps)=> institute.id !== action.payload.id)
            setSessionStorage(LocalStorageTypes.ACTIVE, filteredState)
            return filteredState
        }        
    }
})
export const {addToActiveList, removeFromActiveList} = activeSlice.actions