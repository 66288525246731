import { Box, Button, Typography, useTheme } from "@mui/material"
import { EditableTextArea } from "@/utilities/Components"
import { CareerProps } from "@/types";
import { useState } from "react";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";
import { tokens } from "@/utilities";

interface Props {
    selectedCareer: CareerProps;
    updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  }

export const AcademicDegree = ({
    selectedCareer,
    updateCareer,
  }: Props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isEditingObtainedTitle, setIsEditingObtainedTitle] =
    useState<boolean>(false);
  const [editableObtainedTitle, setEditableObtainedTitle] = useState<
    Partial<CareerProps>
  >({
    obtainedTitle: selectedCareer.obtainedTitle || "",
  });

  const handleObtainedTitleChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setEditableObtainedTitle((prevData) => ({ ...prevData, [name]: value }));
  };

  async function handleObtainedTitleSubmit() {
    try {
      const selectedObtainedTitle = {
        obtainedTitle: selectedCareer.obtainedTitle || "",
      };
      if (
        JSON.stringify(editableObtainedTitle) ===
        JSON.stringify(selectedObtainedTitle)
      ) {
        setIsEditingObtainedTitle(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (selectedCareer.id && editableObtainedTitle) {
        await careersService.update(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          editableObtainedTitle
        );
        updateCareer(editableObtainedTitle, selectedCareer.id);

        // Update selectedCareer
        selectedCareer = { ...selectedCareer, ...editableObtainedTitle };

        console.warn(
          "Record Saved in handleObtainedTitleSubmit",
          selectedCareer,
          selectedCareer.obtainedTitle
        );


        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingObtainedTitle(!isEditingObtainedTitle);
    } catch (error) {
      console.error("Error updating ObtainedTitle", error);
    }
  }
  return (
    <Box
    sx={{
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      position: "relative",
    }}
  >
    {isEditingObtainedTitle ? (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: "0" },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
          }}
          onClick={handleObtainedTitleSubmit}
        >
          Guardar
        </Button>
      </Box>
    ) : (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: "0" },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
          }}
          onClick={() =>
            setIsEditingObtainedTitle(!isEditingObtainedTitle)
          }
        >
          Editar
        </Button>
      </Box>
    )}
    <Typography
      sx={{
        padding: "20px 0",
        textAlign: "left",
        color: colors.grey[100],
        marginTop: "10px",
        width: { xs: "60%" },
      }}
      variant="h3"
    >
      Título Otorgado
    </Typography>

    <hr style={{ width: "100%", marginBottom: "25px" }} />
    <EditableTextArea
      id="obtainedTitle"
      name="obtainedTitle"
      value={editableObtainedTitle.obtainedTitle ?? ""}
      onChange={handleObtainedTitleChange}
      placeholder="Por favor, escriba una versión detallada del título obtenido"
      isEditing={isEditingObtainedTitle}
    />
  </Box>
  )
}
