import  { ReactNode, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from ".";
import Swal from 'sweetalert2'



const adminValues ={
  "Administrador Facultad": 1,
  "Administrador IES": 2,
  "Super Administrador": 3,
}

type AdminRole = keyof typeof adminValues;

interface Props {
  children: ReactNode
  adminLevel?: number
  subscriptionLevel?: number
}
export const PrivateRoute= ({children, adminLevel, subscriptionLevel}:Props): JSX.Element => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()

  const showAlert = ()=>{
    Swal.fire({
        title: "No tiene acceso a esta ruta",
        icon: "error",
        footer: "Si crees que hay un error por favor, comunicate con nosotros.",
        timer: 5000,
        
    }).then(()=>{
      navigate(-1)
    }
        )
  }
  const showAlertSubscription = ()=>{
    Swal.fire({
        title: "Para tener acceso a esta función adquiere uno de nuestros planes",
        icon: "info",
        footer: "Si crees que hay un error por favor, comunicate con nosotros.",
        timer: 5000,
        
    }).then(()=>{
      navigate("/plans")
    })
  }
  
  if (!authContext) {
    return <Navigate to="/login"/>;
  }

  const { user } = authContext;
  //  console.log(user)


    if (!user) {
      return <Navigate to="/login"/>;
    }
    
    if (adminLevel === undefined) {
      return <>{children}</>;
    }
   
    const userAdminValue = user.userData.role? adminValues[user.userData.role as AdminRole] : undefined

    if (userAdminValue === undefined) {
      showAlert();
      return <></>;
    }

    const isAuthorized = userAdminValue >= adminLevel
    
    if(!isAuthorized){
      showAlert()
      return <></>
    }
    if (subscriptionLevel !== undefined && user.subscriptionLevel < subscriptionLevel) {
      showAlertSubscription();
      return <></>;
    }

  return <>{children}</>;

};