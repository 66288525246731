import { ResponsiveChoropleth } from '@nivo/geo'
import {  Box, Button, useTheme } from '@mui/material'
import { countriesCodes, tokens } from '@/utilities'
// import { mockGeographyData as data } from '@/utilities'
import { geoFeatures } from '@/utilities'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UsersByRegion } from '@/types'
// import { handleSvgRef } from '..'


interface Props {
  // isDashboard?:boolean
  forwardRef?:any
}

export function GeoChart({forwardRef}:Props) {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [usersByRegion, setUsersByRegion] = useState<UsersByRegion[]>([])
  
  const formattedData = usersByRegion.map(item => {
    const countryCode = countriesCodes[item.country]; 
    return {
      id: countryCode,
      value: item.total_user_count
    };
  });
  // console.log(isDashboard)
  // console.log(formattedData)
  // console.log(usersByRegion)
  const sortedCountries = usersByRegion ? usersByRegion.sort((a, b) => b.total_user_count - a.total_user_count) : [{total_user_count:0}]
  const maxCount =sortedCountries && sortedCountries.length > 0  ? sortedCountries[0].total_user_count : 0

  const projectionScale = 100
  const [projectionTranslation, setProjectionTranslation] = useState<[number, number]>([0.4, 0.7]);
  const projectionRotation:[number, number, number]=[0, 0, 0]


  const [selectedCountry, setSelectedCountry] = useState<any| null>(null);
  // console.log(selectedCountry)
  // const geoFeaturesByCountry = selectedCountry ? [
  //   {
  //     type: "Feature",
  //     properties : selectedCountry.properties,
  //     geometry: selectedCountry.geometry,
  //     id: selectedCountry.id
  //   }
  // ]
  // :[]

//   const [centerCoordinates, setCenterCoordinates] = useState([0, 0]);
// const [projectionRotation, setProjectionRotation] = useState([0, 0, 0]);
// console.log(projectionRotation)
// console.log(centerCoordinates)

// function getCenterOfPolygon(coordinates: [number[], number[]][]) {
//   if (coordinates.length === 0 || !coordinates[0].length) {
//     return [0, 0];
//   }
//   const points = [...coordinates.flatMap((coordinates) => coordinates)];
//   const x = points.reduce((a, b) => a + b[0], 0) / points.length;
//   const y = points.reduce((a, b) => a + b[1], 0) / points.length;

//   return [x, y];
// }

// console.log(selectedCountry)
  const handleCountryClick = (event:any) => {
    // Aquí puedes acceder a la información de la característica (feature)
    
    // console.log(event)
      // Puedes realizar acciones específicas para el país en el que se hizo clic
    // console.log(`Usuario hizo clic en ${countryName}`);
    // Actualizar el estado para resaltar el país o realizar otras acciones
    setSelectedCountry({
      country: event.properties.name ?? "",
      total: event.value ?? 0
    });
    const countryGeometry = event.geometry
    if (countryGeometry.type === "MultiPolygon") {
      if (countryGeometry.coordinates[0].length === 0) {
        return;
      }
  
    //   const newCenterCoordinates = getCenterOfPolygon(countryGeometry.coordinates[0]);
    //   setCenterCoordinates(newCenterCoordinates);
    //   setProjectionRotation([0, 0, 0]);
    // } else {
    //   if (countryGeometry.coordinates.length === 0) {
    //     return;
    //   }
  
    //   const newCenterCoordinates = getCenterOfPolygon(countryGeometry.coordinates);
    //   setCenterCoordinates(newCenterCoordinates);
    //   setProjectionRotation([0, 0, 0]);
    }
    };

 
//   const colorScale = ["#a9d6e5", "#89c2d9", "#61a5c2", "#468faf", "#2c7da0", "#2a6f97", "#014f86", "#01497c", "#013a63", "#012a4a"];
//   const colorScale = ["#caf0f8", "#ade8f4", "#90e0ef", "#48cae4", "#00b4d8", "#0096c7", "#0077b6", "#023e8a", "#03045e"];
  const colorScale =  ["#00BCED", "#009EE5", "#0080E4", "#0063C3", "#0046A2", "#002D82"];
  const defaultColor = '#DDDCDC'



useEffect(()=>{
    async function fetchCountries() {
      try {
        const usersByRegionSession = sessionStorage.getItem("usersByRegionSession")

        if(usersByRegionSession){
          setUsersByRegion(JSON.parse(usersByRegionSession))
        }else{
        //   const getCareers = await bigQueryService.getCountUsersByCountry()
          // const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getCountUsersByCountry")
          const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getCountUsersByCountryAndCity")
          const getCount = await response.json()
          setUsersByRegion(getCount)

          const sortedCountries = getCount ? getCount.sort((a:UsersByRegion, b:UsersByRegion) => b.total_user_count - a.total_user_count) : [{total_user_count:0}]
          sessionStorage.setItem("usersByRegionSession", JSON.stringify(sortedCountries))

        }
      } catch (error) {
        console.error(error)  
      }
    }
    fetchCountries()
  },[])

    useEffect(() => {
    function handleResize() {

      const windowWidth = window.innerWidth;
      let newTranslationX = 0.4;

      // Asignar diferentes valores de traducción dependiendo del ancho de la ventana
      switch (true) {
        case windowWidth < 600:
          newTranslationX = 0.7; // Si la ventana es menor a 600px
          break;
        case windowWidth < 800:
          newTranslationX = 0.6; // Si la ventana es menor a 800px
          break;
        case windowWidth < 980:
          newTranslationX = 0.7; // Si la ventana es menor a 800px
          break;
        default:
          newTranslationX = 0.4;
          break
      }

      const newTranslation: [number, number] = [newTranslationX, 0.7]; 
      setProjectionTranslation(newTranslation);
      
    }
    // function handleTouchMove(event:any) {
    //   // Calcular el ángulo de rotación basado en el movimiento del usuario
    //   const deltaX = event.touches[0].clientX - event.touches[0].clientX;
    //   const deltaY = event.touches[0].clientY - event.touches[0].clientY;
    //   const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    //   setProjectionRotation([angle, 0, 0]);
    //   console.log(projectionRotation)
      
    // }

    window.addEventListener('resize', handleResize);
    // window.addEventListener('touchmove', handleTouchMove);
    handleResize(); 

    return () => {
      window.removeEventListener('resize', handleResize);
      // window.removeEventListener('touchmove', handleTouchMove);
    };
    }, []);
  return (
    <Box sx={{display:"flex", width:"100%", height:"100%", position:"relative", flexWrap:"wrap"}}>
          <Box id="GeoChartContainer" sx={{width:{xs:"100%",sm:"100%" }, height:"100%", maxHeight:"400px", position:"relative", cursor:"pointer"}}>

                <ResponsiveChoropleth
                        data={formattedData}
                        ref={forwardRef}
                        onClick={handleCountryClick}
                        isInteractive
                        tooltip={e => {
                          return (
                            <div
                              style={{
                                background: colors.background.primary,
                                padding: "9px 12px",
                                border: "1px solid #ccc",
                                borderRadius: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: colors.grey[100],
                              }}
                            >
                              {`${e.feature.label ? `${e.feature.label} : ` : "No registra datos"}` }
                              {e.feature.value}
                            </div>
                          );
                        }}
                        theme={{
                        axis:{
                        
                            domain:{
                                line:{
                                    stroke:colors.grey[100]
                                }
                            },
                            legend:{
                                text:{
                                    fill:colors.grey[100]
                                }
                            },
                            ticks:{
                                line:{
                                    stroke: colors.grey[100],
                                    strokeWidth:1
                                },
                                text:{
                                    fill:colors.grey[100]
                                }
                            }
                        },
                        legends:{
                            text:{
                                fill: colors.grey[100]
                            }
                        },
                    
                    }}
                        features={geoFeatures.features}
                        colors={colorScale}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        domain={[ 0, maxCount]}
                        unknownColor={defaultColor}
                        label="properties.name"
                        valueFormat=".2s"
                        // projectionScale={selectedCountry ? 200 : isDashboard ? 50 : 150}
                        projectionScale={projectionScale}
                        projectionTranslation={projectionTranslation}
                        projectionRotation={projectionRotation}
                        borderWidth={1.5}
                        borderColor={colors.primary[400]}
                        legends={
                          [{
                            anchor: 'bottom-left',
                            direction: 'column',
                            justify: true,
                            translateX: 20,
                            translateY: 20,
                            itemsSpacing: 0,
                            itemWidth: 94,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemTextColor: colors.grey[100],
                            itemOpacity: 0.85,
                            symbolSize: 18,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000',
                                        itemOpacity: 1
                                    
                                    },
                                }
                            ]
                        }
                    ]
                    }
                    /> 

{selectedCountry
        ?(
          <Box
          sx={{
            display:"flex",
            position:{xs:"relative", md:"absolute"},
            width:{xs:"100%", md:"35%", lg:"25%"},
            padding:{xs:"0 0 40px 0", md:"20px"},
            marginTop:{xs:"20px", md:"0"},
            marginBottom:{xs:"40px", md:"0"},
            top:0,
            right:0,
            flexDirection:"column",
            maxHeight:"80%",
            overflowY:"auto",
            // backgroundColor:"#F2F0F0",
          }}
          >
            <Box
              key={selectedCountry.country}
              sx={{
                display:"flex",
                borderBottom:`4px solid ${colors.primary[500]}`,
                width:"100%"
              }}
            >
              <Box sx={{display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Box color={colors.grey[100]} sx={{fontWeight:"bold"}}>{selectedCountry.country}</Box>
                <Box
                  sx={{
                    p:"5px 10px",
                    borderRadius:"4px",
                  }}
                >
                  {selectedCountry.total ?? 0}
                </Box>
              </Box>
            </Box>
                
            {usersByRegion.map((item) => {
            if (item.country === selectedCountry.country) {
              return (
                <Box key={item.country}>
                  {item.cities
                  .sort((a, b) => b.city_user_count - a.city_user_count)
                  .map((city) => (
                    <Box
                      key={`${city.city}-${city.city_user_count}`}
                      sx={{
                        display: "flex",
                        borderBottom:`4px solid ${colors.primary[500]}`,
                        width: "100%",
                      }}
                    >
                      <Box sx={{display:"flex",justifyContent:"space-between", width:"100%"}}>
                          <Box sx={{ color: colors.grey[100], fontWeight: "bold" }}>{city.city.length> 0 ? city.city : "Other"}</Box>
                          <Box sx={{ p: "5px 10px", borderRadius: "4px" }}>{city.city_user_count}</Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              );
            }
            return null;
          })}

        </Box>
        )
        :(
        <Box sx={{
                display:"flex",
                position:{xs:"relative", md:"absolute"},
                width:{xs:"100%", md:"35%", lg:"25%"},
                padding:{xs:"0%", md:"20px"},
                marginTop:{xs:"20px", md:"0"},
                top:0,
                right:0,
                flexDirection:"column",
                maxHeight:"80%",
                overflowY:"auto",
                // backgroundColor:"#F2F0F0",

            }}>
              { usersByRegion
              .sort((a, b) => b.total_user_count - a.total_user_count)
              .map((item, i) => (
                <Box
                  key={`${item.country}-${i}`}
                  sx={{
                    display:"flex",
                    borderBottom:`4px solid ${colors.primary[500]}`,
                    width:"100%"
                  }}
                  onClick={()=>setSelectedCountry({
                    country: item.country,
                    total: item.total_user_count
                  })}
                >
                  <Box sx={{display:"flex",justifyContent:"space-between", width:"100%"}}>
                    <Box color={colors.grey[100]} sx={{fontWeight:"bold"}}>{item.country}</Box>

                    <Box
                      sx={{
                        p:"5px 10px",
                        borderRadius:"4px",
                      }}
                    >
                      {item.total_user_count}
                    </Box>
                  </Box>
                </Box>
              ))} 
            </Box>

        )
        }

          </Box>
          

          <Button
             variant='contained'
              sx={{
                  position:"absolute",
                  display:selectedCountry? "flex" : "none",
                  top:-65,
                  right:20,
                  backgroundColor:colors.grey[100],
                  color: colors.grey[900],
                  textDecoration:"none",
                  gap:"10px",
                  border:`0.5px solid ${colors.grey[300]}`,
                  padding:"10px 20px",
                  borderRadius:"5px",
                  cursor:"pointer"
              }}
              onClick={()=> setSelectedCountry(null)}
          > 
          <ArrowBackIcon/>

          Volver
          </Button>

        
          
    </Box>
    )
}
