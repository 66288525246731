import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  SwitchButton,
  currencies,
  dialCodes,
  tokens,
  useAuth,
} from "@/utilities";
import careersService from "@/api/careers.service";
import "./FormCreateCareer.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const showAlertSuccess = () => {
  Swal.fire({
    title: "Carrera creada satisfactoriamente",
    icon: "success",
    footer: `Revisa la lista de carreras`,
    timer: 2000,
  });
};
const showAlertWrong = () => {
  Swal.fire({
    title: "Lo sentimos, hubo un problema en la creación",
    icon: "error",
    footer: `Por favor, vuelve a intentarlo`,
    timer: 2000,
  });
};

const modalities = ["Presencial", "Virtual", "Híbrida"];

const periods = ["Mensual", "Bimestral", "Cuatrimestral", "Semestral", "Anual"];

const schedule = ["Diurno", "Vespertino", "Nocturno", "Mixto"];

const studyLevels = [
  "Pregrado",
  "Especialización",
  "Maestría",
  "Doctorado",
  "Técnico",
  "Tecnólogo",
];

const initialSwitchValues: Record<string, boolean> = {
  nationalAccreditation: false,
  internationalAccreditation: false,
  exchange: false,
  dualProgram: false,
  summerProgram: false,
  mandatoryInterships: false,
  mandatoryThesis: false,
};

interface Props {
  facultyId?: string;
  handleChangeTab?: (tab: number) => void;
}

export function FormCreateCareer(props: Props) {
  const { user, institute } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams<string>();
  const role = user?.userData.role;
  const facultyID = role === "Administrador IES" ? id : props.facultyId;
  // const instituteId = user?.userData.institute

  const navigate = useNavigate();

  const validationSchema = yup.object({
    code: yup.string().required("El codigo es requerido"),
    name: yup.string().required("El nombre es requerido"),
    levelStudy: yup.string().required("Seleccione un nivel"),
    profileGraduated: yup.string().required("Indique el perfil del graduado"),
    modalities: yup.string().required("Seleccione una modalidad"),
    price: yup
      .number()
      .positive("El precio debe ser positivo")
      .required("Indique el precio del programa"),
    currency: yup.string().required("Seleccione una moneda"),
    credits: yup
      .number()
      .positive("El número debe ser positivo")
      .integer("El número debe ser entero")
      .required("Indique los créditos del programa"),
    duration: yup
      .number()
      .positive("El número debe ser positivo")
      .integer("El número debe ser entero")
      .required("Indique la duración del programa"),
    periods: yup.string().required("Indique los periodos del programa"),
    schedule: yup.string().required("Indique el horario del programa"),
    website: yup.string(),
    description: yup
      .string()
      .required("Indique una breve descripción del programa"),
    contactEmail: yup
      .string()
      .email("Email invalido")
      .required("Indique un email de contacto")
      .test(
        "domain",
        "Debes usar un correo institucional, extensión: .edu",
        (value) => {
          const domainsToExclude = [
            "gmail.com",
            "hotmail.com",
            "outlook.com",
            "yahoo.com",
          ];
          const institutionDomain = "edu";
          //que contenga .edu
          if (
            domainsToExclude.every(
              (domain) => value.split("@")[1] !== domain
            ) &&
            value.includes(`.${institutionDomain}`)
          ) {
            return true;
          } else {
            return false;
          }
        }
      ),
    contactPhone: yup
      .number()
      .positive("El número debe ser positivo")
      .integer("El número debe ser entero")
      .required("Indique número de contacto"),
  });
  const [switchValues, setSwitchValues] = useState(initialSwitchValues);

  const uidInstitution = institute?.code ? institute.code : "";
  const iesCharacter = institute?.character ? institute?.character : "";
  const iesImages = institute?.images ? institute?.images : [];
  const iesLogo = institute?.logo ? institute?.logo : "";
  const institution = institute?.name ? institute?.name : "";
  const headquarter = institute?.headquarters
    ? institute.headquarters
    : [
        {
          title: "Principal",
          address: "",
          country: "",
          region: "",
          city: "",
          zipCode: "",
          // geoLocation:{
          //     latitude: 0,
          //     longitude: 0,
          // }
        },
      ];

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      code: "",
      levelStudy: "",
      profileGraduated: "",
      modalities: "",
      price: null,
      currency: "",
      credits: null,
      duration: null,
      periods: "",
      schedule: "",
      institution,
      webSite: "",
      headquarter,
      description: "",
      uidFaculty: facultyID ? facultyID : "",
      uidInstitution,
      iesCharacter,
      iesImages,
      iesLogo,
      active: true,
      startYear: new Date(),
      costCredit: null,
      inscriptions: new Date(),
      startClass: new Date(),
      compatiblePrograms: [],
      contactEmail: "",
      contactPhone: null,
      phone_code_country: "+57",
      updatedAt: "",
      createdAt: "",
      ref: "",
      candidateProfile: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const updatedValues = {
        ...values,
        id: formik.values.code,
        startYear: new Date(values.startYear),
        inscriptions: new Date(values.inscriptions),
        startClass: new Date(values.startClass),
        nationalAccreditation: switchValues.nationalAccreditation,
        internationalAccreditation: switchValues.internationalAccreditation,
        exchange: switchValues.exchange,
        dualProgram: switchValues.dualProgram,
        summerProgram: switchValues.summerProgram,
        mandatoryInterships: switchValues.mandatoryInterships,
        mandatoryThesis: switchValues.mandatoryThesis,
      };
      if (user && facultyID) {
        try {
          const createCareer = await careersService.create(
            user.userData.institute,
            facultyID,
            updatedValues,
            formik.values.code
          );

          // await careersService.updateID(uidInstitution, facultyID, careerID, careerID)
          if (createCareer) {
            props.handleChangeTab && props.handleChangeTab(0);
            showAlertSuccess();
            resetForm();
            setSwitchValues(initialSwitchValues);
          }
        } catch (error) {
          showAlertWrong();
          console.error(error);
        }
      }
    },
  });

  const handleSwitchChange = (switchName: string) => {
    setSwitchValues((prevSwitchValues) => ({
      ...prevSwitchValues,
      [switchName]: !prevSwitchValues[switchName],
    }));
  };

  // const formik.handleChange = (event: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement>): void => {
  //   const { name, value } = event.target;
  //   setValues({
  //     ...values,
  //     [name]: value
  //   });
  //   }
  // const formik.handleChange = (event: SelectChangeEvent<string>): void => {
  //   const { name, value } = event.target;
  //   setValues({
  //     ...values,
  //     [name]: value
  //   });
  // }

  //   const handleSubmit= async (event: React.FormEvent<HTMLFormElement>): Promise<void>=>{
  //     event.preventDefault()
  //     setValues({
  //       ...values,
  //       nationalAccreditation: switchValues.nationalAccreditation,
  //       internationalAccreditation: switchValues.internationalAccreditation,
  //       exchange: switchValues.exchange,
  //       dualProgram: switchValues.dualProgram,
  //       summerProgram: switchValues.summerProgram,
  //       mandatoryInterships: switchValues.mandatoryInterships,
  //       mandatoryThesis: switchValues.mandatoryThesis,
  //     });
  //     if(user && props.facultyId){
  //         try {
  //             await careersService.create(user.userData.institute, props.facultyId, values)
  //             showAlertSuccess()

  //         } catch (error) {
  //             showAlertWrong()
  //             console.error(error)
  //         }
  //       }

  // }

  return (
    <Box sx={{ padding: "20px", height: "auto" }}>
      {role === "Administrador IES" && (
        <Box
          sx={{
            marginTop: { xs: "5px", sm: "-15px", md: "-55px" },
            marginBottom: "20px",
            width: { xs: "100%", sm: "fit-content" },
            justifyContent: { xs: "center", sm: "flex-start" },
            display: "flex",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#3F51B5",
              color: "#f2f0f0",
              display: "flex",
              textDecoration: "none",
              gap: "10px",
              padding: "10px 20px",
              borderRadius: "5px",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: colors.blueAccent[500],
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/faculties")}
          >
            <ArrowBackIcon />
            Volver a facultades
          </Button>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: colors.primary[400],
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h3" color={colors.grey[100]}>
          Ingresa la información para tu nueva carrera
        </Typography>

        <Typography
          variant="h5"
          sx={{ color: colors.grey[500], marginTop: "0px" }}
        >
          Los campos marcados con * son obligatorios
        </Typography>
      </Box>

      <hr />
      <form onSubmit={formik.handleSubmit} className="form-career">
        {/* <form onSubmit={handleSubmit}> */}
        <FormGroup
          sx={{
            display: { md: "grid" },
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            padding: 2,
            borderRadius: 2,
            border: `1px solid ${colors.blueAccent[200]}`,
            width: { xs: "100%", sm: "100%" },
            gap: "40px",
            marginTop: "30px",
          }}
        >
          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="code"
              name="code"
              label="Código SNIES de la carrera"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="name"
              name="name"
              label="Nombre de la carrera"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              id="webSite"
              name="webSite"
              label="Sitio web"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.webSite}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.webSite && Boolean(formik.errors.webSite)}
              helperText={formik.touched.webSite && formik.errors.webSite}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                sx={{
                  color: `${colors.grey[100]} !important`,
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                  padding: "0 2px",
                }}
                id="levelStudy"
                htmlFor="levelStudy"
              >
                Nivel de estudio *
              </InputLabel>
              <Select
                required
                fullWidth
                variant="outlined"
                id="levelStudy"
                name="levelStudy"
                style={{ color: colors.grey[100], fontSize: "16px" }}
                value={formik.values.levelStudy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.levelStudy && Boolean(formik.errors.levelStudy)
                }
              >
                {studyLevels.map((name: string, index: number) => (
                  <MenuItem value={name} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="price"
              name="price"
              label="Precio de la carrera"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                sx={{
                  color: `${colors.grey[100]} !important`,
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                  padding: "0 2px",
                }}
                id="currency"
              >
                Tipo de moneda *
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                variant="outlined"
                id="currency"
                name="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currency && Boolean(formik.errors.currency)
                }
                style={{ color: colors.grey[100], fontSize: "16px" }}
              >
                {currencies.map((name: string, index: number) => (
                  <MenuItem value={name} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Box className="div-input-faculty">
              <InputLabel sx={{
                  color:colors.grey[100], 
                  fontWeight:"bold",
                  fontSize:"16px"
                  }}  htmlFor="webSite">Nivel de estudio</InputLabel>
                  <input
                  type="text"
                  name="webSite"
                  value={formik.values.webSite}
                  onChange={formik.handleChange}
                  className="input"
                  />
          </Box> */}

          <Grid item xs={12} sm={10}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                sx={{
                  color: `${colors.grey[100]} !important`,
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                  padding: "0 2px",
                }}
                id="modalities"
              >
                Modalidad de la carrera*
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                variant="outlined"
                id="modalities"
                name="modalities"
                value={formik.values.modalities}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.modalities && Boolean(formik.errors.modalities)
                }
                style={{ color: colors.grey[100], fontSize: "16px" }}
              >
                {modalities.map((name: string, index: number) => (
                  <MenuItem value={name} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                sx={{
                  color: `${colors.grey[100]} !important`,
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                  padding: "0 2px",
                }}
                id="schedule"
              >
                Tipo de horario *
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                variant="outlined"
                id="schedule"
                name="schedule"
                value={formik.values.schedule}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.schedule && Boolean(formik.errors.schedule)
                }
                style={{ color: colors.grey[100], fontSize: "16px" }}
              >
                {schedule.map((name: string, index: number) => (
                  <MenuItem value={name} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="credits"
              name="credits"
              label=" Créditos de la carrera "
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.credits}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.credits && Boolean(formik.errors.credits)}
              helperText={formik.touched.credits && formik.errors.credits}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="duration"
              name="duration"
              label="Duración de la carrera "
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              helperText={formik.touched.duration && formik.errors.duration}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                sx={{
                  color: `${colors.grey[100]} !important`,
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                  padding: "0 2px",
                }}
                id="periods"
              >
                Tipo de periodo *
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                variant="outlined"
                id="periods"
                name="periods"
                value={formik.values.periods}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.periods && Boolean(formik.errors.periods)}
                style={{ color: colors.grey[100], fontSize: "16px" }}
              >
                {periods.map((name: string, index: number) => (
                  <MenuItem value={name} key={index}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              id="costCredit"
              name="costCredit"
              label="Costo por crédito"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.costCredit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.costCredit && Boolean(formik.errors.costCredit)
              }
              helperText={formik.touched.costCredit && formik.errors.costCredit}
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              type="date"
              id="startYear"
              name="startYear"
              label="Fecha de creación"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.startYear}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startYear && Boolean(formik.errors.startYear)
              }
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              type="date"
              label="Fecha de inscripciones"
              id="inscriptions"
              name="inscriptions"
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.inscriptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.inscriptions &&
                Boolean(formik.errors.inscriptions)
              }
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              type="date"
              id="startClass"
              name="startClass"
              label="Fecha de inicio de clases "
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.startClass}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startClass && Boolean(formik.errors.startClass)
              }
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              required
              id="contactEmail"
              name="contactEmail"
              label="Correo de contacto "
              fullWidth
              size="small"
              autoComplete="off"
              variant="outlined"
              value={formik.values.contactEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactEmail &&
                Boolean(formik.errors.contactEmail)
              }
              helperText={
                formik.touched.contactEmail && formik.errors.contactEmail
              }
              sx={{ color: colors.grey[100] }}
              InputLabelProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  backgroundColor: colors.background.primary,
                },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormControl
              id="phone"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Select
                value={formik.values.phone_code_country}
                id="phone_code_country"
                name="phone_code_country"
                onChange={formik.handleChange}
                sx={{
                  display: "flex",
                  maxHeight: 47,
                  border: "none",
                  width: { xs: "40%", sm: "45%" },
                  color: colors.grey[100],
                  fontSize: 14,
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: "40ch",
                      right: 0,
                      margin: "0 0 0 24px",
                      color: colors.grey[100],
                    },
                  },
                }}
              >
                {dialCodes.map((country) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    ({country.dial_code}) {country.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="contactPhone"
                name="contactPhone"
                label="Teléfono de contacto"
                fullWidth
                required
                size="small"
                autoComplete="off"
                variant="outlined"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.contactPhone &&
                  Boolean(formik.errors.contactPhone)
                }
                helperText={
                  formik.touched.contactPhone && formik.errors.contactPhone
                }
                sx={{ color: colors.grey[100] }}
                InputLabelProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    backgroundColor: colors.background.primary,
                  },
                }}
                InputProps={{
                  style: { color: colors.grey[100], fontSize: "16px" },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="nationalAccreditation"
            >
              Acreditación nacional
            </InputLabel>

            <SwitchButton
              checked={switchValues.nationalAccreditation}
              setChecked={() => handleSwitchChange("nationalAccreditation")}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="internationalAccreditation"
            >
              Acreditación internacional
            </InputLabel>
            <SwitchButton
              checked={switchValues.internationalAccreditation}
              setChecked={() =>
                handleSwitchChange("internationalAccreditation")
              }
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="exchange"
            >
              Posibilidad de intercambio
            </InputLabel>
            <SwitchButton
              checked={switchValues.exchange}
              setChecked={() => handleSwitchChange("exchange")}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="dualProgram"
            >
              Programa de doble titulación
            </InputLabel>
            <SwitchButton
              checked={switchValues.dualProgram}
              setChecked={() => handleSwitchChange("dualProgram")}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="summerProgram"
            >
              Programa de verano
            </InputLabel>
            <SwitchButton
              checked={switchValues.summerProgram}
              setChecked={() => handleSwitchChange("summerProgram")}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="mandatoryInterships"
            >
              Prácticas obligatorias
            </InputLabel>
            <SwitchButton
              checked={switchValues.mandatoryInterships}
              setChecked={() => handleSwitchChange("mandatoryInterships")}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              sx={{
                color: `${colors.grey[100]} !important`,
                fontSize: "16px",
              }}
              id="mandatoryThesis"
            >
              Tesis obligatoria
            </InputLabel>
            <SwitchButton
              checked={switchValues.mandatoryThesis}
              setChecked={() => handleSwitchChange("mandatoryThesis")}
            />
          </Grid>

          <Grid item xs={12} sm={10} sx={{ gridColumn: "1 / -1" }}>
            <Typography variant="h4" color={colors.grey[100]}>
              Perfil de egresado
            </Typography>
            <TextareaAutosize
              required
              id="profileGraduated"
              name="profileGraduated"
              autoComplete="off"
              value={formik.values.profileGraduated}
              placeholder="Por favor, escriba una breve descripción del perfil de egresado..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderRadius: "5px",
                borderColor: "primary.main",
                padding: "5px 10px",
                width: "100%",
                minWidth: "250px",
                fontSize: "medium",
                fontFamily: "inherit",
                minHeight: "200px",
                whiteSpace: "pre-wrap",
                resize: "vertical",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10} sx={{ gridColumn: "1 / -1" }}>
            <Typography variant="h4" color={colors.grey[100]}>
              Perfil de candidato
            </Typography>
            <TextareaAutosize
              required
              id="candidateProfile"
              name="candidateProfile"
              autoComplete="off"
              value={formik.values.candidateProfile}
              placeholder="Por favor, escriba una breve descripción del perfil de candidato..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderRadius: "5px",
                borderColor: "primary.main",
                padding: "5px 10px",
                width: "100%",
                minWidth: "250px",
                fontSize: "medium",
                fontFamily: "inherit",
                minHeight: "200px",
                whiteSpace: "pre-wrap",
                resize: "vertical",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10} sx={{ gridColumn: "1 / -1" }}>
            <Typography variant="h4" color={colors.grey[100]}>
              Descripción de carrera
            </Typography>
            <TextareaAutosize
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Por favor, escriba una breve descripción de la carrera..."
              id="description"
              style={{
                borderRadius: "5px",
                borderColor: "primary.main",
                padding: "5px 10px",
                width: "100%",
                minWidth: "250px",
                fontSize: "medium",
                fontFamily: "inherit",
                minHeight: "200px",
                whiteSpace: "pre-wrap",
                resize: "vertical",
              }}
            />
          </Grid>

          {/* <Grid item xs={12} sm={10}>
              <InputLabel
                sx={{
                  display: "flex",
                  fontWeight: "bolder",
                  fontSize:"16px",
                  marginBottom:"5px"
                }}
                id="credits"
              >
                Caracter de la carrera *
              </InputLabel>
              <TextField
                required
                id="credits"
                name="credits"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={formik.values.credits}
                onChange={formik.handleChange} 
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px" }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}
              />
            </Grid> */}

          {/* <div className="div-input-faculty">
              <InputLabel sx={{
                  color:colors.grey[100], 
                  fontWeight:"bold",
                  fontSize:"16px"
                  }} htmlFor="logo">Logo de la Facultad</InputLabel>
                  <input
                  type="file"
                  name="logo"
                  value={formik.values.logo}
                  onChange={formik.handleChange} // hacer un handler de imagen
                  className="input"
                  />
          </div>  
          
          <div className="div-input-faculty">
                <InputLabel sx={{
                  color:colors.grey[100], 
                  fontWeight:"bold",
                  fontSize:"16px"
                  }} htmlFor="images">Añadir imágenes de la Facultad</InputLabel>
                  <input
                  type="file"
                  name="images"
                  value={formik.values.images}
                  className="input-img"
                  accept="image/png , image/jpeg, image/webp"
                  multiple   
                  />
        
            </div>

            <div className="div-input-faculty">
              <InputLabel sx={{
                  color:colors.grey[100], 
                  fontWeight:"bold",
                  fontSize:"16px"
                  }} htmlFor="description">Descripción de la Facultad</InputLabel>
                  <textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  className="input textarea"
                  />
          </div>  */}
        </FormGroup>

        <Button variant="contained" type="submit">
          Crear carrera
        </Button>
      </form>
    </Box>
  );
}
