// import SearchIcon from '@mui/icons-material/Search';
import AccountMenu from './modalAccount/modalAccount';
import {Box, IconButton, Typography, useTheme} from '@mui/material';
import { useContext } from 'react';
import { ColorModeContext,  tokens, useAuth } from '@/utilities';
// import {InputBase} from '@mui/material';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import './Topbar.scss';

interface Props{
  open: boolean
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>
}

export const  Topbar=({ open, setOpen }: Props)=> {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)
  const {user} = useAuth()
  // const {institute} = useAuth() 

  return (
      <Box className='search'>

        <Box sx={{
          display:"flex",
          // padding:"0 20px",
          justifyContent:"center",
          alignItems:"center"
        }}>
            <MenuIcon 
            onClick={()=>setOpen(!open)}
            sx={{
              display:{xs:"flex", sm:"none"},
              fontSize:"35px",
              color:"#007FC0",
              marginLeft:"22px"
            }}
            />
            {/* <img 
              src={institute?.logo} 
              alt='' 
              style={{
                width:"48px",
                height:"48px",
                borderRadius:"50%"
              }}  
            /> */}
      
            <Typography 
              variant='h4'      
              sx={{
                paddingLeft:"30px",
                fontWeight:"bold",
                fontStyle:"italic",
                color: colors.grey[100],
                fontSize:{xs:"15px", md:"20px"}
              }}>

              Bienvenido {user?.user.displayName && user.user.displayName.split(" ")[0]}
            </Typography>
            
        </Box>
            {/* <Box
              sx={{
                display:"flex",
                backgroundColor:colors.primary[400],
                borderRadius:"3px",

              }}
              className="left-search"
            >
            <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Buscar" className='.input-search' />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
            </Box> */}

          
            
          {/* ICONS */}
          <Box display="flex" sx={{marginRight:{xs:"10px", sm:"40px"}}}>
            <IconButton onClick={colorMode.toggleColorMode} sx={{"&:hover":{ backgroundColor:"transparent"}}}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            {/* <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton> */}
            {/* <IconButton>
              <SettingsOutlinedIcon />
            </IconButton> */}
            <Box sx={{"&:hover":{ backgroundColor:"transparent"}}}>
              <AccountMenu/>
            </Box>
          </Box>
      </Box>
  )
}
