import { Box,   Button,   Tooltip,  Typography, useTheme } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import { Header } from "@/views";
import {  GeoChart,  PieChart } from "..";
import { useEffect, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TemplateReport, tokens, useAuth } from "@/utilities";
// import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
// import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TopProgramsByInstitute, TopRanking } from "@/types";
import { useNavigate } from "react-router-dom";


// const showAlert = ()=>{
//   Swal.fire({
//       title: "Si desea un reporte detallado por favor, comuniquese con nosotros",
//       icon: "info",
//       footer: `Disponemos de una excelente atención esperando por usted`,
//       timer: 5000,
//   })
// }

export const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {user} = useAuth()
//   const [topCareers, setTopCareers] = useState()
// console.log(topCareers)

const [topCareers, setTopCareers] = useState<TopRanking[]>([])
const [topPrograms, setTopPrograms] = useState<TopProgramsByInstitute[]>([])
// console.log(topPrograms)
const navigate = useNavigate()

useEffect(()=>{
  async function fetchCountries() {
    try {
      const topCareersSession = sessionStorage.getItem("topCareers")
      if(topCareersSession){
        setTopCareers(JSON.parse(topCareersSession))
      }else{
      //   const getCareers = await bigQueryService.getCountUsersByCountry()
        const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getTopRankingCareers")
        const getCount = await response.json()
        const newData = getCount.map((item:any) => {
          const lowerCaseCareerName = item.career_name.toLowerCase();
          const formattedCareerName =lowerCaseCareerName.replace(/[àáâãäåèéêëìíîïòóôõöùúûüñç]/g, (char:string) => accentMap[char] || char)
          return {
            ...item,
            career_name: formattedCareerName.replace(/(?:^|\s)\S/g, (a:string) => a.toUpperCase())
          };
        });
        setTopCareers(newData)
        // console.log(getCount)
        sessionStorage.setItem("topCareers", JSON.stringify(newData))

      }
    } catch (error) {
      console.error(error)  
    }
  }
  fetchCountries()
},[])


//-----------------------------------------GeoChart-----------------------------------
// const [usersByRegion, setUsersByRegion] = useState<UsersByRegion[]>([])

// useEffect(()=>{
//   async function fetchCountries() {
//     try {
   
//       //   const getCareers = await bigQueryService.getCountUsersByCountry()
//         // const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getCountUsersByCountry")
//         const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getCountUsersByCountryAndCity")
//         const getCount = await response.json()
//         setUsersByRegion(getCount)
//         console.log(getCount)
//     } catch (error) {
//       console.error(error)  
//     }
//   }
//   fetchCountries()
// },[])

//------------------------------------------------------

const accentMap: Record<string, string>  = {
  'à': 'á', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a',
  'è': 'é', 'ê': 'e', 'ë': 'e',
  'ì': 'í', 'î': 'i', 'ï': 'i',
  'ò': 'ó', 'ô': 'o', 'õ': 'o', 'ö': 'o',
  'ù': 'ú', 'û': 'u', 'ü': 'u',
  'ç': 'c',
};

useEffect(()=>{
  async function fetchPrograms() {
    try {
      const requestData = {
        uidInstitution: user?.userData.institute && parseInt(user?.userData.institute)
      };

      const programsByInstitute = sessionStorage.getItem("programsByInstitutes")
      const parsePrograms = programsByInstitute && JSON.parse(programsByInstitute)

      const resultUid =parsePrograms && parsePrograms.length > 0 && parsePrograms[0]["uidInstitution"]

      if(parsePrograms && resultUid === requestData.uidInstitution){
        setTopPrograms(JSON.parse(programsByInstitute))
      }else{
        // console.log("REQ BODYYY", JSON.stringify(requestData))
        const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getTopProgramsByInstitutes", {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        })

        // console.log("Responseeee",response)

        if(!response.ok){
          const errorData = await response.json();
          throw new Error(`Error in request: ${response.status} - ${errorData.message}`);       
        }
        
      const getRanking = await response.json()
      const newData = getRanking.flat().map((item:any) => {
        const lowerCaseCareerName = item.career_name.toLowerCase();
        const formattedCareerName = lowerCaseCareerName.replace(/[àáâãäåèéêëìíîïòóôõöùúûüñç]/g, (char:string) => accentMap[char] || char)
        return {
          ...item,
          career_name: formattedCareerName.replace(/(?:^|\s)\S/g, (a:string) => a.toUpperCase())
        };
      });
      // console.log(newData)
          setTopPrograms(newData)
          sessionStorage.setItem("programsByInstitutes", JSON.stringify(newData))
        
        // console.log(getRanking)
      }
      // console.log(JSON.stringify(requestData))
        
      } catch (error) {
        console.error(error)  
      }
    }
  fetchPrograms()
},[user?.userData.institute])


useEffect(()=>{
  async function fetchRedirects() {
    try {
      const requestData = {
        uidInstitution: user?.userData.institute && parseInt(user?.userData.institute)
      };
     
        // console.log("REQ BODYYY", JSON.stringify(requestData))
        const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getRedirectByInstitute", {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        })

        if(!response.ok){
          const errorData = await response.json();
          throw new Error(`Error in request: ${response.status} - ${errorData.message}`);       
        }
        const getRanking = await response.json()
        
        const filteredRanking = getRanking.flat().map((item:any) => {
          const filteredParams = item.event_params.filter((param:any) => {
            return ['institute_name', 'institution', 'siteUrl', 'webSite', 'name', 'career_name'].includes(param.key);
          });
        
          return {
            ...item,
            event_params: filteredParams,
          };
        });
        const flatRanking = filteredRanking.flat();
        // console.log(flatRanking)
        sessionStorage.setItem("redirectsByInstitute", JSON.stringify(flatRanking))
      } catch (error) {
        console.error(error)  
      }
    }
    fetchRedirects()
},[user?.userData.institute])

const containerRef = useRef();

// useEffect(() => {
//   if (containerRef.current) {
//     svgToPdf(containerRef.current);
//   }
// }, [containerRef]);
  // const navigate = useNavigate()
  // const handleImage =async()=>{
  // //  await svgToPdf(containerRef.current)
  //  handlePrintPdf()
  //  navigate("/download/report", {state:{image:imgData}})
   
  // } 
  
  const [imgData, setImgData] = useState<{ buffer: null | string }>({
    buffer: null
  });

  const [imgDevice, setImgDevice] = useState<{ buffer: null | string }>({
    buffer: null
  });
  const [showPDF, setShowPDF]  = useState(false)
  

  function handlePrintPdf(){
    setShowPDF(!showPDF)
    // console.log("You clicked printPdf button.")
    const input = document.getElementById('GeoChartContainer');
    if(!input) {
      console.log('capture element is null');
      return;
    }
    
    html2canvas(input)
      .then((canvas) => {
          const _imgData = canvas.toDataURL('image/png');
            setImgData({ buffer: _imgData})
  
          // ReactPDF.render(<MyDocument imgBase64={imgData}/>, `example.pdf`);
     
      })

    const connectionDevice = document.getElementById("DeviceContainer")
    if(!connectionDevice) {
      console.log('capture element is null');
      return;
    }
    
    html2canvas(connectionDevice)
      .then((canvas) => {
          const _imgDevice = canvas.toDataURL('image/png');
          setImgDevice({ buffer: _imgDevice})
      })
  }

  return (
    <>
    {user?.userData.role && user?.userData.role?.length > 2 ? 
    <Box sx={{m:"20px", maxWidth:{xs:"100%"}}}>

    {/* HEADER */}
    <Box sx={{
      display:{xs:"grid", md:"flex"}, 
      justifyContent:"space-between", 
      alignItems:"center",
      gridTemplateColumns:"repeat(1, 1fr)"
      }}>
      
      <Box sx={{display:"flex", gap:"2rem", alignItems:"center"}}>
      <Box sx={{display:"flex"}}>
        <Header title="Analítica" subtitle="Bienvenido a tu panel de control" />
        <Tooltip title={<Typography  variant="h5"  sx={{color:colors.grey[100], padding:"10px"}}>Esta data representa los últimos 30 días</Typography>} >
          <InfoOutlinedIcon sx={{color:colors.grey[100], cursor:"pointer", marginTop:1}}/>
        </Tooltip>
      </Box>
      <Button
          sx={{
            backgroundColor:"#3F51B5",
            color:"#f2f0f0",
            fontSize:"14px",
            padding: "10px 20px",
            borderRadius:"5px",
            display:"flex",
            width:"fit-content",
            height:"fit-content",
            textTransform: "none",
            "&:hover":{ backgroundColor:colors.blueAccent[500], cursor:"pointer"},
            "&.Mui-disabled": {
              backgroundColor: "#E0E0E0" ,
              color: "#9E9E9E", 
              cursor: "not-allowed",
            },
            lineHeight: "1.1",
          }}
          disabled={user.subscriptionLevel < 1}
          onClick={()=> navigate("/comparative_analytics")}
        >
          Analítica comparativa
          </Button>
      </Box>
     
      <Box
          sx={{
            backgroundColor:"#3F51B5",
            color:"#f2f0f0",
            fontSize:"14px",
            fontWeight: "bold",
            padding: "10px 20px",
            borderRadius:"5px",
            display:"flex",
            width:"fit-content",
            "&:hover":{ backgroundColor:colors.blueAccent[500], cursor:"pointer"}
          }}
          // onClick={()=>showAlert()}
          onClick={handlePrintPdf}
        >
          {showPDF?
          (
            <>
            <ArrowBackIcon sx={{marginRight:"10px"}}/>
            Volver
            </>
          )
          :
          (
            <>
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Descargar
          </>
          )
          }
          {/* <PDFDownloadLink document={<TemplateReport imgBase64={imgData.buffer} />} fileName="Informe" >
              {({  blob, url, loading, error}) =>
                loading ?<Box sx={{textDecoration:"none", fontFamily:"Tahoma", color:"white" }}> Cargando...</Box>  : <Box sx={{textDecoration:"none", fontFamily:"Tahoma", color:"white" }} onClick={()=>console.log("EL EROOOOOORRRRRRRRRR",error)}>Descargar </Box>
              }
            </PDFDownloadLink>       
           */}
          </Box>

    </Box>
      
      {showPDF &&  <TemplateReport imgBase64={imgData.buffer} imgDevice={imgDevice.buffer}/>}
     
              
      
    {/* GRID & CHARTS */}
    {!showPDF &&
      <Box
      sx={{   
        display:{xs:"flex", md:"grid"},
        flexDirection:"column",
        gridTemplateColumns:{xs:"1fr", md:"repeat(12, 1fr)"},
        gridAutoRows:{xs:"160px", md:"140px"},
        gap:"20px",
        margin:{xs:"15px 0", md:"0px"}
      }}
      >
        {/* ROW 1
        <Grid
          item xs={12} sm={10}
          sx={{
            gridColumn:{xs:"span 1", md:"span 3"},
            backgroundColor:colors.primary[400],
            display:"flex",
            alignItems:"center",
            justifyContent:"center",

          }}
        >
          <StatBox
            title="12,361"
            subtitle="Emails Sent"
            progress={0.75}
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid
          item xs={12} sm={10}
          sx={{
            gridColumn:{xs:"span 1", md:"span 3"},
            backgroundColor:colors.primary[400],
            display:"flex",
            alignItems:"center",
            justifyContent:"center",

          }}
        >
          <StatBox
            title="431,225"
            subtitle="Sales Obtained"
            progress={0.50}
            increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid
          item xs={12} sm={10}
          sx={{
            gridColumn:{xs:"span 1", md:"span 3"},
            backgroundColor:colors.primary[400],
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}
        >
          <StatBox
            title="32,441"
            subtitle="New Clients"
            progress={0.30}
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid
          item xs={12} sm={10}
          sx={{
            gridColumn:{xs:"span 1", md:"span 3"},
            backgroundColor:colors.primary[400],
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress={0.80}
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Grid> */}

        
        {/* <Box
          sx={{
            gridColumn:{xs:"span 1", md:"span 8"},
            gridRow:"span 2",
            backgroundColor:colors.primary[400],
          }}
        >
          <Box
          sx={{
            mt:"25px",
            p:"0 30px",
            display:"flex ",
            justifyContent:"space-between",
            alignItems:"center",

          }}
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Ganancias Generadas
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $100.500.200
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{height:{xs:"200px", md:"250px"}, m:"-20px 0 0 0"}}>
            <LineChart isDashboard={true} />
          </Box>
        </Box> */}
        {/* ROW 1 */}
        <Box
        sx={{

          gridColumn:{xs:"span 1", md:"span 4"},
          gridRow:{xs:"span 1", sm:"span 2"},
          backgroundColor:colors.primary[400],
          overflow:"auto",
          maxHeight:350
        }}
        >
          <Box
            sx={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",
              borderBottom:`4px solid ${colors.primary[500]}`,
              colors:colors.grey[100],
              p:"15px",
            }}
          >


            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Top de carreras más buscadas
            </Typography>
          </Box>
          {topCareers.map((career, i) => (
            <Box
              key={`${career.career_name}-${i}`}
              sx={{
                display:"flex",
                // justifyContent:"space-between",
                // alignItems:"center",
                borderBottom:`4px solid ${colors.primary[500]}`,
                p:"15px",

              }}
            >
              {/* <Box>
                <Typography
                  color={colors.blueAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  Ranking
                </Typography>
                <Typography color={colors.grey[100]}>
                  {career.rank}
                </Typography>
              </Box> */}
              <Box sx={{display:"flex", flexDirection:"column",maxWidth:"100%", textAlign:"left"}}>
                <Box color={colors.grey[100]}>{`${i + 1}.  ${career.career_name}`}</Box>
                {/* <Box
                  sx={{
                    backgroundColor:colors.greenAccent[500],
                    p:"5px 10px",
                    borderRadius:"4px",
                  }}
                >
                  {career.institute_name}
                </Box> */}
              </Box>
            </Box>
          ))}
        </Box>

        
        {/* ROW 2 */}
        <Box
        sx={{

          gridColumn:{xs:"span 1", md:"span 4"},
          gridRow:"span 2",
          backgroundColor:colors.primary[400],
          overflow:"auto",
        }}
        >

        {/*----------------Top Programs by institute--------------- 
        // [{
        //   "career_name": "Ingeniería Industrial",
        //   "uidUniversidad": "2823",
        //   "total": "1",
        //   "rank": "1"
        // }]

        */}
          
          <Box
            sx={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",
              borderBottom:`4px solid ${colors.primary[500]}`,
              colors:colors.grey[100],
              p:"15px",
            }}
          >


            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Top 3. Carreras más buscados en tu institución
            </Typography>
          </Box>

          { topPrograms.length > 0 ?
          (
            topPrograms.map((career, i) => (
              <Box
                key={`${career.career_name}-${i}`}
                sx={{
                  display:"flex",
                  justifyContent:"space-between",
                  alignItems:"center",
                  borderBottom:`4px solid ${colors.primary[500]}`,
                  p:"15px",
  
                }}
              >
                {/* <Box>
                  <Typography
                    color={colors.blueAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    Ranking
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {career.rank}
                  </Typography>
                </Box> */}
                <Box sx={{display:"flex", flexDirection:"column", textAlign:"left"}}>
                  <Box color={colors.grey[100]}>{`${i + 1}.  ${career.career_name}`}</Box>
                  {/* <Box
                    sx={{
                      backgroundColor:colors.greenAccent[500],
                      p:"5px 10px",
                      borderRadius:"4px",
                    }}
                  >
                    {career.institute_name}
                  </Box> */}
                </Box>
              </Box>
            ))
          )
          :  <Typography color={colors.grey[100]} sx={{padding:"40px", textAlign:"justify"}} variant="h5" fontWeight="600">
            Aún no registramos datos en la base de datos
            </Typography>
          }
        </Box>

        {/* ROW 3 */}
        <Box
          sx={{

            gridColumn:"span 4",
            gridRow:"span 2",
            backgroundColor:colors.primary[400],
          }}
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[100]} sx={{    borderBottom:`4px solid ${colors.primary[500]}`,
            p:"15px",}}>
            Conexión desde dispositivos
          </Typography>
          <Box height="240px">
            <PieChart/>
          </Box>
          {/* <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $25.600.352 ganancias generadas
            </Typography>
            <Typography>Incluye gastos y costes adicionales</Typography>
          </Box> */}
        </Box>

        {/* <Box
          sx={{
            gridColumn:"span 4",
            gridRow:"span 2",
            backgroundColor:colors.primary[400],
          }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Cantidad vendida
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box> */}
        {/* ROW 4 */}
        <Box
          sx={{
            gridColumn:"span 12",
            gridRow:"span 3",
            backgroundColor:colors.primary[400],

          }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px", color: colors.grey[100],  borderBottom:`4px solid ${colors.primary[500]}`, p:"15px",}}
          >
            Usuarios por país
          </Typography>
          <Box  height={"380px"} ref={containerRef} >
            <GeoChart/>
            {/* <BarChart /> */}
          </Box>
        </Box>

        
      </Box>
     } 

  </Box>
  : <Typography variant="h5"> Tu usuario no tiene un rol asignado.</Typography> 
    }
    </>
    
  );
};

