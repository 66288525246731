import instituteService from "@/api/institute.service";
import { ContactSocialMedia, InstituteProps } from "@/types";
import { isValidPhone, isValidUrl, SwitchButton, tokens } from "@/utilities";
import { EditableInput } from "@/utilities/Components";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";

interface Props {
  institute: InstituteProps | null;
  isEditable: boolean;
  premiumPlan: string;
}

export const SocialMediaInstitute = ({
  isEditable,
  premiumPlan,
  institute,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const iesId = institute?.id;

  const [isEditingSocialMedia, setIsEditingSocialMedia] =
    useState<boolean>(false);
  const [editableSocialMedia, setEditableSocialMedia] = useState<
    Partial<ContactSocialMedia>
  >({
    facebook: institute?.contactSocialMediaIES?.facebook || "",
    hasFacebook: institute?.contactSocialMediaIES?.hasFacebook || false,
    whatsapp: institute?.contactSocialMediaIES?.whatsapp || null,
    hasWhatsapp: institute?.contactSocialMediaIES?.hasWhatsapp || false,
    linkedin: institute?.contactSocialMediaIES?.linkedin || "",
    hasLinkedin: institute?.contactSocialMediaIES?.hasLinkedin || false,
  });

  const [whatsappError, setWhatsappError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);

  const handleSocialMediaChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case "whatsapp":
        setWhatsappError(!isValidPhone(value));
        break;
      case "facebook":
        setFacebookError(!isValidUrl(value, ["fb", "facebook.com"]));
        break;
      case "linkedin":
        setLinkedinError(!isValidUrl(value, ["linkedin.com", "lnkd.in"]));
        break;
      default:
        break;
    }
    setEditableSocialMedia((prevUser) => ({ ...prevUser, [name]: value }));
  };

  async function handleSocialMediaSubmit() {
    try {
      const selectedSocialMedia = {
        facebook: institute?.contactSocialMediaIES?.facebook || "",
        hasFacebook: institute?.contactSocialMediaIES?.hasFacebook || false,
        whatsapp: institute?.contactSocialMediaIES?.whatsapp || null,
        hasWhatsapp: institute?.contactSocialMediaIES?.hasWhatsapp || false,
        linkedin: institute?.contactSocialMediaIES?.linkedin || "",
        hasLinkedin: institute?.contactSocialMediaIES?.hasLinkedin || false,
      };
      console.warn("handleContactInfoSubmit");
      if (
        JSON.stringify(editableSocialMedia) ===
        JSON.stringify(selectedSocialMedia)
      ) {
        setIsEditingSocialMedia(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (iesId) {
        const data = {
          contactSocialMediaIES: {
            facebook: editableSocialMedia.facebook || "",
            hasFacebook: editableSocialMedia.hasFacebook || false,
            whatsapp: editableSocialMedia.whatsapp || null,
            hasWhatsapp: editableSocialMedia.hasWhatsapp || false,
            linkedin: editableSocialMedia.linkedin || "",
            hasLinkedin: editableSocialMedia.hasLinkedin || false,
          },
        };

        await instituteService.update(iesId, data);
        console.warn("Updated in Database!");
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        // sessionStorage.setItem("currentCareers", JSON.stringify)
      }
      setIsEditingSocialMedia(!isEditingSocialMedia);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  }
  const initialSwitchValues: Record<string, boolean> = {};

  const [switchValues, setSwitchValues] = useState(initialSwitchValues);
  console.log(switchValues)

  const handleSocialMediaSwitchChange = (switchName: string) => {
    setSwitchValues((prevSwitchValues) => {
      const updatedSwitchValues = {
        ...prevSwitchValues,
        [switchName]: !prevSwitchValues[switchName],
      };

      setEditableSocialMedia((prevValues) => ({
        ...prevValues,
        ...updatedSwitchValues,
      }));

      return updatedSwitchValues;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingSocialMedia ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
              // disabled={emailError || phoneError}
            }}
            onClick={handleSocialMediaSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() => setIsEditingSocialMedia(!isEditingSocialMedia)}
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
          maxWidth: "75%",
        }}
        variant="h3"
      >
        Redes Sociales
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
          wordWrap:"break-word",
          wordBreak:"break-all",
        }}
      >
        {/*-----Whatsapp-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp
          </Typography>
          <EditableInput
            value={editableSocialMedia.whatsapp}
            name="whatsapp"
            type="text"
            error={whatsappError && (editableSocialMedia.hasWhatsapp ?? false)}
            helperText={
              whatsappError && editableSocialMedia.hasWhatsapp
                ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                : ""
            }
            isEditing={isEditingSocialMedia}
            isDisabled={!isEditable || !editableSocialMedia.hasWhatsapp}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp Activo
          </Typography>
          {isEditingSocialMedia ? (
            <SwitchButton
              checked={editableSocialMedia.hasWhatsapp ?? false}
              setChecked={() => handleSocialMediaSwitchChange("hasWhatsapp")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableSocialMedia.hasWhatsapp ? "Si" : "No"}
            </Typography>
          )}
        </Box>

        {/*-----Linkedin-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
      
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Linkedin
          </Typography>
          <EditableInput
            value={editableSocialMedia.linkedin}
            name="linkedin"
            type="text"
            error={linkedinError && (editableSocialMedia.hasLinkedin ?? false)}
            helperText={
              linkedinError && editableSocialMedia.hasLinkedin
                ? "La URL debe tener dominio linkedin.com o lnkd.in"
                : ""
            }
            isEditing={isEditingSocialMedia}
            isDisabled={!isEditable || !editableSocialMedia.hasLinkedin}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Linkedin Activo
          </Typography>
          {isEditingSocialMedia ? (
            <SwitchButton
              checked={editableSocialMedia.hasLinkedin ?? false}
              setChecked={() => handleSocialMediaSwitchChange("hasLinkedin")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableSocialMedia.hasLinkedin ? "Si" : "No"}
            </Typography>
          )}
        </Box>

        {/*-----Facebook-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Facebook
          </Typography>
          <EditableInput
            value={editableSocialMedia.facebook}
            name="facebook"
            type="text"
            error={facebookError && (editableSocialMedia.hasFacebook ?? false)}
            helperText={
              facebookError && editableSocialMedia.hasFacebook
                ? "La URL debe tener dominio facebook o fb"
                : ""
            }
            isEditing={isEditingSocialMedia}
            isDisabled={!isEditable || !editableSocialMedia.hasFacebook}
            onChange={handleSocialMediaChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Facebook Activo
          </Typography>
          {isEditingSocialMedia ? (
            <SwitchButton
              checked={editableSocialMedia.hasFacebook ?? false}
              setChecked={() => handleSocialMediaSwitchChange("hasFacebook")}
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableSocialMedia.hasFacebook ? "Si" : "No"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
