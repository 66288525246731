import React from 'react'
import { Button,Typography,Modal, Box, useTheme } from '@mui/material';
import { tokens } from '@/utilities';
import { FormCreateFaculty } from '@/components';

const style = {
    position: 'absolute',
    top: 0,
    left: '2.5%',
    width: 350,
    maxHeight:"90vh",
    boxShadow: " 0 0 20px rgba(255, 255, 255, 0.4)",
    p: 4,
    borderRadius: '40px',
    display: 'flex',
    flexWrap: 'wrap',
  };

  interface CodeProps{
    code: string 
  }

export const ModalCreateFaculty = (props:CodeProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  return (
    <Box>
        <Box>
            <Button 
                sx={{
                    background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
                    color:"#f2f0f0"
                }} 
                onClick={handleOpen}
                >Crear Facultad
            </Button>
        </Box>

        <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"

      >
        <Box sx={{
          style,
          backgroundColor:colors.primary[400]}} >
          <Button onClick={handleClose} 
            sx={{
              position:"absolute", 
              fontWeight:"bolder", 
              fontSize:"20px",
              color: colors.grey[100],
              right:0,
              ":hover":{background:"#3F51B5", color:"#FAFBFB"}}}
              >X</Button>

          <Typography id="keep-mounted-modal-title" variant="h2" component="h2" sx={{display: "flex", width:'100%',height:'100px' ,justifyContent:"center", alignItems:"center", boxShadow:"0px 1px 0px rgba(0, 0, 0, 0.1)"}}>
            Crear Facultad  
          </Typography>

            <Box>
                <FormCreateFaculty code={props.code}/>
            </Box>

        </Box>
      </Modal>
    </Box>
  )
}
