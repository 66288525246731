import React,{ useEffect, useState } from "react"
import './FormCreateAdmin.scss'
import userAdminService from "@/api/userAdmin.service"
import Box from '@mui/material/Box';
import institutesData from '@/api/JSON/InstitutesFresh.json'
import { generateRandomPassword, tokens } from "@/utilities"
import { Header } from "@/views";
import { Button, FormControl,  Grid, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import Swal from "sweetalert2";
import facultyService from "@/api/faculty.service";
import { facultyProps } from "@/types";
// import * as yup from 'yup';
// import { useFormik } from 'formik';

const dataJSON = JSON.parse(JSON.stringify(institutesData))

interface Option {
  value: string;
  label: string;
}

const showAlert = ()=>{
  Swal.fire({
      title: "Administrador creado satisfactoriamente",
      icon: "success",
      footer: `Se envió un correo de verificación al usuario`,
      timer: 2000,
  })
}



export const FormCreateAdmin = () => {
  // const {user} = useAuth()
  // const validationSchema = yup.object({
  //   email: yup
  //   .string()
  //   .email("Email invalido")
  //   .required("Indique un email de contacto")
  //   .test('domain', 'Debes usar un correo institucional', (value) => {
  //     const domainsToExclude = ['gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com'];
  //     if (domainsToExclude.every((domain) => value.split('@')[1] !== domain)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }),
  //   phone: yup
  //   .number()
  //   .positive("El número debe ser positivo")
  //   .integer("El número debe ser entero")
  //   .required("Indique número de contacto"),
  //   name: yup
  //   .string()
  //   .required("El nombre es requerido"),
  //   role: yup
  //   .string()
  //   .required("El role es requerido"),
  // })
  
  const [userRegister, setUserRegister]= useState({
    email: "",
    institution:"",
    phone:"",
    name:"",
    faculty:"",
    role:""
  })
  const theme = useTheme()    
  const colors = tokens(theme.palette.mode);
  
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  
  const [faculties, setFaculties] = useState<facultyProps[] | null>([])

  const filteredOptions = dataJSON.institutes
    .map((institute:any) => {
      const instituteCode = Object.keys(institute)[0];
      const instituteData = institute[instituteCode][1];
      if (instituteData && instituteData.name) {
        const label = `${instituteData.name} || ${instituteData.headquarters[0].city}`;
        return { value: instituteCode, label };
      }
      return null;
    })
    .filter((option: Option) => option !== null)
    .filter((option: Option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
   
    const handleSelectChange = (selectedValue: string) => {
      setSelectedOption(selectedValue);
      const selectedOptionLabel = filteredOptions.find((option:Option) => option.value === selectedValue)?.label || '';
      setSearchTerm(selectedOptionLabel);
      setUserRegister({
        ...userRegister,
        institution: selectedValue
      })
      setIsDropdownVisible(false)
    };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsDropdownVisible(value.length > 0)
  };


  const handleSubmit = async (event:React.FormEvent<HTMLFormElement>)=>{
      event.preventDefault()
      try {
        const authUser = {
          displayName: userRegister.name,
          email: userRegister.email,
          password: generateRandomPassword(8)
        }
       const createAdmin = await userAdminService.signUpAdmin(authUser)
       if(createAdmin){
          const profileData = {
            uid: createAdmin, 
            displayName: userRegister.name,
            email: userRegister.email,
            emailVerified: false,
            phoneNumber: userRegister.phone,
            active: false,
            institute: userRegister.institution,
            ref: `/institutes/${userRegister.institution}` ,
            role: userRegister.role,
            faculty: userRegister.faculty,
          };
          const savedInFirestore = await userAdminService.saveUserProfile(profileData, createAdmin);
      
            if (savedInFirestore) {
              console.log("Registro exitoso en Firebase Authentication y Firestore");
              showAlert()

              setUserRegister({
                email: "",
                institution: "",
                phone: "",
                name: "",
                faculty: "",
                role: "",
              });
              setSearchTerm("")

            } else {
              console.error("Error al guardar en Firestore");
            }
       }
      
      } catch (error) {
        console.error(error)
      }
  }
  
  const handlerChange = (event:any): void => {
    const {value, name} = event.target
    if (name === "institution") {
      const selectedInstitute = dataJSON.institutes.find((institute: any) => {
        const instituteCode = Object.keys(institute)[0];
        const instituteData = institute[instituteCode][1];
        return instituteData && instituteData.name === value;
      });
  
      if (selectedInstitute) {
        setUserRegister({
          ...userRegister,
          institution:Object.keys(selectedInstitute)[0]
         
        });
      }
    } else {
      setUserRegister({
        ...userRegister,
        [name]: value
      });
    }
  }
   
  useEffect(()=>{
    async function fetchFaculties() {
        if(selectedOption){
            try {
                const getFaculties = await facultyService.getAll(selectedOption)
                setFaculties(getFaculties)
            } catch (error) {
                console.error("Error trying to get faculties", error)
            }
        }            
    }
    fetchFaculties()
  },[selectedOption])

  return (
    <Box sx={{ padding:{xs:"5px",md:"5"}, width:"100%"}}>
      <Header title="Crear administrador" subtitle="Los campos marcados con * son obligatorios"/>
      
          <Box sx={{backgroundColor:colors.primary[400], width:"100%"}}>
          <form onSubmit={handleSubmit}>

          <Box 
          sx={{
            display:{xs:"flex", md:"grid"},
            flexDirection:"column",
            gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))",
            padding: 2,
            borderRadius: 2,
            border: `1px solid ${colors.blueAccent[500]}`,
            width:"100%",
            
            gap:"50px",
            marginTop:"30px"
          }}>
              
          <Box >
          
            <Grid item xs={12} sm={10} className="custom-select-container">
                <Box >
                  <TextField
                    required
                    id="institution"
                    name="institution"
                    label="Buscar y seleccionar institución"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleInputChange} 
                    sx={{color:colors.grey[100]}}
                    InputLabelProps={{
                      style: { color: colors.grey[100],
                      fontSize:"16px" }
                    }}
                    InputProps={{
                      style:{ color: colors.grey[100],
                        fontSize:"16px" }
                    }}
                    />

                    <Box 
                      className={`custom-select ${isDropdownVisible ? 'visible' : ''}`}
                      sx={{color:colors.primary[600], fontWeight:"bold", fontSize:"16px"}}
                    >
                      {filteredOptions.length > 0 ? (
                        <ul>
                          {filteredOptions.map((option: Option) => (
                            <li key={option.value} onClick={() => handleSelectChange(option.value)}>
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </Box>
              </Box>
            </Grid>
            
          </Box>

          <Grid item xs={12} sm={10}>
              <TextField
                required
                id="name"
                name="name"
                label="Nombres y Apellidos"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.name}
                onChange={handlerChange} 
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px" }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
              <TextField
                type="tel"
                id="phone"
                name="phone"
                value={userRegister.phone}
                label="Número de contacto"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                onChange={handlerChange} 
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px",
                }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={10}>           
              <TextField
                required
                type="email"
                name="email"
                id="email"
                label="Correo electrónico"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.email}             
                onChange={handlerChange}
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px" }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px" }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={10}>
              <FormControl fullWidth>
                <InputLabel id="faculty" sx={{color:colors.grey[100], fontSize:"16px"}}>Elija la facultad</InputLabel>
                <Select
                  id="faculty"
                  name="faculty"
                  required
                  labelId="faculty"
                  value={userRegister.faculty}
                  onChange={handlerChange} 
                >
                {faculties?.map((faculty)=>(
                  <MenuItem  key={faculty.id} value={faculty.name} sx={{fontSize:"16px"}}>{faculty.name}</MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>

              <Grid item xs={12} sm={10}>
                <FormControl fullWidth>
                <InputLabel id="role" sx={{color:colors.grey[100], fontSize:"16px"}}>Tipo de administrador</InputLabel>
                <Select
                  required
                  labelId="role"
                  id="role"
                  name="role"
                  value={userRegister.role}
                  onChange={handlerChange} 
                  
                >
                    <MenuItem value="Super Administrador" sx={{fontSize:"16px"}}>Super Administrador</MenuItem>
                    <MenuItem value="Administrador IES" sx={{fontSize:"16px"}}>Administrador IES</MenuItem>
                    <MenuItem value="Administrador Facultad" sx={{fontSize:"16px"}}>Administrador Facultad</MenuItem>
                    <MenuItem value="Profesor" sx={{fontSize:"16px"}}>Profesor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

            <Button 
              sx={{
                // gridColumn:"1/5",
                // background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
                backgroundColor:colors.grey[100],
                color:"#f2f0f0",            
                borderRadius:"5px",
                fontWeight:"bolder",
                fontSize:"16px",
                ":hover":{
                  cursor:"pointer",
                  backgroundColor: colors.grey[100]

                }
                }}
                type="submit"
                >
                  Crear Administrador
            </Button>

          </Box>
          </form>
          </Box>

    </Box>

  )
}
