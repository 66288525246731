import { configureStore } from "@reduxjs/toolkit";
import { activeSlice, inActiveSlice, instituteSlice } from "./states";
import { InstituteProps, Professor } from "@/types";
import { professorSlice } from "./states/professorsList";

export interface AppStore{
    institutes: InstituteProps[];
    activeList: InstituteProps[];
    inActiveList: InstituteProps[];
    professors: Professor[];
}

export default configureStore<AppStore>({
    reducer:{
        institutes: instituteSlice.reducer,
        activeList: activeSlice.reducer,
        inActiveList: inActiveSlice.reducer,
        professors: professorSlice.reducer,
    }
});