import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Box, Stepper, Step, Button , StepButton, Typography, useTheme} from '@mui/material';
import { FormIESAdministrative, FormIESDetails, FormIESImages } from '.';
import './AddNewIES.scss'
import { InstituteProps, LocationProps } from '@/types';
import { FormCreateNewHeadQuarter } from '..';
import { tokens } from '@/utilities';

const steps = ['Detalles básicos', 'Detalles administrativos', 'Detalles Sedes', 'Imágenes y descripción'];

export function FormAddNewIES(){
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<{
      [k: number]: boolean;
    }>({});
    const [headquarters, setHeadquarters] = useState<LocationProps[]>([]); // Local state to track headquarters

    const totalSteps = () => {
        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
      };
    
      const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((_, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleStep = (step: number) => () => {
        setActiveStep(step);
      };
    
      const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        if (activeStep === 2) { // Check if this is the "Detalles Sedes" step
          // Update the headquarters in the main form with the local state
          formik.setFieldValue('headquarters', headquarters);
        }
        handleNext();
      };
    
      const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
      };

      const formik = useFormik<InstituteProps>({
        initialValues:{
            id:"",
            name: "",
            acronym: "",
            type: "Universidad",
            character: "",
            webSite: "",
            fundationDate: new Date,
            president: "",
            vicePresident: "",
            headquarters: [],
            logo: "",
            images: [],
            description: "",
            active: true,
            code:"",
            subscriptionLevel: 0,
            email:"",
            taxID: "",
            internationalAccreditation: false,
            highAccreditation: false,

      },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: ()=>{
            if(activeStep === (totalSteps() -1)){
                console.log('last step')
            }else{
                setActiveStep((prevStep) => prevStep + 1);
            }
        }
      })

      const formContent = (step:number)=>{
        switch(step){
            case 0:
                return <FormIESDetails formik={formik}/>;
            case 1:
                return <FormIESAdministrative formik={formik}/>;
            case 2: 
                return <FormCreateNewHeadQuarter 
                            headquarters={headquarters}
                            setHeadquarters={setHeadquarters}
                        />
            case 3:
                return <FormIESImages formik={formik}/>;
            default: 
                return <div></div>
        }
      }

  return (
    <Box>
        <Box sx={{ width: '100%', padding:"40px" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} >
            <StepButton color="secondary" onClick={handleStep(index)}>
              <Typography variant='h5'sx={{color:colors.grey[100]}}>{label}</Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {/* All steps completed - you&apos;re finished */}
              Todos los pasos fueron completados, haz terminado.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reiniciar</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Paso  {activeStep + 1}
            </Typography> */}
            
                {<div className='container-form'>
                  {formContent(activeStep)}
                </div>}
                {/* {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )} */}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Atrás
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Siguiente
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Paso {activeStep + 1} completado
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? <div onClick={()=>formik.handleSubmit}>Finalizar</div>
                      // ?'Finalizar'
                      : 'Completar paso'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
    </Box>
  )
}
