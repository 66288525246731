
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container:{
        display:"flex",
        position:"relative",
        fontFamily: 'Helvetica',
      },
    subtitle: {
        fontSize: 14,
        margin: "10px 0",
        fontFamily: 'Helvetica-Bold',
        color: "#002D82",
      },
      rightAbsolute: {
        display:"flex",
        right:0,
        fontFamily: 'Helvetica',
        fontSize:10
      },
      tables:{
        fontSize:10,
        right:0,
        lineHeight:1.5,
        fontFamily: 'Helvetica',
      },
      text: {
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica',
        lineHeight:1.5,

      },
  });

  const deviceCase = (item:string) => {
    switch(item){
      case 'desktop' : return 'computadores';
          break 
      case 'mobile' : return 'celulares';
          break
      case 'tablet' : return 'tabletas';
          break
      default:
          return ""
    }
  }

export const ConnectionByDevice = ({data}:{data:any}) => (
    <>
         <View style={{display:"flex", flexDirection:"row", alignItems:"baseline", zIndex:10, marginBottom:"20px"}}>
           <Text style={styles.subtitle}>Conexiones de los usuarios por dispositivo</Text>
         </View>
         <View style={{...styles.container}}>
            <ul>
              <li><Text style={styles.text} >En el período analizado, los {data.userDevice[0] && deviceCase(data.userDevice[0].device_category)} son los preferidos por los usuarios para realizar la búsqueda de las carreras universitarias {data.userDevice[0] && data.percentages[data.userDevice[0].device_category]}%.
                   {/* {joinDevice}. */}
           </Text></li>
              <li><Text style={styles.text} >El usuario realiza en promedio {data.promEventsByUser} interacciones en la plataforma.</Text></li>
              <li> <Text style={styles.text}>Desde nuestro portal registramos {data.clicksRedirect} clic de redirección al web site de la universidad.
           </Text> </li>
           

           {/* <Text style={{...styles.rightAbsolute, top:80}}>
            El tiempo promedio de conexión es de (X) minutos desde computador y de (X) cuando lo realiza desde dispositivos móviles.
           </Text> */}
           </ul>
            <View style={{width:"100%", maxHeight:220, marginTop:"10px"}} >
             <Image src={data.imgDevice} style={{ objectFit:"contain", height:200}}/>
            </View>

        </View>
    </>

  )


