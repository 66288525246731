import { useState } from "react"
import './CreateNewHeadQuarter.scss'
import { GeoPointProps, LocationProps } from "@/types";
import { Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens, useAuth } from "@/utilities";


interface Props{
  headquarters: LocationProps[];
  setHeadquarters: (headquarters: LocationProps[]) => void;
}

export function FormCreateNewHeadQuarter ({headquarters, setHeadquarters}:Props) {

  const {user}=useAuth()

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [values, setValues]= useState<LocationProps>({
    title: '',
    address: '',
    country: '',
    region: '',
    city: '',
    zipCode: '',
    geoLocation: {
      latitude: 0,
      longitude: 0,
      toJSON(): { latitude: number; longitude: number } {
        return { latitude: this.latitude, longitude: this.longitude };
      },
    }

  });

  const [countHeadQuarters, setCountHeadQuarters] = useState<number>(0);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const premiumPlan = "Para poder acceder a los campos deshabilitados,por favor, dirigete a nuestros planes y adquiere el premium"

    const handlerChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
      const { name, value } = event.target;
    
      if (name === 'latitude' || name === 'longitude') {
        const geoLocation = values.geoLocation || new GeoPointProps(0, 0);
        const newGeoLocation = new GeoPointProps(
          name === 'latitude' ? Number(value) : geoLocation.latitude,
          name === 'longitude' ? Number(value) : geoLocation.longitude
        );
    
        setValues({
          ...values,
          geoLocation: newGeoLocation,
        });
      } else {
        setValues({
          ...values,
          [name]: value,
        });
      }
      }

      const handleSubmit= (event: React.FormEvent<HTMLFormElement>): void=>{
        event.preventDefault()
        if(user){
          if (user.subscriptionLevel >= 3 || countHeadQuarters === 0) {
          setHeadquarters([...headquarters, values]);
          setCountHeadQuarters(countHeadQuarters + 1)
          setValues({
            title: '',
            address: '',
            country: '',
            region: '',
            city: '',
            zipCode: '',
            geoLocation: {
              latitude: 0,
              longitude: 0,
              toJSON(): { latitude: number; longitude: number } {
                return { latitude: this.latitude, longitude: this.longitude };
              },
            }
            });
          }else{
            setErrorOpen(true);
          }
        }
      }

   
  return (
      <Box sx={{
        display:"flex",
        flexDirection:"column",
        backgroundColor:colors.primary[400],
        padding:"20px 40px",
        borderRadius:"5px",
        marginTop:"20px",
        gap:"20px",
        width:"80%"
      }} >
        
        <Typography variant="h3" sx={{color:colors.grey[100]}}>Detalles de la sede</Typography>

        <hr style={{width:"100%"}}/>

        {errorOpen && (
          <Typography variant="h5" sx={{margin:"0", color:"red"}}>{premiumPlan}</Typography>
      )}
        
          <form onSubmit={handleSubmit} >

          <Box sx={{
            display:"grid",
            gridTemplateColumns:"repeat(2,1fr)",
            width:"100%",
            gap:"20px",
            justifyContent:"center",
            textAlign:"center",
            alignItems:"center",
          }}>
            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  required
                  label="Nombre de la sede"
                  id="title"
                  name="title"
                  size="small"
                  autoComplete="off"
                  value={values.title}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>
            
            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  required
                  label="País"
                  id="country"
                  name="country"
                  size="small"
                  autoComplete="off"
                  value={values.country}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  required
                  label="Departamento"
                  id="region"
                  name="region"
                  size="small"
                  autoComplete="off"
                  value={values.region}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  required
                  label="Ciudad"
                  id="city"
                  name="city"
                  size="small"
                  autoComplete="off"
                  value={values.city}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  required
                  label="Dirección"
                  id="address"
                  name="address"
                  size="small"
                  autoComplete="off"
                  value={values.address}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  label="Latitud"
                  id="latitude"
                  name="latitude"
                  size="small"
                  autoComplete="off"
                  value={values.geoLocation?.latitude}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>

            <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  label="Longitud"
                  id="longitude"
                  name="longitude"
                  size="small"
                  autoComplete="off"
                  value={values.geoLocation?.longitude}
                  onChange={handlerChange}
                  InputLabelProps={{
                    style: { color: colors.grey[100],
                    fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ color: colors.grey[100],
                      fontSize:"16px" }
                  }}
                  />
            </Grid>
            
            <Button sx={{
              background:`linear-gradient(to bottom, ${colors.blueAccent[700]} 10%, ${colors.blueAccent[500]})`,
              color:"#f2f0f0",            borderRadius:"5px",
              fontWeight:"bolder",
              fontSize:"16px"
              }} type="submit">Agregar Sede</Button>
            
        </Box>
        </form>

        {headquarters.length > 0  && (
           <Box sx={{width:"100%"}}>
           <Typography variant="h3" sx={{color:colors.grey[100]}}>Sedes creadas:</Typography>
   
             <hr style={{width:"100%"}}/>
            <Box sx={{display:"flex", flexWrap:"wrap", gap:"20px", width:"100%"}}>
              {headquarters.map((hq, index) => (
                  <Box sx={{ width:"270px", padding:"10px 20px"}} key={index}>
                    <Typography variant="h5" color={colors.grey[100]}>
                      Nombre: {hq.title}
                      <br/> 
                      Dirección: {hq.address}
                      <br/>
                      País: {hq.country}
                      <br/>
                      Departamento: {hq.region}
                      <br/>
                      Ciudad: {hq.city}
                      <br/>
                      Latitud: {hq.geoLocation?.latitude}  
                      <br/>
                      Longitud: {hq.geoLocation?.longitude}
                    </Typography>
                    
                  </Box>
              ))}
             </Box>
           </Box>     
        ) }
       
    </Box>
  )
}


