export const modalitiesType = ["Presencial", "Virtual", "Híbrida"];

export const periodsType = ["Mensual", "Bimestral", "Cuatrimestral", "Semestral", "Anual"];

export const scheduleType = ["Diurno", "Vespertino", "Nocturno", "Mixto"];

export const characterType = ["Pública", "Privada"];

export const studyLevelsType = [
  "Pregrado",
  "Especialización",
  "Maestría",
  "Doctorado",
  "Técnico",
  "Tecnólogo",
];


export const isValidEmail = (email: string) => {
  const isEduEmail = email.toLowerCase().includes(".edu");
  return isEduEmail;
};

export const isValidPhone = (phone: string): boolean => {
  const phoneno = /^\d+$/;
  if (phone.match(phoneno)) {
    return true;
  } else {
    return false;
  }
};

export const isValidUrl = (url: string, validators: string[]) => {
  let isOkUrl = false;
  validators.map((validator) => {
    if (!isOkUrl) isOkUrl = url.toLowerCase().includes(validator);
  });
  return isOkUrl;
};

export const isValidValue = (name: string, value: any) => {
  switch (name) {
    case "email":
      return isValidEmail(value);
    case "phone":
      return isValidPhone((value ?? 0).toString());
    case "whatsapp":
      return isValidPhone(value);
    case "facebook":
      return isValidUrl(value, ["fb", "facebook.com"]);
    case "linkedin":
      return isValidUrl(value, ["linkedin.com", "lnkd.in"]);
      break;
    default:
      break;
  }
  return true;
};