
interface Props{
    startMonth: string | Date
    endMonth?: string  | Date
    className?: string
    isEducation?:boolean
}
export default function DateDifference({ startMonth, endMonth, className, isEducation }:Props) {
 
    const startDate =  new Date(startMonth);
    const endDate = endMonth ? new Date(endMonth): new Date();

    const months = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    const startMonthName = months[startDate.getUTCMonth()];
  const endMonthName = months[endDate.getUTCMonth()];

  let yearsDiff = endDate.getUTCFullYear() - startDate.getUTCFullYear();
  let monthsDiff = endDate.getUTCMonth() - startDate.getUTCMonth();

  if (monthsDiff < 0) {
    yearsDiff -= 1;
    monthsDiff += 12;
  }

  return (
    <>
      <span className={className}>
        {`${startMonthName} ${startDate.getUTCFullYear()}`}
        {endMonth ? ` - ${endMonthName} ${endDate.getUTCFullYear()}` : " - Actualmente"}
        <br />
        {isEducation ? ("") : (
          <>
            {yearsDiff > 0 ? ` ${yearsDiff} año${yearsDiff !== 1 ? 's' : ''}` : ''}
            {monthsDiff > 0 ? ` ${monthsDiff} mes${monthsDiff !== 1 ? 'es' : ''}` : ''}
          </>
        )}
      </span>
    </>
  );
}


