import Swal from 'sweetalert2'

export const alertConfirm = ()=> Swal.fire({
  title: '¿Está seguro?',
  text: "El estado de la institución cambiará",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Si, ¡cámbialo!',
  cancelButtonText: 'Cancelar'
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(
      '¡Actualizado!',
      'Su institución fue actualizada satisfactoriamente',
      'success'
    )
  }
  return result.isConfirmed
})

export const alertDelete = ()=> Swal.fire({
  title: '¿Está seguro?',
  text: "La institución seleccionada será eliminada permanentemente",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Si, ¡elimínala!',
  cancelButtonText: 'Cancelar'
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(
      '¡Eliminada!',
      'La institución fue eliminada satisfactoriamente',
      'success'
    )
  }
  return result.isConfirmed
})