import imagesService from "@/api/images.service";
import { CareerProps, ImageProps } from "@/types";
import { tokens } from "@/utilities";
import { Box, Button, InputLabel, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import defaultLogo from "@/assets/imagen.jpg";
import Swal from "sweetalert2";

interface Props {
  premiumPlan: string;
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  isEditable: boolean;
}

export const CareerImages = ({
  premiumPlan,
  selectedCareer,
  isEditable,
  updateCareer,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingImages, setIsEditingImages] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const [downloadURLs, setDownloadURLs] = useState<ImageProps[]>(
    selectedCareer.iesImages || []
  );

  const [defaultImage, setDefaultImage] = useState<ImageProps>(
    selectedCareer.defaultImage || { url: "", alt:"Imagen institución por carrera" }
  );


  const saveImages = async () => {
    try {
      if (selectedCareer.id && downloadURLs.length <= 5) {
        await imagesService.updateImagesByCareerFirestore(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          [...downloadURLs],
          defaultImage
        );
        updateCareer({ iesImages: downloadURLs, defaultImage: defaultImage }, selectedCareer.id);

        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        setIsEditingImages(!isEditingImages);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      Swal.fire({
        title: "Error cargando las imágenes. Por favor, intente nuevamente.",
        icon: "error",
        timer: 2000,
      });
    }
  };

  async function handleImagesSubmit() {
    if (selectedImages.length === 0) {
      Swal.fire({
        title: "Por favor, selecciona imagenes",
        icon: "info",
        timer: 2000,
      });

      return;
    }
    try {
      if (selectedCareer.id) {
        const uploadedURLs = await imagesService.uploadCareersImages(
          selectedCareer.uidInstitution,
          selectedImages,
          selectedCareer.uidFaculty,
          selectedCareer.id
        );
        setDownloadURLs((prevImages) => [...prevImages, ...uploadedURLs]);
        // setCareers((prevCareers: CareerProps[])=>
        // prevCareers.map((career:CareerProps)=>
        //   career.id  === selectedCareer.id ? {...career, images: downloadURLs} : career
        //   )
        // )
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      Swal.fire({
        title: "Error cargando las imágenes. Por favor, intente nuevamente.",
        icon: "error",
        timer: 2000,
      });
    }
  }

  const handleImagesChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 5) {
      Swal.fire({
        title: "Selecciona un máximo de 5 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files) {
      const selectedFiles = Array.from(inputElement.files);
      setSelectedImages(selectedFiles);
    }
  };

  const handleDeleteImages = async (index: number) => {
    try {
      const updatedImages = downloadURLs.filter((_, i) => i !== index);
      setDownloadURLs(updatedImages);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
        gap: "0 20px",
      }}
    >
      {isEditingImages ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            disabled={downloadURLs.length > 5}
            onClick={saveImages}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() => setIsEditingImages(!isEditingImages)}
          >
            Editar
          </Button>
        </Box>
      )}

      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h3"
      >
        Imágenes
      </Typography>

      {downloadURLs.length > 5 && (
        <Typography
          sx={{
            marginTop: "35px",
            color: "red",
            display: "flex",
          }}
          variant="h6"
        >
          Nota: Solo se permite un máximo de 5 imágenes por carrera.
        </Typography>
      )}

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      {downloadURLs.length > 0 ? (
        downloadURLs.map((image, index) => (
          <Box key={index} sx={{ width: "fit-content", position: "relative" }}>
            <img
              src={image.url}
              alt={image.title}
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "15px 0px",
                minHeight: "200px",
                width: "250px",
                maxWidth: "300px",
                maxHeight: "200px",
                borderRadius: "20px",
                marginBottom: "40px",
                objectFit: "cover",
              }}
              onClick={() => setDefaultImage(image)}
            />
            {isEditingImages ? (
              <Button
                sx={{
                  position: "absolute",
                  left: 0,
                  top: -10,
                  padding: 0,
                  border: "none",
                }}
                variant="outlined"
                onClick={() => handleDeleteImages(index)}
              >
                Eliminar
              </Button>
            ) : (
              ""
            )}
          </Box>
        ))
      ) : (
        <Box sx={{ width: "fit-content", position: "relative" }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a"
            alt="Logo Guía Universitaria"
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "15px 0px",
              minHeight: "200px",
              width: "250px",
              maxWidth: "300px",
              maxHeight: "200px",
              borderRadius: "20px",
              marginBottom: "40px",
              objectFit: "cover",
            }}
          />
          {/* { isEditingImages? <Button  sx={{position:"absolute", left:0, top:-10,  padding:0, border:"none"}} variant="outlined" onClick={() => handleDeleteImages(0)}>Eliminar</Button>
            :""
              } */}
        </Box>
      )}

      {isEditingImages && downloadURLs.length < 5 ? (
        <Box>
          <InputLabel id="iesImages">Añadir imagenes de la carrera</InputLabel>
          <input
            type="file"
            id="iesImages"
            name="iesImages"
            onChange={handleImagesChange}
            accept="image/png, image/jpeg, image/webp"
            multiple
          />
          <Button variant="outlined" onClick={handleImagesSubmit}>
            Subir
          </Button>
          {/* <img src={url || ''} alt='firebase-img'/>  */}
        </Box>
      ) : (
        ""
      )}

      {isEditingImages && (
        <Box sx={{ width: "100%" }}>
          <Typography variant="h4" sx={{ color: colors.grey[100] }}>
            Imagen predeterminada
          </Typography>
          <img
            src={
              defaultImage && defaultImage.url ? defaultImage.url : defaultLogo
            }
            alt="Logo Guía Universitaria"
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "15px 0px",
              minHeight: "200px",
              width: "250px",
              maxWidth: "300px",
              maxHeight: "200px",
              borderRadius: "20px",
              marginBottom: "40px",
              objectFit: "cover",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
