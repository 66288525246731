import professorService from "@/api/professor.service";
import { Professor } from "@/types";
import { useEffect, useState } from "react";
import { CardProfessor } from "..";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuth } from "@/utilities";
import Swal from "sweetalert2";


export const FavoritesProfessors = () => {
    
    const {user} = useAuth()
    const uid = user?.user.uid
    const [favorites, setFavorites] = useState<Professor[] | null>()
    
    const navigate = useNavigate()
    const goToDetailPage = (objectID: string)=>{
        navigate(`/professor/${objectID}`)
      }

      
      const handleDelete = async (id:string) => {
        try {
            if(uid){
                await professorService.removeFromFavorites(uid, id);
                const updatedFavorites = favorites?.filter(professor => professor.objectID !== id);
                Swal.fire({
                    title:"Se ha removido el docente de tus favoritos satisfactoriamente.",
                    icon:"success",
                    timer:2000
                })
                setFavorites(updatedFavorites);
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title:"Ocurrió un error removiendo al docente, intente nuevamente",
                icon:"error",
                timer:2000
            })
        }
    }

    useEffect(()=>{
        const fetchFavorites = async()=>{
            try {
                if(uid){
                    const professorsFavoritesList = await professorService.getListByUser(uid)
                    if(professorsFavoritesList){
                        const dataFavorites = await professorService.getFavorites(professorsFavoritesList)
                        setFavorites(dataFavorites)
                     }
                }
               
            } catch (error) {
                console.error(error)
            }
        }
        if(uid){
            
            fetchFavorites()
        }
       },[uid])
  return (
    <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"center", paddingBottom:"50px"}} >
        {favorites?
        (favorites.map(professor=>{
            return (
                <CardProfessor 
                onClick={()=>goToDetailPage(professor.objectID)}
                bannerSrc=""
                profileImgSrc= {professor.photoUrl?professor.photoUrl:"" }
                name= {professor.fullName}
                speciality= {professor.personalData.speciality}
                id={professor.objectID}
                isFavorite={true}
                className=""
                key={professor.objectID}
                handleDelete={()=>handleDelete(professor.objectID)}
                />
            )
        }))
        : "No se han encontrado favoritos aún" 
    }
    </Box>
  )
}
