import { tokens, useAuth } from "@/utilities"
import { Box, Button, Typography,  useTheme } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { convertProgramObject } from ".";
import facultyService from "@/api/faculty.service";
import { facultyProps } from "@/types";
import careersService from "@/api/careers.service";

const requiredFields = [
    "CÓDIGO_SNIES_PROGRAMA",
    "NOMBRE_DEL_PROGRAMA",
    "SITIO_WEB_PROGRAMA",
    "NIVEL_ACADÉMICO",
    "NIVEL_FORMACIÓN",
    "NIVEL_FORMACIÓN_DETALLADO_SEGÚN_MINISTERIO",
    "PERFIL_DEL_GRADUADO",
    "MODALIDAD",
    "TIPO_HORARIO",
    "NÚMERO_CRÉDITOS_PROGRAMA",
    "NÚMERO_PERIODOS_DURACIÓN",
    "TIPO_PERIODO",
    "PRECIO_PROGRAMA_POR_PERIODO",
    "TIPO_DE_MONEDA"
  ];

  function validateProgramObject(programObject:any) {
    for (const requiredField of requiredFields) {
        if (!(requiredField in programObject)) {
          return false;
        }
      }
    
      return true;
  }
  
  function getErrorMessage(programObject:any) {
    let errorMessage = "La fila no es válida porque falta: ";
  
    for (const requiredField of requiredFields) {
        if (!(requiredField in programObject)) {
          errorMessage += requiredField + ", ";
        }
      }
    
      errorMessage = errorMessage.slice(0, -2);
    
      return errorMessage;
  }

export const FormatUploadCareers =  () => {
    const {user, institute} = useAuth()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [facultyData, setFacultyData] = useState<facultyProps | null>(null)

    // const handlerFile = (event: ChangeEvent<HTMLInputElement>)=>{
    //     const file = event.target.files?.[0]

    //     if (file){
    //         const reader = new FileReader()

    //         reader.onload = (e:ProgressEvent<FileReader>)=>{
    //             const data = e.target?.result as string
    //             const workbook = XLSX.read(data, { type: 'binary' });

    //             //First sheet only used
    //             const firstSheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[firstSheetName];

    //             const range = XLSX.utils.decode_range(worksheet['!ref'] || "");
    //             console.log(range)
    //             // Itera sobre las celdas de la hoja de cálculo
    //             let refValues = []
    //             for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
    //                 // Verifica si hay datos en las columnas A a L
    //                 let hasDataInColumnsAtoN = false;
    //                 for (let colNum = range.s.c; colNum <= range.s.c + 14; colNum++) {
    //                 const cellAddress = { r: rowNum, c: colNum };
    //                 const cellRef = XLSX.utils.encode_cell(cellAddress);
    //                 const cellValue = worksheet[cellRef]?.v;
                        
    //                     if (!cellValue) {
    //                         hasDataInColumnsAtoN = true;
    //                         break
    //                         // Puedes hacer más cosas si encuentras un dato, si es necesario
    //                     }
    //                 }
                    
    //                 if (!hasDataInColumnsAtoN) {
    //                     refValues.push((rowNum + 1));
    //                 }
    //                 // Verifica si hay datos en las columnas Q y R
    //                 // const cellB = worksheet[XLSX.utils.encode_cell({ r: rowNum, c: 1 })]?.v //Columna B
    //                 // if(!cellB) refValues.push("B"+(rowNum +1))
    //                 // const cellQ = worksheet[XLSX.utils.encode_cell({ r: rowNum, c: 16 })]?.v; // Columna Q
    //                 // const cellR = worksheet[XLSX.utils.encode_cell({ r: rowNum, c: 17 })]?.v; // Columna R
    //                 // console.log("valores que faltan", refValues)

                    
                
    //                 // if (hasDataInColumnsAtoN || cellQ || cellR) {
    //                 // // Si hay algún dato en las columnas especificadas, realiza la verificación
    //                 // console.log(`Fila ${rowNum + 1} tiene datos relevantes.`);
    //                 // // Realiza la verificación adicional que necesites aquí
    //                 // }
    //         }   
    //     }
    //     reader.readAsBinaryString(file);
    //     }
    // }

    const instituteName = institute?.name
    const headquarter = institute?.headquarters
    const facultyId = user?.userData.faculty
    const instituteId = user?.userData.institute
    const iesCharacter = institute?.character
    const iesImages = institute?.images
    const iesLogo = institute?.logo
    const subscriptionLevel = institute?.subscriptionLevel
    const facultyName = facultyData?.name

    const [error, setError] = useState<null | string[]>([])

      const handlerFile =  (event: ChangeEvent<HTMLInputElement>)=>{
        const file = event.target.files?.[0]
        try {
            if (file){
            
                const reader = new FileReader()
                
                  reader.readAsBinaryString(file)
                  reader.onload = (e:ProgressEvent<FileReader>)=>{
                  const data = e.target?.result
                  const workbook = XLSX.read(data, { type: 'binary' });
            
                  //First sheet only used
                  const firstSheetName = workbook.SheetNames[0];
                  const worksheet = workbook.Sheets[firstSheetName];
                  // console.log(workbook)
                  // Itera sobre las celdas de la hoja de cálculo
                  const dataObject = XLSX.utils.sheet_to_json(worksheet)
                  // console.log(dataObject)
      
                  const errorMessages= [];
      
                  for (let i = 0; i < dataObject.length; i++) {
                    const programObject = dataObject[i];
                    const isValid = validateProgramObject(programObject);
            
                    if (!isValid) {
                      const errorMessage = getErrorMessage(programObject);
                      errorMessages.push(`Error en la fila ${i + 2}: ${errorMessage}`);
                    }
                  }
      
                  setError(errorMessages)

                  if(errorMessages.length === 0 && instituteName && headquarter && facultyId && instituteId && iesCharacter && iesImages && iesLogo && subscriptionLevel && facultyName){
                      const formattedPrograms = dataObject.map((object)=>convertProgramObject(object, instituteName, headquarter, facultyId, instituteId, iesCharacter, iesImages, iesLogo,subscriptionLevel,facultyName))
                    
                      const upload = async ()=>{
                        try {
                            for(const career of formattedPrograms){
                                await  careersService.createCareerFromBulk(career)
                             }
                          //  console.log(formattedPrograms)
                        } catch (error) {
                            console.error(error)
                        }
                        
                      }
                      upload()
                  }
                  // console.log(dataObject);
                  // console.log(errorMessages);
                  event.target.value = ""
                  }
              }
        } catch (error) {
            console.error(error)
        }
       
    }
    useEffect(()=>{
        async function fetchFaculties() {
            if(facultyId && instituteId){
                try {
                    const getFaculties = await facultyService.getById(instituteId, facultyId)
                    setFacultyData(getFaculties)
                } catch (error) {
                    console.error("Error trying to get faculties", error)
                }
            }            
        }
        fetchFaculties()
    },[facultyId,instituteId])

  return (
    <Box sx={{width:"100%",display:"flex"}}>

        <Box sx={{width:"65%",  padding:"40px"}}>
            <Typography variant="h3" color={colors.grey[100]}>Instrucciones:</Typography>
            <Typography variant="h6" color={colors.grey[100]}>Por favor lea bien las instrucciones antes de llenar el formato.</Typography>

            <ol>
                <li><Typography variant="h5">El formato es un Excel diseñado para subir carreras de manera masiva.</Typography></li>
                <li><Typography variant="h5">Una vez abierto el formato deberá diligenciar los campos, en especial los que están en color rojo ya que son de carácter obligatorio. De no ser así, al momento de subirlo fallará la carga</Typography></li>
                <li><Typography variant="h5">Es importante diligenciar el <b>SNIES</b> de cada programa o podrían haber duplicados.</Typography></li>
                <li><Typography variant="h5">Existen campos que solo reciben valores especificos en los cuales tendrá una lista desplegable para elegir.</Typography></li>
                <li><Typography variant="h5">Por favor, cuide la gramática ya que esta información estará abierta al público para que puedan tomar una correcta decisión en su elección de programa.</Typography></li>
                <li><Typography variant="h5">Aquellos campos que son de tipo fecha, tales como; FECHA_INICIO_PROGRAMA, PRÓXIMAS_INSCRIPCIONES e INICIO_DE_CLASES deberán ir en un formato de fecha de la siguiente manera DD/MM/AAAA Ejemplo: 01/01/2023</Typography></li>
                <li><Typography variant="h5">Cuando termine de diligenciar el formato podrá subirlo y se le desplegarán 2 opciones:</Typography>
                    <ul>
                        <li>Adicionar los programas en el formato a la base de datos actual.</li>
                        <li>Reemplazar los programas actuales con la nueva información subida en el formato.</li>
                    </ul>
                    <Typography>Tenga en cuenta que si decide reemplazar los programas se borrará la base de datos existente y se subirá la nueva que está siendo proporcionada por la institución.</Typography>
                </li>
                <li><Typography variant="h5">Si solo desea crear una carrera lo podrá hacer desde el apartado Crear carrera que se encuentra en la parte superior.</Typography></li>
                <li><Typography variant="h5">Si desea actualizar una carrera, diríjase a la tabla de carreras activas o inactivas y allí podrá buscarla filtrandola en la parte superior de la tabla dependiendo del encabezado, en la opción de los 3 puntos </Typography></li>
            </ol>

        </Box>

        <Box sx={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-evenly"}}>
            <Button variant="outlined" href="https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/files%2FExcelFormats%2FFormato_Programas.xlsx?alt=media&token=2af8e9d8-081b-4e37-a566-f8d60e591d3c">Descargar Formato</Button>

            <Box>
                <label htmlFor="format" style={{position:"relative"}}>
                    <input
                    accept=".xlsx, .xls"
                    id="format"
                    type="file"
                    style={{ display: 'none'}}
                    onChange={handlerFile}
                    />
                    <Button variant="outlined" component="span">Subir Formato</Button>   
                </label>
            </Box>
            {error && 
            <ul>
                {error.map((errorMessage, index)=>(
                   <li key={index} style={{color:"red"}}><Typography color={"red"}>{errorMessage}</Typography></li> 	
                ))}
            </ul>
            }
        </Box>
        
    </Box>
  )
}
