import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  SwitchButton,
  convertBase64ToFile,
  dialCodes,
  tokens,
  useAuth,
} from "@/utilities";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  ContactSocialMedia,
  DefaultValue,
  HasSocialMedia,
  ImageProps,
  facultyProps,
} from "@/types";
import facultyService from "@/api/faculty.service";
import { Carousel } from "react-responsive-carousel";
import imagesService from "@/api/images.service";
import { EditableInput } from "@/utilities/Components";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  code: string;
  facultyName: string;
  refFirebase: string;
  setIsFacultyName: (newFacultyName: string) => void;
}

interface Images {
  id: number;
  url: string;
  alt?: string | undefined;
}

const showAlertSuccess = () => {
  Swal.fire({
    title: "Facultad actualizada satisfactoriamente",
    icon: "success",
    footer: `Revisa la lista de facultades`,
    timer: 2000,
  });
};
const showAlertWrong = () => {
  Swal.fire({
    title: "Lo sentimos, hubo un problema en la actualización",
    icon: "error",
    footer: `Por favor, vuelve a intentarlo`,
    timer: 2000,
  });
};

export default function ModalUpdateFaculty({
  code,
  facultyName,
  refFirebase,
  setIsFacultyName,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const splitPath = refFirebase.split("/");
  const facultyID = splitPath[splitPath.length - 1];
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user, institute } = useAuth();
  const isEditable = user?.subscriptionLevel && user.subscriptionLevel > 0;
  // const iesId = institute?.id

  const premiumPlan =
    "Para poder acceder a los campos deshabilitados,por favor, dirigete a nuestros planes y adquiere uno";

  const [valuesChanged, setValuesChanged] = useState(false);

  const [values, setValues] = useState<facultyProps>({
    name: "",
    active: true,
    description: "",
    logo: "",
    webSite: "",
    images: [],
    ref: "",
    contactInfo: {
      default: "ies",
    },
    contactSocialMedia: {
      default: "ies",
    },
    financialSupport: {
      default: "ies",
    },
    iesCharacter: "",
    uidInstitution: "",
    subscriptionLevel: 0,
  });

  const handlerChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;
    if (name === "name") {
      setIsFacultyName(value);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedLogo, setSelectedLogo] = useState<ImageProps>();
  const [logoUrl, setLogoUrl] = useState(values.logo);
  const [imagesUrl, setImagesUrl] = useState<Images[]>([]);

  const handleImagesChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 5) {
      Swal.fire({
        title: "Selecciona un máximo de 5 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files) {
      const selectedFiles = Array.from(inputElement.files);
      setSelectedImages([...selectedImages, ...selectedFiles]);
      const urls = selectedFiles
        .map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
        .filter((promise) => promise !== null); // Filter out null promises

      // Handle promises (outside the map)
      Promise.all(urls)
        .then((imageUrls) => {
          const selectedImages = imageUrls.map(
            (value: unknown, index: number) => {
              const url = value as string; // Cast value to string
              return {
                url,
                id: imagesUrl.length + index + 1,
              };
            }
          );
          setImagesUrl([...imagesUrl, ...selectedImages]);
        })
        .catch((error) => console.error("Error reading file", error));
    }

    // const inputElement = event.target as HTMLInputElement;

    // if (inputElement.files && (inputElement.files.length + imagesUrl.length) > 5) {
    //   Swal.fire({
    //     title:"Selecciona un máximo de 5 archivos.",
    //     icon:"info",
    //     timer:2000
    //   })
    //   event.preventDefault();
    //   return;
    // }

    // if (inputElement.files) {
    //   const selectedFiles = Array.from(inputElement.files);
    //   setSelectedImages([...selectedImages, ...selectedFiles]);

    //   const urls = selectedFiles.map((file) => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onload = () => resolve(reader.result);
    //       reader.onerror = reject;
    //       reader.readAsDataURL(file);
    //     });
    //   }).filter(promise => promise !== null); // Filter out null promises

    //   // Handle promises (outside the map)
    //   Promise.all(urls)
    //   .then((imageUrls) => setImagesUrl([...imagesUrl, ...(imageUrls as string[])]))
    //   .catch((error) => console.error("Error reading file", error));
    // }
  };

  const handleDeleteImages = (id: number) => {
    try {
      const updatedImages = imagesUrl.filter((image) => image.id !== id);
      setImagesUrl(updatedImages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogoChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 1) {
      Swal.fire({
        title: "Selecciona un máximo de 1 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files) {
      const selectedFile = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        setLogoUrl(url);
        setSelectedLogo({ url });
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleDeleteLogo = () => {
    setLogoUrl("");
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      let uploadedLogoURL = null;
      let facultyImages;
      if (isEditable) {
        const logoFormatted = selectedLogo?.url
          ? (convertBase64ToFile({ url: selectedLogo?.url }) as File)
          : null;
        // const filesFormatt = await imagesService.urlsToFiles(imagesUrl)
        const uploadedURLs = await imagesService.uploadFacultyImage(
          code,
          selectedImages,
          facultyID
        );

        if (logoFormatted) {
          uploadedLogoURL = await imagesService.uploadFacultyLogo(
            code,
            logoFormatted,
            facultyID
          );
        }
        const filteredImages = imagesUrl
          .filter((image) => image.url.startsWith("https://"))
          .map((image) => ({
            url: image.url,
          }));
        facultyImages = [...uploadedURLs, ...filteredImages];
      }

      // await imagesService.updateImageFacultyFirestore(user?.userData.institute,createId, [...uploadedURLs])

      const data = {
        ...values,
        images: facultyImages ||
          institute?.images || [
            {
              url: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a",
            },
          ],
        logo: uploadedLogoURL
          ? uploadedLogoURL[0].url
          : institute?.logo ||
            "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Flogo.jpg?alt=media&token=00448418-5d25-4033-84a3-648136db3bc3",
        // "description":values.description,
        // "name": values.name,
        // "webSite":editableContactInfo.webSite ?? "",
        // "active": values.active ?? false,
        // "contactInfo":{
        //   ...values.contactInfo,
        //   default:defaultContactInfoValue,
        //   faculty: {
        //     contactEmail: editableContactInfo.contactEmail || "",
        //     phone_code_country:editableContactInfo?.phone_code_country || "Prefijo",
        //     contactPhone: editableContactInfo?.contactPhone || null,
        //   }
        // },
        // "contactSocialMedia":{
        //   ...values.contactSocialMedia,
        //   default:defaultContactSocialValue,
        //   faculty: {
        //     facebook: editableContactInfo.facebook || "",
        //     hasFacebook: editableContactInfo.hasFacebook || false,
        //     whatsapp: editableContactInfo.whatsapp || null,
        //     hasWhatsapp: editableContactInfo.hasWhatsapp || false,
        //     linkedin: editableContactInfo.linkedin || "",
        //     hasLinkedin: editableContactInfo.hasLinkedin || false,
        //   }
        // },
        // "financialSupport":{
        //   ...values.financialSupport,
        //   default:defaultFinancialValue,
        //   faculty:{
        //     hasFinancialSupport:editableFinancialSupport.hasFinancialSupport || false,
        //     website:editableFinancialSupport.website || "",
        //     email:editableFinancialSupport.email || "",
        //     phone_code_country:editableFinancialSupport.phone_code_country || "Prefijo",
        //     whatsapp:editableFinancialSupport.whatsapp || null,
        //     hasWhatsapp:editableFinancialSupport.hasWhatsapp || false,
        //   }
        // }
      };
      const updated = await facultyService.update(code, facultyID, data);
      if (updated) {
        showAlertSuccess();
        setOpen(false);
      } else {
        showAlertWrong();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    if (valuesChanged) {
      Swal.fire({
        title: "¿Deseas guardar los cambios antes de salir?",
        text: "Los cambios no guardados se perderán.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        allowOutsideClick: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setValuesChanged(false);
          setOpen(false);
        } else {
          setOpen(false);
          setValuesChanged(false);
        }
      });
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    async function fetchFaculty() {
      try {
        const getFaculty = await facultyService.getByRef(refFirebase ?? "");
        if (getFaculty) {
          const data: facultyProps = {
            name: getFaculty.name || "",
            active: getFaculty.active || true,
            description: getFaculty.description || "",
            logo: getFaculty.logo || "",
            webSite: getFaculty.webSite || "",
            webSiteFaculty: getFaculty.webSiteFaculty || "",
            images: getFaculty.images || [],
            ref: getFaculty.ref,
            contactInfo: {
              default: getFaculty.contactInfo?.default || "ies",
              ies: {
                contactEmail: getFaculty.contactInfo?.ies?.contactEmail || "",
                contactPhone: getFaculty.contactInfo?.ies?.contactPhone || null,
                phone_code_country:
                  getFaculty.contactInfo?.ies?.phone_code_country || "Prefijo",
              },
              faculty: {
                contactEmail:
                  getFaculty.contactInfo?.faculty?.contactEmail || "",
                contactPhone:
                  getFaculty.contactInfo?.faculty?.contactPhone || null,
                phone_code_country:
                  getFaculty.contactInfo?.faculty?.phone_code_country ||
                  "Prefijo",
              },
            },
            contactSocialMedia: {
              default: getFaculty.contactSocialMedia?.default || "ies",
              ies: {
                facebook: getFaculty.contactSocialMedia?.ies?.facebook || "",
                hasFacebook:
                  getFaculty.contactSocialMedia?.ies?.hasFacebook || false,
                whatsapp: getFaculty.contactSocialMedia?.ies?.whatsapp || null,
                hasWhatsapp:
                  getFaculty.contactSocialMedia?.ies?.hasWhatsapp || false,
                linkedin: getFaculty.contactSocialMedia?.ies?.linkedin || "",
                hasLinkedin:
                  getFaculty.contactSocialMedia?.ies?.hasLinkedin || false,
              },
              faculty: {
                facebook:
                  getFaculty.contactSocialMedia?.faculty?.facebook || "",
                hasFacebook:
                  getFaculty.contactSocialMedia?.faculty?.hasFacebook || false,
                whatsapp:
                  getFaculty.contactSocialMedia?.faculty?.whatsapp || null,
                hasWhatsapp:
                  getFaculty.contactSocialMedia?.faculty?.hasWhatsapp || false,
                linkedin:
                  getFaculty.contactSocialMedia?.faculty?.linkedin || "",
                hasLinkedin:
                  getFaculty.contactSocialMedia?.faculty?.hasLinkedin || false,
              },
            },
            financialSupport: {
              default: getFaculty.financialSupport?.default || "ies",
              ies: {
                hasFinancialSupport:
                  getFaculty.financialSupport?.ies?.hasFinancialSupport ||
                  false,
                website: getFaculty.financialSupport?.ies?.website || "",
                email: getFaculty.financialSupport?.ies?.email || "",
                phone_code_country:
                  getFaculty.financialSupport?.ies?.phone_code_country ||
                  "Prefijo",
                contactPhone:
                  getFaculty.financialSupport?.ies?.contactPhone || null,
                whatsapp: getFaculty.financialSupport?.ies?.whatsapp || null,
                hasWhatsapp:
                  getFaculty.financialSupport?.ies?.hasWhatsapp || false,
              },
              faculty: {
                hasFinancialSupport:
                  getFaculty.financialSupport?.faculty?.hasFinancialSupport ||
                  false,
                website: getFaculty.financialSupport?.faculty?.website || "",
                email: getFaculty.financialSupport?.faculty?.email || "",
                phone_code_country:
                  getFaculty.financialSupport?.faculty?.phone_code_country ||
                  "Prefijo",
                contactPhone:
                  getFaculty.financialSupport?.faculty?.contactPhone || null,
                whatsapp:
                  getFaculty.financialSupport?.faculty?.whatsapp || null,
                hasWhatsapp:
                  getFaculty.financialSupport?.faculty?.hasWhatsapp || false,
              },
            },
            iesCharacter: getFaculty.iesCharacter,
            uidInstitution: getFaculty.uidInstitution,
            subscriptionLevel: getFaculty.subscriptionLevel,
          };
          setValues(data);
          setLogoUrl(getFaculty.logo ?? "");
          const images = getFaculty.images ? getFaculty.images : [];
          const urls = images.map((img, index) => {
            const id = index;
            return { ...img, id };
          });
          setImagesUrl(urls);
          // setEditableData(getFaculty)
          // setEditableContactInfo({

          // })
        }
      } catch (error) {
        console.error("Error trying to get faculties", error);
      }
    }
    fetchFaculty();
  }, [refFirebase]);

  //-------------------------Contact Info------------------------------
  const fieldsToEdit = "faculty";

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  //--------------Social Medias---------------
  const [whatsappError, setWhatsappError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);

  const isValidEmail = (email: string) => {
    const isEduEmail = email.toLowerCase().includes(".edu");
    return isEduEmail;
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneno = /^\d+$/;
    if (phone.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const isValidUrl = (url: string, validators: string[]) => {
    let isOkUrl = false;
    validators.map((validator) => {
      if (!isOkUrl) isOkUrl = url.toLowerCase().includes(validator);
    });
    return isOkUrl;
  };

  const handleChangeContactInfo = (
    event: React.MouseEvent<HTMLElement>,
    contactInfoDefault: DefaultValue
  ) => {
    if (contactInfoDefault !== null) {
      event.preventDefault();
      setValues((prevInfo) => ({
        ...prevInfo,
        contactInfo: {
          ...prevInfo.contactInfo,
          default: contactInfoDefault,
        },
      }));
    }
  };

  const handleChangeContactSocial = (
    event: React.MouseEvent<HTMLElement>,
    contactSocialDefault: DefaultValue
  ) => {
    if (contactSocialDefault !== null) {
      event.preventDefault();
      setValues((prevInfo) => ({
        ...prevInfo,
        contactSocialMedia: {
          ...prevInfo.contactSocialMedia,
          default: contactSocialDefault,
        },
      }));
    }
  };
  const handleContactInfoChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case "contactEmail":
        setEmailError(!isValidEmail(value));
        break;
      case "contactPhone":
        setPhoneError(!isValidPhone(value));
        break;
      case "whatsapp":
        setWhatsappError(!isValidPhone(value));
        break;
      case "facebook":
        setFacebookError(!isValidUrl(value, ["fb", "facebook.com"]));
        break;
      case "linkedin":
        setLinkedinError(!isValidUrl(value, ["linkedin.com", "lnkd.in"]));
        break;
      default:
        break;
    }
    setValues((prevInfo) => ({
      ...prevInfo,
      contactInfo: {
        ...prevInfo.contactInfo,
        default: prevInfo.contactInfo?.default || "ies",
        faculty: {
          ...prevInfo.contactInfo?.faculty,
          [name]: value,
        },
      },
    }));
  };

  const handleSocialMediaChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    const name: "facebook" | "linkedin" | "whatsapp" = event.target.name; // Type as a union

    switch (name) {
      case "whatsapp":
        setWhatsappError(!isValidPhone(value));
        break;
      case "facebook":
        setFacebookError(!isValidUrl(value, ["fb", "facebook.com"]));
        break;
      case "linkedin":
        setLinkedinError(!isValidUrl(value, ["linkedin.com", "lnkd.in"]));
        break;
      default:
        break;
    }
    setValues((prevInfo) => {
      const faculty = prevInfo.contactSocialMedia
        ?.faculty as ContactSocialMedia;
      faculty[name] = value;

      return {
        ...prevInfo,
        contactSocialMedia: {
          ...prevInfo.contactSocialMedia,
          default: prevInfo.contactInfo?.default || "ies",
          faculty,
        },
      } as facultyProps;
    });
  };

  const handleContactInfoSwitchChange = (switchName: HasSocialMedia) => {
    setValues((prevValues: facultyProps) => {
      const updatedContactSocialMedia = {
        ...prevValues.contactSocialMedia,
        default: prevValues.contactSocialMedia?.default || "ies",
      };
      const faculty = updatedContactSocialMedia.faculty;
      if (faculty) {
        switch (switchName) {
          case "hasFacebook":
            faculty.hasFacebook = !faculty.hasFacebook;
            break;
          case "hasWhatsapp":
            faculty.hasWhatsapp = !faculty.hasWhatsapp;
            break;
          case "hasLinkedin":
            faculty.hasLinkedin = !faculty.hasLinkedin;
            break;
          default:
            break;
        }
      }
      return {
        ...prevValues,
        contactSocialMedia: updatedContactSocialMedia,
      };
    });
  };

  //--------------------------Phone handler--------------------------
  const [country, setCountry] = useState(
    values.contactInfo?.[values.contactInfo?.default]?.phone_code_country ||
      "Prefijo"
  );
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
    // setEditableContactInfo({
    //     ...editableContactInfo,
    //     phone_code_country:event.target.value
    // })
    setValues((prevInfo) => ({
      ...prevInfo,
      contactInfo: {
        ...prevInfo.contactInfo,
        default: prevInfo.contactInfo?.default || "ies",
        faculty: {
          ...prevInfo.contactInfo?.faculty,
          phone_code_country: event.target.value,
        },
      },
    }));
  };

  //-------------------------Financial Support------------------------------
  // const [isEditingFinancialSupport , setIsEditingFinancialSupport] = useState<boolean>(false)
  // const [defaultFinancialValue , setDefaultFinancialValue] = useState(editableData?.financialSupport?.default || "ies")
  // const [editableFinancialSupport, setEditableFinancialSupport] = useState<Partial<FinancialSupport>>({
  //   hasFinancialSupport: editableData?.financialSupport?.[defaultFinancialValue]?.hasFinancialSupport || false,
  //   website: editableData?.financialSupport?.[defaultFinancialValue]?.website || "",
  //   email: editableData?.financialSupport?.[defaultFinancialValue]?.email || "",
  //   phone_code_country: editableData?.financialSupport?.[defaultFinancialValue]?.phone_code_country || "Prefijo",
  //   whatsapp: editableData?.financialSupport?.[defaultFinancialValue]?.whatsapp || null,
  //   hasWhatsapp: editableData?.financialSupport?.[defaultFinancialValue]?.hasWhatsapp || false,
  //   }
  // )

  // const handleFinancialSupportChange = (event: React.ChangeEvent<any>)=>{
  //     const {name, value} = event.target;
  //     switch (name) {
  //       case 'contactEmail':
  //         // setEmailError(!isValidEmail(value));
  //         break;
  //       case 'contactPhone':
  //         // setPhoneError(!isValidPhone(value))
  //         break;
  //       case 'whatsapp':
  //         // setWhatsappError(!isValidPhone(value))
  //         break;
  //       case 'facebook':
  //         // setFacebookError(!isValidUrl(value, ['fb', 'facebook.com']))
  //         break;
  //       case 'linkedin':
  //         // setLinkedinError(!isValidUrl(value, ['linkedin.com', 'lnkd.in']))
  //         break;
  //       default:
  //         break;
  //     }
  //     setEditableFinancialSupport((prevUser) => ({ ...prevUser, [name]: value }));
  // }

  // async function handleFinancialSupportSubmit (){
  //   try {
  //     const selectedFinancialSupport = {
  //       hasFinancialSupport: editableData?.financialSupport?.[defaultFinancialValue]?.hasFinancialSupport || false,
  //       website: editableData?.financialSupport?.[defaultFinancialValue]?.website || "",
  //       email: editableData?.financialSupport?.[defaultFinancialValue]?.email || "",
  //       phone_code_country: editableData?.financialSupport?.[defaultFinancialValue]?.phone_code_country || "Prefijo",
  //       whatsapp: editableData?.financialSupport?.[defaultFinancialValue]?.whatsapp || null,
  //       hasWhatsapp: editableData?.financialSupport?.[defaultFinancialValue]?.hasWhatsapp || false,
  //     };
  //     console.warn("handleContactInfoSubmit");
  //     if (JSON.stringify(editableFinancialSupport) === JSON.stringify(selectedFinancialSupport)) {
  //       setIsEditingFinancialSupport(false);
  //       console.warn("No changes, it doesn't need to update!");
  //       return;
  //     }
  //     // if(iesId){
  //     //   const data={
  //     //     ...editableFinancialSupport,
  //     //   }
  //     //   await careersService.update(selectedCareer.uidInstitution, selectedCareer.uidFaculty, selectedCareer.id, data)
  //     //   updateCareer(data, selectedCareer.id)
  //     //   Swal.fire({
  //     //     title:"Los cambios se han guardado exitosamente",
  //     //     icon:"success",
  //     //     timer:2000
  //     //   })
  //      // sessionStorage.setItem("currentCareers", JSON.stringify)
  //     // }
  //     // setIsEditingFinancialSupport(!isEditingContactInfo)
  //   } catch (error) {
  //       console.error('Error updating contact information', error)
  //   }
  // }

  //--------------------------Switch - Financial Support handler--------------------------
  // const handleFinancialSupportSwitchChange = (switchName: string) => {
  //   setSwitchValues((prevSwitchValues) => {
  //     const updatedSwitchValues = {
  //       ...prevSwitchValues,
  //       [switchName]: !prevSwitchValues[switchName],
  //     };

  //     setEditableFinancialSupport((prevValues) => ({
  //       ...prevValues,
  //       ...updatedSwitchValues,
  //     }));

  //     return updatedSwitchValues;
  //   });
  // };

  // //--------------------------Phone - Financial Support handler--------------------------
  // const [countryFinancialSupport, setCountryFinancialSupport] = useState(editableFinancialSupport.phone_code_country ||'Prefijo');
  // const handleCountryFinancialSupportChange = (event:any) => {
  //   setCountry(event.target.value);
  //   setEditableFinancialSupport({
  //       ...editableFinancialSupport,
  //       phone_code_country:event.target.value
  //   })
  // };
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Editar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <Box sx={{ width: "100%" }}>
          <AppBar
            sx={{
              position: "sticky",
              backgroundColor: "#5394d4",
              padding: "10px 0",
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ flex: 1, fontWeight: "Bold", p: "0 8px" }}
                variant="h5"
              >
                {facultyName}
              </Typography>
              <Button
                onClick={handleClose}
                sx={{
                  color: colors.grey[900],
                  display: "flex",
                  textDecoration: "none",
                  gap: "10px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "16px",
                  width: "fit-content",
                  "&:hover": {
                    backgroundColor: colors.grey[100],
                    cursor: "pointer",
                  },
                }}
              >
                <ArrowBackIcon />
                Volver
              </Button>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.background.primary,
              paddingBottom: "40px",
              minHeight: "400px",
              overflowY: "auto",
            }}
          >
            {!isEditable && (
              <Typography
                variant="h5"
                sx={{ margin: "2rem 0 0", color: "red" }}
              >
                {premiumPlan}
              </Typography>
            )}

            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex" },
                  flexDirection: "column",
                  gridTemplateColumns: "repeat(, minmax(150px, 1fr))",
                  padding: 2,
                  borderRadius: 2,
                  border: `1px solid ${colors.blueAccent[500]}`,
                  width: { xs: "100%", sm: "70%" },
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <Box>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      required
                      type="text"
                      fullWidth
                      size="small"
                      name="name"
                      id="name"
                      label="Nombre de la Facultad"
                      value={values.name}
                      onChange={handlerChange}
                      sx={{ color: colors.grey[100] }}
                      InputLabelProps={{
                        style: {
                          color: colors.grey[100],
                          fontSize: "16px",
                          backgroundColor: colors.background.primary,
                        },
                      }}
                      InputProps={{
                        style: { color: colors.grey[100], fontSize: "16px" },
                      }}
                    />
                  </Grid>
                </Box>

                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel
                    sx={{
                      color: colors.grey[100],
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    htmlFor="logo"
                  >
                    Logo de la facultad
                  </InputLabel>
                  <input
                    type="file"
                    name="logo"
                    id="logo"
                    onChange={handleLogoChange} // hacer un handler de imagen
                    accept="image/png , image/jpeg, image/webp"
                    disabled={!isEditable}
                  />
                </Grid>

                {/* <Box sx={{width:"100%", position:"relative", display:"flex",justifyContent:"center"}}> */}

                {logoUrl && (
                  <Box
                    sx={{
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={logoUrl}
                      alt="Logo facultad"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "15px 0px",
                        minHeight: "100px",
                        width: "100px",
                        maxWidth: "300px",
                        maxHeight: "200px",
                        borderRadius: "20px",
                        objectFit: "contain",
                      }}
                    />

                    <Button
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        padding: 0,
                        border: "none",
                      }}
                      disabled={!isEditable}
                      variant="outlined"
                      onClick={handleDeleteLogo}
                    >
                      Eliminar
                    </Button>
                  </Box>
                )}

                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel
                    sx={{
                      color: colors.grey[100],
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    htmlFor="images"
                  >
                    Añadir imágenes de la facultad
                  </InputLabel>

                  <input
                    type="file"
                    name="images"
                    id="images"
                    onChange={handleImagesChange}
                    accept="image/png , image/jpeg, image/webp"
                    disabled={!isEditable}
                    multiple
                  />
                </Grid>

                {/* <CarouselImages values={values.images}/> */}
                <Carousel
                  showArrows={true}
                  autoPlay={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  width={"100%"}
                  stopOnHover={true}
                >
                  {imagesUrl &&
                    imagesUrl.map((image, index) => (
                      <Box
                        sx={{
                          width: "100%",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        key={index}
                      >
                        <img
                          src={image.url}
                          alt="Imagen facultad"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            margin: "15px 0px",
                            minHeight: "200px",
                            width: "100%",
                            maxWidth: "300px",
                            maxHeight: "200px",
                            borderRadius: "20px",
                            objectFit: "contain",
                          }}
                        />
                        <Button
                          sx={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            padding: 0,
                            border: "none",
                          }}
                          disabled={!isEditable}
                          variant="outlined"
                          onClick={() => handleDeleteImages(image.id)}
                        >
                          Eliminar
                        </Button>
                      </Box>
                    ))}
                </Carousel>

                <Box sx={{ marginBottom: "20px", width: "100%" }}>
                  <Typography
                    sx={{
                      padding: "20px 0",
                      color: colors.grey[100],
                      marginTop: "10px",
                    }}
                    variant="h4"
                  >
                    Información de Contacto
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={values.contactInfo?.default}
                    exclusive
                    onChange={handleChangeContactInfo}
                    aria-label="Platform"
                  >
                    <ToggleButton value="ies">
                      Reusa datos de la Institución
                    </ToggleButton>
                    <ToggleButton value="faculty">
                      Usa datos personalizados
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <Box
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold" }}
                    color={colors.grey[100]}
                  >
                    Web/Enlace/Brochure
                  </Typography>
                  <EditableInput
                    value={
                      values.contactInfo?.default === "faculty"
                        ? values?.webSiteFaculty
                        : values?.webSite
                    }
                    name={
                      values.contactInfo?.default === "faculty"
                        ? "webSiteFaculty"
                        : "webSite"
                    }
                    type="text"
                    isEditing={values.contactInfo?.default === fieldsToEdit}
                    onChange={handlerChange}
                    isDisabled={!isEditable}
                  />
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: { xs: "flex", sm: "grid" },
                    flexDirection: "column",
                    gridTemplateColumns: {
                      sm: "repeat(1,minmax(0,100%))",
                      md: "repeat(2,minmax(0,100%))",
                    },
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Email/Contacto admisiones
                    </Typography>
                    <EditableInput
                      value={
                        values.contactInfo?.[values.contactInfo?.default]
                          ?.contactEmail
                      }
                      name="contactEmail"
                      type="text"
                      error={emailError}
                      helperText={
                        emailError
                          ? "El correo electrónico debe tener la extensión .edu"
                          : ""
                      }
                      isEditing={values.contactInfo?.default === fieldsToEdit}
                      onChange={handleContactInfoChange}
                      isDisabled={!isEditable}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Teléfono de contacto
                    </Typography>
                    {values.contactInfo?.default === fieldsToEdit ? (
                      <FormControl
                        id="phone"
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <Select
                          value={country}
                          id="phone_code_country"
                          label="Prefijo"
                          name="phone_code_country"
                          onChange={handleCountryChange}
                          disabled={!isEditable}
                          sx={{
                            display: "flex",
                            border: "none",
                            width: { xs: "40%", sm: "45%" },
                            color: colors.grey[100],
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: "40ch",
                                right: 0,
                                margin: "0 0 0 24px",
                                color: colors.grey[100],
                              },
                            },
                          }}
                        >
                          {dialCodes.map((country) => (
                            <MenuItem
                              key={country.code}
                              value={country.dial_code}
                            >
                              ({country.dial_code}) {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          id="contactPhone"
                          name="contactPhone"
                          type="text"
                          defaultValue={
                            values.contactInfo?.[values.contactInfo?.default]
                              ?.contactPhone
                          }
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          onChange={handleContactInfoChange}
                          disabled={!isEditable}
                          error={phoneError}
                          helperText={
                            phoneError ? "Sólo se permiten números" : ""
                          }
                          InputLabelProps={{
                            style: {
                              color: colors.grey[100],
                              fontSize: "16px",
                            },
                          }}
                          InputProps={{
                            style: {
                              color: colors.grey[100],
                              fontSize: "16px",
                              width: "100%",
                            },
                          }}
                        />
                      </FormControl>
                    ) : (
                      <Typography variant="h5" color={colors.grey[200]}>
                        {`${
                          values.contactInfo?.[values.contactInfo?.default]
                            ?.phone_code_country
                            ? `(${
                                values.contactInfo?.[
                                  values.contactInfo?.default
                                ]?.phone_code_country
                              })`
                            : ""
                        }  ${
                          values.contactInfo?.[values.contactInfo?.default]
                            ?.contactPhone ?? ""
                        }`}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Typography
                  sx={{
                    padding: "20px 0",
                    color: colors.grey[100],
                    marginTop: "10px",
                  }}
                  variant="h4"
                >
                  Redes Sociales
                </Typography>

                <Box sx={{ marginBottom: "20px", width: "100%" }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={values.contactSocialMedia?.default}
                    exclusive
                    onChange={handleChangeContactSocial}
                    aria-label="Platform"
                  >
                    <ToggleButton value="ies">
                      Reusa datos de la Institución
                    </ToggleButton>
                    <ToggleButton value="faculty">
                      Usa datos personalizados
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: { xs: "flex", sm: "grid" },
                    flexDirection: "column",
                    gridTemplateColumns: {
                      sm: "repeat(1,minmax(0,100%))",
                      md: "repeat(2,minmax(0,100%))",
                    },
                    gap: "20px",
                  }}
                >
                  {/*-----Whatsapp-----*/}
                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Whatsapp
                    </Typography>
                    <EditableInput
                      value={
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.whatsapp
                      }
                      name="whatsapp"
                      type="text"
                      error={
                        whatsappError &&
                        (values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasWhatsapp ??
                          false)
                      }
                      helperText={
                        whatsappError &&
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasWhatsapp
                          ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                          : ""
                      }
                      isEditing={
                        values.contactSocialMedia?.default === fieldsToEdit
                      }
                      isDisabled={
                        !isEditable ||
                        !values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasWhatsapp
                      }
                      onChange={handleSocialMediaChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Whatsapp Activo
                    </Typography>
                    {values.contactSocialMedia?.default === "faculty" ? (
                      <SwitchButton
                        checked={
                          values.contactSocialMedia?.[
                            values.contactSocialMedia?.default
                          ]?.hasWhatsapp ?? false
                        }
                        setChecked={() =>
                          handleContactInfoSwitchChange("hasWhatsapp")
                        }
                        isDisabled={!isEditable}
                      />
                    ) : (
                      <Typography variant="h5" color={colors.grey[500]}>
                        {values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasWhatsapp
                          ? "Si"
                          : "No"}
                      </Typography>
                    )}
                  </Box>

                  {/*-----Linkedin-----*/}
                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Linkedin
                    </Typography>
                    <EditableInput
                      value={
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.linkedin
                      }
                      name="linkedin"
                      type="text"
                      error={
                        linkedinError &&
                        (values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasLinkedin ??
                          false)
                      }
                      helperText={
                        linkedinError &&
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasLinkedin
                          ? "La URL debe tener dominio linkedin.com o lnkd.in"
                          : ""
                      }
                      isEditing={
                        values.contactSocialMedia?.default === fieldsToEdit
                      }
                      isDisabled={
                        !isEditable ||
                        !values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasLinkedin
                      }
                      onChange={handleSocialMediaChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Linkedin Activo
                    </Typography>
                    {values.contactSocialMedia?.default === "faculty" ? (
                      <SwitchButton
                        checked={
                          values.contactSocialMedia?.[
                            values.contactSocialMedia?.default
                          ]?.hasLinkedin ?? false
                        }
                        setChecked={() =>
                          handleContactInfoSwitchChange("hasLinkedin")
                        }
                        isDisabled={!isEditable}
                      />
                    ) : (
                      <Typography variant="h5" color={colors.grey[500]}>
                        {values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasLinkedin
                          ? "Si"
                          : "No"}
                      </Typography>
                    )}
                  </Box>

                  {/*-----Facebook-----*/}
                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Facebook
                    </Typography>
                    <EditableInput
                      value={
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.facebook
                      }
                      name="facebook"
                      type="text"
                      error={
                        facebookError &&
                        (values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasFacebook ??
                          false)
                      }
                      helperText={
                        facebookError &&
                        values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasFacebook
                          ? "La URL debe tener dominio facebook o fb"
                          : ""
                      }
                      isEditing={
                        values.contactSocialMedia?.default === fieldsToEdit
                      }
                      isDisabled={
                        !isEditable ||
                        !values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasFacebook
                      }
                      onChange={handleSocialMediaChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginBottom: "20px",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color={colors.grey[100]}
                    >
                      Facebook Activo
                    </Typography>
                    {values.contactSocialMedia?.default === "faculty" ? (
                      <SwitchButton
                        checked={
                          values.contactSocialMedia?.[
                            values.contactSocialMedia?.default
                          ]?.hasFacebook ?? false
                        }
                        setChecked={() =>
                          handleContactInfoSwitchChange("hasFacebook")
                        }
                        isDisabled={!isEditable}
                      />
                    ) : (
                      <Typography variant="h5" color={colors.grey[500]}>
                        {values.contactSocialMedia?.[
                          values.contactSocialMedia?.default
                        ]?.hasFacebook
                          ? "Si"
                          : "No"}
                      </Typography>
                    )}
                  </Box>

                  <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{ gridColumn: { xs: "1 / -1", sm: "span 2" } }}
                  >
                    <InputLabel
                      sx={{
                        color: colors.grey[100],
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      htmlFor="description"
                    >
                      Descripción de la Facultad
                    </InputLabel>
                    <TextareaAutosize
                      name="description"
                      value={values.description}
                      onChange={handlerChange}
                      placeholder="Por favor, escriba una breve descripción de la facultad..."
                      id="description"
                      style={{
                        borderRadius: "5px",
                        borderColor: "primary.main",
                        padding: "5px 10px",
                        width: "100%",
                        minWidth: "250px",
                        fontSize: "medium",
                        fontFamily: "inherit",
                        minHeight: "200px",
                        whiteSpace: "pre-wrap",
                        resize: "vertical",
                      }}
                    />
                  </Grid>

                  <Grid
                    sx={{
                      gridColumn: { xs: "1 / -1" },
                      display: "flex",
                      justifyContent: { xs: "center" },
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ height: "fit-content" }}
                      type="submit"
                    >
                      Guardar cambios
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
