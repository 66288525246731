import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '40px',
  display: 'flex',
  flexWrap: 'wrap',
};

export default function ModalStars({params}:any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <StarIcon className={`${params > 0 ? 'premium': 'No-premium'}` }/> 
        <StarIcon className={`${params > 1 ? 'premium': 'No-premium'}` }/>
        {/* <StarIcon className={`${params >= 2 ? 'premium': 'No-premium'}` }/> */}
        
      </Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"

      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2" sx={{width:'100%', marginBottom:'15px'}}>
            Nivel según estrellas
          </Typography>
          <StarIcon/>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 , width:'100%', marginBottom:'15px'}}>
          No tiene beneficios actualmente 
          </Typography>
          <br/>
          <StarIcon className='premium'/>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 , width:'100%', marginBottom:'15px'}}>
          Nivel Fremium 
          </Typography>
          <br/>
          <StarIcon className='premium'/>
          <StarIcon className='premium'/>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 , width:'100%', marginBottom:'15px'}}>
          Nivel Básico 
          </Typography>
          <br/>
          <StarIcon className='premium'/>
          <StarIcon className='premium'/>
          <StarIcon className='premium'/>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 , width:'100%', marginBottom:'15px'}}>
          Nivel Avanzado 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}