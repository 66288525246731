import imagesService from "@/api/images.service";
import { ImageProps, InstituteProps } from "@/types";
import { convertBase64ToFile, tokens } from "@/utilities";
import { Box, Button, InputLabel, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import defaultLogo from "@/assets/imagen.jpg";
import Swal from "sweetalert2";

interface Props {
  institute: InstituteProps | null;
  isEditable: boolean;
  premiumPlan: string;
}

export const CarouselImagesInstitute = ({
  institute,
  isEditable,
  premiumPlan,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  isEditable=true
  const [imagesUrl, setImagesUrl] = useState<ImageProps[]>(
    institute?.images || []
  );
  const [isEditingImages, setIsEditingImages] = useState<boolean>(false);

  const [defaultImage, setDefaultImage] = useState<ImageProps>(
    institute?.defaultIesImage || { url: "", alt: "Imagen institución" }
  );

  const iesId = institute?.id;

  const handleImagesChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (
      inputElement.files &&
      inputElement.files.length + imagesUrl.length > 5
    ) {
      Swal.fire({
        title: "Selecciona un máximo de 5 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files) {
      const selectedFiles = Array.from(inputElement.files);
      const urls = selectedFiles
        .map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
        .filter((promise) => promise !== null); // Filter out null promises

      // Handle promises (outside the map)
      Promise.all(urls)
        .then((imageUrls) => {
          const selectedImages = imageUrls.map((value: unknown) => {
            const url = value as string; // Cast value to string
            return {
              url,
              // id: imagesUrl.length + index + 1,
            };
          });
          setImagesUrl([...imagesUrl, ...selectedImages]);
        })
        .catch((error) => console.error("Error reading file", error));
    }
  };

  const handleDeleteImages = (id: number) => {
    try {
      const updatedImages = imagesUrl.filter((_, i) => i !== id);
      setImagesUrl(updatedImages);
    } catch (error) {
      console.error(error);
    }
  };


  const handleSubmitImages = async (): Promise<void> => {
    try {
      if (iesId) {
        const prevImages = imagesUrl.filter((image) =>
          image.url.startsWith("https://")
        );
        const selectedImagesUrl = imagesUrl.filter(
          (image) => !image.url.startsWith("https://")
        );
        const imagesFormatted =
        convertBase64ToFile(selectedImagesUrl) as File[]
        await imagesService.uploadIesImages(iesId, imagesFormatted, prevImages, defaultImage);

        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingImages(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Hubo un error subiendo las imágenes, intente nuevamente",
        icon: "error",
        timer: 2000,
      });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 0 },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
          }}
          onClick={
            isEditingImages
              ? () => handleSubmitImages()
              : () => setIsEditingImages(true)
          }
        >
          {isEditingImages ? "Guardar" : "Editar"}
        </Button>
        {!isEditable && (
          <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
            {premiumPlan}
          </Typography>
        )}
      </Box>

      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
          maxWidth: "75%",
        }}
        variant="h3"
      >
        Imágenes de la institución
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {isEditingImages && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "25px",
            }}
          >
            <InputLabel
              sx={{
                color: colors.grey[100],
                fontWeight: "bold",
                fontSize: "16px",
                width: "fit-content",
              }}
              htmlFor="images"
            >
              Añadir imágenes de la institución (máximo 5)
            </InputLabel>

            <input
              type="file"
              name="images"
              id="images"
              onChange={handleImagesChange}
              accept="image/png , image/jpeg, image/webp"
              disabled={!isEditable}
              multiple
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Carousel
            showArrows={true}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            width={"100%"}
            stopOnHover={true}
          >
            {imagesUrl &&
              imagesUrl.map((image, index) => (
                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "30px 0",
                  }}
                  key={index}
                >
                  <Box onClick={() => setDefaultImage(image)}>
                  <img
                    src={image.url}
                    alt="Imagen institución"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: "15px 0px",
                      minHeight: "200px",
                      width: "100%",
                      maxWidth: "500px",
                      maxHeight: "400px",
                      borderRadius: "2rem",
                      objectFit: "contain",
                    }}
                  

                  />

                  </Box>
                  {isEditingImages && (
                    <Button
                      sx={{
                        position: "relative",
                        justifyContent: "left",
                      }}
                      variant="outlined"
                      onClick={() => handleDeleteImages(index)}
                    >
                      Eliminar
                    </Button>
                  )}
                </Box>
              ))}
          </Carousel>
        </Box>

        {isEditingImages && (
          <Box sx={{ width: { xs: "100%" } }}>
            <Typography variant="h4" sx={{ color: colors.grey[100] }}>
              Imagen predeterminada
            </Typography>
            <img
              src={
                defaultImage && defaultImage.url
                  ? defaultImage.url
                  : defaultLogo
              }
              alt="Logo Guía Universitaria"
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "15px 0px",
                minHeight: "200px",
                width: "250px",
                maxWidth: "300px",
                maxHeight: "200px",
                borderRadius: "20px",
                marginBottom: "40px",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
