import * as React from 'react';
import {Dialog, AppBar, Toolbar ,IconButton ,Typography,Slide, Grid, Box, Button, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { DetailCareers } from '@/components';
import { facultyProps } from '@/types';
import facultyService from '@/api/faculty.service';
import './Modals.scss'
import { tokens } from '@/utilities';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  code:string | undefined
  // selectedFaculty: Faculty | null
  // setSelectedFaculty:React.Dispatch<React.SetStateAction<Faculty | null>>
}
//Necesito recibir por props la data para saber el id de la institución y poder asignarle a la colección de firebase las facultades y carreras correspondiente. 
export function ModalCareers({code}:Props) {

  const theme = useTheme()
  const colors = tokens(theme.palette.mode);
  
  const [open, setOpen] = React.useState(false);
  const [faculties, setFaculties] = React.useState<facultyProps[]>([]);
  const [selectedFaculty, setSelectedFaculty] = React.useState<facultyProps | null>(null);
  // const [selectedFaculty, setSelectedFaculty] = React.useState<facultyProps[]>([]);

  const handleClickOpen = (faculty:facultyProps) => {
    setSelectedFaculty(faculty)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(false);
  }, [code]);

  // React.useEffect(() => {
  //   async function facultyDataAPI() {
  //     try {
  //       if(user){
  //         const facultyData = await facultyService.getAll(user.userData.institute);
  //         setFaculties(facultyData);
  //       }
  //     } catch (error) {
  //       console.error('Error to catch data faculty:', error);
  //     }
  //   }
  //   console.log('modalcareeer', faculties)

  //   facultyDataAPI();
  // }, [user]);
  React.useEffect(() => {
    let isMounted = true; // Flag para rastrear si el componente está montado
  
    async function facultyDataAPI() {
      try {
        if (code && isMounted) {
          const facultyData = await facultyService.getAll(code);
          if (isMounted) {
            setFaculties(facultyData);
          }
        }
      } catch (error) {
        console.error('Error to catch data faculty:', error);
      }
    }
  
    facultyDataAPI();
  
    return () => {
      isMounted = false; 
    };
  }, [code]);

  return (
    <>
      <Box 
        sx={{display:"flex",
            width:"100%",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",

      }}>
        {faculties.length > 0 
              ? faculties.map((faculty, index)=>(
                        
                        <Button 
                          sx={{
                            width:"100%",
                            padding:"5px 15px",
                            // backgroundColor:colors.greenAccent[500],
                            marginBottom:"15px",
                            fontWeight:"bolder",
                            color: colors.grey[100],
                            "&:hover":{backgroundColor:colors.greenAccent[500], color:colors.primary[400], scale:"1.2"}
                          }}
                      key={index} onClick={()=>handleClickOpen(faculty)}>{faculty.name}</Button>
            
                    ))
              : ''    
              }
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky', backgroundColor:'#3F51B5', opacity:'0.7' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{paddingLeft:'5%'}}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Facultad de {selectedFaculty?.name}
            </Typography>
            <Grid sx={{ ml: 2, paddingRight:'3%', margin:'2px'}}  component="div">
              {/* <img src={selectedInstituteData?.logo} alt="logo" style={{width:'70px', height:'70px', borderRadius:'50%'}}/> */}
            </Grid>
            {/* <Button autoFocus sx={{paddingRight:'4%'}} color="inherit" onClick={handleClose}>
              Guardar
            </Button> */}
          </Toolbar>
        </AppBar>
          <div style={{display:'flex', justifyContent:'center'}}>
            {selectedFaculty &&(<DetailCareers selectedFaculty={selectedFaculty}/>)}
          </div>
      </Dialog>
    </>
  );
}