import {  StyleSheet, View, Text} from '@react-pdf/renderer';
import { Fragment } from 'react';



const borderColor = '#90e5fc'
const styles = StyleSheet.create({
  page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 30,
      paddingLeft:60,
      paddingRight:60,
      lineHeight: 1.5,
      flexDirection: 'column',
  }, 
  logo: {
      width: 74,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto'
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
},
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  country: {
    width: '40%',
    borderRightColor: borderColor,
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  city: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
  },
  qty: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%'
  },

  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
},
  countryRow: {
    width: '40%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
},
qtyRow: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
},
cityRow: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
},
amountRow: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
},

rowFooter: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  height: 24,
  fontSize: 12,
  fontStyle: 'bold',
},
descriptionFooter: {
  width: '80%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingRight: 8,
},
total: {
  width: '20%',
  textAlign: 'right',
  paddingRight: 8,
},

});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
      <Text style={styles.country}>País</Text>
      <Text style={styles.city}>Ciudad</Text>
      <Text style={styles.qty}>Cantidad</Text>
      {/* <Text style={styles.rate}>@</Text> */}
      {/* <Text style={styles.amount}>Amount</Text> */}
  </View>
);

const InvoiceTableRow = ({items}:{items:any}) => {
  // console.log(items)
   // console.log(items)
  //  const sortedCities = items.cities.sort((a:any, b:any) => b.city_user_count - a.city_user_count);

  //  // Get the first 4 cities
  //  const firstFourCities = sortedCities.slice(0, 4);
  //  console.log("FIRTS FOUR", firstFourCities)
  const rows = items.cities.map( (item:any, index:number) => 
      <View style={styles.row} key={index}>
          <Text style={styles.countryRow}>{items.country}</Text>
          <Text style={styles.cityRow}>{item.city}</Text>
          <Text style={styles.qtyRow}>{item.city_user_count}</Text>
          {/* <Text style={styles.amountRow}>{(item.qty * item.rate).toFixed(2)}</Text> */}
      </View>
  )
  return (<Fragment>{rows}</Fragment> )
};

const InvoiceTableFooter = ({total}:{total:number}) => {
  // const total = items.map(item => item.qty * item.rate)
  //     .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
  return(    
      <View style={styles.rowFooter}>
          <Text style={styles.descriptionFooter}>TOTAL</Text>
          <Text style={styles.total}>{total}</Text>
      </View>
  )
};

const InvoiceItemsTable = ({data}:{data:any}) => (
  <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={data} />
      {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
      <InvoiceTableFooter total={data.total_user_count} />
  </View>
);


export const TableUsersByRegion = ({data}:{data:any}) => {
    const filteredData = data.filter((item:any) => item.country === 'Colombia');
    const colombiaData = filteredData[0]
  console.log("InTable", colombiaData)
  console.log(data)
  return(
    //     <Page size="A4" style={styles.page}>
    <View>
        {data.map((item: any, index:number) => (
          <InvoiceItemsTable key={index} data={item} />
        ))}    
        {/* {
          filteredData && <InvoiceItemsTable data={colombiaData} />
        } */}
    </View>
    //     </Page> 

)}


