import careersService from "@/api/careers.service";
import { CareerProps } from "@/types";
import { tokens } from "@/utilities";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import { EditableTextArea } from "@/utilities/Components";

interface Props {
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
}

export const CandidateProfile = ({ selectedCareer, updateCareer }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingCandidateProfile, setIsEditingCandidateProfile] =
    useState<boolean>(false);
  const [editableCandidateProfile, setEditableCandidateProfile] = useState<
    Partial<CareerProps>
  >({
    candidateProfile: selectedCareer.candidateProfile || "",
  });

  const handleCandidateProfiledChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setEditableCandidateProfile((prevUser) => ({ ...prevUser, [name]: value }));
  };

  async function handleCandidateProfileSubmit() {
    try {
      const selectedCandidateProfile = {
        candidateProfile: selectedCareer.candidateProfile || "",
      };
      if (
        JSON.stringify(editableCandidateProfile) ===
        JSON.stringify(selectedCandidateProfile)
      ) {
        setIsEditingCandidateProfile(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (editableCandidateProfile == selectedCareer.candidateProfile) {
        setIsEditingCandidateProfile(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (selectedCareer.id && editableCandidateProfile) {
        await careersService.update(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          editableCandidateProfile
        );
        updateCareer(editableCandidateProfile, selectedCareer.id);

        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingCandidateProfile(!isEditingCandidateProfile);
    } catch (error) {
      console.error("Error updating ProfileGraduated", error);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
        paddingBottom: "40px",
      }}
    >
      {isEditingCandidateProfile ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={handleCandidateProfileSubmit}
          >
            Guardar
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() =>
              setIsEditingCandidateProfile(!isEditingCandidateProfile)
            }
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          textAlign: "left",
          color: colors.grey[100],
          marginTop: "10px",
          width: { xs: "60%" },
        }}
        variant="h3"
      >
        Perfil de Candidato
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />
      <EditableTextArea
        id="candidateProfile"
        name="candidateProfile"
        value={editableCandidateProfile.candidateProfile ?? ""}
        onChange={handleCandidateProfiledChange}
        placeholder="Por favor, escriba una versión detallada del perfil del candidato"
        isEditing={isEditingCandidateProfile}
      />
    </Box>
  );
};
