import { AppBar, Box, Dialog, Grid, Slide, Toolbar, Typography, useTheme } from "@mui/material"
import { CardDetail, DetailCareers } from ".."
import { tokens, useAuth } from "@/utilities"
import { useEffect, useState } from "react"
import { facultyProps } from "@/types"
import facultyService from "@/api/faculty.service"
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions"
import React from "react"
import bg from '@/assets/imagen.jpg'
import { useNavigate } from "react-router-dom"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export const CardsFaculties = () => {
    const {user} = useAuth()
    const [faculties, setFaculties] = useState<facultyProps[] | null>([])
    // console.log(faculties)
    const [selectedFaculty, setSelectedFaculty] = useState<facultyProps | null>(null);
    const [isOpen, setIsOpen] = useState(false)
   
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()

    const handleFaculty = (faculty:facultyProps)=>{
        setSelectedFaculty(faculty)
        // setIsOpen(true)
        navigate(`/faculty/${user?.userData.institute}/${faculty.id}`,{state: {facultyName:faculty.name}})
    }

    const handleClose = ()=>{
        setIsOpen(false)
    }

    useEffect(()=>{
        async function fetchFaculties() {
            if(user && user.userData.institute){
                try {
                    const getFaculties = await facultyService.getAll(user.userData.institute)
                    setFaculties(getFaculties)
                } catch (error) {
                    console.error("Error trying to get faculties", error)
                }
            }            
        }
        fetchFaculties()
    },[user])

    return (
    <Box sx={{
        display:"flex",
        flexWrap:"wrap",
        justifyContent:"space-around",
        marginTop:"20px"
    }}> 
        {faculties && faculties.length>0?(
            faculties.map((faculty, index)=>(
                <Box 
                key={index}
                sx={{
                    width:{xs:"300px", sm:"350px"},
                    height:"300px",
                    display:"flex",
                    boxShadow: "0 0 0 1px rgb(0,0,0,0.08)",
                    padding:"20px",
                    backgroundColor:colors.primary[400],
                    borderRadius:"0.8rem",
                    marginBottom:"20px"
                }}>
                    <CardDetail 
                    imgSrc={faculty.images ? faculty.images: bg}
                    title={faculty.name}
                    onClick={()=>{
                        handleFaculty(faculty)
                      }}
                    className=""
                    />
                </Box>
            ))
        ): <p>No se encontraron facultades</p>

        }
        {selectedFaculty && (
        
        <>
      
        <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        
        >
          <AppBar sx={{ position: 'sticky', backgroundColor:colors.primary[400] }}>
            <Toolbar>
              {/* <IconButton
                // edge="start"
                // color="inherit"
                // aria-label="close"
                sx={{paddingLeft:'5%',
                width: "5px",
                border:"2px solid red"
            }} */}

              
                <CloseIcon
                onClick={handleClose}
                sx={{
                borderRadius:"50%",
                color:colors.grey[100],
                ":hover":{background:`${colors.grey[400]}`, scale:"1.2", cursor:"pointer"}
                }}/>
              {/* </IconButton> */}
              <Typography sx={{ ml: 2, flex: 1, color:colors.grey[100]}} variant="h4" >
                Facultad de {selectedFaculty.name}
              </Typography>
              <Grid sx={{ ml: 2, paddingRight:'3%', margin:'2px'}}  component="div">
                {/* <img src={selectedInstituteData?.logo} alt="logo" style={{width:'70px', height:'70px', borderRadius:'50%'}}/> */}
              </Grid>
              {/* <Button autoFocus sx={{paddingRight:'4%'}} color="inherit" onClick={handleClose}>
                Guardar
              </Button> */}
            </Toolbar>
          </AppBar>
            <div style={{display:'flex', justifyContent:'center'}}>
              <DetailCareers selectedFaculty={selectedFaculty}/>
            </div>
        </Dialog>
      </>
        )}
    </Box>
  )
}
