import instituteService from "@/api/institute.service";
import { FinancialSupport, InstituteProps } from "@/types";
import { dialCodes, isValidEmail, isValidValue, SwitchButton, tokens } from "@/utilities";
import { EditableInput } from "@/utilities/Components";
import { Box, Button, FormControl, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";

interface Props {
  institute: InstituteProps | null;
  isEditable: boolean;
  premiumPlan: string;
  }
export const FinancialSupportInstitute = ({
  isEditable,
  premiumPlan,
  institute,  
}: Props) => {

    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const iesId = institute?.id;
    
  const [emailErrorFinancialSupp, setEmailErrorFinancialSupp] = useState(false);

  const initialSwitchValues: Record<string, boolean> = {
    internationalAccreditation: Boolean(institute?.internationalAccreditation),
    // active: !!institute.active
  };

  const [switchValues, setSwitchValues] = useState(initialSwitchValues);
  console.log(switchValues)

  //-------------------------Financial Support------------------------------
  const [isEditingFinancialSupport, setIsEditingFinancialSupport] =
    useState<boolean>(false);
  const [editableFinancialSupport, setEditableFinancialSupport] = useState<
    Partial<FinancialSupport>
  >({
    hasFinancialSupport:
      institute?.financialSupportIES?.hasFinancialSupport || false,
    website: institute?.financialSupportIES?.website || "",
    email: institute?.financialSupportIES?.email || "",
    contactPhone: institute?.financialSupportIES?.contactPhone || null,
    phone_code_country:
      institute?.financialSupportIES?.phone_code_country || "Prefijo",
    whatsapp: institute?.financialSupportIES?.whatsapp || null,
    hasWhatsapp: institute?.financialSupportIES?.hasWhatsapp || false,
  });

  const handleFinancialSupportChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmailErrorFinancialSupp(!isValidEmail(value));
        break;
      default:
        break;
    }
    setEditableFinancialSupport((prevUser) => ({ ...prevUser, [name]: value }));
  };

  async function handleFinancialSupportSubmit() {
    try {
      const selectedFinancialSupport = {
        hasFinancialSupport:
          institute?.financialSupportIES?.hasFinancialSupport || false,
        website: institute?.financialSupportIES?.website || "",
        email: institute?.financialSupportIES?.email || "",
        phone_code_country:
          institute?.financialSupportIES?.phone_code_country || "Prefijo",
        contactPhone: institute?.financialSupportIES?.contactPhone || null,
        whatsapp: institute?.financialSupportIES?.whatsapp || null,
        hasWhatsapp: institute?.financialSupportIES?.hasWhatsapp || false,
      };
      console.warn("handleContactInfoSubmit");
      if (
        JSON.stringify(editableFinancialSupport) ===
        JSON.stringify(selectedFinancialSupport)
      ) {
        setIsEditingFinancialSupport(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (iesId) {
        const data = {
          financialSupportIES: {
            hasFinancialSupport:
              editableFinancialSupport.hasFinancialSupport || false,
            website: editableFinancialSupport.website || "",
            email: editableFinancialSupport.email || "",
            contactPhone: editableFinancialSupport.contactPhone || null,
            phone_code_country:
              editableFinancialSupport.phone_code_country || "Prefijo",
            whatsapp: editableFinancialSupport.whatsapp || null,
            hasWhatsapp: editableFinancialSupport.hasWhatsapp || false,
          },
        };
        console.warn("Updating in Database ...");

     
        await instituteService.update(iesId, data);
        console.warn("Updated in Database!");
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingFinancialSupport(false);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  }

  //--------------------------Switch - Financial Support handler--------------------------
  const handleFinancialSupportSwitchChange = (switchName: string) => {
    setSwitchValues((prevSwitchValues) => {
      const updatedSwitchValues = {
        ...prevSwitchValues,
        [switchName]: !prevSwitchValues[switchName],
      };

      setEditableFinancialSupport((prevValues) => ({
        ...prevValues,
        ...updatedSwitchValues,
      }));

      return updatedSwitchValues;
    });
  };

  //--------------------------Phone - Financial Support handler--------------------------
  const [countryFinancialSupport, setCountryFinancialSupport] = useState(
    editableFinancialSupport.phone_code_country || "Prefijo"
  );
  const handleCountryFinancialSupportChange = (event: any) => {
    setCountryFinancialSupport(event.target.value);
    setEditableFinancialSupport({
      ...editableFinancialSupport,
      phone_code_country: event.target.value,
    });
  };
  return (
    <Box
    sx={{
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      position: "relative",
    }}
  >
    {isEditingFinancialSupport ? (
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 0 },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
            // disabled={emailError || phoneError}
          }}
          onClick={handleFinancialSupportSubmit}
        >
          Guardar
        </Button>
        {!isEditable && (
          <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
            {premiumPlan}
          </Typography>
        )}
      </Box>
    ) : (
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 0 },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
          }}
          onClick={() =>
            setIsEditingFinancialSupport(!isEditingFinancialSupport)
          }
        >
          Editar
        </Button>
      </Box>
    )}
    <Typography
      sx={{
        padding: "20px 0",
        color: colors.grey[100],
        marginTop: "10px",
        maxWidth: "70%",
      }}
      variant="h3"
    >
      Apoyo Financiero
    </Typography>

    <hr style={{ width: "100%", marginBottom: "25px" }} />

    <Box
      sx={{
        marginBottom: "20px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        wordWrap:"break-word",
        wordBreak:"break-all",
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold" }}
        color={colors.grey[100]}
      >
        Tiene Apoyo Financiero
      </Typography>
      {isEditingFinancialSupport ? (
        <SwitchButton
          checked={editableFinancialSupport.hasFinancialSupport ?? false}
          setChecked={() =>
            handleFinancialSupportSwitchChange("hasFinancialSupport")
          }
          isDisabled={!isEditable}
        />
      ) : (
        <Typography variant="h5" color={colors.grey[500]}>
          {editableFinancialSupport.hasFinancialSupport ? "Si" : "No"}
        </Typography>
      )}
    </Box>

    <Box
      sx={{
        marginBottom: "20px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        wordWrap:"break-word",
        wordBreak:"break-all",
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold" }}
        color={colors.grey[100]}
      >
        Web/Enlace/Brochure
      </Typography>
      <EditableInput
        value={editableFinancialSupport.website ?? ""}
        name="website"
        type="text"
        isEditing={isEditingFinancialSupport}
        onChange={handleFinancialSupportChange}
        isDisabled={
          !isEditable ||
          !(editableFinancialSupport.hasFinancialSupport ?? false)
        }
      />
    </Box>

    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: { xs: "flex", sm: "grid" },
        flexDirection: "column",
        gridTemplateColumns: {
          sm: "repeat(1,minmax(0,100%))",
          md: "repeat(2,minmax(0,100%))",
        },
        gap: "20px",
        wordWrap:"break-word",
        wordBreak:"break-all",
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          wordWrap:"break-word",
          wordBreak:"break-all",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Email/Contacto
        </Typography>
        <EditableInput
          value={editableFinancialSupport.email ?? ""}
          name="email"
          type="text"
          error={emailErrorFinancialSupp}
          helperText={
            emailErrorFinancialSupp
              ? "El correo electrónico debe tener la extensión .edu"
              : ""
          }
          isEditing={isEditingFinancialSupport}
          onChange={handleFinancialSupportChange}
          isDisabled={
            !isEditable ||
            !(editableFinancialSupport.hasFinancialSupport ?? false)
          }
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Teléfono de contacto
        </Typography>
        {isEditingFinancialSupport ? (
          <FormControl
            id="phone"
            variant="outlined"
            fullWidth
            size="small"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              value={countryFinancialSupport}
              id="phone_code_country"
              label="Prefijo"
              name="phone_code_country"
              onChange={handleCountryFinancialSupportChange}
              disabled={
                !isEditable ||
                !(editableFinancialSupport.hasFinancialSupport ?? false)
              }
              sx={{
                display: "flex",
                border: "none",
                width: { xs: "40%", sm: "45%" },
                color: colors.grey[100],
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "40ch",
                    right: 0,
                    margin: "0 0 0 24px",
                    color: colors.grey[100],
                  },
                },
              }}
            >
              {dialCodes.map((country) => (
                <MenuItem key={country.code} value={country.dial_code}>
                  ({country.dial_code}) {country.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="contactPhone"
              name="contactPhone"
              type="text"
              value={editableFinancialSupport.contactPhone ?? ""}
              fullWidth
              autoComplete="off"
              variant="outlined"
              onChange={handleFinancialSupportChange}
              disabled={
                !isEditable ||
                !(editableFinancialSupport.hasFinancialSupport ?? false)
              }
              error={
                !isValidValue(
                  "phone",
                  editableFinancialSupport.contactPhone
                )
              }
              helperText={
                !isValidValue(
                  "phone",
                  editableFinancialSupport.contactPhone
                )
                  ? "Sólo se permiten números"
                  : ""
              }
              InputLabelProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
              InputProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  width: "100%",
                },
              }}
            />
          </FormControl>
        ) : (
          <Typography variant="h5" color={colors.grey[200]}>
            {`${editableFinancialSupport.phone_code_country ?? ""} ${
              editableFinancialSupport.contactPhone ?? ""
            }`}
          </Typography>
        )}
      </Box>
    </Box>

    <Typography
      sx={{
        padding: "20px 0",
        color: colors.grey[100],
        marginTop: "10px",
      }}
      variant="h4"
    >
      Redes Sociales
    </Typography>
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: { xs: "flex", sm: "grid" },
        flexDirection: "column",
        gridTemplateColumns: {
          sm: "repeat(1,minmax(0,100%))",
          md: "repeat(2,minmax(0,100%))",
        },
        gap: "20px",
      }}
    >
      {/*-----Whatsapp-----*/}
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Whatsapp
        </Typography>
        <EditableInput
          value={editableFinancialSupport.whatsapp ?? ""}
          name="whatsapp"
          type="text"
          error={
            !isValidValue("phone", editableFinancialSupport.whatsapp) &&
            (editableFinancialSupport.hasWhatsapp ?? false)
          }
          helperText={
            !isValidValue("phone", editableFinancialSupport.whatsapp) &&
            editableFinancialSupport.hasWhatsapp
              ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
              : ""
          }
          isEditing={isEditingFinancialSupport}
          isDisabled={
            !isEditable ||
            !editableFinancialSupport.hasWhatsapp ||
            !editableFinancialSupport.hasFinancialSupport
          }
          onChange={handleFinancialSupportChange}
        />
      </Box>

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Whatsapp Activo
        </Typography>
        {isEditingFinancialSupport ? (
          <SwitchButton
            checked={editableFinancialSupport.hasWhatsapp ?? false}
            setChecked={() =>
              handleFinancialSupportSwitchChange("hasWhatsapp")
            }
            isDisabled={
              !isEditable || !editableFinancialSupport.hasFinancialSupport
            }
          />
        ) : (
          <Typography variant="h5" color={colors.grey[500]}>
            {editableFinancialSupport.hasWhatsapp ? "Si" : "No"}
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
  )
}
