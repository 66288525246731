import { facultyProps } from "@/types/faculty.type";
import { firestore } from "../config/firebase.config";
import { fromFirestore, toFirestore } from "./firestoreAdapter";
import { collection, doc, addDoc, getDocs, updateDoc, deleteDoc, query, where, serverTimestamp, getDoc } from "firebase/firestore";

const facultyCollectionName: string = "institutes/{0}/faculties";

class FacultyService {

    async getAll(instituteId: string, active: boolean = true): Promise<facultyProps[]> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const q = query(collectionRef, where("active", "==", active));
        const querySnapshot = await getDocs(q);

        const faculties: facultyProps[] = querySnapshot.docs.map((doc) => {
            //return data compatible with data types specified in the tasks variable 
            return fromFirestore<facultyProps>({
                id: doc.id,
                ...doc.data()
            } as facultyProps);
        });
        // Return the first institute with the searched code
        return faculties;
    }

    async getById(instituteId: string, facultyId: string): Promise<facultyProps | null> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const docRef = doc(collectionRef, facultyId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const docId = docSnap.id;
            return fromFirestore<facultyProps>({
                id: docId,
                ...docSnap.data()
            } as facultyProps);
        }
        return null;
    }
    
    async getByRef(ref:string): Promise<facultyProps | null> {

        const docRef = doc(firestore, ref);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const docId = docSnap.id;
            return fromFirestore<facultyProps>({
                id: docId,
                ...docSnap.data()
            } as facultyProps);
        }
        return null;
    }

    async getByName(instituteId: string, facultyName: string): Promise<facultyProps[]> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const q = query(collectionRef, where("name", "==", facultyName));
        const querySnapshot = await getDocs(q);

        const institutes: facultyProps[] = querySnapshot.docs.map((doc) => {
            //return data compatible with data types specified in the tasks variable 
            return fromFirestore<facultyProps>({
                id: doc.id,
                ...doc.data()
            } as facultyProps);
        });
        // Return the first institute with the searched code
        return institutes;
    }

    async create(instituteId: string, faculty: facultyProps): Promise<string> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));
        // Add a new document with a generated id.
        const docData = await addDoc(collectionRef, {
            ...toFirestore(faculty),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        });
        return docData.id;  
    }

    async update(instituteId: string, facultyId: string, value: any): Promise<boolean> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const docRef = doc(collectionRef, facultyId);

        if (docRef == null) {
            console.error(`Error updating: institutes/${instituteId}/faculties/${facultyId}`);
            console.error("Doesn't find Faculty");
            return false;
        }

        try {
            // Clone value object
            const newValue = { ...value };
            // Remove id from Insititute object
            // delete newValue['id'];
            // Set updatedAt field with Server timestamp
            newValue.updatedAt = serverTimestamp();
            await updateDoc(docRef, {
                ...newValue
            });// Add a new document with a generated id.
            return true;

        } catch (error) {
            console.error(`Error updating: institutes/${instituteId}/faculties/${facultyId}`);
            console.error(value);
            console.error(error);
            return false;
        }
    }

    async delete(instituteId: string, facultyId: string): Promise<boolean> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const docRef = doc(collectionRef, facultyId);

        if (docRef.id == null) {
            console.error(`Error deleting: institutes/${instituteId}/faculties/${facultyId}`);
            console.error("Doesn't find Faculty");
            return false;
        }

        try {
            await deleteDoc(docRef);
            return true;
        } catch (error) {
            console.error(`Error deleting: institutes/${instituteId}/faculties/${facultyId}`);
            console.error(error);
            return false;
        }
    }

    async setActive(instituteId: string, facultyId: string, active: boolean): Promise<boolean> {
        const collectionRef = collection(firestore, facultyCollectionName.replace("{0}", instituteId));

        const docRef = doc(collectionRef, facultyId);

        if (docRef == null) {
            console.error(`Error setting active: institutes/${instituteId}/faculties/${facultyId}`);
            console.error("Doesn't find Faculty");
            return false;
        }

        try {
            await updateDoc(docRef, {
                active: active,
                updatedAt: serverTimestamp()
            });// Add a new document with a generated id.
            return true;

        } catch (error) {
            console.error(`Error setting active: institutes/${instituteId}/faculties/${facultyId}`);
            console.error(`active value: ${active}`);
            console.error(error);
            return false;
        }
    }
}

export default new FacultyService();
