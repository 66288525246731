import { ContactInfo } from "@/types";
import {
  dialCodes,
  EditableInput,
  isValidEmail,
  isValidPhone,
  tokens,
} from "@/utilities";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";

interface Props {
  values: ContactInfo;
  setContactInfo: (contactInfo: ContactInfo) => void;
}
type GeneralChangeEvent =
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent<string>;

export const ContactInfoForm = ({ values, setContactInfo }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [country, setCountry] = useState("+57");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleCountryChange = (event: GeneralChangeEvent) => {
    setCountry(event.target.value);
    setContactInfo({
      ...values,
      phone_code_country: event.target.value,
    });
  };

  const handlerChange = (event: GeneralChangeEvent): void => {
    const { name, value } = event.target;
    switch (name) {
      case "contactEmail":
        setEmailError(!isValidEmail(value));
        break;
      case "contactPhone":
        setPhoneError(!isValidPhone(value));
        break;
      default:
        break;
    }
    setContactInfo({
      ...values,
      [name]: value,
    });
  };

  return (
    <Grid
      sx={{
        display: "grid",
        gap: "1rem",
      }}
    >

      <Grid item xs={12} sm={10}>
        <EditableInput
          value={values.webSite}
          name="webSite"
          type="text"
          label="Web/Enlace/Brochure"
          isEditing={true}
          onChange={handlerChange}
        />
      </Grid>

      <Grid item xs={12} sm={10}>
        <EditableInput
          value={values.contactEmail}
          name="contactEmail"
          type="text"
          label="Email/Contacto admisiones"
          isEditing={true}
          onChange={handlerChange}
          error={emailError}
          helperText="El correo debe tener una extensión .edu"
        />
      </Grid>

      <FormControl
        id="phone"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <Select
          value={country}
          id="phone_code_country"
          label="Prefijo"
          name="phone_code_country"
          onChange={handleCountryChange}
          sx={{
            display: "flex",
            border: "none",
            width: { xs: "40%", sm: "45%" },
            height: "46.6px",
            color: colors.grey[100],
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                width: "40ch",
                right: 0,
                margin: "0 0 0 24px",
                color: colors.grey[100],
              },
            },
          }}
        >
          {dialCodes.map((country) => (
            <MenuItem key={country.code} value={country.dial_code}>
              ({country.dial_code}) {country.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="contactPhone"
          name="contactPhone"
          type="text"
          fullWidth
          autoComplete="off"
          variant="outlined"
          onChange={handlerChange}
          error={phoneError}
          helperText={phoneError ? "Sólo se permiten números" : ""}
          InputLabelProps={{
            style: { color: colors.grey[100], fontSize: "16px" },
          }}
          InputProps={{
            style: {
              color: colors.grey[100],
              fontSize: "16px",
              width: "100%",
            },
          }}
        />
      </FormControl>
    </Grid>
  );
};
