import { tokens } from "@/utilities";
import { TextField, Typography, useTheme } from "@mui/material";

interface Props{
    value: string | number | undefined | null
    name:string
    type:string
    error?:boolean
    label?:string
    helperText?:string
    isEditing: boolean
    isDisabled?:boolean
    onChange:(event: React.ChangeEvent<any>)=>void
}
export const EditableInput = ({ name, value, type, isEditing,error, label, helperText, onChange, isDisabled=false}:Props) => {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    
  if (isEditing) {
    return (
      <TextField
        id={name}
        name={name}
        type={type} 
        error={error}
        helperText={helperText}
        defaultValue={value}
        fullWidth
        label={label}
        size="small"
        autoComplete="off"
        variant="outlined"
        disabled={isDisabled}
        onChange={onChange}
        InputLabelProps={{
            style: { color: colors.grey[100],
            fontSize:"16px",
            background:colors.background.primary
          }
          }}
          InputProps={{
            style:{ color: colors.grey[100],
              fontSize:"16px",
              width:"100%"
            }
          }}
        />
    );
  } else {
    return (
      <Typography variant='h5' color={colors.grey[200]}>
        {value}
      </Typography>
    );
  }
};

