import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DetailByCareer } from '@/components';
import { Box, Button, useTheme } from '@mui/material';
import { tokens } from '@/utilities';
import { CareerProps } from '@/types';
import '../components/Institutes/Careers/DetailCareers.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

interface Props {
  selectedCareer:CareerProps
  isModalOpen: boolean; 
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateCareer: (careerMap:Partial<CareerProps>, id:string)=>void
}

export function ModalDetailByCareer({selectedCareer, isModalOpen,setIsModalOpen, updateCareer}:Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{maxWidth:"100vw"}}
      >
        <AppBar sx={{ position: 'sticky', backgroundColor:'#5394d4'}}>
          <Toolbar >
            <Typography sx={{ ml: {xs:"5px" ,sm:10}, flex: 1, fontWeight:"Bold" }} variant="h5" >
              {selectedCareer.name}
            </Typography>
            <Button 
                onClick={handleClose}
                  sx={{
                  color:colors.grey[900],
                  display:"flex",
                  textDecoration:"none",
                  gap:"10px",
                  padding:"10px 20px",
                  borderRadius:"5px",
                  fontWeight:"bold",
                  textTransform:"none",
                  fontSize:"16px",
                  "&:hover":{ backgroundColor: colors.grey[100], cursor:"pointer"}

                }}
            >
                <ArrowBackIcon/>
                Volver 
          </Button>
          </Toolbar>
        </AppBar>
          <Box sx={{backgroundColor:colors.primary[500], display:"flex", width:"100%", justifyContent:"center", alignItems:"center"}}>
            <DetailByCareer updateCareer={updateCareer} selectedCareer={selectedCareer}/>
          </Box>
      </Dialog> 
    </Box>
  );
}