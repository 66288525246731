import { CustomComponentProps } from "@/utilities"
import './Cards.scss'
import { Typography } from "@mui/material"
import { ImagesFormat } from "@/types";

interface Props extends CustomComponentProps {
  imgSrc: string | ImagesFormat[];
  title?: string;
  onClick?: () => void;
}

export const CardDetail = (props: Props) => {
  return (
    <div
      onClick={() => (props.onClick ? props.onClick() : '')}
      className="cards"
    >
      <img
        src={
          typeof props.imgSrc === 'string' ?
          props.imgSrc :
          Array.isArray(props.imgSrc) && props.imgSrc.length > 0 ? props.imgSrc[0].url : ""
        }
        className="images"
      />
      <Typography variant="h5" className="title">{props.title}</Typography>
    </div>
  );
};
