import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { EditableInput } from "@/utilities/Components";
import {
  dialCodes,
  isValidEmail,
  isValidValue,
  SwitchButton,
  tokens,
} from "@/utilities";
import { useState } from "react";
import { CareerProps, DefaultValue } from "@/types";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";

interface Props {
  premiumPlan: string;
  isEditable: boolean;
  fieldsToEdit: string;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  selectedCareer: CareerProps;
}

export const FinancialSupport = ({
  premiumPlan,
  isEditable,
  fieldsToEdit,
  selectedCareer,
  updateCareer,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingFinancialSupport, setIsEditingFinancialSupport] =
    useState<boolean>(false);

  const [defaultFinancialValue, setDefaultFinancialValue] = useState(
    selectedCareer?.financialSupport?.default || "ies"
  );

  const [countryFinancialSupp, setCountryFinancialSupp] = useState(
    selectedCareer.financialSupport?.[defaultFinancialValue]
      ?.phone_code_country || "Prefijo"
  );

  const [editableFinancialSupport, setEditableFinancialSupport] = useState<
    Partial<CareerProps>
  >({
    financialSupport: {
      default: defaultFinancialValue,
      ies: {
        hasFinancialSupport:
          selectedCareer.financialSupport?.ies?.hasFinancialSupport || false,
        website: selectedCareer.financialSupport?.ies?.website || "",
        email: selectedCareer.financialSupport?.ies?.email || "",
        phone_code_country:
          selectedCareer.financialSupport?.ies?.phone_code_country || "Prefijo",
        contactPhone:
          selectedCareer.financialSupport?.ies?.contactPhone || null,
        whatsapp: selectedCareer.financialSupport?.ies?.whatsapp || null,
        hasWhatsapp: selectedCareer.financialSupport?.ies?.hasWhatsapp || false,
      },
      faculty: {
        hasFinancialSupport:
          selectedCareer.financialSupport?.faculty?.hasFinancialSupport ||
          false,
        website: selectedCareer.financialSupport?.faculty?.website || "",
        email: selectedCareer.financialSupport?.faculty?.email || "",
        phone_code_country:
          selectedCareer.financialSupport?.faculty?.phone_code_country ||
          "Prefijo",
        contactPhone:
          selectedCareer.financialSupport?.faculty?.contactPhone || null,
        whatsapp: selectedCareer.financialSupport?.faculty?.whatsapp || null,
        hasWhatsapp:
          selectedCareer.financialSupport?.faculty?.hasWhatsapp || false,
      },
      career: {
        hasFinancialSupport:
          selectedCareer.financialSupport?.career?.hasFinancialSupport || false,
        website: selectedCareer.financialSupport?.career?.website || "",
        email: selectedCareer.financialSupport?.career?.email || "",
        phone_code_country:
          selectedCareer.financialSupport?.career?.phone_code_country ||
          "Prefijo",
        contactPhone:
          selectedCareer.financialSupport?.career?.contactPhone || null,
        whatsapp: selectedCareer.financialSupport?.career?.whatsapp || null,
        hasWhatsapp:
          selectedCareer.financialSupport?.career?.hasWhatsapp || false,
      },
    },
  });

  async function handleFinancialSupportSubmit() {
    try {
      const selectedFinancialSupport = {
        hasFinancialSupport:
          selectedCareer.financialSupport?.[defaultFinancialValue]
            ?.hasFinancialSupport || false,
        website:
          selectedCareer.financialSupport?.[defaultFinancialValue]?.website ||
          "",
        email:
          selectedCareer.financialSupport?.[defaultFinancialValue]?.email || "",
        phone_code_country:
          selectedCareer.financialSupport?.[defaultFinancialValue]
            ?.phone_code_country || "Prefijo",
        whatsapp:
          selectedCareer.financialSupport?.[defaultFinancialValue]?.whatsapp ||
          null,
        hasWhatsapp:
          selectedCareer.financialSupport?.[defaultFinancialValue]
            ?.hasWhatsapp || false,
      };

      if (
        JSON.stringify(editableFinancialSupport) ===
        JSON.stringify(selectedFinancialSupport)
      ) {
        setIsEditingFinancialSupport(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (selectedCareer.id) {
        const data = {
          ...editableFinancialSupport,
        };
        await careersService.update(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          data
        );
        updateCareer(data, selectedCareer.id);
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        // sessionStorage.setItem("currentCareers", JSON.stringify)
      }
      setIsEditingFinancialSupport(!isEditingFinancialSupport);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  }

  const handleChangeFinancialSupp = (
    event: React.MouseEvent<HTMLElement>,
    financialDefault: DefaultValue
  ) => {
    if (financialDefault != null) {
      event.preventDefault();
      setDefaultFinancialValue(financialDefault);
      setEditableFinancialSupport((prevInfo) => ({
        ...prevInfo,
        financialSupport: {
          ...prevInfo.financialSupport,
          default: financialDefault,
        },
      }));
    }
  };

  const handleFinancialSupportChange = (
    event: React.ChangeEvent<any> | SelectChangeEvent<any>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "contactEmail":
        // setEmailError(!isValidEmail(value));
        break;
      case "contactPhone":
        // setPhoneError(!isValidPhone(value))
        break;
      case "phone_code_country":
        setCountryFinancialSupp(value);
        break;
      case "whatsapp":
        // setWhatsappError(!isValidPhone(value))
        break;
      case "facebook":
        // setFacebookError(!isValidUrl(value, ['fb', 'facebook.com']))
        break;
      case "linkedin":
        // setLinkedinError(!isValidUrl(value, ['linkedin.com', 'lnkd.in']))
        break;
      default:
        break;
    }
    setEditableFinancialSupport((prevInfo) => ({
      financialSupport: {
        ...prevInfo.financialSupport,
        default: prevInfo.financialSupport?.default || "ies",
        career: {
          ...prevInfo.financialSupport?.career,
          hasFinancialSupport:
            prevInfo.financialSupport?.career?.hasFinancialSupport ?? false,
          [name]: value,
        },
      },
    }));
  };

  const handleFinancialSupportSwitchChange = (switchName: string) => {
    setEditableFinancialSupport((prevValues) => {
      const updatedFinancialSupport = {
        ...prevValues.financialSupport,
        default: prevValues.financialSupport?.default || "ies",
      };
      const career = updatedFinancialSupport.career;
      if (career) {
        switch (switchName) {
          case "hasFinancialSupport":
            career.hasFinancialSupport = !career.hasFinancialSupport;
            break;
          case "hasWhatsapp":
            career.hasWhatsapp = !career.hasWhatsapp;
            break;
          default:
            break;
        }
      }
      return {
        ...prevValues,
        financialSupport: updatedFinancialSupport,
      };
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingFinancialSupport ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
              // disabled={emailError || phoneError}
            }}
            onClick={handleFinancialSupportSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() =>
              setIsEditingFinancialSupport(!isEditingFinancialSupport)
            }
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h3"
      >
        Apoyo Financiero
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      {isEditingFinancialSupport && (
        <Box sx={{ marginBottom: "20px", width: "100%" }}>
          <ToggleButtonGroup
            color="primary"
            value={defaultFinancialValue}
            exclusive
            onChange={handleChangeFinancialSupp}
            aria-label="Platform"
          >
            <ToggleButton value="ies">
              Reusa datos de la Institución
            </ToggleButton>
            <ToggleButton value="faculty">
              Reusa datos de la Facultad
            </ToggleButton>
            <ToggleButton value="career">Usa datos personalizados</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Web/Enlace/Brochure
        </Typography>
        <EditableInput
          value={
            editableFinancialSupport.financialSupport?.[defaultFinancialValue]
              ?.website ?? ""
          }
          name="website"
          type="text"
          isEditing={
            isEditingFinancialSupport && defaultFinancialValue === fieldsToEdit
          }
          onChange={handleFinancialSupportChange}
          // isDisabled={
          //   !isEditable || !editableFinancialSupport.financialSupport?.[defaultFinancialValue]?.hasFinancialSupport
          // }
        />
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Email/Contacto
          </Typography>
          <EditableInput
            value={
              editableFinancialSupport.financialSupport?.[defaultFinancialValue]
                ?.email ?? ""
            }
            name="email"
            type="text"
            error={isValidEmail(
              editableFinancialSupport.financialSupport?.[defaultFinancialValue]
                ?.email ?? ""
            )}
            helperText={
              isValidEmail(
                editableFinancialSupport.financialSupport?.[
                  defaultFinancialValue
                ]?.email ?? ""
              )
                ? "El correo electrónico debe tener la extensión .edu"
                : ""
            }
            isEditing={
              isEditingFinancialSupport &&
              defaultFinancialValue === fieldsToEdit
            }
            onChange={handleFinancialSupportChange}
            // isDisabled={
            //   !isEditable || !editableFinancialSupport.financialSupport?.[defaultFinancialValue]?.hasFinancialSupport
            // }
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Teléfono de contacto
          </Typography>
          {isEditingFinancialSupport &&
          defaultFinancialValue === fieldsToEdit ? (
            <FormControl
              id="phone"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Select
                value={countryFinancialSupp}
                id="phone_code_country"
                label="Prefijo"
                name="phone_code_country"
                onChange={handleFinancialSupportChange}
                disabled={!isEditable}
                sx={{
                  display: "flex",
                  border: "none",
                  width: { xs: "40%", sm: "45%" },
                  color: colors.grey[100],
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: "40ch",
                      right: 0,
                      margin: "0 0 0 24px",
                      color: colors.grey[100],
                    },
                  },
                }}
              >
                {dialCodes.map((country) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    ({country.dial_code}) {country.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="contactPhone"
                name="contactPhone"
                type="text"
                defaultValue={
                  editableFinancialSupport.financialSupport?.[
                    defaultFinancialValue
                  ]?.contactPhone ?? ""
                }
                fullWidth
                autoComplete="off"
                variant="outlined"
                onChange={handleFinancialSupportChange}
                disabled={!isEditable}
                error={
                  !isValidValue(
                    "phone",
                    editableFinancialSupport.financialSupport?.[
                      defaultFinancialValue
                    ]?.contactPhone
                  )
                }
                helperText={
                  !isValidValue(
                    "phone",
                    editableFinancialSupport.financialSupport?.[
                      defaultFinancialValue
                    ]?.contactPhone
                  )
                    ? "Sólo se permiten números"
                    : ""
                }
                InputLabelProps={{
                  style: { color: colors.grey[100], fontSize: "16px" },
                }}
                InputProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    width: "100%",
                  },
                }}
              />
            </FormControl>
          ) : (
            <Typography variant="h5" color={colors.grey[200]}>
              {`${
                editableFinancialSupport.financialSupport?.[
                  defaultFinancialValue
                ]?.phone_code_country ?? ""
              } ${
                editableFinancialSupport.financialSupport?.[
                  defaultFinancialValue
                ]?.contactPhone ?? ""
              }`}
            </Typography>
          )}
        </Box>
      </Box>

      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h4"
      >
        Redes Sociales
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        {/*-----Whatsapp-----*/}
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp
          </Typography>
          <EditableInput
            value={
              editableFinancialSupport.financialSupport?.[defaultFinancialValue]
                ?.whatsapp ?? ""
            }
            name="whatsapp"
            type="text"
            error={
              !isValidValue(
                "phone",
                editableFinancialSupport.financialSupport?.[
                  defaultFinancialValue
                ]?.whatsapp
              ) &&
              (editableFinancialSupport.financialSupport?.[
                defaultFinancialValue
              ]?.hasWhatsapp ??
                false)
            }
            helperText={
              !isValidValue(
                "phone",
                editableFinancialSupport.financialSupport?.[
                  defaultFinancialValue
                ]?.whatsapp
              ) &&
              editableFinancialSupport.financialSupport?.[defaultFinancialValue]
                ?.hasWhatsapp
                ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                : ""
            }
            isEditing={
              isEditingFinancialSupport &&
              defaultFinancialValue === fieldsToEdit
            }
            isDisabled={
              !isEditable ||
              !editableFinancialSupport.financialSupport?.[
                defaultFinancialValue
              ]?.hasWhatsapp
            }
            onChange={handleFinancialSupportChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp Activo
          </Typography>
          {isEditingFinancialSupport &&
          defaultFinancialValue === fieldsToEdit ? (
            <SwitchButton
              checked={
                editableFinancialSupport.financialSupport?.career
                  ?.hasWhatsapp ?? false
              }
              setChecked={() =>
                handleFinancialSupportSwitchChange("hasWhatsapp")
              }
              isDisabled={!isEditable}
            />
          ) : (
            <Typography variant="h5" color={colors.grey[500]}>
              {editableFinancialSupport.financialSupport?.[
                defaultFinancialValue
              ]?.hasWhatsapp
                ? "Si"
                : "No"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
