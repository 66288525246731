import {
  Typography,
  Grid,
  useTheme,
  Box,
  Modal,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "@/utilities";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import facultyService from "@/api/faculty.service";
import { CareerProps, facultyProps } from "@/types";
import Swal from "sweetalert2";
import careersService from "@/api/careers.service";

const alertConfirm = () =>
  Swal.fire({
    title: "Las carreras seleccionadas se moveran",
    text: "¿Está seguro de continuar?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, ¡muevelas!",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    return result.isConfirmed;
  });

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  facultyData: Partial<facultyProps>;
  selectedCareers: CareerProps[];
  setCareers: (careers: CareerProps[]) => void;
  careers: CareerProps[]
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

export const ModalMoveCareers = ({
  open,
  setOpen,
  facultyData,
  selectedCareers,
  setCareers,
  careers
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { code } = useParams();
  const [faculties, setFaculties] = useState<facultyProps[] | null>([]);
  const [facultiesNames, setFacultiesNames] = useState<string>("");
  const [selectedFaculty, setSelectedFaculty] =
    useState<Partial<facultyProps>>();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    value.length <= 1 ? setIsDisabled(true) : setIsDisabled(false)
    setFacultiesNames(value);
    const selectedFaculty = faculties?.find(
      (faculty) => faculty.name === value
    );
    if (selectedFaculty) {
      setSelectedFaculty({
        name: selectedFaculty.name,
        id: selectedFaculty.id,
        uidInstitution: selectedFaculty.uidInstitution,
      });
    } else {
      console.warn("No faculty found with name:", value);
    }
  };

  const handleSubmit = async () => {
    const responseAlert = await alertConfirm();
    if (responseAlert) {
      if (
        code &&
        facultyData.id &&
        selectedFaculty?.id &&
        selectedCareers &&
        selectedFaculty.name
      ) {
        const moved = await careersService.moveCareers(
          code,
          facultyData.id,
          selectedFaculty?.id,
          selectedCareers,
          selectedFaculty.name
        );

        const updatedCareers = careers.filter(
            career => !selectedCareers.some(selectedCareer => selectedCareer.id === career.id)
          );
        moved && setCareers(updatedCareers)

        moved ?
          Swal.fire({
            title: "Las carreras seleccionadas se movieron exitosamente",
            icon: "success",
            timer: 2000,
          })
          :
          Swal.fire({
            title: "Hubo un error moviendo las carreras",
            icon: "error",
            timer: 2000,
          })
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    async function fetchFaculties() {
      if (code) {
        try {
          const getFaculties = await facultyService.getAll(code);
          setFaculties(getFaculties);
        } catch (error) {
          console.error("Error trying to get faculties", error);
        }
      }
    }
    fetchFaculties();
  }, [code]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <Box sx={{ ...style, width: { xs: 300, sm: 450, md: 800 } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <Typography sx={{ flex: 1 }} variant="h4" color={colors.grey[100]}>
            Mover carreras
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Box>

        <Grid
          sx={{
            width: "100%",
            display: { xs: "flex", sm: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(2,minmax(0,100%))",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <InputLabel
              sx={{
                color: colors.grey[100],
                fontWeight: "bold",
                fontSize: "16px",
              }}
              htmlFor="facultiesNames"
            >
              Desde la facultad de:
            </InputLabel>

            <Typography variant="h6" sx={{ fontWeight: "regular" }}>
              {facultyData?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <InputLabel
              sx={{
                color: colors.grey[100],
                fontWeight: "bold",
                fontSize: "16px",
              }}
              htmlFor="selectedFaculty"
            >
              Hacia la facultad de:
            </InputLabel>
            <Select
              value={facultiesNames}
              id="selectedFaculty"
              name="selectedFaculty"
              onChange={handleSelect}
              sx={{
                display: "flex",
                border: "none",
                width: "100%",
                color: colors.grey[100],
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "40ch",
                    right: 0,
                    margin: "0 0 0 24px",
                    color: colors.grey[100],
                  },
                },
              }}
            >
              {faculties
                ?.filter((faculty) => faculty.id !== facultyData.id)
                .map((faculty) => (
                  <MenuItem key={faculty.id} value={faculty.name}>
                    - {faculty.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Grid>

        <InputLabel
          sx={{
            color: colors.grey[100],
            fontWeight: "bold",
            fontSize: "16px",
            marginTop: "1rem",
          }}
        >
          Carreras a mover:
        </InputLabel>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "-5rem",
            margin: "1rem 0",
            maxHeight: "10rem",
            overflowY: "auto",
            alignItems: "center",
          }}
        >
          {selectedCareers.map((career) => (
            <Box
              key={career.id}
              sx={{
                border: "1px solid rgba(255, 255, 255, .25)",
                borderRadius: "10px",
                backgroundColor: " rgba(255, 255, 255, 0.45)",
                boxShadow: " 0 0 5px 1px rgba(0, 0, 0, 0.25)",
                padding: ".5rem",
                width: "90%",
                margin: "5px 0",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                {career?.name}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Box
          sx={{
            width: "100%",
            margin: "1rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={isDisabled}>
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
