
import { TopProgramsByInstitute } from '@/types';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    subtitle: {
        fontSize: 14,
        margin: "10px 0",
        fontFamily: 'Helvetica-Bold',
        color: "#002D82",
        marginBottom:"20px"
      },
      tables:{
        fontSize:10,
        right:0,
        lineHeight:0.5,
        fontFamily: 'Helvetica',
      },
      text: {
        margin:"12px 0",
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
      },
  });

export const TopCareersByInstitution = ({data}:{data:TopProgramsByInstitute[]}) => (
    <>
    <View style={{display:"flex", flexDirection:"column",gap:"0", alignItems:"baseline", margin:"10px 0"}}>
      <Text style={styles.subtitle}>Top 3. Carreras más buscadas en tu institución</Text>
      {/* <Text style={styles.note}>(El orden mostrado no representa un ranking específico.)</Text> */}
    </View>

    <View  style={{display:"flex", gap:"10px"}} render={() => (

    data.flat().length>0 
    ? data.map((career, index)=>(
        
        <View  style={{display:"flex", flexDirection:"row", width:"100%"}}>
          <Text style={{...styles.tables, width:"30px"}}>{`${index + 1}.`}</Text>
          <Text style={styles.tables}>{career.career_name}</Text>
        </View>
      ))
      :<Text style={styles.text} >Aún no registramos datos en la base de datos</Text>
    )}  />
  </>
  )

