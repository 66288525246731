import { PDFViewer } from '@react-pdf/renderer'
import { TemplateReport } from '.'

export const PDFViewe = () => {
  return (
    <PDFViewer style={{ width:"100%", height:"100%"}}>
        <TemplateReport/>
    </PDFViewer>
  )
}
