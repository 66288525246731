import instituteService from "@/api/institute.service";
import { InstituteProps } from "@/types";
import { tokens } from "@/utilities";
import { EditableInput } from "@/utilities/Components";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";

interface Props {
  institute: InstituteProps | null;
  isEditable: boolean;
  premiumPlan: string;
}
export const GeneralInfo = ({ isEditable, premiumPlan, institute }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const iesId = institute?.id;
  isEditable=true

  const [isEditingGeneralInfo, setIsEditingGeneralInfo] =
    useState<boolean>(false);
  const [editableGeneralInfo, setEditableGeneralInfo] = useState<
    Partial<InstituteProps>
  >({
    name: institute?.name,
    acronym: institute?.acronym,
    president: institute?.president,
    vicePresident: institute?.vicePresident,
    taxID: institute?.taxID,
  });
  const handleSubmitGeneralInfo = async (): Promise<void> => {
    try {
      const selectedGeneralInfo = {
        name: institute?.name || "",
        acronym: institute?.acronym || "",
        president: institute?.president || "",
        vicePresident: institute?.vicePresident || "",
        taxID: institute?.taxID || "",
      };

      if (
        JSON.stringify(editableGeneralInfo) ===
        JSON.stringify(selectedGeneralInfo)
      ) {
        setIsEditingGeneralInfo(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (iesId) {
        const data = {
          name: editableGeneralInfo.name || "",
          acronym: editableGeneralInfo.acronym || "",
          president: editableGeneralInfo.president || "",
          vicePresident: editableGeneralInfo?.vicePresident || "",
          taxID: editableGeneralInfo?.taxID || "",
        };
        console.warn("Updating in Database ...");

        console.log("Dataaaa contact info");
        console.log(data);
        await instituteService.update(iesId, data);
        console.warn("Updated in Database!");
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        // sessionStorage.setItem("currentCareers", JSON.stringify)
      }
      setIsEditingGeneralInfo(false);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  };

  const handleGeneralInfoChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;

    setEditableGeneralInfo((prevUser) => ({ ...prevUser, [name]: value }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 0 },
            top: { xs: "35px", sm: "40px" },
            marginBottom: { xs: "20px", sm: "0" },
            display: "flex",
          }}
          onClick={
            isEditingGeneralInfo
              ? () => handleSubmitGeneralInfo()
              : () => setIsEditingGeneralInfo(true)
          }
        >
          {isEditingGeneralInfo ? "Guardar" : "Editar"}
        </Button>
        {!isEditable && (
          <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
            {premiumPlan}
          </Typography>
        )}
      </Box>

      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
          maxWidth: "75%",
        }}
        variant="h3"
      >
        Información General
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Nombre Institución
          </Typography>
          <EditableInput
            value={editableGeneralInfo.name ?? ""}
            name="name"
            type="text"
            isEditing={isEditingGeneralInfo}
            onChange={handleGeneralInfoChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Acrónimo
          </Typography>
          <EditableInput
            value={editableGeneralInfo?.acronym ?? ""}
            name="acronym"
            type="text"
            isEditing={isEditingGeneralInfo}
            onChange={handleGeneralInfoChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Rector del instituto
          </Typography>
          <EditableInput
            value={editableGeneralInfo?.president ?? ""}
            name="president"
            type="text"
            isEditing={isEditingGeneralInfo}
            onChange={handleGeneralInfoChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Vicerrector del instituto
          </Typography>
          <EditableInput
            value={editableGeneralInfo?.vicePresident ?? ""}
            name="vicePresident"
            type="text"
            isEditing={isEditingGeneralInfo}
            onChange={handleGeneralInfoChange}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            N.I.T.
          </Typography>
          <EditableInput
            value={editableGeneralInfo?.taxID ?? ""}
            name="taxID"
            type="text"
            isEditing={isEditingGeneralInfo}
            onChange={handleGeneralInfoChange}
          />
        </Box>
      </Box>
    </Box>
  );
};
