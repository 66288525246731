import { ContactInfo, ContactSocialMedia } from "@/types";

export const getSocialMediaData = (source:Partial<ContactSocialMedia>| undefined, fallback: Partial<ContactSocialMedia>| undefined) => ({
    facebook: source?.facebook || fallback?.facebook || "",
    hasFacebook: source?.hasFacebook || fallback?.hasFacebook || false,
    whatsapp: source?.whatsapp || fallback?.whatsapp || null,
    hasWhatsapp: source?.hasWhatsapp || fallback?.hasWhatsapp || false,
    linkedin: source?.linkedin || fallback?.linkedin || "",
    hasLinkedin: source?.hasLinkedin || fallback?.hasLinkedin || false,
  });

export const hasContactSocialMediaData = (data: Partial<ContactSocialMedia> | undefined): boolean => {
    return Boolean(
      data?.facebook || 
      data?.hasFacebook || 
      data?.whatsapp || 
      data?.hasWhatsapp || 
      data?.linkedin || 
      data?.hasLinkedin
    );
  };

export const getContactInfoData = (
    source: Partial<ContactInfo> | undefined,
    fallback: Partial<ContactInfo> | undefined
  ): ContactInfo => ({
    contactEmail: source?.contactEmail || fallback?.contactEmail || "",
    phone_code_country: source?.phone_code_country || fallback?.phone_code_country || "Prefijo",
    contactPhone: source?.contactPhone || fallback?.contactPhone || null,
    webSite: source?.webSite || fallback?.webSite || "",
  });
  
export const hasContactInfoData = (data: Partial<ContactInfo> | undefined): boolean => {
    return Boolean(
      data?.contactEmail || 
      data?.phone_code_country || 
      data?.contactPhone || 
      data?.webSite
    );
  };