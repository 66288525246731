import { Box, Button, Typography, useTheme } from "@mui/material";
import { EditableTextArea } from "@/utilities/Components";
import { CareerProps } from "@/types";
import { tokens } from "@/utilities";
import { useState } from "react";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";

interface Props {
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
}

export const GraduatedProfile = ({ selectedCareer, updateCareer }: Props) => {
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingProfileGraduated, setIsEditingProfileGraduated] =
    useState<boolean>(false);
  const [editableProfileGraduated, setEditableProfileGraduated] =
    useState<string>(selectedCareer.profileGraduated || "");

  const handleProfileGraduatedChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    setEditableProfileGraduated(value);
    // setEditableData((prevValues)=>({
    //   ...prevValues,
    //   profileGraduated:value
    // }))
  };

  async function handleProfileGraduatedSubmit() {
    try {
      if (editableProfileGraduated == selectedCareer.profileGraduated) {
        setIsEditingProfileGraduated(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (selectedCareer.id && editableProfileGraduated) {
        await careersService.updateProfileGraduated(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          editableProfileGraduated
        );
        updateCareer(
          { profileGraduated: editableProfileGraduated },
          selectedCareer.id
        );

        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingProfileGraduated(!isEditingProfileGraduated);
    } catch (error) {
      console.error("Error updating ProfileGraduated", error);
    }
  }
 
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingProfileGraduated ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={handleProfileGraduatedSubmit}
          >
            Guardar
          </Button>
          {/* {!isEditable && (
                  <Typography variant="h5" sx={{margin:"0", color:"red"}}>{premiumPlan}</Typography>
              )} */}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() =>
              setIsEditingProfileGraduated(!isEditingProfileGraduated)
            }
          >
            Editar
          </Button>
        </Box>
      )}

      <Typography
        sx={{
          padding: "20px 0",
          textAlign: "left",
          color: colors.grey[100],
          marginTop: "10px",
          width: { xs: "60%" },
        }}
        variant="h3"
      >
        Perfil de Egresado
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />
      <EditableTextArea
        id="profileGraduated"
        name="profileGraduated"
        value={editableProfileGraduated}
        onChange={handleProfileGraduatedChange}
        placeholder="Por favor, escriba una versión detallada del perfil graduado"
        isEditing={isEditingProfileGraduated}
      />
    </Box>
  );
};
