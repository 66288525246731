import { Box, useTheme } from "@mui/material";
import { CareerProps } from "@/types";
import { tokens, useAuth } from "@/utilities";
import CareerInfo from "./DetailByCareer/CareerInfo";
import { ContactInfo } from "./DetailByCareer/ContactInfo";
import { FinancialSupport } from "./DetailByCareer/FinancialSupport";
import { CareerImages } from "./DetailByCareer/CareerImages";
import { HeadquarterInfo } from "./DetailByCareer/HeadquarterInfo";
import { ProgramDescription } from "./DetailByCareer/ProgramDescription";
import { GraduatedProfile } from "./DetailByCareer/GraduatedProfile";
import { CandidateProfile } from "./DetailByCareer/CandidateProfile";
import { AcademicDegree } from "./DetailByCareer/AcademicDegree";

interface Props {
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
}

export const DetailByCareer = ({ selectedCareer, updateCareer }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useAuth();

  // const {code, id} = useParams<string>()
  // const role = user?.userData.role
  // const facultyID = role === "Administrador IES" ? id : user?.userData.faculty
  // const instituteID = role === "Administrador IES" ? code : user?.userData.institute

  // const [careers, setCareers] = useState<CareerProps[]>([])

  const isEditable = (user?.subscriptionLevel ?? 0) > 1;
  const premiumPlan =
    "Para poder acceder a los campos deshabilitados, por favor, dirigete a nuestros planes y adquiere uno.";

  const fieldsToEdit = "career";
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "0 20px", sm: "0 50px" },
        backgroundColor: colors.primary[400],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {selectedCareer ? (
        <>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              borderRadius: "5px",
              marginTop: "20px",
              padding: { xs: 0, sm: "20px", md: "50px" },
              gap: "20px",
            }}
          >
            <CareerInfo
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              padding: { xs: 0, sm: "20px", md: "0 50px" },
              width: "100%",
            }}
          >
            <ContactInfo
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
              fieldsToEdit={fieldsToEdit}
            />

            <FinancialSupport
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
              fieldsToEdit={fieldsToEdit}
            />

            <CareerImages
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
            />

            <HeadquarterInfo
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
            />

            <ProgramDescription
              premiumPlan={premiumPlan}
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
              isEditable={isEditable}
            />

            <GraduatedProfile
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
            />

            <CandidateProfile
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
            />

            <AcademicDegree
              selectedCareer={selectedCareer}
              updateCareer={updateCareer}
            />

            <br />
            <br />
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
