import React,{ useState } from "react"
import './FormCreateAdmin.scss'
import userAdminService from "@/api/userAdmin.service"
import Box from '@mui/material/Box';
import { generateRandomPassword, tokens, useAuth, dialCodes} from "@/utilities"
import { Header } from "@/views";
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import Swal from "sweetalert2";
import { UserAdminProfile, facultyProps } from "@/types";



const showAlert = ()=>{
  Swal.fire({
      title: "Administrador creado satisfactoriamente",
      icon: "success",
      footer: `Se envió un correo de verificación al usuario`,
      timer: 2000,
  })
}

interface Props{
  handleChangeTab: (tab:number)=>void
  adminUsers:UserAdminProfile[] | []
  faculties: facultyProps[] | null
  // setAdminUsers:  React.Dispatch<React.SetStateAction<UserAdminProfile[]>>;

}


export const FormCreateAdminFaculty = ({handleChangeTab, adminUsers, faculties}:Props) => {
  const {user} = useAuth()
  
  // const [adminUsers, setAdminUsers] = useState<UserAdminProfile[]>([])

  const [userRegister, setUserRegister]= useState({
    email: "",
    institution:user?.userData.institute,
    phone_code_country:"+57",
    phone:"",
    name:"",
    faculty:"",
    role:""
  })
  const theme = useTheme()    
  const colors = tokens(theme.palette.mode);
  
  // const [faculties, setFaculties] = useState<facultyProps[] | null>([])

  const usedFaculties = adminUsers && adminUsers.map((admin) => admin.faculty);

  const filteredFaculties = faculties?.filter(
    (faculty) => !usedFaculties.includes(faculty.name)
  );

  const handleSubmit = async (event:React.FormEvent<HTMLFormElement>)=>{
      event.preventDefault()
      try {
        const authUser = {
          displayName: userRegister.name,
          email: userRegister.email,
          password: generateRandomPassword(8)
        }
      //  const createAdmin = await userAdminService.signUpAdmin(authUser)
      const createAdmin = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/createUserAdmin", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(authUser) 
    })
    .then((response) => response.json())
    
    console.log("REssspuestaaaa", createAdmin)
      if(createAdmin.status && createAdmin.status === "200"){
        const uidFaculty = faculties?.filter((faculty) => faculty.name === userRegister.faculty)[0].id;

          const profileData = {
            uid: createAdmin.response, 
            displayName: userRegister.name,
            email: userRegister.email,
            emailVerified: false,
            phoneNumber: userRegister.phone,
            active: false,
            institute: userRegister.institution,
            ref: `/institutes/${userRegister.institution}` ,
            role: userRegister.role,
            faculty: userRegister.faculty,
            uidFaculty: uidFaculty

          };
          const savedInFirestore = await userAdminService.saveUserProfile(profileData, createAdmin.response);
      
            if (savedInFirestore) {
              console.log("Registro exitoso en Firebase Authentication y Firestore");
              // setAdminUsers(prevAdminUsers=>({
              //   ...prevAdminUsers,
              //   profileData
              // }))
              handleChangeTab(1)
              showAlert()

              setUserRegister({
                email: "",
                institution: user?.userData.institute,
                phone_code_country:"+57",
                phone: "",
                name: "",
                faculty: "",
                role: "",
              });

            } else {
              console.error("Error al guardar en Firestore");
            }
       }
 
    
    
      
      } catch (error) {
        console.error(error)
      }
  }
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false)

  const handlerChange = (e:any) => {
    const { name, value } = e.target;

    // Validar el correo electrónico
    if (name === 'email') {
      setUserRegister((prevUser) => ({ ...prevUser, [name]: value }));
      setEmailError(!isValidEmail(value));
    }else if (name === 'phone'){
      setUserRegister((prevUser) => ({ ...prevUser, [name]: value }));
      setPhoneError(!isValidPhone(value))
    }
    setUserRegister((prevUser) => ({ ...prevUser, [name]: value }));
    // Añadir más lógica de manejo de cambios según sea necesario
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneno=/^\d+$/;
    if(phone.match(phoneno)){
      return true
    }else{
      return false
    }
  };

  const isValidEmail = (email:string) => {
    const isEduEmail = email.toLowerCase().includes('.edu');
    return isEduEmail;
  };
   

  const [country, setCountry] = useState('+57');
  const handleCountryChange = (event:any) => {
    setCountry(event.target.value);
    setUserRegister({
        ...userRegister,
        phone_code_country:event.target.value
    })
  };
   
//   useEffect(()=>{
//     async function fetchFaculties() {
//         if(user && user.userData.institute){
//             try {
//                 const getFaculties = await facultyService.getAll(user.userData.institute)
//                 setFaculties(getFaculties)
//             } catch (error) {
//                 console.error("Error trying to get faculties", error)
//             }
//         }            
//     }
//     fetchFaculties()
//   },[user])
  
//   useEffect(()=>{
//     async function fetchAdminUsers() {
//         if(role === "Super Administrador"){
//             try {
//                 const adminUsers = await userAdminService.getUsersAdmins()
//                 setAdminUsers(adminUsers)
//             } catch (error) {
//                 console.error("Error trying to get users", error)
//             }
//         }else if(role === "Administrador IES" && user?.userData.institute)  {
//           try {
//                 const adminUsers = await userAdminService.getUsersAdminFaculty(user.userData.institute)
//                 setAdminUsers(adminUsers)
//           } catch (error) {
//             console.error("Error trying to get users", error)
            
//           }
//         }          
//     }
//     fetchAdminUsers()
// },[role, user?.userData.institute])

  return (
    <Box sx={{ padding:{xs:4, sm:5}}}>
    <Header title="Crear usuario" subtitle="Por favor, diligencie todos los campos"/>
          <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <form onSubmit={handleSubmit}>

          <FormGroup 
          sx={{
            display:"grid",
            gridTemplateColumns:"repeat(1fr)",
            padding: 2,
            borderRadius: 2,
            border: `1px solid ${colors.blueAccent[500]}`,
            width:"100%",
            gap:"20px 50px",
            marginTop:"30px",
            maxWidth:"700px",
            backgroundColor:colors.primary[400],
            placeItems:"center"
          }}>

          <Grid item xs={12} sm={10} sx={{width:"100%"}}>
              <TextField
                required
                id="name"
                name="name"
                label="Nombres y Apellidos"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.name}
                onChange={handlerChange} 
                sx={{color:colors.grey[100]}}
                InputLabelProps={{
                  style: { color: colors.grey[100],
                  fontSize:"16px",
                  backgroundColor:colors.primary[400]
                }
                }}
                InputProps={{
                  style:{ color: colors.grey[100],
                    fontSize:"16px",
                    height:"45px"
                  }
                }}
                  />
            </Grid>

          <Grid item xs={12} sm={10} sx={{width:"100%"}}>
              <TextField
                required
                type="email"
                name="email"
                id="email"
                label="Correo electrónico"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.email}
                onChange={handlerChange}
                error={emailError}
                helperText={emailError ? 'El correo electrónico debe tener la extensión .edu' : ''}
                sx={{ color: colors.grey[100] }}
                InputLabelProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    backgroundColor: colors.primary[400],
                  },
                }}
                InputProps={{
                  style: { color: colors.grey[100], fontSize: "16px" , height:"45px"},
                }}
              />
            </Grid>

            <Grid sx={{gridColumn:{xs:"span 1" }, width:"100%", marginBottom:"15px"}}item xs={12} sm={10}>
                        <FormControl id="phone" variant="outlined" fullWidth size="small" sx={{ display: "flex", flexDirection: "row" }}>
                         <Select
                            value={country}
                            id="phone_code_country"
                            name="phone_code_country"
                            onChange={handleCountryChange}
                            sx={{ display: "flex", border: "none", width: {xs:"40%", sm:"45%"}, color:colors.grey[100], maxHeight:"46.6px", paddingRight:"30px", textOverflow: "ellipsis" }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        width: '40ch',
                                        right:0,
                                        margin: '0 0 0 24px',
                                        color:colors.grey[100],
                                    },
                                },
                            }}
                        >
                            {dialCodes.map((country) => (
                            <MenuItem key={country.code} value={country.dial_code}>
                               <Typography variant="h5">({country.dial_code}) {country.name}</Typography> 
                            </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            
                            id="phone"
                            name="phone"
                            label="Número de contacto"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            value={userRegister.phone}
                            onChange={handlerChange} 
                            error={phoneError}
                            helperText={phoneError ? 'Sólo se permiten números' : ''}
                            sx={{color:colors.grey[100], width: {xs:"60%", sm:"55%"},
                            display: "flex"}}
                            InputLabelProps={{
                            style: { color: colors.grey[100],
                            fontSize:"16px",
                            backgroundColor:colors.primary[400]
                            }
                            }}
                            InputProps={{
                            style:{ color: colors.grey[100],
                                fontSize:"16px" }
                            }}
                        />
                        </FormControl>

                    </Grid>

           

            <Grid item xs={12} sm={10} sx={{width:"100%"}}>
              <FormControl fullWidth size="small" >
                <InputLabel id="faculty" sx={{color:`${colors.grey[100]} !important`, fontSize:"16px", backgroundColor:colors.primary[400], padding:"0 2px"}}>Elija la facultad</InputLabel>
                <Select
                  id="faculty"
                  name="faculty"
                  size="small"
                  required
                  labelId="faculty"
                  value={userRegister.faculty}
                  onChange={handlerChange} 
                  sx={{
                    color: colors.grey[100],
                    height:"45px",
                    fontSize:16
                    // '& .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: colors.grey[100],
                    // },
                    // '&:hover .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: colors.grey[100],
                    // },
                    // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: colors.grey[100],
                    // },
                  }}
                >
                {filteredFaculties?.map((faculty)=>(
                  <MenuItem  key={faculty.id} value={faculty.name} sx={{fontSize:"16px"}}>{faculty.name}</MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>

              <Grid item xs={12} sm={10} sx={{width:"100%"}}>
                <FormControl fullWidth size="small">
                <InputLabel id="role" sx={{color:`${colors.grey[100]} !important`, fontSize:"16px", backgroundColor:colors.primary[400], padding:"0 2px"}}>Tipo de usuario</InputLabel>
                <Select
                  required
                  labelId="role"
                  id="role"
                  name="role"
                  size="small"
                  value={userRegister.role}
                  onChange={handlerChange} 
                  sx={{ color: colors.grey[100], backgroundColor: colors.primary[400], height:"45px", fontSize:16}}
                >
                    <MenuItem value="Administrador Facultad" sx={{fontSize:"16px"}}>Administrador Facultad</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

            <Button 
              variant="contained"
              sx={{
                
                backgroundColor:"#3F51B5",
                color:"#f2f0f0",         
                borderRadius:"5px",
                fontWeight:"bolder",
                width:"fit-content",
                ":hover":{
                  cursor:"pointer",
                  backgroundColor: colors.blueAccent[500]

                }
                }}
                disabled={phoneError  }
                type="submit"
                >
                  Crear usuario
            </Button>

          </FormGroup>
          </form>
          </Box>
    </Box>
  )
}
