import { FinancialSupport } from "@/types";
import {
  dialCodes,
  EditableInput,
  isValidEmail,
  isValidValue,
  SwitchButton,
  tokens,
} from "@/utilities";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

interface Props {
  values: FinancialSupport;
  setFinancialSupport: (values: any) => void;
}
type DefaultSwitchValues = keyof FinancialSupport;
type GeneralChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | SelectChangeEvent<string>;

export const FinancialSupportForm = ({
  values,
  setFinancialSupport,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [country, setCountry] = useState("+57");
  const [emailError, setEmailError] = useState(false);

  const handleFinancialSupportChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmailError(!isValidEmail(value));
        break;
      default:
        break;
    }
    setFinancialSupport({
      ...values,
      [name]: value,
    });
  };

  const handleSwitch = (switchName: DefaultSwitchValues) => {
    setFinancialSupport((prevValues: FinancialSupport) => {
      return {
        ...prevValues,
        [switchName]: !prevValues[switchName],
      };
    });
  };

  const handleCountryChange = (event: GeneralChangeEvent) => {
    setCountry(event.target.value);
    setFinancialSupport({
      ...values,
      phone_code_country: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        display: "grid",
        gap: "1rem",
      }}
    >
      <InputLabel
        sx={{
          color: colors.grey[100],
          fontWeight: "bold",
          fontSize: "16px",
        }}
        htmlFor="financialSupport"
      >
        Apoyo financiero
      </InputLabel>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          wordWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        <SwitchButton
          checked={values.hasFinancialSupport ?? false}
          setChecked={() => handleSwitch("hasFinancialSupport")}
        />
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Tiene Apoyo Financiero
        </Typography>
      </Box>

<EditableInput
          value={values.website ?? ""}
          name="website"
          type="text"
          label="Web/Enlace/Brochure"
          isEditing={true}
          onChange={handleFinancialSupportChange}
          isDisabled={!values.hasFinancialSupport}
        />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
          wordWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            wordWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <EditableInput
            value={values.email ?? ""}
            name="email"
            type="text"
            label="Email/Contacto"
            error={emailError}
            helperText="El correo debe tener una extensión .edu"
            isEditing={true}
          isDisabled={!values.hasFinancialSupport}
            onChange={handleFinancialSupportChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <FormControl
            id="phone"
            fullWidth
            size="small"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              value={country}
              id="phone_code_country"
              label="Prefijo"
              name="phone_code_country"
              onChange={handleCountryChange}
              sx={{
                display: "flex",
                width: { xs: "40%", sm: "45%" },
                height: "46.6px",
                color: colors.grey[100],
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "40ch",
                    right: 0,
                    margin: "0 0 0 24px",
                    color: colors.grey[100],
                  },
                },
              }}
            >
              {dialCodes.map((country) => (
                <MenuItem key={country.code} value={country.dial_code}>
                  ({country.dial_code}) {country.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="contactPhone"
              name="contactPhone"
              type="text"
              value={values.contactPhone ?? ""}
              fullWidth
              label="Teléfono de contacto"
              autoComplete="off"
              variant="outlined"
              onChange={handleFinancialSupportChange}
              error={!isValidValue("phone", values.contactPhone)}
              disabled={!values.hasFinancialSupport}
              helperText={
                !isValidValue("phone", values.contactPhone)
                  ? "Sólo se permiten números"
                  : ""
              }
              InputLabelProps={{
                style: { color: colors.grey[100], fontSize: "16px" },
              }}
              InputProps={{
                style: {
                  color: colors.grey[100],
                  fontSize: "16px",
                  width: "100%",
                },
              }}
            />
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        {/*-----Whatsapp-----*/}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <EditableInput
            value={values.whatsapp ?? ""}
            name="whatsapp"
            type="text"
            label="Whatsapp"
            error={
              !isValidValue("phone", values.whatsapp) &&
              (values.hasWhatsapp ?? false)
            }
            helperText={
              !isValidValue("phone", values.whatsapp) && values.hasWhatsapp
                ? "Sólo se permiten números y debe incluir el código del pais. Ej. 573115552244"
                : ""
            }
            isEditing={true}
            isDisabled={!values.hasFinancialSupport || !values.hasWhatsapp}

            onChange={handleFinancialSupportChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems:"center",
            gap:"1rem"
          }}
        >
         
          <SwitchButton
            checked={values.hasWhatsapp ?? false}
            setChecked={() => handleSwitch("hasWhatsapp")}
          />
           <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Whatsapp Activo
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
