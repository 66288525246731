
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container:{
        display:"flex",
        position:"relative",
        fontFamily: 'Helvetica',
      },
    subtitle: {
        fontSize: 14,
        fontFamily: 'Helvetica-Bold',
        color: "#002D82",
      },
      rightAbsolute: {
        display:"flex",
        right:0,
        fontFamily: 'Helvetica',
        fontSize:10
      },
      tables:{
        fontSize:10,
        right:0,
        lineHeight:3,
        fontFamily: 'Helvetica',
      },
      text: {
        margin:"12px 0",
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
      },
  });

export const ImageUsersByRegion = ({imgBase64}:{imgBase64:any}) => (
    <>
         <View style={{display:"flex", flexDirection:"row", alignItems:"baseline",marginTop:"40px",marginBottom:"20px",zIndex:10}}>
           <Text style={styles.subtitle}>Usuarios por país</Text>
         </View>

        <View style={{width:"100%"}} >
            <Image src={imgBase64} style={{transform:"scale(1)", objectFit:"cover", width:"100%"}}/>
        </View>

    </>

    )

