import { DataGrid, GridRenderCellParams, esES, GridToolbar, GridColDef} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {addToActiveList, addToInactiveList} from '@/redux/states'
import { AppStore } from '@/redux/store';
import { Box, Button, Checkbox, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {alertConfirm, alertDelete} from '@/utilities/alertsSwal';
import { ModalStars, ModalTableDetail } from '@/modals';
import instituteService from '@/api/institute.service';
// import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { InstituteProps, LocationProps } from '@/types';
import './Table.scss'
import { tokens } from '@/utilities';
import { Header } from '@/views';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// type AppDispatch = ThunkDispatch<AppStore, undefined, AnyAction>

export interface TableProps {
    active: boolean
}

export function Table(props:TableProps){

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isOn, setIsOn] = useState(props.active);
    const [paginationModel, setPaginationModel]= useState({
        pageSize: 10,
        page: 0,
    })

    const dispatch= useDispatch()

    const activeList = useSelector((store: AppStore)=> store.activeList)
    const inactiveList = useSelector((store: AppStore)=> store.inActiveList)
    
    // const [selectedInstitute, setSelectedInstitute] = useState<InstituteProps[]>([]);

    const [selectedInstituteData, setSelectedInstituteData] = useState<InstituteProps | null> (null);

	const findInstitute = (institute: InstituteProps)=> !!inactiveList.find (ies=> ies.id === institute.id)
    
	//   !! use to convert the result in a boolean

	// const filterInstitute = (institute: InstituteProps)=> inactiveList.filter(ies=> ies.id !== institute.id)
    
   
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

   
    const getInstituteData= (id:string)=> {
        if(props.active === true){
        return  activeList.find((item)=> item.id == id) || null

        } else if(props.active === false){

            return inactiveList.find((item)=> item.id == id) || null
        }
        return null
    }
    
    const handleDetailClick = (id: string) => {
        const selectedInstitute = getInstituteData(id)
          setSelectedInstituteData(selectedInstitute);
          setIsModalOpen(true);
        //   console.log("EL ID SELECCIONADO ES", id)
    };

    const handleDelete = async (id: string)=>{
        try {
            const responseAlertDelete = await alertDelete()
            if (responseAlertDelete ){
                instituteService.delete(id)
            }
        } catch (error) {
            console.error(error)
        }
    }
    // check logic in hanldeChange

    const handleChange = (institute: InstituteProps)=>{
        // const filteredInstitute = findInstitute(institute)
        // ? filterInstitute(institute)
        // :[...selectedInstitute, institute];
        // // dispatch(addToInactiveList(filteredInstitute))
        // setSelectedInstitute(filteredInstitute)
        console.log("checked", institute)
    }

    const handleClickSwitch = async (id: string) => {
        const responseAlert = await alertConfirm()
        if (responseAlert ){
             rows.map(institute =>{
                if(institute.id === id){
                    return {...institute, active: !institute.active}
                }
                return institute
            })
            setIsOn(prevState => !prevState);
        }
        // Lógica adicional según tus preferencias, utilizando rowId para identificar la fila
        if (!isOn) {
          // Realizar acciones cuando el switch cambia a "on"
          
        } else {
          // Realizar acciones cuando el switch cambia a "off"
       
        }
      };
      
    const columns = [
        props.active === false && {
            field: 'actions',
            type:'actions',
            sortable: false,
            headerName: '',
            flex: 1,
            minWidth: 50,
            renderCell: (params: GridRenderCellParams) => <>{
                <Checkbox 
                    size='small'
                    // checked={params.row.id}
                    checked={findInstitute(params.row.id)} 
                    onChange={()=>handleChange(params.row.id)}
                />
            }</>
        },
        {
            key: 'name',
            field: 'name',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 250,
            cellClassName: "name-column--cell",
            renderCell: (params: GridRenderCellParams)=><>{params.value}</>/*what we want to show in row, we are going to associate the object (in this case IES -comes from data-) and decide how to show. GridRedenderCellParams are the grid´s params  */

        },
        {
            key: 'headquarters',
            field: 'headquarters',
            headerName: 'Sede',
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams)=><>
            {params.row.headquarters.map((hq:LocationProps, index:number) => (
            <div key={index}>{hq.title}</div>
      ))    }
            </>
        },
        {
            key: 'character',
            field: 'character',
            headerName: 'Carácter',
            flex: 1,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams)=><>{params.value}</>
        },
        {
            key:'type',
            field: 'type',
            headerName: 'Tipo',
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams)=><>{params.value}</>
        },
        {
            key:'email',
            field: 'email',
            headerName: 'Email Admin',
            flex: 1,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams)=><>{params.value}</>
        },
        {
            key: 'subscriptionLevel',
            field: 'subscriptionLevel',
            headerName: 'Nivel Premium',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params: GridRenderCellParams)=>(
                <>
                <div className='icon-table'>
                    <ModalStars 
                        params={params.value}
                    />
                {/* <span className='level'>Click para ver detalles</span> */}
                </div>
                </>
            )
        },
        {
            key: 'detail',
            field: 'detail',
            headerName: 'Detalle',
            flex: 1,
            minWidth: 100,
            type:"actions",
            renderCell: (params: GridRenderCellParams)=>(
                <Box className='icon-table'  >
                    <Box sx={{
                        backgroundColor:colors.blueAccent[700],
                        borderRadius:"5px",
                        border:"none"
                    }} 
                    onClick={()=>handleDetailClick(params.row.id)}
                   >
                      
                    <Button variant="outlined" sx={{border:"none"}} >
                        <RemoveRedEyeIcon/>
                    </Button>
                    </Box>
                </Box>
            )
        },
        {
            key: 'inactive',
            field: 'Inactive',
            headerName: 'Inactivar',
            flex: 1,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams)=>(
            <>
                <ToggleButtonGroup
                    value={params.row.active}
                    exclusive
                    onChange={()=>{
                        const updatedInstitutes = rows.map(institute =>{
                            if(institute.id === params.row.id){
                                return {...institute, active: !institute.active}
                            }
                            return institute
                        })
                        addToInactiveList(updatedInstitutes)
                    }}
                >
                    
                    <ToggleButton  value={params.row.active}>
                      <label className={`switch-button ${isOn ? 'on' : 'off'}`} onClick={()=>handleClickSwitch}>
                        <input type="checkbox" checked={isOn} readOnly />
                        <span className="slider"></span>
                        </label>
                    </ToggleButton>
               
                </ToggleButtonGroup>
            </>)
        },
        props.active === false &&
        {
            key: 'delete',
            field: 'delete',
            headerName: 'Eliminar',
            flex: 1,
            renderCell: (params: GridRenderCellParams)=>(
                <div className='icon-table'>
                    <Box onClick={() => handleDelete(params.row.id)}>
                    <DeleteForeverIcon className='icon-delete'/>
                    </Box>
                </div>
            )
        },
    ].filter(Boolean) as GridColDef<InstituteProps>[]

    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const institutes = await instituteService.getAll(props.active)
                if(props.active === true){
                    dispatch(addToActiveList(institutes))
                }else{
                    dispatch(addToInactiveList(institutes))
                }
                // dispatch(addInstitutes(institutes))
            } catch (error) {
              console.error(error)  
            }
        }
        fetchData()
    },[dispatch, props.active])

    useEffect(() => {
        setIsOn(props.active);
      }, [props.active]);
      
    const rows = props.active ?activeList: inactiveList
    
  return (
    <Box sx={{
        padding:"0 30px"
    }}>
        <Header title='Tabla instituciones' subtitle='Maneja tus instituciones'/>
        <Box sx={{
            paddingBottom:"80px",
            "& .MuiDataGrid-root":{
                border:"none",
            },
            "& .MuiDataGrid-cell":{
                borderBottom:"none"
            },
            "& .name-column--cell":{
                color: colors.blueAccent[500]
            },
            "& .MuiDataGrid-columnHeaders":{
                backgroundColor: "#007FC0",
                borderBottom: "none",
                color:"#fcfcfc"
            },
            "& .MuiDataGrid-virtualScroller":{
                backgroundColor: colors.primary[400]
            },
            "& .MuiDataGrid-footerContainer":{
                borderTop: "none",
                backgroundColor: "#007FC0"
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                color: `${colors.grey[100]} !important`
            }
        }}>
            <DataGrid
                rows={rows}
                columns={columns}
                disableColumnSelector
                disableRowSelectionOnClick
                autoHeight
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row:any)=> row.id}
                pageSizeOptions={[10,15,20]}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{toolbar:GridToolbar}}
                sx={{
                    height:'100%',
                    '& .MuiDataGrid-cell:hover': {
                        color: colors.blueAccent[300],
                    },
                }}
                
                />
        </Box>
        { selectedInstituteData && ( // Mostrar el modal si está seleccionado y hay datos seleccionados
            <ModalTableDetail
                selectedInstituteData={selectedInstituteData}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} 
            />
        )}
    </Box>
  )
}

