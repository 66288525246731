import { Box, useTheme } from "@mui/material";
import { tokens, useAuth } from "@/utilities";
import { ImagesInstitute } from "./components/ImagesInstitute";
import { CarouselImagesInstitute } from "./components/CarouselImagesInstitute";
import { GeneralInfo } from "./components/GeneralInfo";
import { ContactInfoInstitute } from "./components/ContactInfoInstitute";
import { FinancialSupportInstitute } from "./components/FinancialSupportInstitute";
import { SocialMediaInstitute } from "./components/SocialMediaInstitute";

export default function UpdateInstitute() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user, institute } = useAuth();

  const isEditable = (user?.subscriptionLevel ?? 0) > 0;

  const premiumPlan =
    "Para poder acceder a los campos deshabilitados,por favor, dirigete a nuestros planes y adquiere uno";

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.background.primary,
        padding: "0 30px 40px 30px",
        minHeight: "400px",
        overflowY: "auto",
      }}
    >
      <ImagesInstitute />

      <CarouselImagesInstitute
        institute={institute}
        isEditable={isEditable}
        premiumPlan={premiumPlan}
      />

      <GeneralInfo
        institute={institute}
        isEditable={isEditable}
        premiumPlan={premiumPlan}
      />

      <ContactInfoInstitute
        institute={institute}
        isEditable={isEditable}
        premiumPlan={premiumPlan}
      />

      <SocialMediaInstitute
        institute={institute}
        isEditable={isEditable}
        premiumPlan={premiumPlan}
      />

      <FinancialSupportInstitute
        institute={institute}
        isEditable={isEditable}
        premiumPlan={premiumPlan}
      />
    </Box>
  );
}
