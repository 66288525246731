// export const currencies = [
//     "Peso colombiano : COP",
//     "Dólar estadounidense : USD",
//     "Euro : EUR",
//     "Peso argentino : ARS",
//     "Peso chileno : CLP",
//     "Peso cubano : CUP",
//     "Peso dominicano : DOP",
//     "Peso mexicano : MXN",
//     "Peso uruguayo : UYU",
//     "Bolívar venezolano : VES",
//     "Sol peruano : PEN",
//     "Quetzal guatemalteco : GTQ",
//     "Lempira hondureño : HNL",
//     "Córdoba nicaragüense : NIO",
//     "Balboa panameño : PAB",
// ]
export const currencies = [
    "COP",
    "USD",
    "ARS",
    "CLP",
    "CUP",
    "DOP",
    "MXN",
    "UYU",
    "VES",
    "PEN",
    "GTQ",
    "HNL",
    "NIO",
    "PAB",
]