import { Cancel } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from '@mui/material';
import  { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './crop/CropImages';
import Swal from 'sweetalert2';

const zoomPercent = (value:number) => {
  return `${Math.round(value * 100)}%`;
};

interface CropImageProps {
  logo: string
  setOpenCrop: (open: boolean) => void
  setLogo: (url: string) => void
  setFile: (file: File) => void
}

type CropArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type CropAreaPixels = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type CropResult = {
  file: File;
  url: string;
};
export const CropEasy = ({ logo, setOpenCrop, setLogo, setFile }: CropImageProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropAreaPixels | null>(null);


  const cropComplete = (_croppedArea:CropArea, croppedAreaPixels:CropAreaPixels | null) => {
    setCroppedAreaPixels(() => {
      if (croppedAreaPixels) {
        return croppedAreaPixels;
      } else {
        return null;
      }
    });
  };

  const cropImage = async () => {
    try {
      if (!croppedAreaPixels) return;
      const result:CropResult | null= await getCroppedImg(
        logo,
        croppedAreaPixels,
        rotation
      );
      if(result){
        setLogo(result.url);
        setFile(result.file);
        setOpenCrop(false);
        console.log(result.url)
        console.log(result.file)
      }
    } catch (error) {
      Swal.fire({
        title: "Hubo un error cargando la imagen",
        icon: "info",
        footer: `Por favor, intenta otra vez`,
        timer: 2000,
    });
      console.log(error);
    }
  };

  
  return (
    <Box sx={{
       display:"flex",
       flexDirection:"column",
       height:"auto",

    }}>
      <DialogContent
        dividers
        sx={{
          background: '#333',
          position: 'relative',
          height: 400,
          width: 'auto',
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={logo}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <Box sx={{ width: '100%', mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              color='secondary'
              onChange={(_, zoom) => setZoom(zoom as number)}
            />
          </Box>
          <Box>
            <Typography>Rotación: {rotation + '°'}</Typography>
            <Slider
              color='secondary'
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(_, rotation) => setRotation(rotation as number)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent:"center",
            gap:2
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Guardar 
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
};


