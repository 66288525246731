// # types/geoPoint.type.ts

// export interface GeoPointProps {

/**
 * An immutable object representing a geographic location in Firestore. The
 * location is represented as latitude/longitude pair.
 *
 * Latitude values are in the range of [-90, 90].
 * Longitude values are in the range of [-180, 180].
 */
export interface GeoPointPropsOld {
    latitude: number,
    longitude: number,

}

export class GeoPointProps {
    /**
     * The number of seconds of UTC time since Unix epoch 1970-01-01T00:00:00Z.
     */
    latitude: number;
    /**
     * The fractions of a second at nanosecond resolution.*
     */
    longitude: number;
    /**
     * Creates a new immutable `GeoPoint` object with the provided latitude and
     * longitude values.
     * @param latitude - The latitude as number between -90 and 90.
     * @param longitude - The longitude as number between -180 and 180.
     */
    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    toJSON() {
        return {
            latitude: this.latitude,
            longitude: this.longitude
        }
    }
}

// export type GeoPointProps = {
//     latitude: number;
//     longitude: number;
//     toJSON(): { latitude: number; longitude: number };
//   };

//   export class GeoPoint {
//     constructor(public latitude: number, public longitude: number) {}
  
//     toJSON(): { latitude: number; longitude: number } {
//       return { latitude: this.latitude, longitude: this.longitude };
//     }
//   }