import React from 'react'
import TuneIcon from '@mui/icons-material/Tune';
import { Button,Typography,Modal, Box, useTheme } from '@mui/material';
import { RangeInput, RefinementList, ToggleRefinement } from 'react-instantsearch';
import { PanelFilters } from '@/components';
import '@/components/Professors/ProfessorList.scss'
import { tokens } from '@/utilities';

export const ModalProfessorFilters = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

   
  return (
    <Box>
            <Button variant="contained" sx={{gap:"10px", padding:"5.8px 15px"}} onClick={handleOpen}>Filtros <TuneIcon className="icon-filter"/></Button>
            

        <Modal
        keepMounted
        open={open}
        onClose={handleClose}

      >
        <Box sx={{
          backgroundColor:colors.primary[400],
          width: "350px",
          maxHeight:"90vh",
          position: 'absolute',
          top: 0,
          left: '2.5%',
          p: 4,
          borderRadius: '10px',
          display: 'flex',
          flexWrap: 'wrap',}} >
          <Button onClick={handleClose} sx={{position:"absolute", fontWeight:"bolder", fontSize:"larger", color:"#3F51B5", ":hover":{background:"#3F51B5", color:"#FAFBFB"}}}>X</Button>
          <Typography variant="h3" sx={{display: "flex", width:'100%',height:'50px', justifyContent:"center", alignItems:"center", boxShadow:"0px 1px 0px rgba(0, 0, 0, 0.1)", color:"#3F51B5"}}>
            Filtros 
          </Typography>

        <Box className='container-filters'>
              <PanelFilters header="Años de Experiencia">
                    <RangeInput     
                        attribute="seniority" 
                        precision={0}
                        // min={0}
                        style={{background:""}}
                        translations={{
                          separatorElementText: '-',
                          submitButtonText: 'Filtrar',
                        }}
                        classNames={{
                          root:"range-box",
                          submit:"btn-range",
                          form: "form-range",
                          input:"range-input"
                        }} 
                    />
              </PanelFilters>

              {/* <PanelFilters header=" Áreas de Interés">
                    <RefinementList
                        attribute="areasOfInterest"
                        searchable={true}
                        searchablePlaceholder="Buscar Áreas de Interés"
                        showMore={true}
                        limit={5}
                        translations={{
                          showMoreButtonText({ isShowingMore }) { 
                            return isShowingMore ? 'Mostrar menos' : 'Mostrar más';
                          },
                        }}
                        classNames={{
                          root:"refinement-list-box",
                          label:"refinement-label", 
                          count:"count-number-refinementList-level",
                          item:"item-refinementList"
                        }}
                    />
              </PanelFilters> */}

              {/* <PanelFilters header="Modalidad de las clases">
                    <Menu 
                      attribute="categories" 
           
                    />
              </PanelFilters> */}

              <PanelFilters header="Nivel académico">
                    <RefinementList 
                       attribute="personalData.educationLevel" 
                       classNames={{
                        count:"count-number-refinementList",
                        item:"item-refinementList"
                       }}
                      
                    />
              </PanelFilters>

              <PanelFilters header="Género">
                    <RefinementList 
                       attribute="personalData.gender" 
                       classNames={{
                        count:"count-number-refinementList-filter",
                        root:"root-refinementList-filter",
                        list:"list-refinementList-filter",
                        item:"item-refinementList"
                       }}
                      
                    />
              </PanelFilters>

              <PanelFilters header="País de residencia">
                    <RefinementList 
                       attribute="personalData.location.country" 
                       classNames={{
                        count:"count-number-refinementList-filter",
                        root:"root-refinementList-filter",
                        item:"item-refinementList",
                        list:"list-refinementList-filter",

                       }}
                      
                    />
              </PanelFilters>

              <PanelFilters header="Ciudad de recidencia">
                    <RefinementList 
                       attribute="personalData.location.city" 
                       classNames={{
                        count:"count-number-refinementList-filter",
                        root:"root-refinementList-filter",
                        item:"item-refinementList",
                        list:"list-refinementList-filter",

                       }}
                      
                    />
              </PanelFilters>

              <PanelFilters header="Disponibilidad de reubicación">
                    <ToggleRefinement
                            attribute="available"
                            label=""
                            // on={relocate}
                            // off={!relocate}
                            // onClick={()=>setRelocate(!relocate)}
                            classNames={{
                              root: 'toggle-refinement',
                              label: 'slider-refinement',
                              checkbox: 'toggle-input',
                            }}
                    />
              
              </PanelFilters>
        </Box>

        </Box>
      </Modal>
    </Box>
  )
}
