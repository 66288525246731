export const convertBase64ToFile = (
    data: { url: string } | { url: string }[]
  ): File[] | File => {
    // Verificar si los datos son un arreglo
    const isArray = Array.isArray(data);

    // Función auxiliar para procesar un solo objeto
    const processData = (item: { url: string }): File => {
      // Extraer metadatos y datos Base64 de la URL
      const [metadata, base64Data] = item.url.split(",");

      // Extraer el tipo MIME de los metadatos (e.g., "image/png")
      const mimeType = metadata.match(/:(.*?);/)?.[1] || "";
      if (!mimeType) {
        console.warn("No se pudo determinar el tipo MIME de los metadatos.");
        throw new Error("Tipo MIME inválido"); // Puedes personalizar el manejo de errores
      }

      // Decodificar la cadena Base64 a un array de bytes
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Crear un Blob (objeto binario) con el tipo MIME especificado
      const blob = new Blob([byteArray], { type: mimeType });

      // Extraer la extensión del tipo MIME (e.g., "png")
      const extension = mimeType.split("/")[1] || "bin";

      // Crear un nombre de archivo (puedes personalizarlo)
      const filename = `file.${extension}`;

      // Crear un objeto File a partir del Blob y el nombre de archivo
      return new File([blob], filename);
    };

    if (isArray) {
      return data.map(processData);
    } else {
      return processData(data);
    }
  }