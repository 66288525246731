import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, createUserWithEmailAndPassword, onAuthStateChanged, UserCredential, updateProfile, sendEmailVerification, deleteUser, signOut } from "firebase/auth";
import { ImageProps, UserAdminProfile, UserAuthenticated, UserData } from "@/types";
import { User } from "@/types";
import {  collection, doc,setDoc, getDoc, getDocs, query, where, updateDoc,  serverTimestamp } from "firebase/firestore";
import { firestore, storage } from "@/config/firebase.config";
import { StorageReference, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { calculateSize, mapAuthCodeToMessage } from "@/utilities";
import Swal from "sweetalert2";
import { FirebaseError } from "firebase/app";
// import bcrypt from 'bcrypt'

const auth = getAuth();
const currentUser = auth.currentUser
const collectionName = "users/{0}"


const storageName = "professors/{0}/{name}"
const metaDataMIME = {
    contentType: 'image/jpeg'
}
const MAX_WIDTH = 320;
const MAX_HEIGHT = 300;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

class UsersAdmin {

    async getUserAuthData(){
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userData ={
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL,
                    emailVerified: user.emailVerified
                }
                
                 return userData   
                
            }else{
                console.log("User is not logged")
            }
        })
    }

    async resendVerificationEmail(user:any){
        console.log(user)
            await sendEmailVerification(user)
        
    }

    async  signUpAdmin (userAuth:User){
       
        try {
           const userCredential = await createUserWithEmailAndPassword(auth, userAuth.email, userAuth.password)
            if(userCredential){
                const uid = userCredential.user.uid
                await sendEmailVerification(userCredential.user);
                return uid 
            }
        } catch (error) {
            console.error('Error creating user:', error);
            if (error instanceof FirebaseError) {
                console.log(error.code)
                const errorMessage = mapAuthCodeToMessage(error.code);
                Swal.fire({
                  title:errorMessage,
                  icon: 'error',
                  footer: 'Intenta otra vez',
                  timer: 4000,            
              })
              }
            return null;
        }
    
    }

     async login (user: UserAuthenticated): Promise<UserCredential>{
        await setPersistence(auth, browserSessionPersistence)
        return signInWithEmailAndPassword(auth, user.email, user.password)
    }

    async logOut(){
        signOut(auth)
        .then(()=>{
            console.log("sign-out successful")
        })
        .catch((error)=>{
            console.error(error)
        })
    }
    
    //definir los datos que se van a modificar // displayname y photo
    async update (displayName: string, photoURL:string){
        if (currentUser){
            updateProfile(currentUser, {
                displayName:displayName,
                photoURL:photoURL
            })
            .then(()=>{
                console.log('Profile updated')
            })
            .catch(error=>{
                console.error('An error ocurred', error)
            })
        }else{
            console.error('User doesnt exist')
        }
    }

    // async emailVerification (){
      
    //     try {
    //         const user = auth.currentUser
    //         console.log(user)
    //         if(user){
               
    //         }else{
    //             console.error('No se pudo obtener el usuario actual.');
    //         }
    //     } catch (error) {
    //         console.error('Error al enviar la verificación por correo:', error);
    //     }

    // }

    // async recoverPassword (email:string){

    //         await sendPasswordResetEmail(auth, email)
    //         .then(()=>{
    //             console.log('Password reset email sent!')
    //         })
    //         .catch((error) => {
    //             console.error(error.message)
    //           });

    // }

    async deleteUser(){
        if(currentUser){
          await deleteUser(currentUser)
            .then(() => {
                // User deleted.
              }).catch((error) => {
                // An error ocurred
                console.error(error.message)              
            });
        }
    }

    async saveUserProfile(profile: UserAdminProfile, uid:string): Promise<boolean>{
       

        if(!uid){
            console.log("Id no encontrado")
            return Promise.resolve(false)
        } 
        try {
            const collectionName = "users";
            await setDoc(doc(firestore, collectionName, uid), profile)
            return Promise.resolve(true);
        } catch (error) {
            console.error("Error in UserAdminService.saveUser", error)
            return Promise.resolve(false);
        }
    }

    async getByEmail(email:string):Promise<UserAdminProfile | null>{
        const collectionRef = collection(firestore,collectionName.replace("{0}", ""))

        const q = query(collectionRef, where("email", "==", email))
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
            return null; 
        }

        const user=querySnapshot.docs[0].data() as UserAdminProfile
        return user
    }

    async getUserFromFirebase(uid:string):Promise<UserData | null>{
        const docRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           return docSnap.data() as UserData
         
          
        } else {
          console.log("No such document!");
          return null
        }
    }

    async getUsersAdmins():Promise<UserAdminProfile[]>{
        
        const collectionRef = collection(firestore,"users")
        const q = query(collectionRef, where("role", "in",["Super Administrador", "Profesor","Administrador IES", "Administrador Facultad"] ))
        const querySnapshot = await getDocs(q)

        const users: UserAdminProfile[] = querySnapshot.docs.map((doc)=>{
            return doc.data() as UserAdminProfile
        })
        return users
    }

    async getUsersAdminFaculty(instituteId: string):Promise<UserAdminProfile[]>{
        const collectionRef = collection(firestore,"users")
        try{
            const q = query(collectionRef, where("role", "in", ["", "Administrador IES", "Administrador Facultad"]), where("institute", "==", instituteId))
            const querySnapshot = await getDocs(q)
            const users: UserAdminProfile[] = querySnapshot.docs.map((doc)=>{
                return doc.data() as UserAdminProfile
            })
            return users
        }catch(error){
            console.error(error)
            return[]
        }
    }

    async updateActiveStatus(adminID:string, value:boolean):Promise<boolean>{
        const collectionRef = collection(firestore,"users")
        const docRef = doc(collectionRef,adminID)
        if (docRef == null) {
            console.error(`Error updating: users/${adminID}`);
            console.error(" Doesn't find this user ");
            return false;
        }
        try {
           await updateDoc(docRef, {
                updatedAt: serverTimestamp(),
                active:value
            });// Add a new document with a generated id.
            return true;

        } catch (error) {
            console.error(`Error updating: users/${adminID}`);
            console.error(" Doesn't find this user ");
            return false;
        }


    }

    async updateUserData(adminID:string, data:UserAdminProfile):Promise<boolean>{
        const collectionRef = collection(firestore,"users")
        const docRef = doc(collectionRef,adminID)
        if (docRef == null) {
            console.error(`Error updating: users/${adminID}`);
            console.error(" Doesn't find this user ");
            return false;
        }
        try {
           await updateDoc(docRef, {
                ...data,
                updatedAt: serverTimestamp(),
            });// Add a new document with a generated id.
            return true;

        } catch (error) {
            console.error(`Error updating: users/${adminID}`);
            console.error(" Doesn't find this user ");
            return false;
        }


    }


    async updateProfileImage(professorId:string, image: File):Promise<ImageProps | null>{
        try {
        const fileStorageRef = storageName
        .replace("{0}", professorId)
        .replace("{name}", image.name);
        
        const storageRef = ref(storage, fileStorageRef);

            const uploadedImage = await this.optimizedImage(storageRef, image)
            Swal.fire({
                title:"Imagen subida exitosamente",
                icon:"success",
                timer:2000
            })

            const collectionRef = collection(firestore,"professors")
            const docRef = doc(collectionRef, professorId)       

            const updateData = {
                'photoUrl': uploadedImage.url
            }
            await updateDoc(docRef, updateData)
            return uploadedImage
        } catch (error) {
            console.error('Error uploading image:', error);
            Swal.fire({
                title:"Hubo un error cargando la imagen, intente nuevamente",
                icon:"error",
                timer:2000
            })

        }

        return null
    }
    async optimizedImage(storageRef: StorageReference, file:File): Promise<ImageProps>{
        return new Promise<ImageProps>((resolve, reject)=>{
            const img = new Image();
            img.src= URL.createObjectURL(file)
            img.onerror= function(){
                URL.revokeObjectURL(this.src);
                reject(new Error("Cannot load image"))
            }
            img.onload = function(){
                URL.revokeObjectURL((this as HTMLImageElement).src)

                // calculate new width and height
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT); 
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight

                const ctx =  canvas.getContext("2d")
                ctx?.drawImage(img, 0, 0, newWidth, newHeight)

                //convert canvas to blob with indicated quality

                canvas.toBlob(
                    (blob)=>{
                        if(!blob){
                            reject(new Error("Error compressing image"));
                            return;
                        }

                        //upload Blob to Firebase Storage
                        const uploadTask = uploadBytesResumable(storageRef, blob, metaDataMIME);
                        uploadTask.on(
                            'state_changed',
                            // (snapshot)=>{},
                            null,
                            (error)=>{
                                console.log(error)
                                reject(new Error("Error uploading compressed image"));
                            },
        
                            async()=>{
                                try {
                                     const downloadURL= await getDownloadURL(uploadTask.snapshot.ref)
                                   
                                    // console.log('File available at', downloadURL);
                                    const compressedImageProps : ImageProps = {
                                        url: downloadURL
                                    }

                                    resolve(compressedImageProps)  
                                } catch (error) {
                                    console.error('Error getting download URL:', error);
                                    reject(new Error("Error getting download URL"));
                                }
                            }
                        )
                        
                    },
                    MIME_TYPE,
                    QUALITY
                )
            }
        })
    }

    // async recoverPassword(uid:string, newPassword: string){
    //     const queryStrings = new URLSearchParams(window.location.search);
    //     const getParams = Object.fromEntries(queryStrings.entries())
    //     const tokenEncrypted = getParams.token
    //     const validToken = await bcrypt.compare(uid, tokenEncrypted)
    //     if(validToken){
    //         await updateProfile (uid,{
    //             password: newPassword
    //         })
    //     }else{
    //         console.log("hubo un error en el cambio de contraseña")
    //     }
    // }

//    async createAdminUser(userAdmin:CreateUserAdmin):Promise<string>{
//     try{
        
//        const userRecord = await admin.auth().createUser({
//             email: userAdmin.email,
//             emailVerified: false,
//             phoneNumber:userAdmin.phoneNumber,
//             password: generateRandomPassword(8),
//             displayName: userAdmin.displayName,
//             photoURL: "",
//             disabled: false,
//         })
//         console.log('Successfully created new user:', userRecord.uid);
//         return userRecord.uid
       
//      }catch (error) {
//         console.error('Error creating new user:', error);
//         return ""
//      }
//     }
}

export default new UsersAdmin();