// import { Box } from '@mui/material';
import { TopProgramsByInstitute, TopRanking, UserDevice, UsersByRegion } from '@/types';
import { PDFViewer, Font} from '@react-pdf/renderer';
// import domtoimage from 'dom-to-image'
// import html2canvas from 'html2canvas';
// import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '.';
import { ReportPDF } from '@/components/Report/pdfReport';


Font.register({family:"Roboto", src:"https://fonts.googleapis.com/css2?family=Roboto"})

  
    // const getTopCareersByInstitute = 
    //  [
    //   {
    //     career_name: "Ingeniería Ambiental",
    //     institute_name: null,
    //     total: 9,
    //     rank: 1,
    //   },
    //   {
    //     career_name: "Maestría en Políticas Públicas",
    //     institute_name: "Universidad Nacional de Colombia",
    //     total: 5,
    //     rank: 2,
    //   },
    //   {
    //     career_name: "Especialización en Patología y Cirugía Bucal",
    //     institute_name: "Pontificia Universidad Javeriana",
    //     total: 5,
    //     rank: 2,
    //   },
    // ]
    

    // let geoUrl
    // const geoChart = document.getElementById('GeoChartContainer');
    // // if(geoChart){
    // //   const blob = await domtoimage.toBlob(geoChart);
    // //   geoUrl = URL.createObjectURL(blob);
      
    // // }

    // const newUrl = geoUrl && `http://localhost:8080/${geoUrl.slice(27)}`
    // console.log(geoUrl)
    // console.log(newUrl)

    // console.log(geoUrl)
    // if(geoChart){
    //   domtoimage.toPng(geoChart)
    // .then(function (dataUrl) {
    //     var img = new Image();
    //     img.src = dataUrl;
    //     document.body.appendChild(img);
    // })
    // .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    // });
    // }

    // let geoImage:any

    // export async function svgToPdf(container:HTMLElement){

    //   const svg = container.querySelector("svg")
    //   if(svg){
    //     const { width, height } = svg.getBBox();
    //     const doubleWidth = width 
    //     const doubleHeight = height 
    //     const canvas = document.createElement("canvas");
    //     canvas.width = doubleWidth;
    //     canvas.height = doubleHeight;
    //     const ctx = canvas.getContext("2d");   
      
    //     const img = new Image(doubleWidth, doubleHeight)
    //     const svgBlob = new Blob([svg?.outerHTML], {
    //       type: "image/svg+xml;charset=utf-8"
    //       // type: "image/png"
    //     })
    //     const URL = window.URL || window.webkitURL || window;
    //     const svgUrl = URL.createObjectURL(svgBlob);
    //     img.src = svgUrl;
    //     img.addEventListener("load", async () => {
    //       if(ctx){
    //         ctx.fillStyle = "white";
    //         ctx.fillRect(0, 0, canvas.width, canvas.height);
    //         ctx.drawImage(img, 0, 0, doubleWidth, doubleHeight);
    //         const png = await canvas.toDataURL();
    //         console.log("LA IMAGNNNNNN", png)
    //         const pngImageBytes = await fetch(png).then((res) => res.arrayBuffer());
    //         console.log("BUfeeeeer", pngImageBytes)
    //         const data = new Uint8Array(pngImageBytes);
    //         console.log("DATAAAAAAAAA UIN8", data)
    //         const pngBlob = new Blob([data], {
    //           type: 'image/png'
    //         });
    //         console.log("BLOOOOOOOB", pngBlob)
    //         geoImage = {png};
    //         URL.revokeObjectURL(svgUrl);
    //         return png;
    //       }
    //     });
    //     img.addEventListener("error", (error) => {
    //       URL.revokeObjectURL(svgUrl);
          
    //       throw new Error(error.message);
    //     });
    //   }
    // }

    // export async function PngToPdfDownload(png:string) {

    //   console.log("photoo 64", png);
    //   const pngImageBytes = await fetch(png).then((res) => res.arrayBuffer());
    // console.log("photoo bytess",pngImageBytes)
    //   const data = new Uint8Array(pngImageBytes)
    //   geoImage = {pngImage:data}
      // const pdfDoc = await PDFDocument.create();
    
      // const pngImage = await pdfDoc.embedPng(pngImageBytes);
      // const pngDims = pngImage.scale(0.25);
    
      // const page = pdfDoc.addPage();
    
      // page.drawImage(pngImage, {
      //   x: page.getWidth() / 2 - pngDims.width / 2,
      //   y: page.getHeight() / 2,
      //   width: pngDims.width,
      //   height: pngDims.height
      // });
    
      // const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      // const { width, height } = page.getSize();
      // const fontSize = 30;
      // page.drawText(
      //   "Creating PDFs in JavaScript is awesome! Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!vCreating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome!Creating PDFs in JavaScript is awesome! Creating PDFs in JavaScript is awesome! Creating PDFs in JavaScript is awesome!",
      //   {
      //     x: 50,
      //     y: height - 4 * fontSize,
      //     size: fontSize,
      //     font: timesRomanFont,
      //     color: rgb(0, 0.53, 0.71)
      //   }
      // );
    
      // const pdfBytes = await pdfDoc.save();
      // download(pdfBytes, "export.pdf", "application/pdf");
    // }
    
  
    export const TemplateReport = ({imgBase64, imgDevice}:any) => {

      const {user} = useAuth()
      console.log("Template Report USER", user)
      const [getTopCareers, setGetTopCareers] = useState<TopRanking[]>([])
      const [getTopCareersByInstitute, setGetTopCareersByInstitute] = useState<TopProgramsByInstitute[]>([])
      const [usersByRegion, setUsersByRegion] = useState<UsersByRegion[]>([])
      const [userDevice, setUserDevice] = useState<UserDevice[]>([])
      const [redirects, setRedirects] = useState<any[]>([])

      const totalUsersCount =  usersByRegion.reduce((total, region) => total + region.total_user_count, 0);

      const totalEvents = userDevice.reduce((total,device)=> total + device.event_count, 0)

      const promEventsByUser =  totalUsersCount !== 0 ? Math.ceil(totalEvents / totalUsersCount) : 0;

      function percentageByDevice(data: UserDevice[]): Record<string, number> {
        const totalEvents = data.reduce((total, device) => total + device.event_count, 0);
        const percentages: Record<string, number> = {};
        data.forEach((device) => {
          const percentage = parseFloat(((device.event_count / totalEvents) * 100).toFixed(2))
          percentages[device.device_category] = percentage;
        });
      
        return percentages;
      }
      const percentages = percentageByDevice(userDevice);


      
      const currentDate = new Date();
      // Obtener la fecha de 30 días atrás
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      // Formatear las fechas en el formato deseado (por ejemplo, DD/MM/AAAA)
      const formatDate = (date:any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      };
      const formattedCurrentDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

      // Crear la cadena de texto con las fechas
      const reporte = `~ Reporte descargado por: ${user?.user.displayName} ${formattedCurrentDate} . Analítica del: ${formatDate(thirtyDaysAgo)} al ${formatDate(currentDate)}~`;

      const clicksRedirect = redirects.length
      const userRegion = usersByRegion
      const data = {
        "getTopCareers": getTopCareers,
        "getTopCareersByInstitute": getTopCareersByInstitute,
        "reporte": reporte,
        "connection": {
          "imgDevice":imgDevice,
          "userDevice":userDevice,
          "promEventsByUser": promEventsByUser,
          "clicksRedirect":clicksRedirect,
          "percentages":percentages

        },
        "usersByRegion": userRegion,
        "imgBase64": imgBase64
      }
      useEffect(()=>{
          try {
            const topCareersSession = sessionStorage.getItem("topCareers")
            if(topCareersSession){
              setGetTopCareers(JSON.parse(topCareersSession))
            }

            const topProgramsSession = sessionStorage.getItem("programsByInstitutes")
            if(topProgramsSession){
              setGetTopCareersByInstitute(JSON.parse(topProgramsSession))
            }

            const usersByRegionSession = sessionStorage.getItem("usersByRegionSession")
            if(usersByRegionSession){
              setUsersByRegion(JSON.parse(usersByRegionSession))
            }

            const userDeviceSession = sessionStorage.getItem("deviceConnection")
            if(userDeviceSession){
              setUserDevice(JSON.parse(userDeviceSession))
            }

            const redirectsInstitute = sessionStorage.getItem("redirectsByInstitute")
            if(redirectsInstitute){
              setRedirects(JSON.parse(redirectsInstitute))
            }

          } catch (error) {
            console.error(error)
          }
      },[])

    return    (
        <PDFViewer style={{width:"100%", height:"100%", minHeight:"70vh", display:"flex", flexDirection:"column", flexWrap:"wrap"}}>
          <ReportPDF data={data}/>
      </PDFViewer>
       
  )
    // }

  }
//   <Document>
//   {/* Header */}
//   <Page size="A4" style={styles.body}>
      
//       <Text style={styles.headerText} fixed>
//       {reporte}
//       </Text>


//       <View  style={styles.headerContent}>

//         <View style={styles.leftSection}>
//           <ImagePDF
//               style={styles.image}
//               src={logo}
//           />
        
//           <View >
//             <Text style={styles.titleLogoUp}>Guía</Text>
//             <Text style={styles.titleLogoDown}>Universitaria</Text>
//           </View>

//         </View>

//         {/* <View style={styles.rightSection}>
//         <View style={{display:"flex", justifyContent:"flex-end", textAlign:"right"}}>
//             <Text style={styles.titleLogoUp}>Guía Universitaria</Text>
//             <Text style={styles.crendentials}>Bogotá D.C., Colombia</Text>
//             <Text style={styles.crendentials}>(+57) 300 3621500</Text>
//             <Text style={styles.crendentials}>Soporte@guiauniversitaria.net</Text>
//             <Text style={styles.crendentials}>Https://guiauniversitaria.net/</Text>

//           </View>
//         </View> */}
//       </View>
      

//     <View style={styles.sectionOne}>
//       <View style={{display:"flex", flexDirection:"row",gap:"20px", alignItems:"baseline", marginBottom:"20px"}}>
//         <Text style={styles.subtitle}>Top de carreras más buscadas</Text>
//         {/* <Text style={styles.note}>(El orden mostrado no representa un ranking específico.)</Text> */}
//       </View>

//       <View  style={{display:"flex", gap:"5px"}} render={() => (
//         getTopCareers.map((career, index)=>(
          
//           <View  style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
//             <Text style={styles.tables}>{`${index + 1}.`}</Text>
//             <Text style={styles.tables}>{career.career_name}</Text>
//           </View>
//         ))
//       )}  />
//     </View>

//     <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
//   `${pageNumber} / ${totalPages}`
// )} fixed />
//     </Page>


//       {/* -------------------------------Second Page--------------------------- */}
//     <Page size="A4" style={styles.body}>
//     <Text style={styles.headerText} fixed>
//       {reporte}
//       </Text>


//     <View style={styles.section}>
//       <View style={{display:"flex", flexDirection:"column",gap:"0", alignItems:"baseline", margin:"10px 0"}}>
//         <Text style={styles.subtitle}>Top 3. Carreras más buscadas en tu institución</Text>
//         {/* <Text style={styles.note}>(El orden mostrado no representa un ranking específico.)</Text> */}
//       </View>

//       <View  style={{display:"flex", gap:"10px"}} render={() => (

//       getTopCareersByInstitute.flat().length>0 
//       ? getTopCareersByInstitute.map((career, index)=>(
          
//           <View  style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between"}}>
//             <Text style={styles.tables}>{`${index + 1}.`}</Text>
//             <Text style={styles.tables}>{career.career_name}</Text>
//           </View>
//         ))
//         :<Text style={styles.text} >Aún no registramos datos en la base de datos</Text>
//       )}  />
//     </View>


//     <View style={styles.section}>
//         <View style={{display:"flex", flexDirection:"row",gap:"20px", alignItems:"baseline", marginBottom:"20px", zIndex:10}}>
//           <Text style={styles.subtitle}>Conexiones de los usuarios por dispositivo</Text>
//         </View>
//         <View style={{...styles.container}}>
//           <View style={{display:"flex"}} >
//             <ImagePDF src={imgDevice} style={{display:"flex",transform:"scale(0.3)",marginBottom:-150, marginTop:-150, marginLeft:-220,  zIndex:-10, padding:0 }}/>

//           </View>

//           <Text style={styles.rightAbsolute} >En el período analizado, los {userDevice[0] && deviceCase(userDevice[0].device_category)} son los preferidos por los usuarios para realizar la búsqueda de las carreras universitarias {userDevice[0] && percentages[userDevice[0].device_category]}%.
//                   {/* {joinDevice}. */}
//           </Text>

//           {/* <Text style={{...styles.rightAbsolute, top:80}}>
//            El tiempo promedio de conexión es de (X) minutos desde computador y de (X) cuando lo realiza desde dispositivos móviles.
//           </Text> */}

//           <Text style={styles.text} >El usuario realiza en promedio {promEventsByUser} interacciones en la plataforma.</Text>

//           <Text style={{...styles.rightAbsolute, top:70}}>Desde nuestro portal registramos {clicksRedirect} clic de redirección al web site de la universidad.
//           </Text> 

//         </View>

//     </View>
//     <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
//   `${pageNumber} / ${totalPages}`
// )} fixed />

    
//     <View style={styles.section}>
//         <View style={{display:"flex", flexDirection:"row",gap:"20px", alignItems:"baseline", marginBottom:30}}>
//           <Text style={styles.subtitle}>Usuarios por país</Text>
//         </View>
//         <ImagePDF src={imgBase64} />
//     </View>
//       </Page>
//     {/* -----------------------------Third Page ------------------------*/}
    

      
//     <Page size="A4" style={styles.body} >
//     <Text style={styles.headerText}  fixed>
//       {reporte}
//       </Text>
    
//     <View style={styles.section}>
//         <View style={{display:"flex", gap:"10px", marginTop:"20px"}} render={() => (
          
//           usersByRegion.map((region, index)=>(
//             <View  style={{backgroundColor:"#DDDCDC", display:"flex", flex:1}} key={index}>
//             <Text  style={{...styles.tables, fontFamily: 'Helvetica-Bold', fontSize:10, marginLeft:20, marginTop:20}} >{region.country}</Text>
//             <View  style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-around"}}>
//               <Text style={{...styles.tables, fontSize:10}} >{`Total: ${region.total_user_count}`}</Text>

//               <View style={{width:"40%"}} render={()=>(

//                 region.cities.map((city, index)=>(
//                   <View  style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", padding:30}} key={index}>
//                     <Text  style={{...styles.tables, fontFamily: 'Helvetica-Bold', fontSize:10}}>{city.city? city.city : "Other"}</Text>
//                     <Text  style={{...styles.tables, marginLeft:"20px", fontSize:10}}>{`${city.city_user_count}`}</Text>
//                   </View>
//                 ))

//               )}>
//               </View>
//             </View>
//             </View>
//           ))
          
//         )}  />
      

//     </View>

       

// {/* <ImagePDF
//           style={{ border:"2px solid blue", justifyContent:"center", alignItems:"center", display:"flex"}}
//           source={{data:geoImage, format: 'png'  || 'jpg' }}
//       /> */}
// {/* <ImagePDF src={{ uri: geoImage, method: 'GET', body: '', headers: {} }} cache={false} style={{ border:"2px solid blue", justifyContent:"center", alignItems:"center", display:"flex"}}/> */}
      
//   <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
//   `${pageNumber} / ${totalPages}`
// )} fixed />
//   </Page>
//   </Document>