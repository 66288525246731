export const plans  = [
    {
        name: "Free",
        benefit: true,
        benefit1: true,
        benefit2: true,
        benefit3: true,
        benefit4: false,
        benefit5: false,
        benefit6: false,
        benefit7: false,
        benefit8: false,
        benefit9: false,

    },
    {
        name: "Silver",
        benefit: true,
        benefit1: true,
        benefit2: true,
        benefit3: true,
        benefit4: true,
        benefit5: false,
        benefit6: false,
        benefit7: false,
        benefit8: false,
        benefit9: false,

    },
    {
        name: "Premium",
        benefit: true,
        benefit1: true,
        benefit2: true,
        benefit3: true,
        benefit4: true,
        benefit5: true,
        benefit6: true,
        benefit7: true,
        benefit8: true,
        benefit9: true,
    }
]

export const plansText=[
    {
        Free:[
            "Exhibición de oferta académica",
            "Click de redirección a sitio web de la institución",
            "Click de redirección a programas seleccionados",
            "Analítica limitada"
        ],
        Silver:[
            "Exhibición de oferta académica",
            "Click de redirección a sitio web de la institución",
            "Click de redirección a programas seleccionados",
            "Analítica completa",
            "Acceso limitado a la base de datos de potenciales profesores",
            "Facultad para crear administradores"
        ],
        Premium:[
            "Exhibición de oferta académica",
            "Click de redirección a sitio web de la institución",
            "Click de redirección a programas seleccionados",
            "Acceso completo a la base de datos de potenciales profesores",
            "Facultad para crear administradores",
            "Resumen analítica de datos",
            "Analítica de datos detallada",
            "Analítica comparativa",
            "Recomendaciones estratégicas para propuestas de captación",
            "Recomendaciones de innovación de oferta académica"
        ]
    }
]