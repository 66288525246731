import { Box, Button, Typography, useTheme } from "@mui/material";
import { EditableInput } from "@/utilities/Components";
import Swal from "sweetalert2";
import { useState } from "react";
import { CareerProps, LocationProps } from "@/types";
import careersService from "@/api/careers.service";
import { tokens } from "@/utilities";

interface Props {
  premiumPlan: string;
  isEditable: boolean;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  selectedCareer: CareerProps;
}

export const HeadquarterInfo = ({
  premiumPlan,
  isEditable,
  selectedCareer,
  updateCareer,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingHeadquarter, setIsEditingHeadquarter] =
    useState<boolean>(false);
  const [editableHeadquarter, setEditableHeadquarter] = useState<
    LocationProps[]
  >(selectedCareer.headquarter || []);
  // const [editableHeadquarter, setEditableHeadquarter] = useState<LocationProps[] >([])

  const handleHeadquarterChange = (
    event: React.ChangeEvent<any>,
    index: number
  ): void => {
    const { name, value } = event.target;
    event.preventDefault();

    const updatedHeadquarter = [...editableHeadquarter];

    updatedHeadquarter[index] = {
      ...updatedHeadquarter[index],
      [name]: value,
    };
    setEditableHeadquarter(updatedHeadquarter);
  };

  const handleHeadquarterSubmit = async () => {
    try {
      if (editableHeadquarter == selectedCareer.headquarter) {
        setIsEditingHeadquarter(false);
        return;
      }

      if (selectedCareer.id && editableHeadquarter) {
        await careersService.updateHeadquarter(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          editableHeadquarter
        );
        updateCareer({ headquarter: editableHeadquarter }, selectedCareer.id);
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingHeadquarter(!isEditingHeadquarter);
    } catch (error) {
      console.error("Error updating headquarter", error);
      Swal.fire({
        title: "Hubo un error en la actualización",
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingHeadquarter ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={handleHeadquarterSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() => setIsEditingHeadquarter(!isEditingHeadquarter)}
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h3"
      >
        Sedes
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      <Box
        sx={{
          display: { xs: "flex", sm: "grid" },
          width: "100%",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "start",
        }}
      >
        {editableHeadquarter &&
          editableHeadquarter.map((item, index) => (
            <Box
              sx={{
                backgroundColor: colors.primary[400],
                padding: 0,
                width: "fit-content",
                borderRadius: "15px",
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
              key={index}
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: colors.grey[100], fontWeight: "bold" }}
                >
                  Título Sede:
                </Typography>
                <EditableInput
                  value={item.title}
                  name="title"
                  type="text"
                  isEditing={isEditingHeadquarter}
                  onChange={(event) => handleHeadquarterChange(event, index)}
                  isDisabled={!isEditable}
                />
              </Box>

              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: colors.grey[100], fontWeight: "bold" }}
                >
                  Dirección:
                </Typography>
                <EditableInput
                  value={item.address}
                  name="address"
                  type="text"
                  isEditing={isEditingHeadquarter}
                  onChange={(event) => handleHeadquarterChange(event, index)}
                  isDisabled={!isEditable}
                />
              </Box>

              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: colors.grey[100], fontWeight: "bold" }}
                >
                  País:
                </Typography>
                <EditableInput
                  value={item.country}
                  name="country"
                  type="text"
                  isEditing={isEditingHeadquarter}
                  onChange={(event) => handleHeadquarterChange(event, index)}
                  isDisabled={!isEditable}
                />
              </Box>

              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: colors.grey[100], fontWeight: "bold" }}
                >
                  Región:
                </Typography>
                <EditableInput
                  value={item.region}
                  name="region"
                  type="text"
                  isEditing={isEditingHeadquarter}
                  onChange={(event) => handleHeadquarterChange(event, index)}
                  isDisabled={!isEditable}
                />
              </Box>

              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: colors.grey[100], fontWeight: "bold" }}
                >
                  Ciudad:
                </Typography>
                <EditableInput
                  value={item.city}
                  name="city"
                  type="text"
                  isEditing={isEditingHeadquarter}
                  onChange={(event) => handleHeadquarterChange(event, index)}
                  isDisabled={!isEditable}
                />
              </Box>

              {/* <Box>
                      <Typography variant='h5' sx={{color:colors.grey[100], fontWeight:"bold"}}>Latitud: 
                      </Typography>
                      <EditableInput 
                        value={item.geoLocation?.latitude}
                        name='geoLocation.latitude'
                        type="text"
                        isEditing={isEditingHeadquarter}
                        onChange={handleHeadquarterChange}
                        isDisabled={!isEditable}
                        />
                    </Box>

                    <Box>
                      <Typography variant='h5' sx={{color:colors.grey[100], fontWeight:"bold"}}>Longitud:
                      </Typography>
                      <EditableInput 
                        value={item.geoLocation?.longitude}
                        name='geoLocation?.longitude'
                        type="text"
                        isEditing={isEditingHeadquarter}
                        onChange={handleHeadquarterChange}
                        isDisabled={!isEditable}
                        />
                    </Box> */}

              {/* <div>Localización: {item.geoLocation.map((location, index)=>(
                      <div className="location" key={index}>
                         <p>País: {location.country}</p>
                        <p>Región: {location.region}</p>
                        <p>Ciudad: {location.city}</p>
                        <p>Código Postal: {location.zipCode}</p>
                        <p>Latitud: {location.latitude}</p>
                        <p>Longitud: {location.longitude}</p>

                      </div>
                    ))}
                  </div> */}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
