import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { DataGrid, GridRenderCellParams,  esES } from '@mui/x-data-grid';
import { Header } from '@/views';
import { SwitchButton, generateRandomPassword, tokens, useAuth } from '@/utilities';
import userAdminService from '@/api/userAdmin.service';
import { UserAdminProfile, facultyProps } from '@/types';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ModalDetailAdmin } from '.';
import Swal from 'sweetalert2';
  
interface Props{
  adminUsers:UserAdminProfile[]
  faculties: facultyProps[] | null
  setAdminUsers:  React.Dispatch<React.SetStateAction<UserAdminProfile[]>>;
}
export const TableUsers = ({adminUsers, faculties, setAdminUsers}:Props) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedAdmin, setSelectedAdmin] = useState<UserAdminProfile>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {user} = useAuth()
  const role = user?.userData.role
  
  // const [adminUsers, setAdminUsers] = useState<UserAdminProfile[]>([])
  console.log(adminUsers)
  
  const handleSwitch = async (id:string, value:boolean) =>{
    try {
      const userSelected= adminUsers.filter((user)=> user.uid === id)
      Swal.fire({
        title: `El estado de ${userSelected[0].displayName ? userSelected[0].displayName : "administrador"} cambiará`,
        text: "¿Desea continuar con la operación?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        cancelButtonColor: '#002D82',
        confirmButtonText: 'Si, ¡cámbialo!',
        cancelButtonText: 'Cancelar'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const update = await userAdminService.updateActiveStatus(id, value)
          // console.log(id)
          if(update){
            setAdminUsers((prevUsers: UserAdminProfile[])=>
              prevUsers.map((user:UserAdminProfile)=>
                user.uid === id ? {...user, active: value} : user
              )
            )
          }
          Swal.fire(
            '¡Actualizado!',
            'El administrador fue actualizado satisfactoriamente',
            'success'
          )
        }
        }
      )
    
    } catch (error) {
      console.error("Error updating active status", error)
    }
  }

  const updateAdminUsers = (adminToUpdate: UserAdminProfile)=>{
    setAdminUsers(prevAdminUsers =>{
      const updatedAdminUsers = prevAdminUsers.map(admin=>{
        if(admin.uid === adminToUpdate.uid){
          return {...admin, ...adminToUpdate}
        }
        return admin
      })
      return updatedAdminUsers
    })
  }

  const handleDetailClick = (id: string) => {
    console.log(id)
    try {
      const admin = adminUsers.find((admin) => admin.uid === id)
      setSelectedAdmin(admin)
      setIsModalOpen(true);
    } catch (error) {
      console.error("Admin doesnt find in collection", error)
    }
  }
  
  const columns = useMemo(
    () => [
      {
        field: 'displayName',
        headerName: 'Nombre',
        flex:1,
        minWidth: 200,
        renderCell: (params:GridRenderCellParams) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
            onClick={ ()=>handleDetailClick(params.row.uid)}
          >
            {/* <img
              alt="avatar"
              height={30}
              src={"https://via.placeholder.com/150"}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            /> */}
            <span>{params.value}</span>
          </Box>
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        flex:1,
        minWidth: 200,
        sortable: false,
        renderCell: (params:GridRenderCellParams) => (
          <Box  sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}>
            {/* {params.row.instituteLogo
            ?(
            <img
              alt="instituteLogo"
              src={params.row.instituteLogo}
              loading="lazy"
              style={{ borderRadius: '50%', height:"40px", width:"40px" }}
            />) */}
            {/* <AccountCircle sx={{scale:"2", margin:"0 10px"}}/> */}
            
            <span>{params.value}</span>
          </Box>
        ),
      },
    //   {
    //     field: 'rol',
    //     headerName: 'Rol',
    //     width: 200,
    //     type: 'number',
    //     renderCell: (params) => (
    //       <Box
    //         component="span"
    //         sx={(theme) => ({
    //           backgroundColor:
    //             params.value < 50_000
    //               ? theme.palette.error.dark
    //               : params.value >= 50_000 && params.value < 75_000
    //               ? theme.palette.warning.dark
    //               : theme.palette.success.dark,
    //           borderRadius: '0.25rem',
    //           color: '#fff',
    //           maxWidth: '9ch',
    //           p: '0.25rem',
    //         })}
    //       >
    //         {params.value.toLocaleString('en-US', {
    //           style: 'currency',
    //           currency: 'USD',
    //           minimumFractionDigits: 0,
    //           maximumFractionDigits: 0,
    //         })}
    //       </Box>
    //     ),
    //   },
    {
        field: 'role',
        headerName: 'Rol',
        flex:1,
        minWidth: 200,
    },
      {
        field: 'institute',
        headerName: 'Código institución',
        flex:1,
        minWidth: 100,
      },
      {
        field: 'faculty',
        headerName: 'Facultad',
        flex:1,
        minWidth: 200,      
      },
      {
        key: 'detail',
        field: 'detail',
        headerName: 'Ver detalle',
        flex: 1,
        type:"actions",
        renderCell: (params: GridRenderCellParams)=>(
                <Box sx={{
                    backgroundColor:"#3F51B5",
                    borderRadius:"25px",
                    border:"none",
                  
                }} 
                onClick={ ()=>handleDetailClick(params.row.uid)}
               >
                <Button variant="outlined" sx={{border:"none", "&:hover":{border:"none"} }} >
                    <RemoveRedEyeIcon style={{color:"white"}}/>
                </Button>
                </Box>
        )
    },
      {
        field: 'active',
        headerName: 'Activo',
        minWidth: 70,
        renderCell: (params:GridRenderCellParams) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent:"center",
              gap: '1rem',
            }}
          >
            
            {/* <SwitchButton checked={params.value} setChecked={() => userAdminService.updateActiveStatus(params.row.uid, !params.value)}/> */}
            <SwitchButton 
              checked={params.value}
              setChecked={()=>handleSwitch(params.row.uid, !params.value)}
            />

          </Box>
        ),
      },

    
    ],[handleDetailClick, handleSwitch]
  );

  //Corregir , el useEffect original está en TabsUsers, per cuando se crea un nuevo usuario lo redirige a este componente sin tener en cuenta el administrador nuevo creado, es cuestión de actualizar estados con un setter. 

useEffect(()=>{
  async function fetchAdminUsers() {
      if(role === "Super Administrador"){
          try {
              const adminUsers = await userAdminService.getUsersAdmins()
              setAdminUsers(adminUsers)
          } catch (error) {
              console.error("Error trying to get users", error)
          }
      }else if(role === "Administrador IES" && user?.userData.institute)  {
        try {
              const adminUsers = await userAdminService.getUsersAdminFaculty(user.userData.institute)
              setAdminUsers(adminUsers)
        } catch (error) {
          console.error("Error trying to get users", error)
          
        }
      }          
  }
  fetchAdminUsers()
},[role, user?.userData.institute, setAdminUsers])

  return (
    <Box sx={{ width: '100%', padding:{xs:"20px", md:"20px" }}}>
        <Header title='Usuarios administradores' subtitle='Aquí podrá modificar y ver todos los usuarios asignados'/>
      {role? (
        <Box sx={{
          paddingBottom:"80px",
          "& .MuiDataGrid-root":{
            border:"none",
        }, 
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: "#3F51B5",
            borderBottom: "none",
            color:'#fcfcfc',
        },
        "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: "#3F51B5",
            color:'#fcfcfc',

        },
        "& .MuiTablePagination-root":{
          color:"#fcfcfc"
        },
        // '& .MuiDataGrid-sortIcon': {
        //   visibility: 'visible !important',
        //   color:"#fcfcfc !important",
        //   display:"flex",
        //   marginRight:"10px"

      
        // },
        // '& .MuiSvgIcon-root': {
        //   color:"#fcfcfc !important",
        //   display:"flex",
        //   marginRight:"10px"


      
        // },
        // '&.MuiDataGrid-filterIcon':{
        //   visibility: 'visible !important',
        //   color:"#fcfcfc !important",
        //   display:"flex",
        //   marginRight:"10px"

        // },
        // '& .MuiDataGrid-headerFilterRow':{
        //   visibility: 'visible !important',
        //   color:"#fcfcfc !important",
        //   display:"flex",
        //   marginRight:"10px"


        // },

        
        }}>
          <DataGrid
            rows={adminUsers}
            
            sx={{
              // disable cell selection style
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
                cursor: 'pointer'
      
              },
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
              },
              '& .MuiSelect-select':{
                display:"flex",
                alignItems:"center",
              },
              '& .MuiDataGrid-selectedRowCount':{
                color:"#fcfcfc"
              },
              '& .MuiDataGrid-sortIcon':{
                color:"#fcfcfc",
                visibility:"visible !important"
              },
              "& .MuiTablePagination-root":{
                color:"#fcfcfc"
              },
               '& .MuiSvgIcon-root': {
                color:"#fcfcfc !important"
            
              },
              '&.MuiDataGrid-filterIcon':{
                visibility: 'visible !important',
                color:"#fcfcfc !important",
              },
              '& .MuiDataGrid-headerFilterRow':{
                visibility: 'visible !important',
                color:"#fcfcfc !important",
              },
              '& .MuiDataGrid-menuOpen':{
                visibility: 'visible !important',
                color:"#fcfcfc !important",
              },
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible !important'
              },
              '.MuiDataGrid-menuIcon': {
                visibility: 'visible !important',
                marginRight:"20px"
              },
          
            }}
            getRowId={() => generateRandomPassword(12)}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 15, page: 0 },
              },
              sorting: {
                sortModel: [{ field: 'displayName', sort: 'asc' }],
              },
            }}
            pageSizeOptions={[15,30,50]}
            // checkboxSelection
            autoHeight
            disableColumnFilter={false}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            // slots={{
            //   toolbar: () => (
            //     <div style={{ display: 'flex', gap: '0.5rem' }}>
            //       <Button
            //         color="error"
            //         variant="contained"
            //         onClick={() => {
            //           console.log('Deactivate selected');
            //         }}
            //       >
            //         Desactivar
            //       </Button>
            //       <Button
            //         color="success"
            //         variant="contained"
            //         onClick={() => {
            //           console.log('Activate selected');
            //         }}
            //       >
            //         Activar
            //       </Button>
            //       <Button
            //         color="info"
            //         variant="contained"
            //         onClick={() => {
            //           console.log('Contact selected');
            //         }}
            //       >
            //         Contactar
            //       </Button>
            //     </div>
            //   ),
            // }}
          />
          {selectedAdmin && <ModalDetailAdmin selectedAdmin={selectedAdmin} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} updateAdminUsers={updateAdminUsers} faculties={faculties} adminUsers={adminUsers}/>}
        </Box>

      )
        :<></>
    }
    </Box>
  );
};

