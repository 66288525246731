
import logo from '@/assets/LogoGU.svg';
import logo_original from '@/assets/Logo_R.png';

import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import './Sidebar.scss'
import { CustomComponentProps, tokens, useAuth } from '@/utilities';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { dataSliderSuperAdmin } from '..';

interface Props extends CustomComponentProps{
  open: boolean
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>
}
interface SliderItem {
  name: string;
  icon: JSX.Element;
  link: string;
  role: string[];
}

function Sidebar({ open, setOpen }: Props) {
      
      const {institute} = useAuth() 
      const theme = useTheme()
      const colors = tokens(theme.palette.mode);
      const {user} = useAuth()
      const navigate = useNavigate();

        const role = user?.userData.role
        let filteredData:SliderItem[] = []; 

        if (role) {
            filteredData = dataSliderSuperAdmin.filter((element) => element.role.includes(role));
           
        }
   


      const toggleOpen =()=>{
        setOpen(!open)
      }
      // const handleTabClick = (index:number) => {
      //   setSelectedTab(index); 
      // };
      const handleTabClick = (link:string) =>{
        navigate(link)
        if (window.innerWidth <= 600) { 
          setOpen(false);
      }
      }

       
  return (
    // <Box sx={{height:"100%"}}>
      <Box 
        sx={{
          backgroundColor:colors.primary[400],
          display:{xs:`${open? "flex" : "none"}`, sm:"flex"}
        }} className={`${open? 'Sidebar' : 'SidebarClosed'}`}>
          <Box className='top-logoGU' sx={{ padding: open ? "20px 0px 20px 30px" : "0"}}>
              {open
                ? (
                  <Box sx={{display:"flex", flexDirection:"column", padding:"10px", gap:"10px", textAlign:"left", justifyContent:"center", alignItems:"center"}}>
                    {role !== "Super Administrador"
                    ?(
                      < >
                      <Typography variant='h3'fontWeight="bold" color={colors.grey[100]}>{institute?.name}</Typography>
                      <img src={institute?.logo} alt='' style={{width:"70px", height:"70px"}}/>
                      <Typography variant='h5' fontWeight="bold" color={colors.grey[100]}>{role}</Typography>
                      </>
                    )
                    : (
                      < >
                      <Typography variant='h3'fontWeight="bold" color={colors.grey[100]}>Guía Universitaria</Typography>
                      <Typography variant='h5' fontWeight="bold" color={colors.grey[100]}>{role}</Typography>
                      </>
                    )
                    }
                  </Box>
                )
                :(   
                  <Box sx={{display:"flex", padding:"10px",width:{xs:"50px", md:"70px"} , justifyContent:"center" , height:{xs:"50px", md:"70px"}}}>
                    {role !== "Super Administrador"
                    ?<img src={institute?.logo} alt='' style={{width:"50px", height:"50px"}}/>
                    : ""
                    }
                  </Box>
                )
                
              }

              {/* <img src={logo} alt='logo-GU'/> */}
              {/* open
                ?<Typography variant='h3' fontWeight="bold" color={colors.grey[100]}>{role}</Typography>
                : ''
            */}
              
          </Box>
          <br/>
          <div className='middle-categories'>                      
                  {filteredData.map((element, index)=>{
                      return (
                          <Box key={index} className='category' onClick={() => handleTabClick(element.link)}>
                              <Box className='section' sx={{color: colors.grey[100]}}>
                                {open ? element.icon
                                : (
                                <Tooltip title={<Typography variant='h5' sx={{color:colors.grey[200], borderRadius:"5px"}}>{element.name}</Typography>}>
                                  {element.icon}
                                </Tooltip>
                              )
                                }
                                  <Typography variant='h5'  className={`${open ? 'linkText' : 'linkTextClosed'}`}>{element.name}</Typography>
                              </Box>
                          </Box>
                      )
                  })}
                  <IconButton className="menuBtn" onClick={toggleOpen}>
                      {open
                        ? <KeyboardDoubleArrowLeftOutlinedIcon className='icon' />
                        : <KeyboardDoubleArrowRightOutlinedIcon className='icon'/>
                      }
                  </IconButton>  
          </div>
          <Box sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'transparent',
            display:"flex",
            flexDirection:open? "row" : "column",
            justifyContent:"center",
            alignItems:"center",
            borderRadius:"25px",
            cursor:"pointer"
          }}
          onClick={()=>navigate("/dashboard")}
          >
            <Box sx={{width:{xs:"100%", sm:"90%"} ,height:{xs:"120px"},  display:"flex", alignItems:"center", justifyContent:"center"}}>
              <img src={open ? logo_original : logo} alt='logo' style={{ padding:"10px", height:"100px"}}/>
            </Box>            
              {/* {open
                ? (
                  ""
                  // <Box>
                  //   <Typography variant="h5" sx={{fontWeight:"bolder", color:"#002D82", lineHeight:"1"}}>Guía</Typography>
                  //   <Typography variant="h5" sx={{fontWeight:"bolder", color:"#007FC0"}}>Universitaria</Typography>
                  // </Box> 
                )
                :(
                  <Box sx={{display:"flex", justifyContent:"center", alignItems:"baseline", marginBottom:"20px"}} >
                    <Typography variant="h4" sx={{fontWeight:"bolder", color:"#002D82", lineHeight:"1"}}>G</Typography>
                    <Typography variant="h4" sx={{fontWeight:"bolder", color:"#007FC0"}}>U</Typography>
                  </Box> 
            )
              } */}
          </Box>
          {/* <Box sx={{width:"100%", height:{xs:"120px"},  display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Typography variant="h6" 
              sx={{
                  fontSize:"8px"
                }}>
                v 1.0.1
              </Typography>
            </Box> */}
      </Box>
    //* </Box> */}
    )
  }
  
  export default Sidebar
