
import { TopRanking } from '@/types';
import {Text, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    subtitle: {
        display:"flex",
        fontSize: 14,
        margin: "10px 0",
        fontFamily: 'Helvetica-Bold',
        color: "#002D82",
  
      },
      tables:{
        display:"flex",
        fontSize:10,
        right:0,
        lineHeight:1,
        fontFamily: 'Helvetica',
  
      },
    
  });

export const TopCareers = ({data}:{data:TopRanking[]}) => (
    <>
             <View style={{display:"flex", flexDirection:"row",gap:"20px", alignItems:"baseline", marginBottom:"20px"}}>
                <Text style={styles.subtitle}>Top de carreras más buscadas</Text>
                {/* <Text style={styles.note}>(El orden mostrado no representa un ranking específico.)</Text> */}
              </View>

              <View  style={{display:"flex", gap:"5px"}} render={() => (
                data.map((career, index)=>(
                  <View  style={{display:"flex", flexDirection:"row", width:"100%"}}>
                    <Text style={{...styles.tables, width:"30px"}}>{`${index + 1}.`}</Text>
                    <Text style={styles.tables}>{career.career_name}</Text>
                  </View>
                ))
              )}  />
    </>
  )

