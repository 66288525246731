import { useState } from "react"
import './Register.scss'
import { useNavigate } from "react-router-dom"
import userAdminService from "@/api/userAdmin.service"
import logo from '@/assets/Logo_R.png'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React from "react"
import institutesData from '@/api/JSON/InstitutesFresh.json'
import { dialCodes, mapAuthCodeToMessage, tokens, useAuth } from "@/utilities"
import { Button, FormControl, FormGroup, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import { FirebaseError } from "firebase/app"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Swal from "sweetalert2"

interface State extends SnackbarOrigin {
  open: boolean;
}
const dataJSON = JSON.parse(JSON.stringify(institutesData))
const sessionUser= import.meta.env.VITE_SESSION_USER_STORAGE

interface Option {
  value: string;
  label: string;
}

const nowDate= new Date()
const formatOptions = { 
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true as const
  };


export const Register = () => {

  const theme = useTheme()
  const colors = tokens(theme.palette.mode);
  
  const {signUp, logOut} = useAuth()
  const [isRegistering, setIsRegistering] = useState(true)
  const [userRegister, setUserRegister]= useState({
    email: "",
    password:"",
    confirmPassword:"",
    institution:"",
    phone:"",
    phone_code_country:"+57",
    name:""
  })
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  console.log(selectedOption)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  
  const filteredOptions = dataJSON.institutes
    .map((institute:any) => {
      const instituteCode = Object.keys(institute)[0];
      const instituteData = institute[instituteCode][1];
      if (instituteData && instituteData.name) {
        const label = `${instituteData.name} || ${instituteData.headquarters[0].city}`;
        return { value: instituteCode, label };
      }
      return null;
    })
    .filter((option: Option) => option !== null)
    .filter((option: Option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //   const selectedValue = e.target.value;
    //   setSelectedOption(selectedValue);
    //   console.log('Institución seleccionada:', selectedValue);
    //   setSearchTerm('')
    // };

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSelectChange = (selectedValue: string) => {
      setSelectedOption(selectedValue);
      const selectedOptionLabel = filteredOptions.find((option:Option) => option.value === selectedValue)?.label || '';
      setSearchTerm(selectedOptionLabel);
      setUserRegister({
        ...userRegister,
        institution: selectedValue
      })
      setIsDropdownVisible(false)
    };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsDropdownVisible(value.length > 0)
  };

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const navigate = useNavigate()

  const handleSubmit = async (event:React.FormEvent<HTMLFormElement>)=>{
      event.preventDefault()
      try {
        const authUser = {
          email: userRegister.email,
          password: userRegister.password,
          displayName: userRegister.name
        }
       const register = await signUp(authUser)
      
       if(register){
        // console.log(user)
          const registeredUserData =  sessionStorage.getItem(sessionUser)
          const parsedData = registeredUserData? JSON.parse(registeredUserData): {}
          const profileData = {
            uid: parsedData.uid, 
            displayName: userRegister.name,
            email: userRegister.email,
            emailVerified: parsedData.emailVerified,
            createdAt: parsedData.createdAt,
            lastLoginAt: parsedData.lastLoginAt,
            phoneNumber: userRegister.phone,
            phone_code_country: userRegister.phone_code_country,
            active: false,
            institute: userRegister.institution,
            ref: `/institutes/${userRegister.institution}` ,
            role: "",
            faculty: "",
          };
          // console.log(profileData)
          const savedInFirestore = await userAdminService.saveUserProfile(profileData, parsedData.uid);
      
            if (savedInFirestore) {
              console.log("Registro exitoso en Firebase Authentication y Firestore");
              await logOut()

              Swal.fire({
                title:"Registro exitoso",
                icon: 'success',
                timer: 4000,            
              })
              await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/newUserNotification",{
                method:"POST",
                headers: {
                  "Content-Type": "application/json",
                  },
                body: JSON.stringify({
                  secret:import.meta.env.VITE_SECRET_EMAIL_KEY,
                  name: userRegister.name,
                  instituteName: userRegister.institution,
                  email: userRegister.email, 
                  status: "Pendiente", 
                  role:"institutes",
                  createdAt: nowDate.toLocaleString('es-ES', formatOptions),
                  id:parsedData.uid,
                  phone: `(${userRegister.phone_code_country}) ${userRegister.phone}`
                })
              })

              navigate('/login')
      
            } else {
              console.log("Error al guardar en Firestore");
            }

       }
      
      } catch (error:unknown) {
        // showAlertWrong()
      if (error instanceof FirebaseError) {
        console.log(error.code)
        const errorMessage = mapAuthCodeToMessage(error.code);
        Swal.fire({
          title:errorMessage,
          icon: 'error',
          footer: 'Intenta otra vez',
          timer: 4000,            
      })
      }
      }
  }
  
  const handlerChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void=>{
    const {value, name} = event.target
    if (name === "institution") {
      const selectedInstitute = dataJSON.institutes.find((institute: any) => {
        const instituteCode = Object.keys(institute)[0];
        const instituteData = institute[instituteCode][1];
        return instituteData && instituteData.name === value;
      });
  
      if (selectedInstitute) {
        setUserRegister({
          ...userRegister,
          institution:Object.keys(selectedInstitute)[0]
         
        });
      }
    }  if (name === 'email') {
      setUserRegister((prevUser) => ({ ...prevUser, [name]: value }));
      setEmailError(!isValidEmail(value));
    }else if(name === "phone"){
      setUserRegister((prevUser) => ({ ...prevUser, [name]: value }));
      setPhoneError(!isValidPhone(value))
    }
    
    else {
      setUserRegister({
        ...userRegister,
        [name]: value
      });
    }
  } 

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false)
  
  const isValidEmail = (email:string) => {
    const isEduEmail = email.toLowerCase().includes('.edu');
    const isGUEmail = email.toLowerCase().includes('guiauniversitaria.net');
    return isEduEmail || isGUEmail;
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneno=/^\d+$/;
    if(phone.match(phoneno)){
      return true
    }else{
      return false
    }
  };

  const [country, setCountry] = useState('+57');
  const handleCountryChange = (event:any) => {
    setCountry(event.target.value);
    setUserRegister({
        ...userRegister,
        phone_code_country:event.target.value
    })
  };



  return (
    <>
    {isRegistering?
      (    
      <div className="register">
        <div className="left-side-register">
          <form onSubmit={handleSubmit} className="form-register">
          <Box sx={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
              <img
                src={logo}
                alt="logo"
                />
                 {/* <Box >
                  <Typography variant="h5" sx={{fontWeight:"bolder", color:"#002D82", lineHeight:"1"}}>Guía</Typography>
                  <Typography variant="h5" sx={{fontWeight:"bolder", color:"#007FC0"}}>Universitaria</Typography>
                </Box> */}
        </Box>
        <FormGroup sx={{width:"100%", display:"flex",maxHeight:"100px", alignItems:"center"}}>
          <h1>Administrador Institucional</h1>
            
        </FormGroup>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="institution">Institución Educativa</InputLabel>
              <ApartmentIcon className="icon"/>
                <TextField
                  required
                  fullWidth
                  id="institution"
                  label="Buscar institución"
                  variant="standard"
                  value={searchTerm}
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& input:-webkit-autofill": {
                      transition: "background-color 5000s ease-in-out 0s",
                      backgroundColor: "transparent !important",
                    },
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      paddingLeft: "40px",
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
                />
    
            <div className={`custom-select ${isDropdownVisible ? 'visible' : ''}`}>
              {filteredOptions.length > 0 ? (
                <ul>
                  {filteredOptions.map((option: Option) => (
                    <li key={option.value} onClick={() => handleSelectChange(option.value)}>
                      <Typography variant="h5" color={colors.grey[100]}>{option.label}</Typography>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>


                <Box sx={{ width: 500}}>
                <HelpOutlineIcon className="help-icon"  onClick={handleClick({ vertical: 'top', horizontal: 'center' })}/>

                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  open={open}
                  onClose={handleClose}
                  message="Si no ha podido encontrar la institución porfavor comuniquese con nosotros a soporte@guiauniversitaria.net"
                  key={vertical + horizontal}
                  
                />
              </Box>
            </Box>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="name">Nombres y Apellidos<span style={{ color: "#007FC0" }}>*</span></InputLabel>

              <TextField
                required
                name="name"
                id="name"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.name}
                onChange={handlerChange}
                sx={{color:"#292929",
                  '& input': {
                    textAlign: "left",

                  }
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" ,
                    }
                  }}
                  InputProps={{
                    style:{ 
                   
                      paddingLeft: "40px",
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
              />
              <PersonIcon className="icon"/>

            </Box>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="phone">Número de contacto<span style={{ color: "#007FC0" }}>*</span></InputLabel>

                <FormControl id="phone" variant="outlined" fullWidth size="small" sx={{ display: "flex", flexDirection: "row" }}>
                    <Select
                    value={country}
                    id="phone_code_country"
                    name="phone_code_country"
                    onChange={handleCountryChange}
                    sx={{ display: "flex", border: "none", width: {xs:"40%", sm:"45%"}, color:"#292929" , maxHeight:44}}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 200,
                                width: '40ch',
                                right:0,
                                margin: '0 0 0 24px',
                                color:colors.grey[100],
                                
                            },
                        },
                    }}
                >
                    {dialCodes.map((country) => (
                    <MenuItem key={country.code} value={country.dial_code}>
                      <Typography variant="h5" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >({country.dial_code}) {country.name}</Typography>  
                    </MenuItem>
                    ))}
                </Select>
              {/* <LocalPhoneIcon className="icon"/> */}
              <TextField
                required
                type="tel"
                name="phone"
                id="phone"
                fullWidth
                size="small"
                autoComplete="on"
                variant="outlined"
                value={userRegister.phone}
                onChange={handlerChange}
                error={phoneError}
                helperText={phoneError ? 'Sólo se permiten números' : ''}
                
                sx={{color:"#292929",
                  '& input': {
                    textAlign: "left",
                  },
                  "& input:-webkit-autofill": {
                    transition: "background-color 5000s ease-in-out 0s",
                    backgroundColor: "transparent !important",
                  },
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
              />
            </FormControl>

            </Box>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="email">Correo Electrónico<span style={{ color: "#007FC0" }}>*</span></InputLabel>
              <EmailIcon className="icon"/>
              <TextField
                required
                type="email"
                name="email"
                value={userRegister.email}
                onChange={handlerChange}
                fullWidth
                size="small"
                autoComplete="on"
                variant="outlined"
                error={emailError}
                helperText={emailError ? 'El correo electrónico debe tener la extensión .edu' : ''}
                sx={{color:"#292929",
                  '& input': {
                    textAlign: "left",
                  }
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      paddingLeft: "40px",
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
              />
            </Box>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="password">Contraseña<span style={{ color: "#007FC0" }}>*</span></InputLabel>
              <TextField
                type={showPassword ? "text" :"password"}
                name="password"
                id="password"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.password}
                onChange={handlerChange}
                sx={{color:"#292929",
                  '& input': {
                    textAlign: "left",
                  }
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      paddingLeft: "40px",
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
              />
              <LockIcon className="icon"/>
              <VisibilityOutlinedIcon sx={{
                position:"absolute",
                zIndex:10,
                color:"#3F51B5",
                right:"12px",
                width: "20px",
                height: "20px",
                top: "35px",
                cursor:"pointer"
              }}
              onClick={togglePasswordVisibility}
              />
            </Box>

            <Box className="div-input">
              <InputLabel sx={{
                  fontWeight: "bold",
                  fontSize:"16px",
                  color: "#292929"
                 }} id="confirmPassword">Verificar Contraseña<span style={{ color: "#007FC0" }}>*</span></InputLabel>
              <LockIcon className="icon"/>
              
              <TextField
                type={showConfirmPassword ? "text" :"password"}
                name="confirmPassword"
                id="confirmPassword"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
                value={userRegister.confirmPassword}
                onChange={handlerChange}
                sx={{color:"#292929",
                  '& input': {
                    textAlign: "left",
                  }
                  }}
                  InputLabelProps={{
                    style: { 
                      padding: "5px 15px 5px 45px",
                      color: "#292929",
                      fontSize:"16px" }
                  }}
                  InputProps={{
                    style:{ 
                      paddingLeft: "40px",
                      fontSize:"16px",
                      color: "#292929"
                     }
                  }}
              />
               <VisibilityOutlinedIcon sx={{
                position:"absolute",
                zIndex:10,
                color:"#3F51B5",
                right:"12px",
                width: "20px",
                height: "20px",
                top: "35px",
                cursor:"pointer"
              }}
              onClick={toggleConfirmPasswordVisibility}
              />  
            </Box>

            <Button type="submit" variant="contained" className="register-btn">Registrarse</Button>

            <div className="switch-login">
              <p>¿Ya tiene una cuenta? <span onClick={()=> setIsRegistering(!isRegistering)} className="link">¡Inicia sesión!</span></p>
              

            </div>
          </form>

        </div>
 
      </div>
      )
      : ( 
        navigate("/login")
      )
  }
        
    </>
  )
}
