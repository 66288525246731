import { Header } from "@/views";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CustomSelect from "./components/customSelect.component";
import { ComparativeAnalyticsProps } from "./comparativeAnalytics.model";
import { processData } from "./comparativeAnalytics.hooks";
import { tokens } from "@/utilities";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react"; 

const adminValues = {
  "Administrador Facultad": 1,
  "Administrador IES": 2,
  "Super Administrador": 3,
};

type AdminRole = keyof typeof adminValues;

const ComparativeAnalyticsComponent = (props: ComparativeAnalyticsProps) => {
  const {
    userType,
    searchTermIES,
    optionsIES,
    handleInputChange,
    handleSelectChange,
    isDropdownIESVisible,
    selectedIES,
    searchTermFaculty,
    optionsFaculties,
    selectedFaculty,
    isDropdownFacultiesVisible,
    optionsCareers,
    searchTermCareer,
    isDropdownCareerVisible,
    selectedCareer,
    comparationData,
    getComparationData,
    isLoading,
    dataCareer,
  } = props;

  const userAdminValue = adminValues[userType as AdminRole];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  // const dataForChart = processedData && processedData.reverse();
  const [processedData, setProcessedData] = useState<any[]>([]);

  const dataForTable = processedData?.map((item: any, idx: number) => ({
      ...item,
      formattedName: `${idx + 1}. ${item.institute_name} - ${item.career_name}`,
    })).slice(0, 6) || [];

  const [title, setTitle] = useState<string>("");

  const handleGenerateReport = async () => {
    if (dataCareer) {
      setTitle(`Resultados para ${dataCareer.label}`);
      await getComparationData(); // Llama a la función para obtener los datos
    }
  };

  useEffect(() => {
    if (comparationData) {
      const newProcessedData = processData(comparationData);
      setProcessedData(newProcessedData);
    } else {
      setProcessedData([]);
    }
  }, [comparationData]);

  return (
    <Box sx={{ m: "20px", maxWidth: { xs: "100%" } }}>
      <Header
        title="Analítica comparativa"
        subtitle="Nota: La data representada hace referencia a los últimos 30 días"
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { sx: "100%" },
        }}
      >
        {/* Botón de regresar */}
        <Button
          variant="contained"
          sx={{
            display: "flex",
            gap: "10px",
            borderRadius: "5px",
            fontWeight: "regular",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
          Volver a Dashboard
        </Button>

        {/* Botón de generar reporte */}
        <Button
          sx={{
            backgroundColor: "#3F51B5",
            color: "#f2f0f0",
            fontSize: "14px",
            borderRadius: "5px",
            display: { xs: "none", md: "flex" },
            width: "fit-content",
            height: "fit-content",
            textTransform: "none",
            "&:hover": {
              backgroundColor: colors.blueAccent[500],
              cursor: "pointer",
            },
            "&.Mui-disabled": {
              backgroundColor: "#E0E0E0",
              color: "#9E9E9E",
              cursor: "not-allowed",
            },
          }}
          disabled={!selectedCareer}
          onClick={handleGenerateReport}
        >
          Generar reporte
        </Button>
      </Box>

      {/* Selects dinámicos */}
      {userAdminValue >= 1 && (
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
            borderRadius: 2,
            width: "100%",
            gap: "20px 50px",
            marginTop: "30px",
          }}
        >
          {/* Select de Institución */}
          {userAdminValue >= 3 && (
            <CustomSelect
              id="institution"
              name="ies"
              label="Buscar y seleccionar institución"
              value={searchTermIES}
              onChange={(e) => handleInputChange(e, "ies")}
              isDropdownVisible={isDropdownIESVisible}
              options={optionsIES}
              onSelect={(option) => handleSelectChange(option, "ies")}
            />
          )}
          {/* Select de Facultad */}
          {(userAdminValue >= 2 || selectedIES) && (
            <CustomSelect
              id="faculty"
              name="faculty"
              label="Buscar y seleccionar facultad"
              value={searchTermFaculty}
              onChange={(e) => handleInputChange(e, "faculty")}
              isDropdownVisible={isDropdownFacultiesVisible}
              options={optionsFaculties}
              onSelect={(option) => handleSelectChange(option, "faculty")}
            />
          )}
          {/* Select de Institución */}
          {(userAdminValue >= 1 || selectedFaculty) && (
            <CustomSelect
              id="career"
              name="career"
              label="Buscar y seleccionar carrera"
              value={searchTermCareer}
              onChange={(e) => handleInputChange(e, "career")}
              isDropdownVisible={isDropdownCareerVisible}
              options={optionsCareers}
              onSelect={(option) => handleSelectChange(option, "career")}
            />
          )}
        </Box>
      )}

      {/* Botón de generar reporte */}
      <Button
          sx={{
            backgroundColor: "#3F51B5",
            color: "#f2f0f0",
            fontSize: "14px",
            borderRadius: "5px",
            display: {xs:"flex", md:"none"},
            width: "fit-content",
            height: "fit-content",
            textTransform: "none",
            marginTop:"20px",
            "&:hover": {
              backgroundColor: colors.blueAccent[500],
              cursor: "pointer",
            },
            "&.Mui-disabled": {
              backgroundColor: "#E0E0E0",
              color: "#9E9E9E",
              cursor: "not-allowed",
            },
          }}
          disabled={!selectedCareer}
          onClick={getComparationData}
        >
          Generar reporte
        </Button>

      {/* Título dinámico */}
      <Typography
        variant="h4"
        sx={{
          color: colors.grey[100],
          marginTop: "20px",
        }}
      >
        {title}
      </Typography>
      {/* Tabla de resultados */}
      {dataCareer   ? (
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(2, minmax(350px, 1fr))",
            borderRadius: 2,
            width: "100%",
            gap: "20px 50px",
            marginTop: "30px",
          }}
        >
          {isLoading ? (
             <CircularProgress
                sx={{
                  color: theme.palette.mode === "dark" ? "#ffffff" : "#3F51B5",
                }}
              />
          ) : dataForTable && dataForTable.length > 0  ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Carrera</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>No. de veces</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataForTable.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{item.formattedName}</TableCell>
                      <TableCell align="right">{item.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : comparationData && (
            <Typography variant="h5" color={colors.grey[500]}>
              No encontramos coincidencias de comparaciones
            </Typography>
          )}
        </Box>
        ) : null}
    </Box>
  );
};

export default ComparativeAnalyticsComponent;
