import { plans, plansText } from ".";
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './PremiumPlans.scss'
import { Box, Button, Modal,  Typography, useTheme } from "@mui/material";
import { Header } from "@/views";
import {   tokens, useAuth } from "@/utilities";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import couponService from "@/api/coupon.service";
// import { Coupon, CouponResponse } from "@/types";
// import instituteService from "@/api/institute.service";
import CloseIcon from '@mui/icons-material/Close';
import { PaypalCheckoutButton } from "..";
import waitingListService from "@/api/waitingList.service";
// import institutesData from '@/api/JSON/InstitutesFresh.json'


// const dataJSON = JSON.parse(JSON.stringify(institutesData))

const nowDate= new Date()
const formatOptions = { 
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true as const
  };

export default function PremiumPlans() {
//     const theme = useTheme()
//     const colors = tokens(theme.palette.mode);
//   return (
   
//   )
const theme = useTheme()
const colors = tokens(theme.palette.mode)
const {user, institute} = useAuth()

const [planSelected, setPlanSelected] = useState<string>("Premium")


const email=  user?.userData.email || ""
const plan=  planSelected
const name= user?.user.displayName || ""
const phone= user?.userData.phoneNumber || null
const phone_code = user?.userData.phone_code_country || null
const role = user?.userData.role ? user.userData.role : ""
const uidFaculty = user?.userData.faculty || ""
const uidInstitute =  institute?.id ?  institute.id : ""
const instituteName= institute && institute.name
const headquarter =  institute?.headquarters ? institute.headquarters : []
const userId = user?.userData.uid || ""


// const [openFreemium, setOpenFreemium] = useState(false);
const [openPremium, setOpenPremium] = useState(false);

// const handleClickOpenSilver = () => setOpenFreemium(true);
// const handleClickOpenPremium = () => setOpenPremium(true);

// const handleCloseFreemium = () => setOpenFreemium(false);
const handleClosePremium = () => setOpenPremium(false);

// const navigate = useNavigate()

// const [values, setValues]= useState({
//     code:""
// })

// const handlerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
//     const { name, value } = event.target;
//     const formattedValue = value.toUpperCase()
//     let firstValue,
//         secondValue, 
//         finalValue
//     if(formattedValue.length === 9){
//         finalValue= formattedValue
//     }
//     if(formattedValue.length === 8 && !formattedValue.includes("-")){
//         firstValue = formattedValue.slice(0, 4)
//         secondValue = formattedValue.slice(4)
//         finalValue = `${firstValue}-${secondValue}`
//     }
//        setValues({
//     ...values,
//     [name]: finalValue
//     });
//     }

// const handleRedeemCoupon = async()=>{
//    try {
//        const coupon = await couponService.getCoupon(values.code)

//        if(coupon && (coupon as CouponResponse).message){
//         Swal.fire({
//             title: (coupon as CouponResponse).message,
//             icon: "error",
//             footer: "Intenta nuevamente con otro código",
//             timer: 5000,
//         })     
//         }
//          else if(coupon && (coupon as Coupon).usedBy && user?.user.uid ){
          
//             const filterUsedBy = (coupon as Coupon).usedBy.find((userInDb)=> userInDb.id === user.user.uid)
//             // console.log(filterUsedBy)
//             if(filterUsedBy){
//                 Swal.fire({
//                     title: "El código proporcionado ya ha sido usado por este usuario",
//                     icon: "error",
//                     footer: "Intenta nuevamente con otro código",
//                     timer: 5000,
//                 })
               
//             }else{
//                 const dataToUpdate={
//                  code:values.code, 
//                  userId:user?.user.uid, 
//                  status:(coupon as Coupon).active, 
//                  count:(coupon as Coupon).count, 
//                  maxCount:(coupon as Coupon).maxCount
//                 }
//                 // console.log(dataToUpdate.code)
//                 const updatedCoupon = await couponService.updateUsedBy(dataToUpdate)
//                 Swal.fire({
//                     title: updatedCoupon.message,
//                     icon: updatedCoupon.success? "success": "error",
//                     footer: "Mejoramos cada día para tu servicio",
//                     timer: 5000,
//                 })
//                 .then((result)=>{
//                     if(result.isConfirmed || result.dismiss === Swal.DismissReason.timer){
//                         navigate("/profile")
//                     }
//                 })
//                 if(updatedCoupon.success === true){
//                     await instituteService.updateSubscriptioLevel(user.user.uid, {level:1})
//                 }
//                 // console.log(updatedCoupon)
//             }
//        }
//     //    console.log(coupon)
//     //    console.log(user?.user.uid)
//    } catch (error) {
//     console.error("Error in handleRedeemCoupon", error)
//    }
// }

// const handleSubmit = ()=>{
//     console.log("datos enviados")
// }
const handleBought = async (plan?:string)=>{
    try{

            const data = {
                ref: role === "Administrador IES" ? `/institutes/${uidInstitute}`: `/institutes/${uidInstitute}/faculties/${uidFaculty}`,
                name,
                email,
                phone,
                phone_code,
                institute: instituteName,
                uidInstitute,
                uidFaculty, 
                plan: plan || "Premium",
                role,
                headquarter,
                userId,
               
            }
            console.log(data)
            if(uidInstitute){
                const id= await waitingListService.createByInstitute(data)
                console.log(id)

            }
            console.log("secret:", import.meta.env.VITE_SECRET_EMAIL_KEY);
            console.log("name:", name);
            console.log("email:", email);
            console.log("status:", "Pendiente");
            console.log("role:", "institutes");
            console.log("createdAt:", nowDate.toLocaleString('es-ES', formatOptions));
            console.log("id:", userId);
            console.log("uidInstitution:", uidInstitute);
            console.log("institutionName:", instituteName);
            console.log("phone:", `(${phone_code ?? ""}) ${phone}`);
            console.log("plan:", plan);
            

            await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/newUserIntestedInBuyPlan",{
                method:"POST",
                headers: {
                  "Content-Type": "application/json",
                  },
                body: JSON.stringify({
                  secret:import.meta.env.VITE_SECRET_EMAIL_KEY,
                  name: name,
                  email: email, 
                  status: "Pendiente", 
                  role:"institutes",
                  createdAt: nowDate.toLocaleString('es-ES', formatOptions),
                  id:userId,
                  uidInstitution: uidInstitute,
                  institutionName:instituteName,
                  phone: `(${phone_code?? ""}) ${phone}`,
                  plan: plan

                })
              })
            Swal.fire({
                title: "Un asesor te estará contactando",
                icon: "info",
                // footer: "Nos pondremos en contacto para continuar con el proceso",
                timer: 5000,
            })
           
    }
    catch (error){
        console.error(error)
    }

}

//pasar esto a una cloud function 
const product = {
    description:"Premium",
    price:1199
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    width:"500px",
    height:"300px",
    transform: 'translate(-50%, -50%)',
    bgcolor: `${colors.primary[400]}`,
    boxShadow: 24,
    p: 4,
    gap:"50px",
    borderRadius:"15px"
};

return (
    <Box sx={{padding:4}}>
        <Header title="Planes" subtitle=""/>
     <Box  display={{xs:"none", md:"flex"}}>
        <Box className="premium-plans">
            <Box sx={{ padding:"4.2% 0"}} className="benefits">
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Exhibición de oferta académica</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Click de redirección a programas seleccionados</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Resumen analítica de datos</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Autogestión de facultades y programas</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Acceso a base de datos de potenciales profesores</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Analítica de datos detallada</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Analítica comparativa</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Usuarios ilimitados</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Recomendaciones estratégicas para propuestas de captación</Typography>
                <Typography variant="h5" sx={{ color:colors.grey[100]}} className="text-benefits">Recomendaciones de innovación de oferta académica</Typography>

            </Box>

            {plans.map((plan)=>{
                return (
                    <Box sx={{backgroundColor:colors.primary[400]}} className="plans" key={plan.name}>
                
                        <Typography variant="h4"sx={{
                            color:colors.grey[100]
                        }}>{plan.name}</Typography>
                            <p className="plan-benefits">{plan.benefit ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit1 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit2 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit3 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit4 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit5 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit6 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit7 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit8 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            <p className="plan-benefits">{plan.benefit9 ? <VerifiedIcon className="icon-true"/> : <HighlightOffIcon className="icon-false"/>}</p>
                            {plan.name === "Free"  
                            ? ""
                            :  <Button variant="outlined"  onClick={()=>{
                                // if(plan.name === "Silver"){
                                //     handleClickOpenSilver() 
                                // }
                                if(plan.name === "Silver" || plan.name === "Premium"){
                                    handleBought(plan.name)
                                }
                            }} key={plan.name
                                
                            }>¡Compra Ahora!</Button>
                        }
                    </Box>
                
                )    
            })}
        </Box>
    </Box>
    <Box padding={4} display={{xs:"flex", md:"none"}}>

    {/* <Box sx={{
        width:"100%",
        display:"flex",
        justifyContent:{xs:"center", sm:"flex-start"},
        padding:{xs:"0", sm:"0 40px"},
    }}
    >
        <Button variant="contained" sx={{
            border:`0.5px solid ${colors.grey[300]}`,
            backgroundColor:colors.grey[100],
            color:colors.grey[900],
            gap:"10px",
            fontSize:"16px",
            margin:"20px 0"
        }}
    onClick={()=>navigate('/profile')}
        
        > 
            <ArrowBackIcon/>
            Volver
        </Button>
    </Box> */}

    <Box sx={{
        display:"flex",
        width:"100%",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
        marginBottom:"40px",
        flexWrap:"wrap"
    }}>
        
        <Box sx={{
        display:"flex",
        flexDirection:"column",
        minWidth:300,
        maxWidth:400,
        borderRadius:"20px",
        // transform: planSelected === "Free" ? 'scale(1.2)' : 'scale(1)', 
        boxShadow: planSelected === "Free" ? `0 0 20px 10px ${colors.blueAccent[400]}, 0 2px 5px ${colors.blueAccent[400]}` : ""
    }}
    onClick={()=>setPlanSelected("Free")}
    >
        <Box sx={{ 
            backgroundColor:planSelected === "Free"? "#3F51B5" : "#E6EEFB",
            padding:"10px 20px",
            borderRadius:"20px 20px 0 0"
        }}>
            <Typography variant="h3" sx={{color:planSelected === "Free"? "#fff" : "#3F51B5", padding:"10px 15px", textAlign:"center"}}> Free</Typography>
        </Box>

        <Box sx={{
            display:"flex",
            flexDirection:"column",
            backgroundColor:"#fff",
            borderRadius:"0 0 20px 20px",
            padding:"0 40px"

        }}>
            <Box sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"baseline",
                marginTop:"20px"
            }}>
                <Typography variant="h2" color={'#3F51B5'} > Gratis</Typography>
            </Box>

            <Box sx={{
                textAlign:"left",
                margin:"30px 0"
            }}>
                
                {plansText[0].Free.map((benefit, index)=>(
                
                <Box sx={{ 
                    display:"flex",
                    gap:"10px"
                }}
                key={index}
                >
                    <VerifiedIcon sx={{color:'#3F51B5'}}/>
                    <Typography variant="h5" color={colors.grey[300]} >{benefit}</Typography>
                </Box>
                ))}

            </Box>

        
        </Box>
    </Box>

    <Box sx={{
        display:"flex",
        flexDirection:"column",
        minWidth:300,
        maxWidth:400,
        borderRadius:"20px",
        // transform: planSelected === "Silver" ? 'scale(1.2)' : 'scale(1)', 
        boxShadow: planSelected === "Silver" ? `0 0 20px 10px ${colors.blueAccent[400]}, 0 2px 5px ${colors.blueAccent[400]}` : ""
    }}
    onClick={()=>setPlanSelected("Silver")}
    >
        <Box sx={{ 
            backgroundColor:planSelected === "Silver"? "#3F51B5" : "#E6EEFB",
            padding:"10px 20px",
            borderRadius:"20px 20px 0 0"
        }}>
            <Typography variant="h3" sx={{color:planSelected === "Silver"? "#fff" : "#3F51B5", padding:"10px 15px", textAlign:"center"}}> Silver</Typography>
        </Box>

        <Box sx={{
            display:"flex",
            flexDirection:"column",
            backgroundColor:"#fff",
            borderRadius:"0 0 20px 20px",
            padding:"0 40px"

        }}>
            <Box sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"baseline",
                marginTop:"20px"
            }}>
                <Typography variant="h2" color={'#3F51B5'} > USD $ 899</Typography>
                <Typography variant="h4" color={'#CBD4E1'}>&nbsp;/&nbsp;Mes</Typography>
            </Box>

            <Box sx={{
                textAlign:"left",
                margin:"30px 0"
            }}>
            
            {plansText[0].Silver.map((benefit, index)=>(
                
                <Box sx={{ 
                    display:"flex",
                    gap:"10px"
                }}
                key={index}
                >
                    <VerifiedIcon sx={{color:'#3F51B5'}}/>
                    <Typography variant="h5" color={colors.grey[300]} >{benefit}</Typography>
                </Box>
                ))}

            </Box>

            <Button sx={{
                marginBottom:"20px",
                backgroundColor:planSelected === "Silver"? "#3F51B5" : "#E6EEFB",
                ":hover":{
                    cursor:"pointer",
                    scale:"1.1",
                    backgroundColor:planSelected === "Silver"? "#3F51B5" : "#E6EEFB"
                }
            }}
            onClick={()=>setPlanSelected("Silver")}
            >
                <Typography  sx={{
                    color:planSelected === "Silver"? "#fff" : "#3F51B5",
                }}
                // onClick={handleClickOpenSilver} 
                    > Obtener ahora</Typography>
                
            </Button>
            
        </Box>
    </Box>


    <Box sx={{
        display:"flex",
        flexDirection:"column",
        maxWidth:400,
        minWidth:300,

        borderRadius:"20px",
        // transform: planSelected === "Premium" ? 'scale(1.2)' : 'scale(1)',
        boxShadow: planSelected === "Premium" ? `0 0 20px 5px ${colors.blueAccent[400]}, 0 2px 5px ${colors.blueAccent[400]}` : "",
    }}
    onClick={()=>setPlanSelected("Premium")}

    >
        <Box sx={{ 
            backgroundColor:planSelected === "Premium"? "#3F51B5" : "#E6EEFB",
            padding:"10px 20px",
            borderRadius:"20px 20px 0 0"
        }}>
            <Typography variant="h3" sx={{color:planSelected === "Premium"? "#fff" : "#3F51B5", padding:"10px 15px", textAlign:"center"}}> Premium</Typography>
        </Box>

        <Box sx={{
            display:"flex",
            flexDirection:"column",
            backgroundColor:"#fff",
            borderRadius:"0 0 20px 20px",
            padding:"0 40px"

        }}>
            <Box sx={{
                display:"flex",
                justifyContent:"center",
                alignItems:"baseline",
                marginTop:"20px"

            }}>
                <Typography variant="h2" color={'#3F51B5'} > USD $ 1199 </Typography>
                <Typography variant="h4" color={'#CBD4E1'}> &nbsp;/&nbsp;Mes</Typography>
            </Box>

            <Box sx={{
                textAlign:"left",
                margin:"30px 0"
            }}>
        
        
        {plansText[0].Premium.map((benefit, index)=>(
                
                <Box sx={{ 
                    display:"flex",
                    gap:"10px"
                }}
                key={index}
                >
                    <VerifiedIcon sx={{color:'#3F51B5'}}/>
                    <Typography variant="h5" color={colors.grey[300]} >{benefit}</Typography>
                </Box>
                ))}
                
            </Box>

            {/* <Grid sx={{width:"100%", marginBottom:"15px"}}item xs={12} sm={10}>
                        <InputLabel
                                htmlFor="code"
                                style={{
                                color: colors.grey[300],
                                fontSize: "16px",
                                }}
                            >
                                Ingrese el código promocional
                        </InputLabel>
                        <TextField
                            type="text"
                            id="code"
                            name="code"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            value={values.code}
                            onChange={handlerChange} 
                            sx={{color:colors.grey[300]}}
                            inputProps={{
                            maxLength:9,
                            style:{ color: colors.grey[300],
                                fontSize:"16px" },

                            }}
                        />
                        <Button variant="outlined" sx={{color:"#3F51B5", fontSize:"12px", marginTop:"10px"}}
                        onClick={handleRedeemCoupon}
                        >Canjear Código</Button>
            </Grid> */}

            {/* <Button variant="contained" sx={{marginBottom:"20px"}}
            // onClick={handleClickOpenPremium}
            onClick={handleBought}
            >
                Comprar ahora
                
            </Button>
             */}
        </Box>

        {openPremium?(
            <Modal
            open={openPremium}
            onClose={handleClosePremium}
            >
                <Box sx={style}>

                    <CloseIcon sx={{
                    position:"relative",
                    }}
                    onClick={()=>setOpenPremium(false)}
                    />
                    <Typography  variant="h4" color={colors.grey[100]} >
                        Elige el método de pago.
                    </Typography>

                    <Box sx={{height:"50px", width:"400px"}}>
                        <PaypalCheckoutButton product={product} onClose={handleClosePremium}/>
                    </Box>
                </Box>
            
            </Modal>
            )
            :null
        }

        {/* {openFreemium?(
                <Modal
                open={openFreemium}
                onClose={handleCloseFreemium}
                >
                <Box sx={style}>
                    <Typography  variant="h3" sx={{color:colors.grey[100]}} >
                        Por favor ingresa tu código de prueba
                    </Typography>
                    <Button onClick={handleCloseFreemium} 
                        sx={{
                        position:"absolute", 
                        fontWeight:"bolder", 
                        fontSize:"20px",
                        color: colors.grey[100],
                        right:0,
                        ":hover":{background:"transparent"}}}
                        >X</Button>
                    <form onSubmit={handleSubmit} style={{width:"100%"}}>
                        <Grid sx={{width:"100%", marginBottom:"15px"}}item xs={12} sm={10}>
                            <TextField
                                required
                                id="code"
                                name="code"
                                label="Código"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={values.code}
                                onChange={handlerChange} 
                                sx={{color:colors.grey[100]}}
                                InputLabelProps={{
                                style: { color: colors.grey[100],
                                fontSize:"16px" }
                                }}
                                InputProps={{
                                style:{ color: colors.grey[100],
                                    fontSize:"16px" }
                                }}
                            />
                        </Grid>

                        <Button variant="outlined" type="submit"> Canjear código</Button>
                    </form>
                </Box>
            </Modal>

            ):null
        }  */}
    
    </Box>

    </Box>
    </Box>
    </Box>
)
}
