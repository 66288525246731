import { Box } from "@mui/material"
import { FormCreateFaculty, PanelTabsFaculties } from "..";
import { useAuth } from "@/utilities"
import { CardsFaculties } from "./CardsFaculties";
import { useState } from "react";


export const TabsFaculties = () => {
  const {user} = useAuth()
  const code = user?.userData.institute

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (tab:number) => {
    setSelectedTab(tab);
  };  
  
    const data = [
        {
            name: "Facultades",
            link: <CardsFaculties/>
        },
        {
            name: "Crear Facultad",
            link: code ? <FormCreateFaculty code={code} handleChangeTab={handleChangeTab} /> : null
        },
    
    ]
  return (
    <Box>
        <PanelTabsFaculties data={data} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
    </Box>
  )
}
