import { ResponsivePie } from '@nivo/pie'
import { tokens } from '@/utilities';
import { Box,  useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserDevice } from '@/types';



export function PieChart () {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [userDevice, setUserDevice] = useState<UserDevice[]>([])

    const colorsPie = ["#002D82", "#009EE5", "#0063C3",   "#0080E4",   "#00BCED",  "#0046A2"]; 

    const totalEventCount = userDevice.reduce((sum, obj) => sum + obj.event_count, 0);

    // const usedColors = new Set(); 
  const filteredData = userDevice.filter(item => item.device_category === 'desktop' || item.device_category === 'mobile');
  
  const formattedData = filteredData.map(item => {

//     let color;
//   for (const c of colorsPie) {
//     if (!usedColors.has(c)) {
//       color = c;
//       usedColors.add(c);
//       break;
//     }
//   }
        let deviceName;

        // Modificar los nombres de las categorías según tu requerimiento
        if (item.device_category === 'desktop') {
        deviceName = 'Computadora';
        } else if (item.device_category === 'mobile') {
        deviceName = 'Celular';
        }
        //  else if (item.device_category === 'tablet') {
        // deviceName = 'Tableta';
        // } 
        else {
        // Puedes manejar otras categorías aquí si es necesario
        deviceName = 'Otro';
        }

    return {
      id: deviceName,
      label: deviceName,
      value: item.event_count,
      color: colorsPie,
     
    };
  });



    useEffect(()=>{
        async function fetchCountries() {
          try {
            const deviceConnection = sessionStorage.getItem("deviceConnection")

            if(deviceConnection){
                setUserDevice(JSON.parse(deviceConnection))
            }else{
            //   const getCareers = await bigQueryService.getCountUsersByCountry()
              const response = await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getEventsByDevice")
              const getCount = await response.json()
              setUserDevice(getCount)
            //   console.log(getCount)
            sessionStorage.setItem("deviceConnection", JSON.stringify(getCount))

            }
          } catch (error) {
            console.error(error)  
          }
        }
        fetchCountries()
    },[])
    return (
    <Box id="DeviceContainer" sx={{width:{xs:"100%" }, height:"100%", maxHeight:"400px", position:"relative"}}>
            <ResponsivePie
            data={formattedData}
            arcLabel={value => `${((value.value / totalEventCount) * 100).toFixed(2)}%`}
            theme={{
                axis:{
                    domain:{
                        line:{
                            stroke:colors.grey[100]
                        }
                    },
                    legend:{
                        text:{
                            fill:colors.grey[100]
                        }
                    },
                    ticks:{
                        line:{
                            stroke: colors.grey[100],
                            strokeWidth:1
                        },
                        text:{
                            fill:colors.grey[100]
                        }
                    }
                },
                legends:{
                    text:{
                        fill: colors.grey[100]
                    }
                }
            }}
            margin={{ top: 20, right: 0, bottom: 60, left: 0}}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            // borderWidth={1}
            colors={colorsPie}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={colors.grey[100]}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        8
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: colors.grey[100],
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                
                }
            ]}
            fill={[
            
                {
                    match: {
                        id: 'desktop'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'mobile'
                    },
                    id: 'dots'
                },
                // {
                //     match: {
                //         id: 'tablet'
                //     },
                //     id: 'lines'
                // },
            ]}
            
            // legends={[
            //     {
      
            //         anchor: 'top-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 20,
            //         translateY: 0,
            //         itemWidth: 100,
            //         itemHeight: 40,
            //         itemsSpacing: 10,
            //         symbolSize: 20,
            //         itemDirection: 'left-to-right',
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemTextColor: '#000'
            //                 }
            //             }
            //         ],
                    
            //     },
                
            // ]}
        />
        <Box
                position="absolute"
                display={"flex"}
                gap={"20px"}
                justifyContent={"space-around"}
                width={"100%"}
                bottom={0}
                p={1}
            >
                {formattedData.map((entry, index) => (
                <Box key={entry.id} mb={1}>
                    <Box
                    display="inline-block"
                    width="10px"
                    height="10px"
                    bgcolor={colorsPie[index]}
                    mr={1}
                    />
                    <span>{`${entry.id}: ${entry.value}`}</span>
                </Box>
                ))}
            </Box>
    </Box>
    
    );
    }
