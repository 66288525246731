import { tokens } from "@/utilities";
import { TextareaAutosize, Typography, useTheme } from "@mui/material";

interface Props{
    value: string | number | undefined
    id:string
    name:string
    isEditing: boolean
    isDisabled?:boolean
    onChange:(event: React.ChangeEvent<any>)=>void
    placeholder:string
}
export const EditableTextArea = ({id, name, value,  isEditing, onChange, isDisabled=false, placeholder}:Props) => {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    
  if (isEditing) {
    return (
      <TextareaAutosize
        id={id}
        name={name}
        value={value}
        autoComplete="off"
        disabled={isDisabled}
        onChange={onChange}
        placeholder={placeholder}
        style={{
            borderRadius: '5px',
            borderColor: 'primary.main',
            padding: '5px 10px',
            width:"100%",
            minWidth: '250px',
            fontSize: 'medium',
            fontFamily:"inherit",
            minHeight: '200px',
            whiteSpace: 'pre-wrap',
            resize: 'vertical',
          }}  
        />
    );
  } else {
    return (
      <Typography variant='h5' color={colors.grey[200]}>
        {value}
      </Typography>
    );
  }
};

