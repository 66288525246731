import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ReactNode } from "react";

export const PaypalProvider = ({children}:{ children: ReactNode }) => {
  return (
    <PayPalScriptProvider options={{
        clientId: import.meta.env.VITE_PAYPAL_CLIENT_ID_DEV, 
        // clientId: "sb", 
        currency:"USD",
        components: ["buttons", "marks", "messages"],
        dataPageType: "checkout",
        vault:true, //Save your customers' payment information for billing agreements, subscriptions, or recurring payments.
        // sdkBaseUrl: "http://localhost.paypal.com:8000/sdk/js",
    }}
    deferLoading={false}
    >
        {children}
    </PayPalScriptProvider>
  )
} 
