import instituteService from "@/api/institute.service";
import { InstituteProps, ContactInfo } from "@/types";
import { dialCodes, isValidEmail, isValidPhone, tokens } from "@/utilities";
import { EditableInput } from "@/utilities/Components";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";

interface Props {
  institute: InstituteProps | null;
  isEditable: boolean;
  premiumPlan: string;
}

export const ContactInfoInstitute = ({
  isEditable,
  premiumPlan,
  institute,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const iesId = institute?.id;

  const [isEditingContactInfo, setIsEditingContactInfo] =
    useState<boolean>(false);
  const [editableContactInfo, setEditableContactInfo] = useState<
    Partial<ContactInfo> & Partial<InstituteProps>
  >({
    contactEmail: institute?.contactInfoIES?.contactEmail || "",
    phone_code_country:
      institute?.contactInfoIES?.phone_code_country || "Prefijo",
    contactPhone: institute?.contactInfoIES?.contactPhone || null,
    webSite: institute?.webSite || "",
  });
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleContactInfoChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case "contactEmail":
        setEmailError(!isValidEmail(value));
        break;
      case "contactPhone":
        setPhoneError(!isValidPhone(value));
        break;
      default:
        break;
    }
    setEditableContactInfo((prevUser) => ({ ...prevUser, [name]: value }));
  };

  async function handleContactInfoSubmit() {
    try {
      const selectedContactInfo = {
        contactEmail: institute?.contactInfoIES?.contactEmail || "",
        phone_code_country:
          institute?.contactInfoIES?.phone_code_country || "Prefijo",
        contactPhone: institute?.contactInfoIES?.contactPhone || null,
        webSite: institute?.webSite || "",
      };
      console.warn("handleContactInfoSubmit");
      if (
        JSON.stringify(editableContactInfo) ===
        JSON.stringify(selectedContactInfo)
      ) {
        setIsEditingContactInfo(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }
      if (iesId) {
        const data = {
          webSite: editableContactInfo.webSite,
          contactInfoIES: {
            contactEmail: editableContactInfo.contactEmail || "",
            phone_code_country:
              editableContactInfo.phone_code_country || "Prefijo",
            contactPhone: editableContactInfo?.contactPhone || null,
          },
        };

        await instituteService.update(iesId, data);
        console.warn("Updated in Database!");
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
        // sessionStorage.setItem("currentCareers", JSON.stringify)
      }
      setIsEditingContactInfo(!isEditingContactInfo);
    } catch (error) {
      console.error("Error updating contact information", error);
    }
  }

  const [country, setCountry] = useState(
    editableContactInfo.phone_code_country || "Prefijo"
  );
  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
    setEditableContactInfo({
      ...editableContactInfo,
      phone_code_country: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      {isEditingContactInfo ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
              // disabled={emailError || phoneError}
            }}
            onClick={handleContactInfoSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: 0 },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() => setIsEditingContactInfo(!isEditingContactInfo)}
          >
            Editar
          </Button>
        </Box>
      )}
      <Typography
        sx={{
          padding: "20px 0",
          color: colors.grey[100],
          marginTop: "10px",
          maxWidth: "75%",
        }}
        variant="h3"
      >
        Información de Contacto
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color={colors.grey[100]}
        >
          Web/Enlace/Brochure
        </Typography>
        <EditableInput
          value={editableContactInfo.webSite}
          name="webSite"
          type="text"
          isEditing={isEditingContactInfo}
          onChange={handleContactInfoChange}
          isDisabled={!isEditable}
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: { xs: "flex", sm: "grid" },
          flexDirection: "column",
          gridTemplateColumns: {
            sm: "repeat(1,minmax(0,100%))",
            md: "repeat(2,minmax(0,100%))",
          },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Email/Contacto admisiones
          </Typography>
          <EditableInput
            value={editableContactInfo.contactEmail}
            name="contactEmail"
            type="text"
            error={emailError}
            helperText={
              emailError
                ? "El correo electrónico debe tener la extensión .edu"
                : ""
            }
            isEditing={isEditingContactInfo}
            onChange={handleContactInfoChange}
            isDisabled={!isEditable}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            color={colors.grey[100]}
          >
            Teléfono de contacto
          </Typography>
          {isEditingContactInfo ? (
            <FormControl
              id="phone"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Select
                value={country}
                id="phone_code_country"
                label="Prefijo"
                name="phone_code_country"
                onChange={handleCountryChange}
                disabled={!isEditable}
                sx={{
                  display: "flex",
                  border: "none",
                  width: { xs: "40%", sm: "45%" },
                  color: colors.grey[100],
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: "40ch",
                      right: 0,
                      margin: "0 0 0 24px",
                      color: colors.grey[100],
                    },
                  },
                }}
              >
                {dialCodes.map((country) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    ({country.dial_code}) {country.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="contactPhone"
                name="contactPhone"
                type="text"
                defaultValue={editableContactInfo.contactPhone ?? ""}
                fullWidth
                autoComplete="off"
                variant="outlined"
                onChange={handleContactInfoChange}
                disabled={!isEditable}
                error={phoneError}
                helperText={phoneError ? "Sólo se permiten números" : ""}
                InputLabelProps={{
                  style: { color: colors.grey[100], fontSize: "16px" },
                }}
                InputProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    width: "100%",
                  },
                }}
              />
            </FormControl>
          ) : (
            <Typography variant="h5" color={colors.grey[200]}>
              {`${
                editableContactInfo.phone_code_country
                  ? `(${editableContactInfo.phone_code_country})`
                  : ""
              }  ${
                editableContactInfo.contactPhone
                  ? editableContactInfo.contactPhone
                  : ""
              }`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
