import { ImagesFormat, LocationProps } from "@/types";

export function convertProgramObject(programObject:any, instituteName:string, headquarter:LocationProps[], facultyId:string, instituteId:string, iesCharacter:string, iesImages:ImagesFormat[], iesLogo:string, subscriptionLevel:number, facultyName:string) {
    // Convertir las propiedades del objeto
    const convertedObject = {
      active: true ,
      code: programObject.CÓDIGO_SNIES_PROGRAMA,
      name: programObject.NOMBRE_DEL_PROGRAMA,
      webSite: programObject.SITIO_WEB_PROGRAMA,
      academicLevel: programObject.NIVEL_ACADÉMICO,
      levelStudy: programObject.NIVEL_FORMACIÓN,
      levelStudyDetail: programObject.NIVEL_FORMACIÓN_DETALLADO_SEGÚN_MINISTERIO,
      profileGraduated: programObject.PERFIL_DEL_GRADUADO,
      candidateProfile:programObject.PERFIL_DEL_GRADUADO,
      modalities: programObject.MODALIDAD,
      schedule: programObject.TIPO_HORARIO,
      credits: programObject.NÚMERO_CRÉDITOS_PROGRAMA,
      duration: programObject.NÚMERO_PERIODOS_DURACIÓN,
      periods: programObject.TIPO_PERIODO,
      price: programObject.PRECIO_PROGRAMA_POR_PERIODO,
      currency:programObject.TIPO_DE_MONEDA,

      admissionsPeriodicity: programObject.PERIODICIDAD_DE_LAS_ADMISIONES || "",
      institution: instituteName,
      nationalAccreditation:programObject.ACREDITACIÓN_NACIONAL || false,
      internationalAccreditation:programObject.ACREDITACIÓN_INTERNACIONAL || false,
      ministryRecognition: programObject.RECONOCIMIENTO_DEL_MINISTERIO || "",
      headquarter: headquarter,
      description: programObject.DESCRIPCIÓN_PROGRAMA || "",
      uidFaculty: facultyId,
      uidInstitution: instituteId,
      iesCharacter:iesCharacter,
      iesImages: iesImages || [],
      iesLogo: iesLogo || "",
      startYear: programObject.FECHA_INICIO_PROGRAMA || new Date(),
      costCredit: programObject.COSTO_DEL_CRÉDITO || null,
      inscriptions: programObject.PRÓXIMAS_INSCRIPCIONES || new Date(),
      startClass: programObject.INICIO_DE_CLASES || new Date(),
      compatiblePrograms: [],
      exchange: programObject.INTERCAMBIOS || false,
      dualProgram: programObject.DOBLE_TITULACIÓN || false,
      summerProgram: programObject.PROGRAMAS_DE_VERANO || false,
      mandatoryInterships:programObject.PRÁCTICAS_OBLIGATORIAS || false,
      mandatoryThesis: programObject.TESIS_O_TRABAJO_DE_GRADO_OBLIGATORIO || false,
      contactEmail: programObject.EMAIL_DEL_PROGRAMA || "",
      contactPhone:programObject.TELÉFONO_CONTACTO_PROGRAMA || null,
      facultyName: facultyName,
      ref: `/institute_DEV/${instituteId}/faculties/${facultyId}/careers`,
      subscriptionLevel: subscriptionLevel,
    };
  
    // Agregar cualquier propiedad adicional
  
    return convertedObject;
  }