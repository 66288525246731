import * as React from 'react';
import Switch from '@mui/material/Switch';

interface Props{
    checked: boolean | undefined
    setChecked:(check:boolean)=> void
    isDisabled?:boolean
}

export const SwitchButton= ({checked, setChecked, isDisabled=false}:Props) =>{
//   const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      color={checked? "success" : "error"}
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: checked ? '#007FC0' : '#002D82',
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: checked ? '#007FC0' : '#002D82', 
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          color:"#007FC0",
        
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor:"#007FC0",
        },
      }}
      disabled={isDisabled}
    />
  );
}