import { Box, Button, DialogActions, DialogContent,  IconButton,  Modal,Typography, useTheme } from '@mui/material';
import {  useState } from 'react';
import Crop from "@mui/icons-material/Crop";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { CropEasy, tokens, useAuth } from "@/utilities";
import logoGU from "@/assets/Logo_R.png"
import instituteService from '@/api/institute.service';

export const ImagesInstitute = () => {

    const {institute, setInstitute}= useAuth()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    const [openImage, setOpenImage] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [logo, setLogo] = useState(institute?.logo);
    const [openCrop, setOpenCrop] = useState(false);

    const handleClickOpenImage = () => setOpenImage(true);

    const handleCloseImage = () =>{
        setOpenImage(false);
        setFile(null)
        setLogo(institute?.logo)
    } 

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB 
    const handleChangeImage = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    if (file) {
        if (file.size > MAX_FILE_SIZE) {
        alert('El archivo es demasiado grande. El tamaño máximo permitido es 5MB.');
        e.target.value = ''; 
        } else {
        setFile(file);
        setLogo(URL.createObjectURL(file));
        setOpenCrop(true);
        }
    }
    }
    };

    const handleUploadImage = async () => {
        if (file) {
          try {
            const iesID = institute?.id;
            if (iesID) {
              const uploadedImage = await instituteService.updateLogo(iesID, file);
              if (uploadedImage) {
                setLogo(uploadedImage.url)
                setInstitute((prevInfo:any) => ({
                  ...prevInfo,
                  logo: uploadedImage.url
                }))
                setOpenImage(false)
                //Imagen subida con éxito, ahora puedes hacer más acciones si es necesario
                console.log('URL de la imagen subida:', uploadedImage.url);
              } else {
                // Manejar errores si la subida de la imagen falla
                console.error('Error al subir la imagen.');
              }
            } else {
              // Manejar el caso en el que el usuario no está autenticado
              console.log('Usuario no autenticado.');
            }
          } catch (error) {
            // Manejar errores generales
            console.error('Error al subir la imagen:', error);
          }
        } else {
          // Manejar el caso en el que no se ha seleccionado ningún archivo
          console.log('No se ha seleccionado ningún archivo.');
        }
      };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        width:{xs:"80%", md:"700px"},
        transform: 'translate(-50%, -50%)',
        bgcolor: `${colors.primary[400]}`,
        boxShadow: 24,
        p: 4,
        height:"auto",
        marginTop:{xs:"60px", sm:"0px" , md:"0px"}    };

  return (
    <>
            <Box sx={{
                  padding:{xs:"20px 0", md:"20px  40px"},
                  width:{xs:"100%", md:"50%"},
                  display:"flex",
                  flexDirection:"column",
                  justifyContent:"center",
                  alignItems:"center",
                  textAlign:"center",
                  gap:"10px",
                }}>
              
                  <Box sx={{
                    position:"relative",
                  }}>
                  
                    
              
                    
                    <img
                    src={logo || logoGU}
                    loading='eager'
                    style={{
                      display:"flex",
                      height:"220px",
                      width:"220px",
                      borderRadius:"50%",
                      textAlign:"center",
                      border:`1px solid ${colors.blueAccent[500]}`,
                      objectFit:"contain",
                      padding:"10px"
                    }}
                    onClick={handleClickOpenImage}
                    />

                    <AddAPhotoOutlinedIcon sx={{
                      position:"absolute",
                      right:0,
                      top:0,
                      fontSize:"30px",
                      color:"#007FC0",
                      ":hover":{
                          cursor:"pointer",
                          scale:"1.2"
                      }
                      }}
                      onClick={handleClickOpenImage}
                      />

                  </Box>

                  {/* <Box sx={{
                    position:"relative",
                    width:"100%",
                    maxWidth:"300px",
                    padding:"20px 0",
                  }}>
                      <BorderColorOutlinedIcon sx={{
                          position:"absolute",
                          right:{xs:"-35px", sm:"-40px"},
                          fontSize:"30px",
                          color:"#007FC0",
                          ":hover":{
                            cursor:"pointer",
                            scale:"1.2"
                          }
                        }}
                        // onClick={handleClickOpen}
                        />
                        <Typography variant='h3' color={colors.grey[100]}>{`${institute?.name || "Nombre de la institución"}`}</Typography>
                        <Typography variant='h5' color={colors.grey[100]}>{`${ institute?.acronym || ""}`}</Typography>

                  </Box> */}


        </Box>

               {/* ------------------Image modal and handler---------------------- */}
        
               {openImage 
        ?(
          <>
            <Modal 
              open={openImage}
              onClose={handleCloseImage}
              style={{
                height:"100%",
                overflowY:"scroll",
                zIndex:2
            }}
              >
                <Box sx={style}>
                  <Typography  variant="h4" color={colors.grey[100]} >
                      Selecciona para modificar el logo de la institución
                  </Typography>
                  <CloseIcon sx={{
                    position:"absolute",
                    right:"15px",
                    top:"15px",
                    cursor:"pointer"
                  }}
                  onClick={handleCloseImage}
                  />

              {!openCrop?(
                <>
                
                    {logo && logo.length > 5
                        ? 
                      ( 
                        <DialogContent dividers>
                      <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", justifyContent:"center"}}>
                          <label htmlFor="image" style={{position:"relative"}}>
                            <input
                              accept="image/*"
                              id="image"
                              type="file"
                              style={{ display: 'none'}}
                              onChange={handleChangeImage}
                            />
                            <img
                              src={logo || logoGU}
                              loading='eager'
                              style={{
                                display:"flex",
                                height:"220px",
                                width:"220px",
                                borderRadius:"50%",
                                textAlign:"center",
                                border:`1px solid ${colors.blueAccent[500]}`,
                                objectFit:"cover",
                                cursor:"pointer",
                                alignItems:"center",

                              }}
                            />
                      <AddAPhotoOutlinedIcon sx={{
                          position:"absolute",
                          right:0,
                          top:0,
                          fontSize:"30px",
                          color:"#007FC0",
                          ":hover":{
                              cursor:"pointer",
                              scale:"1.2"
                          }
                          }}
                          onClick={handleClickOpenImage}
                          />

                          </label>

                        </Box>
                      </DialogContent>
                      )
                        :
                        ( 
                          <DialogContent dividers>
                        <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", justifyContent:"center"}}>
                            <label htmlFor="image" style={{position:"relative"}}>
                              <input
                                accept="image/*"
                                id="image"
                                type="file"
                                style={{ display: 'none'}}
                                onChange={handleChangeImage}
                              />
                        <img
                        src={logoGU}
                        alt="Imagen no disponible"
                        loading='eager'
                        style={{
                          display:"flex",
                          height:"220px",
                          width:"220px",
                          borderRadius:"50%",
                          textAlign:"center",
                          border:`1px solid ${colors.blueAccent[500]}`,
                          objectFit:"contain",
                          padding:"10px"
                        }}
                        />
                         <AddAPhotoOutlinedIcon sx={{
                          position:"absolute",
                          right:0,
                          top:0,
                          fontSize:"30px",
                          color:"#007FC0",
                          ":hover":{
                              cursor:"pointer",
                              scale:"1.2"
                          }
                          }}
                          onClick={handleClickOpenImage}
                          />

                          </label>

                        </Box>
                      </DialogContent>
                      )
                    }

                        <DialogActions sx={{width:"100%", minWidth:{xs:"100%", sm:350}}}>
                          <Box sx={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"100%",
                            gap:"8px"
                          }} >
                            {file && (
                              <Button sx={{
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center", 
                                padding:"0 10px",
                              }} 
                              variant="outlined"
                              onClick={()=>setOpenCrop(true)}
                              >
                                    <IconButton
                                      aria-label="Crop"
                                      color="secondary"
                                      onClick={() => setOpenCrop(true)}
                                      sx={{display:"flex", padding:"0 5px"}}
                                    >
                                      <Crop />
                                    </IconButton>
                                    Modificar
                                </Button>
                                  )}

                              <Button variant="contained"  onClick={handleUploadImage} sx={{backgroundColor:"#007FC0", display:"flex"}}>
                                  Guardar
                              </Button>
                          </Box>
                        </DialogActions>
                </>
                )
              :(
                logo?
                <CropEasy {...{logo, setOpenCrop, setLogo, setFile}}/>
                :""
              )
              }
             
                </Box>
            </Modal>
          </>
        )
        :""
        }
    </> 
 )
}
