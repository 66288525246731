import { useAuth } from "@/utilities"
import { FormCreateAdmin, FormCreateAdminFaculty } from "."
import { UserAdminProfile, facultyProps } from "@/types"

interface Props{
  handleChangeTab: (tab:number)=>void
  adminUsers:UserAdminProfile[]
  faculties: facultyProps[] | null
  // setAdminUsers:  React.Dispatch<React.SetStateAction<UserAdminProfile[]>>;
}

export const CreateAdmin = ({handleChangeTab, adminUsers, faculties}:Props) => {

    const {user} = useAuth()
    const role = user?.userData.role

  return (
    <>
    {role === "Super Administrador" &&(
        <FormCreateAdmin/>
    )}

    {role === "Administrador IES" &&(
        <FormCreateAdminFaculty handleChangeTab={handleChangeTab} adminUsers={adminUsers} faculties={faculties}/>
    )}

    {role !== "Super Administrador" && role !== "Administrador IES" && (
        <></>
    )}
    </>
  )
}
