import { Box, Button, Typography, useTheme } from "@mui/material";
import { EditableTextArea } from "@/utilities/Components";
import { useState } from "react";
import { CareerProps } from "@/types";
import careersService from "@/api/careers.service";
import Swal from "sweetalert2";
import { tokens } from "@/utilities";

interface Props {
  premiumPlan: string;
  selectedCareer: CareerProps;
  updateCareer: (careerMap: Partial<CareerProps>, id: string) => void;
  isEditable: boolean;
}
export const ProgramDescription = ({
  premiumPlan,
  selectedCareer,
  updateCareer,
  isEditable,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isEditingProgramDescription, setIsEditingProgramDescription] =
    useState<boolean>(false);
  const [editableProgramDescription, setEditableProgramDescription] =
    useState<string>(selectedCareer.description);

  const handleProgramDescriptionChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    setEditableProgramDescription(value);
  };

  async function handleProgramDescriptionSubmit() {
    try {
      if (editableProgramDescription == selectedCareer.description) {
        setIsEditingProgramDescription(false);
        console.warn("No changes, it doesn't need to update!");
        return;
      }

      if (selectedCareer.id && editableProgramDescription) {
        await careersService.updateProgramDescription(
          selectedCareer.uidInstitution,
          selectedCareer.uidFaculty,
          selectedCareer.id,
          editableProgramDescription
        );
        updateCareer(
          { description: editableProgramDescription },
          selectedCareer.id
        );
        Swal.fire({
          title: "Los cambios se han guardado exitosamente",
          icon: "success",
          timer: 2000,
        });
      }
      setIsEditingProgramDescription(!isEditingProgramDescription);
    } catch (error) {
      console.error("Error updating ProgramDescription", error);
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        position: "relative",
        marginBottom: "40px",
      }}
    >
      {isEditingProgramDescription ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={handleProgramDescriptionSubmit}
          >
            Guardar
          </Button>
          {!isEditable && (
            <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
              {premiumPlan}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: { xs: 0, sm: "0" },
              top: { xs: "35px", sm: "40px" },
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
            }}
            onClick={() =>
              setIsEditingProgramDescription(!isEditingProgramDescription)
            }
          >
            Editar
          </Button>
        </Box>
      )}

      <Typography
        sx={{
          padding: "20px 0",
          textAlign: "left",
          width: { xs: "60%" },

          color: colors.grey[100],
          marginTop: "10px",
        }}
        variant="h3"
      >
        Descripción del Programa
      </Typography>

      <hr style={{ width: "100%", marginBottom: "25px" }} />

      <EditableTextArea
        id="profileGraduated"
        name="profileGraduated"
        value={editableProgramDescription}
        onChange={handleProgramDescriptionChange}
        placeholder="Por favor, escriba un resumen del programa otorgado a los estudiantes"
        isEditing={isEditingProgramDescription}
      />
    </Box>
  );
};
