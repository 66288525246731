import { useState } from "react";
import {
  ContactInfo,
  ContactSocialMedia,
  facultyProps,
  FinancialSupport,
  ImageProps,
} from "@/types";
import facultyService from "@/api/faculty.service";
import "./FormCreateFaculty.scss";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { convertBase64ToFile, tokens, useAuth } from "@/utilities";
import imagesService from "@/api/images.service";
import { useNavigate } from "react-router-dom";
import { ContactInfoForm } from "./components/ContactInfoForm";
import { FinancialSupportForm } from "./components/FinancialSupportForm";
import { SocialMediaForm } from "./components/SocialMediaForm";
import { ImagesFaculty } from "./components/ImagesFaculty";

const showAlertSuccess = () => {
  Swal.fire({
    title: "Facultad creada satisfactoriamente",
    icon: "success",
    footer: `Revisa la lista de facultades`,
    timer: 2000,
  });
};
const showAlertWrong = () => {
  Swal.fire({
    title: "Lo sentimos, hubo un problema en la creación",
    icon: "error",
    footer: `Por favor, vuelve a intentarlo`,
    timer: 2000,
  });
};

export interface Props {
  code: string;
  handleChangeTab?: (tab: number) => void;
}

export function FormCreateFaculty(props: Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user, institute } = useAuth();
  const iesCharacter = institute?.character ?? "";
  const subscriptionLevel = institute?.subscriptionLevel ?? 0;
  const isEditable = user?.subscriptionLevel && user.subscriptionLevel > 0;
  const premiumPlan =
    "Para poder acceder a los campos deshabilitados,por favor, dirigete a nuestros planes y adquiere uno";

  const navigate = useNavigate();

  const initialValuesContactInfo: ContactInfo = {
    contactEmail: "",
    contactPhone: null,
    phone_code_country: "+57",
    webSite: "",
  };
  const initialValuesSocialMedia: ContactSocialMedia = {
    facebook: null,
    hasFacebook: false,
    whatsapp: null,
    hasWhatsapp: false,
    linkedin: null,
    hasLinkedin: false,
  };
  const initialValuesFinancialSupport: FinancialSupport = {
    hasFinancialSupport: false,
    website: "",
    email: "",
    phone_code_country: "+57",
    contactPhone: null,
    whatsapp: null,
    hasWhatsapp: false,
  };

  const initialValues: facultyProps = {
    name: "",
    active: true,
    description: "",
    logo: "",
    webSiteFaculty: "",
    images: [],
    ref: "",
    iesCharacter,
    uidInstitution: props.code,
    subscriptionLevel,
    contactInfo: {
      default: "faculty",
      ies: institute?.contactInfoIES || { ...initialValuesContactInfo },
      faculty: { ...initialValuesContactInfo },
    },
    contactSocialMedia: {
      default: "faculty",
      ies: institute?.contactSocialMediaIES || { ...initialValuesSocialMedia },
      faculty: { ...initialValuesSocialMedia },
    },
    financialSupport: {
      default: "faculty",
      ies: institute?.financialSupportIES || {
        ...initialValuesFinancialSupport,
      },
      faculty: { ...initialValuesFinancialSupport },
    },
  };

  const [values, setValues] = useState({ ...initialValues });
  const [contactInfo, setContactInfo] = useState({
    ...initialValuesContactInfo,
  });
  const [socialMedia, setSocialMedia] = useState({
    ...initialValuesSocialMedia,
  });
  const [financialSupport, setFinancialSupport] = useState({
    ...initialValuesFinancialSupport,
  });

  const handlerChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [selectedImages, setSelectedImages] = useState<ImageProps[]>([]);
  const [selectedLogo, setSelectedLogo] = useState<ImageProps>({ url: "" });
  const [defaultImage, setDefaultImage] = useState<ImageProps>({
    url: "",
    alt: "Imagen institución",
  });

  const handleLogoChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 1) {
      Swal.fire({
        title: "Selecciona un máximo de 5 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files && inputElement.files[0]) {
      const file = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const image = { url };
        setSelectedLogo({ ...image });
      };

      reader.onerror = (error) => {
        console.error("Error reading file", error);
      };
      reader.readAsDataURL(file); // Leemos el archivo como una URL base64
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    const imagesFormatted = selectedImages.length
      ? (convertBase64ToFile(selectedImages) as File[])
      : [];

    const logoFormatted = selectedLogo.url
      ? (convertBase64ToFile(selectedLogo) as File)
      : null;

    const defaultImageFormatted = defaultImage.url
      ? (convertBase64ToFile(defaultImage) as File)
      : null;

    const valuesFormatted: facultyProps = {
      ...values,
      contactInfo: {
        default: "faculty",
        ...values.contactInfo,
        faculty: contactInfo,
      },
      contactSocialMedia: {
        default: "faculty",
        ...values.contactSocialMedia,
        faculty: socialMedia,
      },
      financialSupport: {
        default: "faculty",
        ...values.financialSupport,
        faculty: financialSupport,
      },
    };
    try {

      const createId = await facultyService.create(props.code, valuesFormatted);

      if (createId && user?.userData.institute) {
        const uploadedURLs = imagesFormatted.length
          ? await imagesService.uploadFacultyImage(
              user?.userData.institute,
              imagesFormatted,
              createId
            )
          : [];

        let uploadedLogoURL = null;
        if (logoFormatted) {
          uploadedLogoURL = await imagesService.uploadFacultyLogo(
            user?.userData.institute,
            logoFormatted,
            createId
          );
        }

        const uploadedDefaultImage = defaultImageFormatted
          ? await imagesService.uploadFacultyDefaultImage(
              user?.userData.institute,
              defaultImageFormatted,
              createId
            )
          : null;

        const updated = await facultyService.update(props.code, createId, {
          ref: `/institutes/${props.code}/faculties/${createId}`,
          images: uploadedURLs ||
            institute?.images || [
              {
                url: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a",
              },
            ],
          logo: uploadedLogoURL
            ? uploadedLogoURL[0].url
            : institute?.logo ||
              "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Flogo.jpg?alt=media&token=00448418-5d25-4033-84a3-648136db3bc3",
          defaultImage: uploadedDefaultImage
            ? uploadedDefaultImage[0].url
            : "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a",
        });
        if (updated) {
          showAlertSuccess();
          props.handleChangeTab && props.handleChangeTab(0);
        } else {
          showAlertWrong();
        }
      } else {
        const updated = await facultyService.update(props.code, createId, {
          ref: `/institutes/${props.code}/faculties/${createId}`,
          images: [
            {
              url: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a",
            },
          ],
          logo: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Flogo.jpg?alt=media&token=00448418-5d25-4033-84a3-648136db3bc3",
        });
        if (updated) {
          showAlertSuccess();
          props.handleChangeTab && props.handleChangeTab(0);
        } else {
          showAlertWrong();
        }
      }
      setValues({ ...initialValues });
      navigate("/faculties");
    } catch (error) {
      console.error(error);
    }
  };
  // const [editableImages, setEditableImages] = useState<ImageProps[] | []>(selectedCareer.iesImages || [])

  return (
    <Box sx={{ padding: { xs: "20px", md: "30px" }, width: "100%" }}>
      <Box>
        <Typography variant="h2" color={colors.grey[100]}>
          Ingresa la información para tu nueva facultad
        </Typography>
        <Typography
          variant="h5"
          sx={{ color: colors.grey[500], marginTop: "0px" }}
        >
          Los campos marcados con * son obligatorios
        </Typography>
      </Box>

      <hr />
      {/* <Typography variant="h2" color={colors.grey[100]} marginTop="10px">Detalles de la facultad</Typography> */}
      {!isEditable && (
        <Typography variant="h5" sx={{ margin: "0", color: "red" }}>
          {premiumPlan}
        </Typography>
      )}

      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(2, minmax(150px, 1fr))",
            padding: 2,
            borderRadius: 2,
            border: `1px solid ${colors.blueAccent[500]}`,
            width: { xs: "100%", sm: "70%" },
            gap: "30px",
            marginTop: "30px",
          }}
        >

<InputLabel
        sx={{
          color: colors.grey[100],
          fontWeight: "bold",
          fontSize: "16px",
          gridColumn: { xs: "1 / -1", sm: "span 2" } 
        }}
     
      >
        Información de contacto
      </InputLabel>
          <Box sx={{ gridColumn: "1 / -1" }}>
            <Grid item xs={12} sm={10}>
              <TextField
                required
                type="text"
                fullWidth
                size="small"
                name="name"
                id="name"
                label="Nombre de la Facultad"
                value={values.name}
                onChange={handlerChange}
                sx={{ color: colors.grey[100] }}
                InputLabelProps={{
                  style: {
                    color: colors.grey[100],
                    fontSize: "16px",
                    backgroundColor: colors.background.primary,
                  },
                }}
                InputProps={{
                  style: { color: colors.grey[100], fontSize: "16px" },
                }}
              />
            </Grid>
          </Box>

          <Grid sx={{ gridColumn: "1 / -1" }}>
            <ContactInfoForm
              values={contactInfo}
              setContactInfo={setContactInfo}
            />
          </Grid>

          <Grid sx={{ gridColumn: "1 / -1" }}>
            <SocialMediaForm
              values={socialMedia}
              setSocialMedia={setSocialMedia}
            />
          </Grid>

          <Grid sx={{ gridColumn: "1 / -1" }}>
            <FinancialSupportForm
              values={financialSupport}
              setFinancialSupport={setFinancialSupport}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            sx={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "1 / -1",
              gap: "1rem",
            }}
          >
            <InputLabel
              sx={{
                color: colors.grey[100],
                fontWeight: "bold",
                fontSize: "16px",
              }}
              htmlFor="logo"
            >
              Logo de la facultad
            </InputLabel>
            <input
              type="file"
              name="logo"
              id="logo"
              onChange={handleLogoChange} // hacer un handler de imagen
              accept="image/png , image/jpeg, image/webp"
              disabled={!isEditable}
            />
            {selectedLogo.url && (
              <img
                src={selectedLogo.url}
                alt="Logo facultad"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "15px 0px",
                  minHeight: "200px",
                  width: "100%",
                  maxWidth: "250px",
                  maxHeight: "400px",
                  borderRadius: "2rem",
                }}
              />
            )}
          </Grid>

          <ImagesFaculty
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            defaultImage={defaultImage}
            setDefaultImage={setDefaultImage}
          />

          <Grid
            item
            xs={12}
            sm={10}
            sx={{ gridColumn: { xs: "1 / -1", sm: "span 2" } }}
          >
            <InputLabel
              sx={{
                color: colors.grey[100],
                fontWeight: "bold",
                fontSize: "16px",
              }}
              htmlFor="description"
            >
              Descripción de la Facultad
            </InputLabel>
            <TextareaAutosize
              name="description"
              value={values.description}
              onChange={handlerChange}
              placeholder="Por favor, escriba una breve descripción de la facultad..."
              id="description"
              style={{
                borderRadius: "5px",
                borderColor: "primary.main",
                padding: "5px 10px",
                width: "100%",
                minWidth: "250px",
                fontSize: "medium",
                fontFamily: "inherit",
                minHeight: "200px",
                whiteSpace: "pre-wrap",
                resize: "vertical",
              }}
            />
          </Grid>
          {/* <img src={url || ''} alt='firebase-img'/> */}

          <Grid
            sx={{
              gridColumn: { xs: "1 / -1" },
              display: "flex",
              justifyContent: { xs: "center" },
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ height: "fit-content" }}
              type="submit"
            >
              Crear Facultad
            </Button>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}
