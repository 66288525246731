import { LocalStorageTypes} from "@/models";
import { InstituteProps } from "@/types";
import { getLocalStorage, setLocalStorage } from "@/utilities";
import {createSlice} from '@reduxjs/toolkit'

const initialState: InstituteProps[] = []

export const instituteSlice = createSlice({
    name: LocalStorageTypes.INSTITUTES,
    initialState: getLocalStorage(LocalStorageTypes.INSTITUTES)
    ? JSON.parse(getLocalStorage(LocalStorageTypes.INSTITUTES) as string) 
    : initialState,
    reducers:{ 
      
      addInstitutes:(state, action)=>{
      setLocalStorage(LocalStorageTypes.INSTITUTES, state)
      return action.payload
    },
  }

})

export const {addInstitutes}= instituteSlice.actions