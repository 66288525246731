// import { Faculty } from "@/models";
import './DetailCareers.scss'
// import { CardDetail } from "../../Dashboard/Cards";
import { useEffect, useState } from "react";
// import { faculties } from "..";
import { CareerProps, facultyProps } from "@/types";
import { Box,  Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "@/utilities";
import careersService from "@/api/careers.service";
import { ModalCreateCareer } from "@/modals";
import { DetailByCareer } from "@/components";
import bg from '@/assets/imagen.jpg'

interface Props {
  selectedFaculty: facultyProps
}

export  function DetailCareers({selectedFaculty}:Props) {
  // console.log(selectedFaculty)
  const [selectedCareer, setSelectedCareer] = useState<CareerProps>()
  const [careers, setCareers] = useState<CareerProps[]>([])
  const theme = useTheme()
  const colors = tokens(theme.palette.mode);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const updateCareer = (career: Partial<CareerProps>, id:string)=>{
    setCareers(prevCareers =>{
      const updatedCareer = prevCareers.map(careerMap=>{
        if(careerMap.id === id){
          return {...careerMap, ...career}
        }
        return careerMap
      })
      return updatedCareer
    })
  }
  const handleCareerClick = (career: CareerProps) => {
    // console.log(career)
    setSelectedCareer(career);
  };
  useEffect(()=>{
    async function fetchCareers() {
        if(selectedFaculty){
            try {
                const getCareers = await careersService.getAll(selectedFaculty.ref)
                  setCareers(getCareers)
            } catch (error) {
                console.error("Error trying to get faculties", error)
            }
        }            
    }
    fetchCareers()
},[selectedFaculty])

  return (
    <Box 
      sx={{
        backgroundColor:colors.primary[500]      
      }}
      className="detail-careers"
      >
        
        <Box 
        sx={{
          boxShadow: `0 0 0 1px ${colors.blueAccent[500]}40`,
          backgroundColor:colors.primary[400]
        }}
        className="banner-careers">
            <img
                src={selectedFaculty.images? selectedFaculty.images[0].url : bg}
                alt="Imagen facultad"
            /> 
              <Box sx={{display:{xs:"none", md:"flex"}}} className="title">
                <Typography variant="h3" 
                sx={{
                  textAlign:"center",
                  color:`${colors.grey[100]}`,
                  padding:"40px"
                  }}>Facultad de {selectedFaculty.name}</Typography>
              {/* <Button>Ver todos los programas</Button> */}
            </Box>
        </Box>

        {/* <Box className="levels">
            <Typography variant="h3"
              sx={{
                marginTop:"20px",
                color:`${colors.grey[100]}`
                }}>
                  Niveles de Estudio
              </Typography>
            <hr />
            <Box className="card">
    
              {faculties.map((faculty:Faculty, index:number)=>(
                <div key={index} className="faculty-career">
                    {faculty.careers.map((career, index)=>(
                        <CardDetail className=""
                        //   onClick={()=>goToDetailPage(levelStudy)}
                          title={career.levelStudy} 
                          imgSrc='https://previews.123rf.com/images/elnur/elnur1609/elnur160901656/62409985-joven-estudiante-la-preparaci%C3%B3n-para-los-ex%C3%A1menes.jpg' 
                          key={index}
                        />
                    ))}

                </div>
            ))}
            </Box>
        </Box> */}


        <Box className="careers-details">

            <Box sx={{flexDirection:{xs:"column-reverse", sm:"row"}, display:"flex", flexWrap:"wrap", alignItems:"baseline", width:"100%", gap:{xs:"20px", sm:0}}}>
              <Typography variant="h3"
                sx={{
                  display:"flex",
                  color:`${colors.grey[100]}`,
                  width:{xs:"100%", sm:"50%"},
                 justifyContent: "flex-start"
                  }}>
                  Carreras
              </Typography>
                 
       
              <Box   sx={{
                  display:"flex",
                  width:{xs:"100%", sm:"50%"},
                  justifyContent:{xs:"center", sm:"end"},
                  }}
           >
                  {selectedFaculty.id && <ModalCreateCareer facultyId={selectedFaculty.id} />}
              </Box>
            </Box>

              <Box sx={{width:{xs:"100%", sm:"30%"}, marginTop:{xs:"20px", sm:"0"}}}>

              <Grid item xs={12} sm={10} >
                <Box >
                  <TextField
                    
                    id="institution"
                    name="institution"
                    label="Buscar carrera"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleInputChange} 
                    sx={{color:colors.grey[100]}}
                    InputLabelProps={{
                      style: { color: colors.grey[100],
                      fontSize:"16px" }
                    }}
                    InputProps={{
                      style:{ color: colors.grey[100],
                        fontSize:"16px" }
                    }}
                    />

              </Box>
            </Grid>
            {searchTerm 
            ?  (<Box 
            sx={{backgroundColor:colors.primary[400], display:"flex", borderRadius:"5px", padding:"5px 30px", paddingBottom:"30px", flexDirection:"column"  }}
          >
            {careers?.filter((career) => career.name.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((career: CareerProps, index: number) => (
                <Box
                  sx={{
                    ":hover": {
                      scale:"1.2", 
                      cursor:"pointer"
                    }  
                  }}   
                  className={`career ${selectedCareer === career ? 'active': ''}`}
                  key={index}
                  onClick={() => handleCareerClick(career)}
                >
                  <Typography variant="h4"
                    sx={{
                      marginTop:"20px",
                      color:`${colors.grey[100]}`
                    }}
                  >
                    {career.name}
                  </Typography>
                </Box>
              ))}
          </Box>
        )
            :(  <Box 
              sx={{backgroundColor:colors.primary[400], display:{xs:"none" ,sm:"flex"}, borderRadius:"5px", padding:"5px 30px", paddingBottom:"30px", flexDirection:"column" }}
            
              >
                {careers?.map((career:CareerProps, index: number)=>(
                    <Box
                      sx={{
                        ":hover":{
                          backgroundColor:colors.grey[100],
                         
                          cursor:"pointer"}  
                      }}   
                      className={`career ${selectedCareer === career ? 'active': ''}`}
                      key={index}
                      onClick={()=> handleCareerClick(career)}
                      >
                        <Typography variant="h4"
                          sx={{
                            marginTop:"20px",
                            color:`${colors.grey[100]}`,
                            ":hover":{
                              color:colors.grey[900],
                            }
                            }}>
                        {career.name}
                        </Typography>
                    </Box>
                ))}
            </Box>)
            }
                
              </Box>

              <Box sx={{width:{xs:"100%", sm:"65%"}}}>
                {selectedCareer && <DetailByCareer updateCareer={updateCareer} selectedCareer={selectedCareer}/>}
                {/* </div> */}
              </Box>
        </Box>

        
    </Box>
  )
}
