import { ComparationDataProps } from "./comparativeAnalytics.model";

export const processData = <T extends ComparationDataProps>(data: T[]) => {
  const processedData = data
    .map((item, index) => ({
      ...item,
      rank: index + 1,
    }))
    .sort((a, b) => b.total - a.total);

  return processedData;
};
