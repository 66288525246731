import { tokens } from "@/utilities";
import { MenuItem, Select,  SelectChangeEvent,  Typography, useTheme } from "@mui/material";

interface Props{
    value: string | number | undefined
    name:string
    type:string
    isEditing: boolean
    data:any
    onChange:(event: SelectChangeEvent<any>)=>void
}
export const EditableSelectInput = ({ name, value, type, isEditing, onChange, data }:Props) => {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    
  if (isEditing) {
    return (
      <Select
        id={name}
        name={name}
        type={type} 
        defaultValue={value}
        size="small"
        fullWidth
        autoComplete="off"
        variant="outlined"
        onChange={onChange}
        style={{ color: colors.grey[100], fontSize: "16px"}}
        >
            {data.map((name:string, index:number)=>(
                <MenuItem value={name} key={index}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
  } else {
    return (
      <Typography variant='h5'  color={colors.grey[200]}>
        {value}
      </Typography>
    );
  }
};

