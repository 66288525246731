import { Box } from "@mui/material"
import {  FavoritesProfessors, ProfessorList } from "."
import PanelTabs from "../Dashboard/Sidebar/Tabs"
// import { Chats } from ".."


export const TabsProfessor= () => {

    const data = [
        {
            name: "Buscar Profesor",
            link: <ProfessorList/>
            },
            {
                name: "Favoritos",
                link: <FavoritesProfessors/>
            },
            // {
            //     name: "Mensajes",
            //     link: <Chats/>
            // },
    
    ]
  return (
    <Box sx={{width:"100%"}}>
        <PanelTabs data={data}/>
    </Box>
  )
}
