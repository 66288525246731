import { ImageProps } from "@/types";
import { tokens, useAuth } from "@/utilities";
import { Box, Button, Grid, InputLabel, useTheme } from "@mui/material";
import Swal from "sweetalert2";
import defaultLogo from "@/assets/imagen.jpg";

interface Props {
  selectedImages: ImageProps[];
  setSelectedImages: (newImages: ImageProps[]) => void;
  defaultImage: ImageProps;
  setDefaultImage: (image: ImageProps) => void;
}
export const ImagesFaculty = ({
  selectedImages,
  setSelectedImages,
  defaultImage,
  setDefaultImage,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useAuth();

  const isEditable = user?.subscriptionLevel && user.subscriptionLevel > 0;

  const handleImagesChange = (event: React.ChangeEvent<any>) => {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 5) {
      Swal.fire({
        title: "Selecciona un máximo de 5 archivos.",
        icon: "info",
        timer: 2000,
      });
      event.preventDefault();
      return;
    }

    if (inputElement.files) {
      const selectedFiles = Array.from(inputElement.files);
      const urls = selectedFiles
        .map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
        .filter((promise) => promise !== null); // Filter out null promises

      // Handle promises (outside the map)
      Promise.all(urls)
        .then((imageUrls) => {
          const images = imageUrls.map((value: unknown) => {
            const url = value as string; // Cast value to string
            return {
              url,
              // id: imagesUrl.length + index + 1,
            };
          });
          setSelectedImages([...selectedImages, ...images]);
        })
        .catch((error) => console.error("Error reading file", error));
    }
  };

  const handleDeleteImages = (id: number) => {
    try {
      const updatedImages = selectedImages.filter((_, i) => i !== id);
      setSelectedImages(updatedImages);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sm={10}
      sx={{
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 / -1",
        gap: "1rem",
      }}
    >
      <InputLabel
        sx={{
          color: colors.grey[100],
          fontWeight: "bold",
          fontSize: "16px",
        }}
        htmlFor="images"
      >
        Añadir imágenes de la facultad
      </InputLabel>

      <input
        type="file"
        name="images"
        id="images"
        onChange={handleImagesChange}
        accept="image/png , image/jpeg, image/webp"
        multiple
        disabled={!isEditable}
      />

      {selectedImages && (
        <Grid
          container
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "repeat(2, minmax(150px, 1fr))",
            },
            gap: "20px",
          }}
        >
          {selectedImages.map((image, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                flexDirection: "column-reverse",
                justifyContent: "center",
                alignItems: "center",
                margin: "30px 0",
              }}
            >
              <Box onClick={() => setDefaultImage(image)}>
                <img
                  src={image.url}
                  alt="Imagen facultad"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "15px 0px",
                    minHeight: "200px",
                    width: "100%",
                    maxWidth: "500px",
                    maxHeight: "400px",
                    borderRadius: "2rem",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Button
                sx={{
                  position: "relative",
                  justifyContent: "left",
                }}
                variant="outlined"
                onClick={() => handleDeleteImages(index)}
              >
                Eliminar
              </Button>
            </Box>
          ))}
        </Grid>
      )}

      <Box sx={{ width: { xs: "100%" } }}>
        <InputLabel
          sx={{
            color: colors.grey[100],
            fontWeight: "bold",
            fontSize: "16px",
          }}
          htmlFor="images"
        >
          Imagen predeterminada
        </InputLabel>

        <img
          src={
            defaultImage && defaultImage.url ? defaultImage.url : defaultLogo
          }
          alt="Logo Guía Universitaria"
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "15px 0px",
            minHeight: "200px",
            width: "250px",
            maxWidth: "300px",
            maxHeight: "200px",
            borderRadius: "20px",
            marginBottom: "40px",
            objectFit: "cover",
          }}
        />
      </Box>
    </Grid>
  );
};
